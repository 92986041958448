<!-- <div class="total_container">
    <div class="main-container">
        <div class="headPart">
            <app-text-editor-card [data]="data.title"></app-text-editor-card>
        </div>
        <div class="color">
            <div class="link_related">
                <div class="link_and_value">
                    <div class="link_dropdown input_card">
                        <div class="link_label">Type</div>
                        <mat-form-field appearance="outline" class="editor_button">
                            <mat-select [(ngModel)]="data.details.type" (selectionChange)="onTypeChange($event)">
                                <mat-option value="INPUT" class="editor_button_dropdonw">Input</mat-option>
                                <mat-option value="DROPDOWN" class="editor_button_dropdonw">Dropdown</mat-option>
                                <mat-option value="TEXTAREA" class="editor_button_dropdonw">Textarea</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="link_value input_card">
                        <div class="link_label">Hint Value</div>
                        <mat-form-field class="editor_button" appearance="outline">
                            <input matInput [(ngModel)]="data.details.hint"/>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<section class="popup_section">
    <div class="popup_title_close_btn">
        <div class="popup_title">Contact Field</div>
        <div class="close_btn" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="hr_line"></div>
    <div class="popup_bottom_section">
        <div class="field_type_section" (click)="dropdownClick()">
            <div class="image_text_container">
                <div class="image_container">
                    <img [src]="selectedField.img" alt="text_field">
                </div>
                <div class="text_container">
                    <div class="header_text">
                        {{selectedField.title}}
                    </div>
                    <div class="description_text">
                        {{selectedField.description}}
                    </div>
                </div>
            </div>
            <div class="arrow_container">
                <div class="arrow_icons"><mat-icon>keyboard_arrow_down</mat-icon></div>
            </div>
        </div>
        <div class="label_mandatory_option">
            <div class="label">Field Label</div>
            <div class="mandatory_field">
                Mandatory <ng-switchery name="switchery-check" [(ngModel)]="itemData.details.mandatory"></ng-switchery>
            </div>
        </div>
        <div class="editor_text">
            <textarea name="" id="multiliner" class="textarea"
            [(ngModel)]="itemData.title.value" (keyup)="increaseTextAreaHeight($event)"
            [cdkTextareaAutosize]="true" 
            style="max-height: 100px;"></textarea>
        </div>

        <div class="label_text">Field Place holder</div>
        <div class="editor_text">
            <textarea name="" id="multiliner" class="textarea"
            [(ngModel)]="itemData.details.hint" (keyup)="increaseTextAreaHeight($event)"
            [cdkTextareaAutosize]="true" 
            style="max-height: 100px;"></textarea>
        </div>


        <div class="options_values" *ngIf="selectedField.type === 'DROPDOWN'">
            <div class="hr_line"></div>
            <div class="values_title">Options</div>
            <div class="values_hint">Enter multiple options with comma separate</div>
            <div class="values">
                <textarea name="" id="multiliner" class="textarea" (keyup)="enterValues($event)"
                [cdkTextareaAutosize]="true" [(ngModel)]="itemData.options"
                style="max-height: 100px;"></textarea>
            </div>
        </div>
    </div>
    <div class="button_section" *ngIf="!data.edit">
        <button (click)="updateList()">Update List</button>
    </div>
</section>
<section class="dropdown_section" *ngIf="openDropdown">
    <ng-container *ngFor="let item of textTypeArray; let i = index">
        <div class="single_dropdown_item" (click)="selectedItem(item)">
            <div class="image_text_container">
                <div class="image_container">
                    <img [src]="item.img" alt="field_type">
                </div>
                <div class="text_container">
                    <div class="header_text">
                        {{item.title}}
                    </div>
                    <div class="description_text">{{item.description}}</div>
                </div>
            </div>
        </div>
    </ng-container>
</section>
