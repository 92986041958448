<section class="entire-section">
  <div class="header">
    <p class="heading">Button style</p>
    <mat-icon class="cursor-pointer" (click)="closeDialog()">close</mat-icon>
  </div>

  <div class="content-section">
    <div class="tabs">
      <div [ngClass]="{'selected-tab' : selectedTab === 'Theme', 'left-tab-border' : selectedTab === 'Theme'}"
        (click)="changeTabs('Theme')">From theme</div>
      <div [ngClass]="{'selected-tab' : selectedTab === 'Custom', 'right-tab-border' : selectedTab === 'Custom'}"
        (click)="changeTabs('Custom')">Custom</div>
    </div>

    <div class="mt-20" *ngIf="selectedTab === 'Theme'">
      <label class="input-label">Style</label>
      <mat-form-field appearance="outline" style="width: 100%;height: 36px;">
        <mat-select [(ngModel)]="selectedStyle">
          <ng-container *ngFor="let style of styles">
            <mat-option (click)="changeButtonStyle(style)" [value]="style.name">{{style.name}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="mt-20 display" *ngIf="selectedTab === 'Custom'">
      <div>
        <label class="input-label">Type</label>
        <mat-form-field appearance="outline" style="width: 100%;height: 36px;">
          <mat-select [(ngModel)]="buttonStyle.type" (ngModelChange)="changeButtonStle()">
            <ng-container *ngFor="let style of type">
              <mat-option [value]="style.value">{{style.value}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <label class="input-label">Shape</label>
        <mat-form-field appearance="outline" style="width: 100%;height: 36px;">
          <mat-select [(ngModel)]="buttonStyle.shape" (ngModelChange)="changeButtonStle()">
            <ng-container *ngFor="let style of shape">
              <mat-option [value]="style.value">{{style.value}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="preview">
      <label class="input-label">Preview</label>
      <Button class="btn-style" [ngClass]="{'outline-btn': buttonStyle.type === 'Outline', 'solid-btn': buttonStyle.type === 'Solid'
      ,'round-btn': buttonStyle.shape === 'Round', 'square-btn': buttonStyle.shape === 'Square'}">{{selectedTab === 'Custom' ? 'Custom' : (buttonStyle.shape === 'Round' && buttonStyle.type === 'Outline') ? 'Style 1' : 'Style 2'}}</Button>
    </div>
  </div>
</section>
