<div>
  <p class="heading mb-4">Rounded corners</p>
  <p class="desc">Choose the size of rounded corners for your website.</p>
  <mat-form-field appearance="outline" style="width: 100%;">
    <mat-select [(ngModel)]="selectedCorners" (ngModelChange)="changeCorners()">
      <ng-container *ngFor="let item of corners">
        <mat-option [value]="item.type">{{item.type}}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>
<hr class="line">
<div>
  <p class="heading mb-4">Animations</p>
  <p class="desc">Choose an animation style for how website elements appear</p>
  <mat-form-field appearance="outline" style="width: 100%;">
    <mat-select [(ngModel)]="selectedAnimationType" (ngModelChange)="changeAnimationType()">
      <ng-container *ngFor="let item of animationType">
        <mat-option [value]="item.type">{{item.type | titlecase}}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <div class="animation-speed" *ngIf="selectedAnimationType != 'none'">
    <p class="heading">Speed</p>
    <div class="buttons w-fit">
      <div *ngFor="let speed of animationSpeed;let i = index" (click)="changeAnimationSpeed(speed.type)"
        [ngClass]="{'selected-tab' : speed.type === selectedAnimationSpeed, 'left-tab': i == 0, 'right-tab' : i == animationSpeed.length - 1}">
        {{speed.type | titlecase}}
      </div>
    </div>
  </div>
</div>
