<section class="body_section">
    <div class="left_section">
        <div class="left_section_title">
            Contact Us
        </div>
        <div class="left_section_sub_title">
            Tell us how we can help and we’ll get in touch shortly.
        </div>
        <div class="left_section_bottom">
            <div class="icon_name">
                <div class="icon">
                    <img src="./../../../../assets/images/contact_us/whats_app.svg" alt="whatsapp">
                </div>
                <div class="name">
                    <a href="https://wa.me/8801602414" target="_blank">8801602414</a>
                </div>
            </div>
            <div class="icon_name">
                <div class="icon">
                    <img src="./../../../../assets/images/contact_us/mobile_icon.svg" alt="mobile_icon">
                </div>
                <div class="name">
                    <a href="tel:+918801602414" target="_blank">+91-8801602414</a>
                </div>
            </div>
            <!-- <div class="icon_name">
                <div class="icon">
                    <img src="././../../../../assets/images/contact_us/mail.svg" alt="mail_icon">
                </div>
                <div class="name">
                    <a href="mailto:sandeep@simpo.ai" target="_blank">sandeep@simpo.ai - <span style="color:#b6b6b6;font-size:14px">For Sales & Marketing</span></a>
                </div>
            </div> -->
            <div class="icon_name">
                <div class="icon">
                    <img src="././../../../../assets/images/contact_us/mail.svg" alt="mail_icon">
                </div>
                <div class="name">
                    <a href="mailto:hello@simpo.ai" target="_blank">hello@simpo.ai - <span style="color:#b6b6b6;font-size:14px">For Support</span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="right_section">
        <div class="box_section">
            <div class="input_card">
                <div class="label_name">Full Name<sup>*</sup></div>
                <mat-form-field class="sfull_width" appearance="outline">
                    <input matInput placeholder="Enter full name" [(ngModel)]="fullName"/>
                </mat-form-field>
            </div>
            <div class="input_container input_card">
                <div class="label_name">Email Address<sup>*</sup></div>
                <mat-form-field class="sfull_width" appearance="outline">
                    <input matInput placeholder="Enter email address" [(ngModel)]="mailId"  #emailCheck="ngModel" type="email" required/>
            <mat-error *ngIf="!emailCheck.valid && (emailCheck.touched || emailCheck.dirty)">
                  Please Enter Your Email
                </mat-error>
                </mat-form-field>
            </div>
            <div class="input_container input_card">
                <div class="label_name">Mobile Number<sup>*</sup></div>
                <mat-form-field class="sfull_width" appearance="outline">
                    <input matInput placeholder="Enter mobile number" type="number" maxlength="2" [(ngModel)]="mobileNumber"  #mobileCheck="ngModel"
                    pattern="^[6-9]\d{9}$|^[1-9]\d{9}$" onKeyPress="if(this.value.length==15) return false;" type="number"
                 />
                    <mat-error
                    *ngIf="!leadMobile.valid && (leadMobile.dirty || leadMobile.touched) ">
                        Please Enter Mobile Number
                </mat-error>
                </mat-form-field>
            </div>
            <div class="input_container">
                <div class="label_name">How can we help</div>
                <textarea rows="6" cols="50" placeholder="Tell us more about how we can help your business" [(ngModel)]="message"></textarea>
            </div>
            <div class="btn_section">
                <button (click)="submit()"  [disabled]="fullName === '' || mobileNumber === '' || (mobileNumber.toString().length < 8) || mailId === ''">
                    <span *ngIf="contactBtnLoader"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
                    <span *ngIf="!contactBtnLoader">Submit</span>
                </button>
            </div>
        </div>
    </div>
</section>