<div class="share section" *ngIf="screenWidth > 475">
  <div class="header-website">
    <div class="header-text">Share Website</div>
    <div class="close-image" (click)="closeDilog()">
      <img src="./../../../assets/images/close.svg" alt="close-img" />
    </div>
  </div>
  <hr />
  <div class="social_url">
    <div class="twitter" (click)="share('twitter')">
      <img src="./../../../assets/images/social/twitter.svg" alt="twitter" />
    </div>
    <div class="facebook" (click)="share('facebook')">
      <img src="./../../../assets/images/social/facebook.svg" alt="facebook" />
    </div>
  </div>
  <div class="url-container">
    <div class="url-display">
      {{ webSiteUrl }}
    </div>
    <div class="clipboard" (click)="clipboardCopy()" *ngIf="!clipboard">
      <mat-icon>file_copy</mat-icon>
    </div>
    <div class="clipboard" *ngIf="clipboard">
      <img src="./../../../assets/images/common/copied_link.svg" alt="" />
    </div>
  </div>
  <hr *ngIf="screenWidth > 475" />
  <div class="bottom-section" *ngIf="screenWidth > 475">
    <div class="close-button">
      <button (click)="closeDilog()">Close</button>
    </div>
  </div>
</div>
<div *ngIf="screenWidth <= 475">
  <div class="heading_section">
    <div class="heading">
        Share Website
    </div>
    <div class="closing_bar" (click)="closeBottomSheet()"><mat-icon>close</mat-icon></div>
  </div>
  <div class="hr_line"></div>
  <div style="display: flex;gap: 25px;margin-top: 30px;">
    <div class="twitter" (click)="share('twitter')">
      <img src="./../../../assets/images/social/twitter.svg" alt="twitter" />
    </div>
    <div class="facebook" (click)="share('facebook')">
      <img src="./../../../assets/images/social/facebook.svg" alt="facebook" />
    </div>
  </div>
  <div class="url-container" style="margin-top: 25px;position: relative;background-color: #E6F5FF;margin-bottom: 15px;">
    <div style="width: 85%;color: black;font-weight: bold;">
      {{ webSiteUrl }}
    </div>
    <div class="clipboard" style="position: absolute;right: 25px;width: auto;" (click)="clipboardCopy()" *ngIf="!clipboard">
      <mat-icon>file_copy</mat-icon>
    </div>
    <div class="clipboard" *ngIf="clipboard">
      <img src="./../../../assets/images/common/copied_link.svg" alt="" />
    </div>
  </div>
</div>
