import { Component, HostListener, Input, OnInit, ChangeDetectorRef, EventEmitter, Output} from '@angular/core';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { TemplateService } from 'src/app/services/template.service';
import { DynamicItemPopupComponent } from '../dynamic-item-popup/dynamic-item-popup.component';
import { NavbarEditorCardComponent } from '../navbar-editor-card/navbar-editor-card.component';
import { ContactusDetailsEditCardComponent } from '../contactus-details-edit-card/contactus-details-edit-card.component';
import { ContactusEditorCardComponent } from '../contact-us-card/contactus-editor-card.component';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { duration } from 'moment';

@Component({
  selector: 'app-editor-right-side-panel',
  templateUrl: './editor-right-side-panel.component.html',
  styleUrls: ['./editor-right-side-panel.component.scss','./mq-editor-right-side-panel.component.scss']
})

export class EditorRightSidePanelComponent implements OnInit{

  @Input() totalWebsiteData :any;
  @Input() allPages : any;
  @Input() samePageComponents:any;
  // @Input() removeTopNavbar: boolean = true;
  @Output() deleteComponentData = new EventEmitter<any>();
  data: any = null;

  pageLevelEditor : boolean  = true;
  pageLevelData : any = {};

  constructor(
    private _eventHelper : EventEmmiterService,
    private _tempalteService : TemplateService,
    private _snackbar : MatSnackBar,
    private cdr: ChangeDetectorRef
  ){
    this.getScreenSize();
  }

  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
    // console.log(this.screenWidth);
  }

  componentData : any;
  componentIndex : any;
  businessDetails :any;
  dynamicItemSelectedIndex = 0;
  isContactUsSection =false;
  isNavbarSection : boolean = false;
  metaTitle : any = '';
  metaDescription : any = '';
  keywords : any = '';
  gettingPageLevelEditSubscribe : any ;
  sendingPageLevelEditSubscribe : any ;
  editSectionSubscribe : any ;
  replicaComponentData : any;

  ngOnDestroy(){
    if(this.gettingPageLevelEditSubscribe){
      this.gettingPageLevelEditSubscribe.unsubscribe();
    }
    if(this.sendingPageLevelEditSubscribe){
      this.sendingPageLevelEditSubscribe.unsubscribe();
    }
    if(this.editSectionSubscribe){
      this.editSectionSubscribe.unsubscribe();
    }
  }
  animationType = [
    {
      key : 'Top',
      value : 'Top'
    },
    {
      key : 'Bottom',
      value : 'Bottom'
    },
    {
      key : 'Left',
      value : 'Left'
    },
    {
      key : 'Right',
      value : 'Right'
    },
    {
      key : 'FadeIn',
      value : 'FadeIn'
    },
    {
      key : 'Zoom',
      value : 'Zoom'
    }
  ]

  animationSpeed = [
    {
      key : 'Slow',
      value : 'Slow'
    },
    {
      key : 'Medium',
      value : 'Medium'
    },
    {
      key : 'Fast',
      value : 'Fast'
  }
 ]
  // gettingLoader : boolean = true;
  ngOnInit(): void {
    this.checkingLinks(this.dynamicItemSelectedIndex);
    this.getPageLevelEdit();
    let bDetails = window.localStorage.getItem('bDetails');
    if(bDetails) {
      this.businessDetails = JSON.parse(bDetails);
    }
    this.editSectionSubscribe = this._eventHelper.editSection.subscribe(
      (res:any) => {
        if(!res.data){
          return
        }
        this.pageLevelEditor = res?.pageLevelEditor;
        this.componentIndex = res.index;
        this.componentData = res.data;

        this.replicaComponentData = JSON.parse(JSON.stringify(res.data));
        // console.log(res);
        if(this.componentData.category.name.toUpperCase().indexOf("CONTACT") !== -1) {
          this.isContactUsSection = true;
          this.isNavbarSection = false;

          //checking status for locations in contactus form
          if(this.componentData.styles['locationTitle'].hasOwnProperty(status)){}
          else{
            this.componentData.styles['locationTitle'].status = true
          }

          //checking status for contact details in contact us form
          if(this.componentData.styles['contactTitle'].hasOwnProperty(status)){}
          else{
            this.componentData.styles['contactTitle'].status = true
          }

          //checking status for mail related stuff in contact us form
          if(this.componentData.styles['mailTitle'].hasOwnProperty(status)){}
          else{
            this.componentData.styles['mailTitle'].status = true;
          }

        }
        else if(this.componentData.category.name.toUpperCase().indexOf("TOP NAV BAR") !== -1){
          // console.log('section');
          this.isNavbarSection = true;
          this.isContactUsSection = false;
        }
        else{
          this.isNavbarSection = false;
          this.isContactUsSection = false;
          this.dynamicItemSelectedIndex = 0;
        }
      }
    )

  }

  //checking for page level edit
  getPageLevelEdit(){
   this.gettingPageLevelEditSubscribe =  this._eventHelper.pageLevelEdit.subscribe(
      (res) => {
        this.pageLevelEditor = res.status;
        if(this.pageLevelEditor){
          this.getPageLevelData();
        }
      }
    )
  }

  //getting page level data
  getPageLevelData(){
    this.sendingPageLevelEditSubscribe = this._eventHelper.sendingPageData.subscribe(
      (res) =>{
         res.data;
        this.pageLevelData = res.data;
        // console.log(this.pageLevelData)
        // if(this.pageLevelData.seoData != null){
          this.metaTitle = this.pageLevelData.seoData != null ? this.pageLevelData.seoData.metaTitle : '';
          this.metaDescription = this.pageLevelData.seoData != null ? this.pageLevelData.seoData.metaDescription : '';
          this.keywords = this.pageLevelData.seoData.keywords != null ? this.pageLevelData.seoData.keywords : '';
        // }
        // console.log(this.pageLevelData);
      }
    )
  }

  //checking text type

  returnValue : any;
  getValue(item){
    if(item != null){
      if((item['lm_type']?.split('_')[0]) === 'TEXT'){
        return this.returnValue = 'text';
      }
      else if ((item['lm_type']?.split('_')[0]) === 'BUTTON'){
        return this.returnValue = 'button';
      }
      else if((item['lm_type']?.split('_')[0]) === 'attachment'){
        return this.returnValue = 'attachment';
      }
      else if ((item['lm_type'] === 'RATING')){
        return this.returnValue = 'RATING'
      }
      else if(item['lm_type']==='animation'){
        return this.returnValue = 'animation'
      } else if(item['lm_type']==='background_attachment'){
        return this.returnValue = 'background_attachment';
      }
      else{
        return this.returnValue = ''
      }
      return this.returnValue;
    }
  }


  //get item styles types

  // returnItemStylesValue : any ;
  // imgUrl : any;
  // getItemStylesValues(key){
  //   if((key['lm_type']?.split('_')[0]) === 'attachment'){
  //     this.returnItemStylesValue = 'image';
  //     // this.imgUrl = value.attachment;
  //   }
  // }

  //this for orginal object structure from json(presnt coming like ascending order)
  getOriginalObjectStructure(){
    return 0;
  }

  savePageData(){
    let data = {
      id : this.businessDetails.templateId,
      components : this.totalWebsiteData.components
    }
    this._tempalteService.updateWebsiteData(data).subscribe(
      (res:any) => {
        // console.log(res.data);
      },
      (err) => {
        // console.log(err.error.message)
      }
    )
    // console.log(this.totalWebsiteData);
  }


  // dynamic values related

  //checking links for dynamic items
  checkingLinks(index){
    if(this.componentData?.itemStyles != null || this.componentData != undefined){
      let dynamicItems = this.componentData.itemStyles[index];
      Object.keys(dynamicItems).forEach( key => {
        if(this.componentData.itemStyles[this.dynamicItemSelectedIndex][key]['type']){
          this.buttonLinks.forEach(btl => {
            if(btl.value === this.componentData.itemStyles[this.dynamicItemSelectedIndex][key]['type']){
              this.buttonRedirectionValue = btl.value
            }
          });
          if(this.buttonRedirectionValue === 'INTERNAL'){
            this.internalPageRedirectionValue = this.componentData.itemStyles[index].links.url;
          }
          else if(this.buttonRedirectionValue === 'EXTERNAL'){
            this.externalPageRedirectionValue = this.componentData.itemStyles[index].links.url;
          }
          else{
            this.samePageRedirectionValue = this.componentData.itemStyles[this.dynamicItemSelectedIndex].links.url
          }
        }
        else{
          this.buttonRedirectionValue = '';
          this.internalPageRedirectionValue = '';
          this.samePageRedirectionValue = '';
          this.externalPageRedirectionValue = '';
        }
      })
    }
  }
  dynamicSelectionChange(ev) {
    this.dynamicItemSelectedIndex = ev.value;
    this.checkingLinks(this.dynamicItemSelectedIndex);
    this._eventHelper.showSaveOption.emit({data:true});
  }

  addNewItem() {
    // let newItem = Object.assign({}, this.componentData.itemStyles[0]);
    // this.componentData.itemStyles.push(newItem);
    let newItem = JSON.parse(JSON.stringify(this.componentData.itemStyles[0]));
    // this.componentData.itemStyles.push(newItem)
    // this.dynamicItemSelectedIndex = this.componentData.itemStyles.length-1;
    let editPopupModel = this._tempalteService.openDialog(
      DynamicItemPopupComponent,
      '85vh',
      '460px',
      {newItem,edit:false}
    )
    editPopupModel.afterClosed().subscribe(
      (res:any) => {
        if(res.data != null){
          this.componentData.itemStyles.push(res.data);
          this._eventHelper.showSaveOption.emit({data:true});
        }
      }
    )
  }

  buttonLinks : any = [
    {
      name:'external link',
      value:'EXTERNAL'
    },
    {
      name:'internal link',
      value:'INTERNAL'
    },
    {
      name:'same page',
      value:'SAME_PAGE'
    }
  ]

  buttonRedirectionValue : any ='';
  internalPageRedirectionValue : any = '';
  samePageRedirectionValue : any = '';
  externalPageRedirectionValue : any = '';

  links : any = {
    type:'',
    url:''
  }

  buttonRedirections(ev){
    this.componentData.itemStyles[this.dynamicItemSelectedIndex].links = this.links
    this.componentData.itemStyles[this.dynamicItemSelectedIndex].links.type = ev;
    if(ev === 'INTERNAL'){
      this.componentData.itemStyles[this.dynamicItemSelectedIndex].links.url = this.internalPageRedirectionValue;
    }
    else if(ev === 'EXTERNAL'){
      this.componentData.itemStyles[this.dynamicItemSelectedIndex].links.url = this.externalPageRedirectionValue;
    }
    else{
      this.componentData.itemStyles[this.dynamicItemSelectedIndex].links.url = this.samePageRedirectionValue;
    }
  }

  deleteItem(i){
    // this.componentData.itemStyles.splice(this.dynamicItemSelectedIndex,1);
    // this.dynamicItemSelectedIndex = this.componentData.itemStyles.length-1
    // console.log(i)
    // console.log(this.componentData)
    if(((this.componentData.category.name).split(' ').join('_')).toLowerCase() === 'footer' || ((this.componentData.category.name).split(' ').join('_')).toLowerCase() === 'top_nav_bar'){
      this.componentData.itemStyles.splice(i,1)
    }
    else{
      if(this.componentData.itemStyles.length === 1){
        this._snackbar.open(
          "You can't able to delete all items",
          "Ok",
          {
            duration:1500
          }
        )
      }
      else{
        this.componentData.itemStyles.splice(i,1)
      }
    }
    this._eventHelper.showSaveOption.emit({data:true});
  }


  //contactus
  deleteContactField(ev,i){
    if(this.componentData.itemStyles.length === 1){
      this._snackbar.open(
        "You can't able to delete all items",
        "Ok",
        {
          duration:1500
        }
      )
    }
    else{
      this.componentData.itemStyles.splice(i,1)
    }
    this._eventHelper.showSaveOption.emit({data:true});
  }

  addContactField(){
    // let newItem = Object.assign({}, this.componentData.itemStyles[0]);
    // this.componentData.itemStyles.push(newItem);
    // console.log(this.componentData.itemStyles)
    let a = JSON.parse(JSON.stringify(this.componentData.itemStyles[0]));
    this.componentData.itemStyles.push(a);
    this._eventHelper.showSaveOption.emit({data:true});
  }

  updateMetaTitle(){
    this.pageLevelData.seoData.metaTitle = this.metaTitle;
    this._eventHelper.showSaveOption.emit({data:true});
  }
  updateMetaDescription(){
    this.pageLevelData.seoData.metaDescription = this.metaDescription;
    this._eventHelper.showSaveOption.emit({data:true});
  }
  updateKeywords(){
    this.pageLevelData.seoData.keywords = this.keywords;
    this._eventHelper.showSaveOption.emit({data:true});
  }

  editDynamicItem(item){
    let editPopupModel = this._tempalteService.openDialog(
      DynamicItemPopupComponent,
      '85vh',
      '460px',
      {item,edit:true,samePageComponents : this.samePageComponents , componentData:this.componentData}
    )
  }

  editNavbarDynamicItem(item){
    let navbarEditModel = this._tempalteService.openDialog(
      NavbarEditorCardComponent,
      'auto',
      '460px',
      {data:item,allPages:this.allPages,samePageComponents:this.samePageComponents,edit:true ,}
    )
  }
  addNewNavbarItem(){
    let a = JSON.parse(JSON.stringify(this.componentData.itemStyles[0].item))
    let navbarEditModel = this._tempalteService.openDialog(
      NavbarEditorCardComponent,
      'auto',
      '460px',
      {data:a,allPages:this.allPages,samePageComponents:this.samePageComponents,edit:false}
    )

    navbarEditModel.afterClosed().subscribe(
      (res:any) => {
        if(res.item != null){
          this.componentData.itemStyles.push(res);
          this._eventHelper.showSaveOption.emit({data:true});
        }
      }
    )
  }

  editLocationDetails(title,value,longitude,latitude){
    let contactUsDetailsModel = this._tempalteService.openDialog(
      ContactusDetailsEditCardComponent,
      '85vh',
      '460px',
      {data:[title,value,longitude,latitude]}
    )
  }

  editContactDetails(title,value){
    let contactUsDetailsModel = this._tempalteService.openDialog(
      ContactusDetailsEditCardComponent,
      'auto',
      '460px',
      {data:[title,value]}
    )
  }

  editContactField(item){
    let contactUsDetailsModel = this._tempalteService.openDialog(
      ContactusEditorCardComponent,
      '530px',
      '525px',
      {item,edit:true}
    )
  }
  addContactItem(){
    let a = JSON.parse(JSON.stringify(this.componentData.itemStyles[0]))
    let contactUsDetailsModel = this._tempalteService.openDialog(
      ContactusEditorCardComponent,
      '530px',
      '525px',
      {a,edit:false}
    )

    contactUsDetailsModel.afterClosed().subscribe(
      (res:any) =>{
        if(res.data != null){
          this.componentData.itemStyles.push(res.data.a);
          this._eventHelper.showSaveOption.emit({data:true});
          // console.log(res.data);
        }
      }
    )
  }
  pageLoader:boolean=false

  // save Changes
  applyChanges(){
    this._eventHelper.saveChanges.emit({saveChanges:true,data:this.componentData,index:this.componentIndex});

    this._snackbar.open("Your changes have saved successfully",'close',{
      duration: 3000
    });
  }

  discardChanges(){
    this._eventHelper.localDiscardChanges.emit({data:this.replicaComponentData,discard:true})
    // if(_.isEqual(this.componentData,this.replicaComponentData)){
    // }
    // else{
    //   this.componentData = this.replicaComponentData;
    //   this._eventHelper.discardChanges.emit({data:this.replicaComponentData})
    // }


      // find keys
      // let keyObj1 = Object.keys(this.componentData);
      // let keyObj2 = Object.keys(this.replicaComponentData);

      // // find values
      // let valueObj1 = Object.values(this.componentData);
      // let valueObj2 = Object.values(this.replicaComponentData);


      // // find max length to iterate
      // if(keyObj1.length > keyObj2.length) {
      //   var biggestKey = keyObj1.length;
      // } else {
      //   var biggestKey = keyObj2.length;
      // }

      // // now compare their keys and values
      // for(var i=0; i<biggestKey; i++) {
      //   if(keyObj1[i] == keyObj2[i] && valueObj1[i] == valueObj2[i]) {
      //   }
      //   else {
      //     // it prints keys have different values
      //     this.componentData = this.replicaComponentData;
      //     this._eventHelper.discardChanges.emit({data:this.replicaComponentData});
      //     this.replicaComponentData = JSON.parse(JSON.stringify(this.componentData));
      //   }
      // }
      // console.log(this.replicaComponentData);
      // console.log(this.componentData)
  }
  close(ev){

  }
  closeDialog(pageName:any, action:String){

  }

  pageNameChange(){
    this._eventHelper.pageNameChange.emit({pageName:this.pageLevelData.pageName});
    this.pageLevelData.path = `/${(this.pageLevelData.pageName.split(' ').join('_')).toLowerCase()}`;
    this._eventHelper.showSaveOption.emit({data:true});
  }

  changeColor(){
    var bgColor = this.componentData.bgColor
    const threshold = 130; // Adjust this threshold as needed
    const r = parseInt(bgColor.slice(1, 3), 16);
    const g = parseInt(bgColor.slice(3, 5), 16);
    const b = parseInt(bgColor.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    var color = brightness > threshold ? '#000000' : '#ffffff';
    for(const key in this.componentData.styles){
      if(this.componentData.styles[key].color &&  !key.includes("button")){
        this.componentData.styles[key].color = color
      }
    }

    if(this.componentData.itemStyles){
      this.componentData.itemStyles.forEach((item:any) => {
        if(item['bgTransparent']){
          Object.keys(item).forEach((key) => {
            if(item[key].color && !key.includes("button")){
                item[key].color = color
            }
          })
        }
      })
    }

    if(this.componentData?.styles['bgImage']?.backgroundColor){
      var rgbaValue = this.componentData?.styles['bgImage']?.backgroundColor;
      var colorStr = rgbaValue.slice(rgbaValue.indexOf('(') + 1, rgbaValue.indexOf(')'));
      var colorArr = colorStr.split(',')
      let opacity = colorArr[3];
      let newrgba = 'rgb' + '(' + r + "," + g + "," + b + "," + opacity +')';
      this.componentData.styles['bgImage'].backgroundColor = newrgba
    }
    this._eventHelper.showSaveOption.emit({data:true});

  }

  openEditor(){

  }

  changeAnimation(){
      this._eventHelper.changeAnimation.emit({index:this.componentIndex,newData:this.componentData});
      this._eventHelper.showSaveOption.emit({data:true});
  }

  toggleBackgrondImage(event){
    this.componentData.styles['option'].bgImage = event
    this.componentData.styles['option'].bgColor = !event;
    this._eventHelper.showSaveOption.emit({data:true});
  }

  // showSaveTab : boolean = false;
  // ngDoCheck(){
  //   // console.log(this.cdr)
  //   if(JSON.stringify(this.componentData) == JSON.stringify(this.replicaComponentData)){
  //     // console.log('no changes detected');
  //     this.showSaveTab = false;
  //   }
  //   else{
  //     // console.log('changes detected');
  //     this.showSaveTab = true;
  //     return;
  //   }
  // }
}
