import { Component , Inject, Optional, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BlogServiceService } from '../../blog-service.service';
import moment from 'moment';

@Component({
  selector: 'app-add-tags',
  templateUrl: './add-tags.component.html',
  styleUrls: ['./add-tags.component.scss']
})
export class AddTagsComponent {

  constructor(
    private dialogRef: MatDialogRef<AddTagsComponent>,
    private _bottomSheetRef: MatBottomSheetRef<AddTagsComponent>,
    @Optional()   @Inject(MAT_DIALOG_DATA) public data,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
    public blogService:BlogServiceService
  ){
    this.getScreenSize()
  }

  screenWidth: any;

  @HostListener("window:resize",["$event"])
  getScreenSize(){
    this.screenWidth = window.innerWidth;
  }

  enterTag: string ='';
  enterSlug: string = '';
  enterDescription: string ='';
  imgFile: string ='';
  edit:boolean =  this.data?.edit;

  isLoading: boolean = false;

  ngOnInit() {
    // this.edit = this.screenWidth > 475 ? this.data?.edit || false: this.bottomSheetData?.edit || false

    // if(this.screenWidth > 475 ? this.data.edit : this.bottomSheetData.edit){
    //   this.enterTag= this.screenWidth > 475 ? this.data.data.tagName : this.bottomSheetData.data.tagName;
    //   this.enterDescription = this.screenWidth > 475 ? this.data.data.description : this.bottomSheetData.data.description;

    // }
    // if(this.screenWidth > 475 ? (this.data.type == 'EDITTAG' && this.data.edit) : (this.bottomSheetData.type == 'EDITTAG' && this.bottomSheetData.edit)){
    //   this.enterTag = this.screenWidth > 475 ? this.data.data.blogTag.tagName : this.bottomSheetData.data.blogTag.tagName;
    //   this.enterDescription = this.screenWidth > 475 ? this.data.data.blogTag.description : this.bottomSheetData.data.blogTag.description;
    // }

    if(this.data.edit){
      this.enterTag=this.data.data.tagName;
      this.enterDescription=this.data.data.description;

    }
    if(this.data.type == 'EDITTAG' && this.data.edit){
      this.enterTag = this.data.data.blogTag.tagName;
      this.enterDescription = this.data.data.blogTag.description;
    }

  }

  createBusiness(){
   this.isLoading = true;

   let data= {
      tagName: this.enterTag,
      description: this.enterDescription,
      createdTimeStamp: moment(new Date()).format(),
      blogCount: 0,
      createdById: '',
      createdByName: "",
      businessId: JSON.parse(window.localStorage.getItem("bDetails")||"").id
    }

    data.createdById = localStorage.getItem('staffId') || '';
    data.createdByName = localStorage.getItem('staffName') || '';

    this.blogService.createBlogTags(data).subscribe({
      next:(res:any)=>{
          this.blogService.openSnack("Tag created sucessfully","Close");
          this.dialogRef.close("success");
      },
      error:(err)=>{
        this.isLoading = false;
        this.blogService.openSnack(err.error.message,"close");
      }
    })
  }

  editBusiness(){
    this.isLoading = true;

    let data= {
      id:this.data.data.blogTag.id,
      tagName: this.enterTag,
      description: this.enterDescription,
      createdTimeStamp: moment(new Date()).format(),
      blogCount: this.data.data.blogCount,
      createdById: this.data.data.createdById,
      createdByName: this.data.data.createdByName,
      businessId: JSON.parse(window.localStorage.getItem("bDetails")||"").id
    }

    data.createdById = localStorage.getItem('staffId') || '';
    data.createdByName = localStorage.getItem('staffName') || '';

    this.blogService.updateBlogTag(data).subscribe({
      next:(value:any)=>{
        this.blogService.openSnack("Tag updated sucessfully","Close");
        this.dialogRef.close()
      },
      error:(err)=>{
        this.isLoading = false;
        this.blogService.openSnack("Something went wrong, please try again", "Close");
      },
    })
  }

  closeBtn(){
    this.dialogRef.close();
  }
}
