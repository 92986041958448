import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonStyleModel, EventsService } from 'simpo-component-library';

@Component({
  selector: 'app-button-style-editor',
  templateUrl: './button-style-editor.component.html',
  styleUrls: ['./button-style-editor.component.scss']
})
export class ButtonStyleEditorComponent {
  constructor(public dialogRef: MatDialogRef<ButtonStyleEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventService: EventsService){}

    selectedTab : 'Theme' | 'Custom' = "Theme";
    buttonStyle!: ButtonStyleModel;
    id! : string;
    btnId!: string;

    ngOnInit() {
      this.buttonStyle = this.data?.buttonStyle;
      this.id = this.data?.id;
      this.btnId = this.data?.btnId
      this.selectedStyle = (this.buttonStyle.shape === 'Round' && this.buttonStyle.type === 'Outline') ? 'Style 1' : 'Style 2'
    }

    closeDialog(){
      this.dialogRef.close();
    }

    changeTabs(selectedTab: 'Theme' | 'Custom'){
      this.selectedTab = selectedTab;

    }

    styles = [
      {
        name: 'Style 1',
        type: 'Outline',
        shape: 'Round'
      },
      {
        name: 'Style 2',
        type: 'Solid',
        shape: 'Round'
      }
    ]

    selectedStyle: string = 'Style 1'
    changeButtonStyle(style: any){
      this.buttonStyle.shape = style.shape;
      this.buttonStyle.type = style.type;

      this.eventService.buttonStyleChangeChecks.emit({id: this.id+this.btnId, buttonStyle: this.buttonStyle, color: this.data.color});
      this.selectedStyle = style.name;
    }

    type = [
      {
        value: 'Solid'
      },
      {
        value: 'Outline'
      }
    ]

    shape = [
      {
        value: 'Round'
      },
      {
        value: 'Square'
      }
    ]

    changeButtonStle(){
      this.eventService.buttonStyleChangeChecks.emit({id: this.id+this.btnId, buttonStyle: this.buttonStyle, color: this.data.color});
    }
}
