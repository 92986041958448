<section class="main_section">
    <div class="headPart">
        <div class="title">
            <div class="mega_title">Logo</div>
            <div class="sub_title">Replace company name with a logo</div>
        </div>
        <div class="display_section switch_toggle_btn">
            <!-- <ng-switchery name="switchery-check" [(ngModel)]="data.status"  
            (ngModelChange)="changeButtonStatus($event)"></ng-switchery> -->
            <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedLogoStatus">
                <!-- <mat-radio-button value="1" (change)="changeButtonStatus('Logo')">Logo</mat-radio-button>
                <mat-radio-button value="2" (change)="changeButtonStatus('Heading')">Text Logo</mat-radio-button>
                <mat-radio-button value="2" (change)="changeButtonStatus('Both')">Both</mat-radio-button> -->
                <ng-container *ngFor="let item of logoStatus">
                    <mat-radio-button [value]="item.value" (change)="changeButtonStatus(item.value)">{{item.name | titlecase}}</mat-radio-button>
                </ng-container>
              </mat-radio-group>
        </div>
    </div>
</section>
<section class="main_section" *ngIf="data.status">
    <ng-container *ngIf="!pageLoader">
    
    <div class="inside-div" *ngIf="data.attachment ==='';else showImage">
        <div class="upper-div">
                <div class="upload-icon">
                    <img src="./../../../../../../../../../assets/images/editor/uploadIcons.svg" alt="upload-icon">
                </div>
                <div class="sub-image-text">
                <span class="text-color">Click to upload</span> or drag and drop PNG or JPG (atleast. 32x32px)
                </div>
        </div> 
        <input id="vendorImage" type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" class="browse-btn" id="browse_img"/>
    </div>
    <ng-template #showImage>
        <div class="image_fill">
            <img [src]="data.attachment" [alt]="data.altName">
            <div class="show-reUpload">
                <div class="updateImage" (click)="addImage(data)">
                    Change Image
                </div>
                <input id="vendorImage" type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" class="browse-btn display_image" id="browse_img"
                (change)="updateImage($event)"/>
            </div>
        </div>
    </ng-template>
</ng-container>
    <div class="scrolling_bar">
        <div class="bar_title">Logo Size</div>
        <div class="bar">
            <mat-slider (input)="test($event)"
            min="1" max="130">
            <input matSliderThumb [(ngModel)]="sliderValue" #slider>
            </mat-slider>
        </div>
    </div>
</section>
<div class="hr_line"></div>

<section class="loader_section" *ngIf="pageLoader">
    <div class="api_loader">
        <div class="dots-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
</section>