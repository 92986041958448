import { Component, OnInit } from '@angular/core';
import { StorageKeys } from './shared-module-files/simpo.constant';
import { StaffServiceService } from './master-config-components/micro-apps/staff/service/staff-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  constructor(private staffService: StaffServiceService){}


  ngOnInit(): void {
    this.getStaffPermission()
    localStorage.setItem("REQUEST_FROM", "ECOMMERCE");
  }
  title = 'beeos-website-generator';

  getStaffPermission(){
    let staffId = localStorage.getItem(StorageKeys.STAFF_ID);
    let businessId = localStorage.getItem("businessId");

    if(staffId && businessId) {
      this.staffService.getStaffPermissionByStaffId(staffId, businessId).subscribe((res: any) => {
        localStorage.setItem(StorageKeys.PERMISSIONS, JSON.stringify(res.data));
      })
    }
  }
}
