<section class="main-section">
    <div class="left-section" (click)="redirectToHome()">
        <img src="./../../../../assets/images/footer/logo_image.svg" alt="simpo_logo" />
    </div>
    <div class="middle-section">
        <div class="middle-left-side">
            <div class="middle-ui">Resources</div>
            <div class="middle-li" (click)="pricing()">Pricing</div>
            <div class="middle-li" (click)="contact()">Contact Us</div>
            <!-- <div class="middle-li">Blogs</div> -->
        </div>
        <div class="middle-right-side">
            <div class="middle-ui">Company</div>
            <!-- <div class="middle-li">About Us</div> -->
            <div class="middle-li" (click)="termsConditions()">Terms & Conditions</div>
            <div class="middle-li" (click)="privacyPolicy()">Privacy Policy</div>
        </div>
    </div>
    <div class="right-section">
        <div class="linkedin_logo">
            <img src="./../../../../assets/images/footer/linked-in.svg" alt="linkedin_logo"
            (click)="socialLinks('LINKED_IN')">
        </div>
        <div class="instagram_logo">
            <img src="./../../../../assets/images/footer/instagram_logo.svg" alt="instagram_logo"
            (click)="socialLinks('INSTA')" />
        </div>
        <div class="twitter_logo">
            <img src="./../../../../assets/images/footer/twitter_logo.svg" alt="twitter_logo" 
            (click)="socialLinks('TWITTER')"/>
        </div>
        <div class="facebook_logo">
            <img src="./../../../../assets/images/footer/facebook_logo.svg" alt="facebook_logo" 
            (click)="socialLinks('FACEBOOK')"/>
        </div>
    </div>
</section>