<section *ngIf="!loaderSection">
    <section class="main_section">
        <div class="left_part">
            <div class="logo-left" *ngIf="scrWidth < 475px">
                <img src="./../../../assets/images/named-logo.svg" alt="logo">
            </div>
            <ngx-slick-carousel class="carousel carousel_box" [config]="slideConfig" #slickModal="slick-carousel">
                <div ngxSlickItem class="single_success_story" *ngFor="let item of businessCategoryImages">
                    <div class="person_short_note">
                        <img [src]="item" alt="category_images">
                    </div>
                    <!-- <div class="reviewer-company">
                        <div class="person_name">
                            {{item?.reviewedby?.text}}
                        </div>
                        <div class="person_designation" [id]="item.position.id"
                        [ngStyle]="item.position.textStyles ? item.position.textStyles : {}">
                            &nbsp;{{item?.position?.text}}
                        </div>
                        <div [id]="item.companyName.id"
                        [ngStyle]="item.companyName.textStyles ? item.companyName.textStyles : {}">
                            &nbsp; - {{item?.companyName?.text}}
                        </div>
                    </div> -->
                </div>
            </ngx-slick-carousel>
        </div>
        <div class="right_part">
            <div class="logo" *ngIf="scrWidth > 475px">
                <img src="./../../../assets/images/named-logo.svg" alt="logo">
            </div>
            <div class="bottom_section" *ngIf="!suggestedNamesScreen">
                <div class="back" (click)="backToScreen()">
                    <mat-icon>keyboard_backspace</mat-icon>&nbsp;Back
                </div>
                <div class="title">What’s the name of your business?</div>
                <div class="taking_input">
                    <mat-form-field appearance="outline" class="sfull-width domain-input">
                        <input matInput placeholder="Enter your business name" [(ngModel)]="businessName">
                    </mat-form-field>
                </div>
                <div class="suggest_businessType" *ngIf="suggestedNames && suggestedNames.length > 0">
                    Not Sure? <span style="color: #0496ff;cursor: pointer;font-weight: 500;"
                        (click)="seeSuggestedNames()"> See some suggestions
                        <!-- <img src="./../../../assets/images/common/blub.svg" alt="bulb"></span> -->
                        <img src="../../../assets/images/common/suggestions_bulb.svg" alt="bulb"></span>
                </div>
                <button [disabled]="!businessName || businessName.trim() === ''" class="generate_website" (click)="generateWebsite()">Next</button>
            </div>
            <div class="bottom_section" *ngIf="suggestedNamesScreen">
                <div class="title">What’s the name of your business?</div>
                <div class="some_suggesions">Here are some suggestions</div>
                <div class="suggestions" [ngClass]="{ 'hidden': isHidden }">
                    <ng-container *ngFor="let item of suggestNames">
                        <button (click)="clickedSuggestions(item)">{{ item | titlecase}}</button>
                    </ng-container>
                </div>
                <div class="enter_own_section">
                    <div class="enter_own" (click)="enterOwnBName()">
                        <mat-icon>keyboard_backspace</mat-icon>&nbsp;Enter my own
                    </div>
                    <div class="see_more" (click)="seeMore()">
                        <mat-icon>refresh</mat-icon> &nbsp;See more
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>
<section *ngIf="loaderSection" class="loader">
    <!-- <img src="./../../../assets/images/common/beeos_loader.gif" alt="beeos_loader"> -->
    <app-lottie-loader></app-lottie-loader>
</section>
