<section class="display">
  <p class="heading">Style 1</p>

  <div>
    <label class="input-label">Type</label>
    <mat-form-field appearance="outline" style="width: 100%;height: 36px;">
      <mat-select [(ngModel)]="style1Type" (ngModelChange)="changeStyle1Button()">
        <ng-container *ngFor="let type of buttonType">
          <mat-option  [value]="type.type">{{type.type}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

  </div>

  <div>
    <label class="input-label">Shape</label>
    <mat-form-field appearance="outline" style="width: 100%;height: 36px;">
      <mat-select [(ngModel)]="style1Shape" (ngModelChange)="changeStyle1Button()">
        <ng-container *ngFor="let shape of buttonShape">
          <mat-option [value]="shape.type">{{shape.type}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

  </div>

  <div class="preview">
    <label class="input-label">Preview</label>
    <Button class="btn-style" [ngClass]="{'outline-btn': style1Type === 'Outline', 'solid-btn': style1Type === 'Solid'
    ,'round-btn': style1Shape === 'Round', 'square-btn': style1Shape === 'Square'}">Style 1</Button>
  </div>

</section>

<hr class="line">

<section class="display">
  <p class="heading">Style 2</p>

  <div>
    <label class="input-label">Type</label>
    <mat-form-field appearance="outline" style="width: 100%;height: 36px;">
      <mat-select [(ngModel)]="style2Type" (ngModelChange)="changeStyle2Button()">
        <ng-container *ngFor="let type of buttonType">
          <mat-option [value]="type.type">{{type.type}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

  </div>

  <div>
    <label class="input-label">Shape</label>
    <mat-form-field appearance="outline" style="width: 100%;height: 36px;">
      <mat-select [(ngModel)]="style2Shape" (ngModelChange)="changeStyle2Button()">
        <ng-container *ngFor="let shape of buttonShape">
          <mat-option [value]="shape.type">{{shape.type}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

  </div>


  <div class="preview">
    <label class="input-label">Preview</label>
    <Button class="btn-style" [ngClass]="{'outline-btn': style2Type === 'Outline', 'solid-btn': style2Type === 'Solid'
    ,'round-btn': style2Shape === 'Round', 'square-btn': style2Shape === 'Square'}">Style 2</Button>
  </div>
</section>
