import { Component, HostListener, Inject, OnInit, OnDestroy, Optional } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlogServiceService } from '../blog-service.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Subscription } from 'rxjs';
import { Author, Blog, BlogType, Blog_Cachekeys, Tag } from '../blog';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import moment from 'moment';

enum CREATE_BLOG_SECTION {
  "MAIN"="MAIN",
  "META"="META"
}

@Component({
  selector: 'app-meta-tags-popup',
  templateUrl: './meta-tags-popup.component.html',
  styleUrls: ['./meta-tags-popup.component.scss']
})
export class MetaTagsPopupComponent implements OnInit, OnDestroy {

  constructor(
    private dialogRef: MatDialogRef<MetaTagsPopupComponent>,
    private _bottomSheetRef: MatBottomSheetRef<MetaTagsPopupComponent>,
    @Optional()   @Inject(MAT_DIALOG_DATA) public data,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
    private blogService: BlogServiceService,
  ) {
    this.getScreenSize();
    if (this.scrWidth > 475) {
      this.dialogRef.updatePosition({ top: '90px', right: '10px' });
    }
    // else {
    //   this.dialogRef.updatePosition({ top: '120px', right: '50px' });
    // }
  }

  ngOnInit() {
    if(this.scrWidth > 475){
      this.data = this.data
    }
    if(this.scrWidth < 475){
      this.data = this.bottomSheetData
    }
    console.log(this.blog)
    this.blog = this.blogService.getBlogData();

    let businessDetails: any = window.localStorage.getItem('bDetails');
    businessDetails = businessDetails ? JSON.parse(businessDetails) : null;

    if (businessDetails) {
      this.businessWebsiteUrl = businessDetails.websiteUrl;
    }

    this.fillInitialData();

    this.metaForm.setValue({
      metaTitle: this.blog.blogMeta.metaTitle,
      metaDescription: this.blog.blogMeta.metaDescription.content
    })

    this.subscriptions.add(
      this.tagControl.valueChanges.subscribe({
        next: (value: string | null) => {
          if (value) {
            this.handleSearchForTags();
          }
        }
      })
    );

    this.subscriptions.add(
      this.authorControl.valueChanges.subscribe({
        next: (value: string | null) => {
          if (value) {
            this.handleSearchForAuthors();
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  scrWidth: number=0;
  businessWebsiteUrl: string = '';

  private subscriptions: Subscription = new Subscription();

  currentBlogSection: CREATE_BLOG_SECTION = CREATE_BLOG_SECTION.MAIN;

  blog: Blog = new Blog();

  separatorKeysCodes: number[] = [ENTER, COMMA];  // For tags or author chips
  tagControl: FormControl = new FormControl<string>('');
  filteredTags: Tag[] = [];
  tags: string[] = [];
  showAddTagOption: boolean = false;

  authorControl: FormControl = new FormControl<string>('');
  filteredAuthors: Author[] = [];
  authors: string[] = [];
  showAddAuthorOption: boolean = false;

  metaForm: FormGroup = new FormGroup({
    metaTitle: new FormControl(''),
    metaDescription: new FormControl('')
  })

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrWidth = window.innerWidth;
  }

  closeDialog(data: any = null) {
    if(this.scrWidth>475){
      this.dialogRef.close(data);
    }
    if(this.scrWidth<475){
      this._bottomSheetRef.dismiss(data);
    }
  }

  fillInitialData() {
    if (this.blog.keyWords.content) {
      this.tags = this.blog.keyWords.content.split(',');
    }

    if (this.blog.author.content) {
      this.authors = this.blog.author.content.split(',');
    }
  }

  toggleMetaCardView() {
    if (this.currentBlogSection === CREATE_BLOG_SECTION.META) {
      this.metaForm.setValue({
        metaTitle: this.blog.blogMeta.metaTitle,
        metaDescription: this.blog.blogMeta.metaDescription.content
      });
    }

    this.currentBlogSection = this.currentBlogSection === CREATE_BLOG_SECTION.MAIN ? CREATE_BLOG_SECTION.META : CREATE_BLOG_SECTION.MAIN;
  }

  handleSearchForTags = this.blogService.debounce(() => this.searchForTags());

  searchForTags() {
    const businessId = this.blog.blogType === BlogType.MASTER ? '' : this.blog.businessId;

    this.blogService.searchBlogsTagByBusinessId(this.tagControl.value, businessId).subscribe({
      next: (response: any) => {
        this.filteredTags = response.data || [];

        this.showAddTagOption = !this.filteredTags.some((tag: Tag) => tag.tagName.toLowerCase() === this.tagControl.value.toLowerCase());
      },
      error: (error: any) => {
        this.showAddTagOption = true;
      }
    });
  }

  addTag(value: string, chipInput: HTMLInputElement) {
    if (!value) return;

    this.blog.addTag(value);
    this.tags = this.blog.keyWords.content.split(',');

    chipInput.value = '';
    this.tagControl.setValue('');
  }

  addNewTag(event: MatChipInputEvent): void {
    // const value = (event.value || '').trim();

    // if (value) {
    //   this.blog.addTag(value);
    //   this.tags = this.blog.keyWords.content.split(',');
    // }

    // // Clear the input value
    // event.chipInput!.clear();
  }

  removeTag(tag: string) {
    this.blog.removeTag(tag);
    this.tags = this.blog.keyWords.content.split(',').filter((value: string) => value);
  }

  addTagThroughApi(chipInput: HTMLInputElement) {
    const tagCreateRequest = {
      tagName: chipInput.value,
      description: '',
      createdTimeStamp: moment(new Date()).format(),
      blogCount: 0,
      createdById: "",
      createdByName: "",
      businessId: this.blog.businessId
    }

    tagCreateRequest.createdById = localStorage.getItem('staffId') || '';
    tagCreateRequest.createdByName = localStorage.getItem('staffName') || '';

    this.blogService.createBlogTags(tagCreateRequest).subscribe({
      next: (response: any) => {},
      error: (error: any) => {
        this.removeTag(tagCreateRequest.tagName);
        this.blogService.openSnack("Failed to add tag, please try again", "Ok");
      }
    });

    this.addTag(tagCreateRequest.tagName, chipInput);
    this.showAddTagOption = false;
  }

  handleSearchForAuthors = this.blogService.debounce(() => this.searchForAuthors());

  searchForAuthors() {
    const businessId = this.data.data?.blogType == 'Master' ? '' : this.blog.businessId;

    this.blogService.searchBlogsAuthorByBusinessId(this.authorControl.value , businessId || '').subscribe({
        next: (response: any)=>{
          this.filteredAuthors = response.data;

          this.showAddAuthorOption = !this.filteredAuthors.some((author: Author) => author.authorName.toLowerCase() === this.authorControl.value.toLowerCase());
        },
        error: (error: any) => {
          this.showAddAuthorOption = true;
        }
      })
  }

  addAuthor(value: string, chipInput: HTMLInputElement){
    if (!value) return;

    this.blog.addAuthor(value);
    this.authors = this.blog.author.content.split(',');

    chipInput.value = '';
    this.authorControl.setValue('');
  }

  addNewAuthor(event: MatChipInputEvent): void {
    // const value = (event.value || '').trim();

    // if (value) {
    //   this.blog.addAuthor(value);
    //   this.authors = this.blog.author.content.split(',');
    // }

    // // Clear the input value
    // event.chipInput!.clear();
  }

  authorEmail : any = '';
  addAuthorThroughApi(authorInput: HTMLInputElement) {
    const authorCreateRequest = {
      authorName: authorInput.value,
      email: '',
      imgUrl: '',
      createdTimeStamp: moment(new Date()).format(),
      blogCount: 0,
      createdById: "",
      createdByName: "",
      businessId: this.blog.businessId
    }

    authorCreateRequest.createdById = localStorage.getItem('staffId') || '';
    authorCreateRequest.createdByName = localStorage.getItem('staffName') || '';

    this.blogService.createBlogAuthor(authorCreateRequest).subscribe({
      next: (response: any) => {},
      error: (error: any) => {
        this.removeTag(authorInput.value);
        this.blogService.openSnack("Failed to add author, please try again", "Ok");
      }
    });

    this.addAuthor(authorInput.value, authorInput);
    this.showAddAuthorOption = false;
  }

  removeAuthor(author: string) {
    this.blog.removeAuthor(author);
    this.authors = this.blog.author.content.split(',').filter((value: string) => value);
  }

  saveMetaInformation() {
    this.blog.blogMeta.metaTitle = this.metaForm.value.metaTitle;
    this.blog.blogMeta.metaDescription.content = this.metaForm.value.metaDescription;

    this.currentBlogSection = CREATE_BLOG_SECTION.MAIN;
  }
  addLoader: boolean = false;
  saveBlogSettings() {
    this.addLoader = true;
  this.blogService.setBlogData(this.blog);

  const duration =1000;
  setTimeout(() => {
    this.addLoader = false;
    this.closeDialog("success");

    this.blogService.openSnack('Blog settings saved successfully','close');

  }, duration);
}

enteringMetatitle(ev){
  this.blog.blogMeta.metaTitle;
}

}
