<section class="main_section">
    <section class="top_layer" *ngIf="!urlShared">
        <div class="d-flex justify-content-between align-items-center w-100">
            <div class="left_container" (click)="goBack()">
                <mat-icon>keyboard_arrow_left</mat-icon>
                <img *ngIf="srcWidth > 475" src="./../../assets/images/logo.svg" alt="" class="logo">
            </div>
            <div class="right_container flex-grow-1" *ngIf="srcWidth > 475">
                <ng-container>
                    <ng-template *ngTemplateOutlet="CustomizeActions"></ng-template>

                    <div class="publish d-flex" *ngIf="!staffId || !business.length">
                        <!-- <button (click)="!staffId ? signUp('NOT_PUBLISHED') : createBusiness('NOT_PUBLISHED')"
                            class="save-btn">Save</button>
                        <button (click)="!staffId ? signUp('PUBLISHED') : createBusiness('PUBLISHED')"
                            *ngIf="!googleTokenId" class="publish_btn">Publish</button> -->
                        <button (click)="signUp('PUBLISHED')" *ngIf="googleTokenId">Done</button>
                    </div>

                    <div class="btn-group publish" role="group" *ngIf="staffId && business.length">
                        <button type="button" class="dropdown-toggle publish_btn" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Actions
                        </button>
                        <ul class="dropdown-menu" style="z-index: 10001;">
                            <li><a class="dropdown-item cursor-pointer"
                                    (click)="createBusiness('PUBLISHED')">Publish</a></li>
                            <li><a class="dropdown-item cursor-pointer"
                                    (click)="createBusiness('NOT_PUBLISHED')">Save</a></li>
                        </ul>
                    </div>
                </ng-container>
            </div>

            <!-- <ng-container *ngIf="srcWidth <= 475">
                <div style="display: flex;gap: 5px; height: 28px;">
                    <img src="../../../assets/images/editor/regenerate.svg" alt="" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
                    <div class="mobile-header" data-bs-toggle="offcanvas" data-bs-target="#addPageBottomSheet"
                        aria-controls="addPageBottomSheet">
                        <span style="position: relative;left: 13px;">Home </span><mat-icon
                            class="mat-dropdown">keyboard_arrow_down</mat-icon>
                    </div>
                    <img src="../../../assets/images/editor/share.svg" alt="" (click)="shareWebsiteMobile()">
                    <div class="btn-group publish" role="group" *ngIf="staffId && business.length">
                        <button type="button" class="publish_btn" aria-expanded="false"
                            (click)="createBusiness('NOT_PUBLISHED')">
                            Save
                        </button>
                    </div>
                </div>
            </ng-container> -->
            <ng-container *ngIf="srcWidth <= 475">
              <div class="d-flex justify-content-end">
                <button #container class="regenerate d-flex align-items-center justify-content-start gap-2" style="background-color: lightblue;"
                (click)="openRegeneratePopup()" *ngIf="!colorPalletBoolean && !regenerateFontsBoolean">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="20" height="20"
                    class="icon h-5 w-5" aria-hidden="true">
                    <path fill="url(#a)" fill-rule="evenodd"
                        d="M14.6152 1.59489c.3013.16794.4491.51968.3584.85241L12.982 9.74996h7.268c.2987 0 .5689.17719.6879.45114.119.2739.0642.5923-.1396.8106l-10.5 11.25c-.2353.2521-.61226.3113-.91349.1433-.30124-.1679-.44911-.5196-.35837-.8524L11.0181 14.25H3.75002c-.29865 0-.56884-.1772-.68786-.4511-.11903-.2739-.06421-.5923.13957-.8107L13.7017 1.73823c.2353-.25214.6123-.31128.9135-.14334Z"
                        clip-rule="evenodd"></path>
                    <defs>
                        <linearGradient id="a" x1="12" x2="12" y1="1.49994" y2="22.5" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#0267c1"></stop>
                            <stop offset="1" stop-color="#0267c1"></stop>
                        </linearGradient>
                    </defs>
                </svg>
                </button>
                <div class="regenerate-popup d-flex alig-items-center gap-2 justify-content-center" *ngIf="regenerateSection && !colorPalletBoolean && !regenerateFontsBoolean" (click)="stopPropagation($event)">
                  <button class="inside-regenerate-btns d-flex align-items-center gap-1 justify-content-start flex-column"
                  (click)="entireWebsiteRegenerate()">
                      <svg class="img" width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M4.01108 19.9525C4.27565 20.0656 4.58327 19.9712 4.7378 19.7271L11.7691 8.55522C11.8829 8.3744 11.8898 8.14608 11.7868 7.95956C11.6838 7.77245 11.4869 7.65628 11.2735 7.65628H6.58085L8.32608 0.730146C8.39647 0.450341 8.25397 0.161357 7.98905 0.0474897C7.72585 -0.0657916 7.41628 0.0291694 7.26233 0.272919L0.231082 11.4448C0.117215 11.6256 0.11034 11.8539 0.213348 12.0405C0.316355 12.2276 0.513191 12.3437 0.726629 12.3437H5.41928L3.67405 19.2699C3.60366 19.5497 3.74612 19.8387 4.01108 19.9525Z"
                              fill="#000000" />
                      </svg>
                      Entire website
                  </button>
                  <button class="inside-regenerate-btns d-flex align-items-center gap-1 justify-content-start flex-column" (click)="openFonts()">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 20 20"
                          width="24" height="24" class="icon w-5 h-5" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                              d="M4.16667 5.83333c-.44203 0-.86595-.17559-1.17851-.48815C2.67559 5.03262 2.5 4.60869 2.5 4.16667c0-.44203.17559-.86595.48816-1.17851C3.30072 2.67559 3.72464 2.5 4.16667 2.5c.44202 0 .86595.17559 1.17851.48816.31256.31256.48815.73648.48815 1.17851M4.16667 5.83333c.44202 0 .86595-.17559 1.17851-.48815.31256-.31256.48815-.73649.48815-1.17851M4.16667 5.83333v8.33337M5.83333 4.16667h8.33337m0 0c0 .44202.1756.86595.4881 1.17851.3126.31256.7365.48815 1.1785.48815m-1.6666-1.66666c0-.44203.1756-.86595.4881-1.17851.3126-.31257.7365-.48816 1.1785-.48816.4421 0 .866.17559 1.1785.48816.3126.31256.4882.73648.4882 1.17851 0 .44202-.1756.86595-.4882 1.17851-.3125.31256-.7364.48815-1.1785.48815m0 0v8.33337m-9.99997 1.6666c0 .4421-.17559.866-.48815 1.1785-.31256.3126-.73649.4882-1.17851.4882-.44203 0-.86595-.1756-1.17851-.4882-.31257-.3125-.48816-.7364-.48816-1.1785 0-.442.17559-.8659.48816-1.1785.31256-.3125.73648-.4881 1.17851-.4881m1.66666 1.6666c0-.442-.17559-.8659-.48815-1.1785-.31256-.3125-.73649-.4881-1.17851-.4881m1.66666 1.6666h8.33337m0 0c0 .4421.1756.866.4881 1.1785.3126.3126.7365.4882 1.1785.4882.4421 0 .866-.1756 1.1785-.4882.3126-.3125.4882-.7364.4882-1.1785 0-.442-.1756-.8659-.4882-1.1785-.3125-.3125-.7364-.4881-1.1785-.4881m-1.6666 1.6666c0-.442.1756-.8659.4881-1.1785.3126-.3125.7365-.4881 1.1785-.4881M8.33333 8.33333h3.33337M10 11.6667V8.33333">
                          </path>
                      </svg>
                      Fonts
                  </button>
                  <button class="inside-regenerate-btns d-flex align-items-center gap-1 justify-content-start flex-column" (click)="openColor()">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 20 20"
                          width="25" height="25" class="icon w-5 h-5" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                              d="M10 17.5c-1.98912 0-3.89678-.7902-5.3033-2.1967C3.29018 13.8968 2.5 11.9891 2.5 10c0-1.98912.79018-3.89678 2.1967-5.3033S8.01088 2.5 10 2.5c4.1417 0 7.5 2.985 7.5 6.66667 0 .88333-.395 1.73163-1.0983 2.35663-.7034.625-1.6575.9767-2.6517.9767h-2.0833c-.3718-.006-.7349.1125-1.0316.3367-.2967.2241-.5099.541-.6057.9003-.09578.3593-.0687.7403.077 1.0824.1456.3421.4015.6257.7269.8056.1664.1535.2805.3553.3263.5771.0458.2217.021.4522-.071.6591-.0919.2068-.2464.3797-.4416.4943-.1953.1146-.4215.1651-.647.1445Z">
                          </path>
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                              d="M6.25 8.75c0 .22101.0878.43298.24408.58926.15628.15628.36824.24407.58925.24407.22102 0 .43298-.08779.58926-.24407.15628-.15628.24408-.36825.24408-.58926 0-.22101-.0878-.43298-.24408-.58926-.15628-.15628-.36824-.24407-.58926-.24407-.22101 0-.43297.08779-.58925.24407C6.3378 8.31702 6.25 8.52899 6.25 8.75Zm3.33333-2.5c0 .22101.0878.43298.24408.58926.15628.15628.36829.24407.58929.24407.221 0 .4329-.08779.5892-.24407.1563-.15628.2441-.36825.2441-.58926 0-.22101-.0878-.43298-.2441-.58926-.1563-.15628-.3682-.24407-.5892-.24407-.221 0-.43301.08779-.58929.24407-.15628.15628-.24408.36825-.24408.58926Zm3.33337 2.5c0 .22101.0878.43298.244.58926.1563.15628.3683.24407.5893.24407.221 0 .433-.08779.5893-.24407.1562-.15628.244-.36825.244-.58926 0-.22101-.0878-.43298-.244-.58926-.1563-.15628-.3683-.24407-.5893-.24407-.221 0-.433.08779-.5893.24407-.1562.15628-.244.36825-.244.58926Z">
                          </path>
                      </svg>
                      Colors
                  </button>
              </div>
                <div class="page-dropdown whitebackgroundInput" style="width: 45%;">
                  <mat-form-field appearance="outline" style="width: 90%;" class="days_dropdown">
                    <mat-select [(ngModel)]="currentPageId" (ngModelChange)="changePage()">
                      <mat-option [value]="item.id" *ngFor="let item of templatePages;let i = index">{{item.pageName}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="multi_page d-flex border"
                  style="width: fit-content; border-radius: 5px;font-size: 14px; font-weight: 600;background-color: white; height: 37px;"
                  (click)="createNewPage()">
                  <button class="button-style d-flex justify-content-center align-items-center"
                    style="border: none; border-radius: 5px;">
                    <mat-icon>add</mat-icon></button>
                </div>
              </div>
            </ng-container>
        </div>
    </section>

    <!-- component Dynamic pickup section -->
    <section class="bottom_layer" [ngClass]="urlShared ? 'full_bottom_layer' : 'bottom_layer'">
        <ng-template #websiteStructure></ng-template>
    </section>


    <!-- singup tab appearce in bottom of the screen -->
    <!-- <div class="signup-tab" *ngIf="!staffId && !googleTokenId && !urlShared">
        <div class="signupsection" [ngClass]="{'shaking-animation': needUserToLogin}">
            <div class="signUp-text">
                Edit and publish your website, get a custom domain, analytics and more
            </div>
            <div class="signUp-btn">
                <button (click)="signUp('PUBLISHED')">Sign up free</button>
            </div>
        </div>
    </div> -->

    <ng-container *ngIf="srcWidth < 475">
      <div #container class="regenerate-container">
        <button class="regenerate d-flex align-items-center justify-content-start gap-2"  (click)="signUp('PUBLISHED')"
        *ngIf="!staffId || !business.length">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="20" height="20"
            class="icon w-5 h-5" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
              d="M9.53086 16.1224C9.08517 15.0243 8.00801 14.25 6.75 14.25c-1.65685 0-3 1.3431-3 3 0 1.2426-1.00738 2.25-2.25002 2.25-.0507 0-.101-.0017-.15086-.005.77736 1.3478 2.23317 2.255 3.90086 2.255 2.47823 0 4.48856-2.0033 4.49995-4.4789.00005-.007.00007-.014.00007-.0211 0-.3988-.07783-.7795-.21914-1.1276Zm0 0c1.19434-.4071 2.33034-.9519 3.38664-1.6196m-5.0425-.0259c.4073-1.1972.9531-2.3358 1.62224-3.3944m3.42026 3.4203c1.8805-1.1887 3.5084-2.7666 4.7631-4.64874l3.876-5.814c.1261-.18913.1934-.41136.1934-.63867 0-.6359-.5155-1.15139-1.1514-1.15139-.2273 0-.4495.06729-.6387.19338l-5.814 3.87599c-1.8821 1.25476-3.46 2.88267-4.64866 4.76313m3.42026 3.4203c-.6779-1.5195-1.9008-2.7424-3.42026-3.4203">
            </path>
          </svg>
          Customize
        </button>
        <!-- Font Families -->
        <div class="font-families" *ngIf="regenerateFontsBoolean && !regenerateSection">
            <div class="done-button" (click)="closeFonts()" >
                <img class="close-img" src="../../../assets/images/close.svg">
              </div>
            <ng-container *ngFor="let item of fontFamilies">
                <div class="card-height border rounded bg-white px-2 py-2 cursor-pointer" (click)="selectedFontFamily(item)">
                    <div class="font-title" [style.fontFamily]="item?.headingFontValues?.fontFamily">
                        {{item.displayHeading}}
                    </div>
                    <div class="font-desc" [style.fontWeight]="item?.bodyFontValues?.fontWeight">
                        {{item.displayBody}}
                    </div>
                </div>
            </ng-container>
        </div>

        <!-- Color Palettes -->
        <div class="regenerate-popup-color" *ngIf="colorPalletBoolean">
          <div class="done-button" (click)="closePalette()" >
            <img class="close-img" src="../../../assets/images/close.svg">
          </div>
            <ng-container *ngFor="let theme of themes">
                <div class="palette" (click)="selectTheme(theme)">
                    <div *ngFor="let style of theme.componentStyles; let i = index">
                        <div class="palette-card" [ngStyle]="{'background-color' : style.bgColor}" [ngClass]="{ 'left-pallet-border': i === 0, 'right-pallet-border': i === 3 }">
                            <div class="button-text">
                              <div style="height: 10px; width: 10px;border-radius: 50%;" [ngStyle]="{'background-color' : style.accentColor}"></div>
                              <p style="height: 10px; width: 15px; color: transparent;" >Aa</p>
                            </div>
                          </div>
                    </div>
                </div>
            </ng-container>
        </div>
      </div>
    </ng-container>

    <div class="signup-tab" *ngIf="staffId || googleTokenId">
        <div *ngIf="srcWidth <= 475" class="signupsection" style="flex-direction: row;gap: 30PX;
            align-items: center;
            width: 95%;" [ngClass]="{'shaking-animation': needUserToLogin}">
            <div class="signUp-text" style="margin-bottom: 0; font-weight: bold;
            color: black;">
                Publish your website
            </div>
            <div class="signUp-btn">
                <button *ngIf="!googleTokenId" (click)="createBusiness('PUBLISHED')">Publish</button>
                <button *ngIf="googleTokenId" (click)="signUp('PUBLISHED')">Publish</button>
            </div>
        </div>
    </div>
</section>
<div class="loading" id="animationLoader" *ngIf="screenLoader">
    <section class="loader_section">
        <!-- <app-loader [textLoop]="textsForScreenLoader"></app-loader> -->
        <app-lottie-loader></app-lottie-loader>
    </section>
    <div class="loading_text" id="addingText">{{loadingText}}</div>
</div>
<div class="loading" *ngIf="poppperLoader" id="popperLoader">
    <app-lottie-poppper></app-lottie-poppper>
</div>

<ng-template #CustomizeActions>
    <div class="d-flex align-items-center customize-items">
        <div class="page-dropdown whitebackgroundInput">
            <mat-form-field appearance="outline" class="days_dropdown">
                <mat-select [(ngModel)]="currentPageId" (ngModelChange)="changePage()">
                    <mat-option [value]="item.id" *ngFor="let item of templatePages;let i = index">{{item.pageName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="multi_page d-flex border mr-20"
          style="width: fit-content; border-radius: 5px;font-size: 14px; font-weight: 600;background-color: white; height: 40px;"
          (click)="createNewPage()">
          <button class="button-style d-flex justify-content-center align-items-center"
            style="border: none; border-radius: 5px;">
            <mat-icon>add</mat-icon></button>
        </div>

        <div #container class="regenerate-container">
            <button class="regenerate d-flex align-items-center justify-content-start gap-2"
                (click)="openRegeneratePopup()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="20" height="20"
                    class="icon h-5 w-5" aria-hidden="true">
                    <path fill="url(#a)" fill-rule="evenodd"
                        d="M14.6152 1.59489c.3013.16794.4491.51968.3584.85241L12.982 9.74996h7.268c.2987 0 .5689.17719.6879.45114.119.2739.0642.5923-.1396.8106l-10.5 11.25c-.2353.2521-.61226.3113-.91349.1433-.30124-.1679-.44911-.5196-.35837-.8524L11.0181 14.25H3.75002c-.29865 0-.56884-.1772-.68786-.4511-.11903-.2739-.06421-.5923.13957-.8107L13.7017 1.73823c.2353-.25214.6123-.31128.9135-.14334Z"
                        clip-rule="evenodd"></path>
                    <defs>
                        <linearGradient id="a" x1="12" x2="12" y1="1.49994" y2="22.5" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#0267c1"></stop>
                            <stop offset="1" stop-color="#0267c1"></stop>
                        </linearGradient>
                    </defs>
                </svg>
                Regenerate
            </button>
            <div class="regenerate-popup d-flex alig-items-center gap-2 flex-column justify-content-center" *ngIf="regenerateSection && !colorPalletBoolean && !regenerateFontsBoolean" (click)="stopPropagation($event)">
                <button class="inside-regenerate-btns d-flex align-items-center gap-1 justify-content-start"
                (click)="entireWebsiteRegenerate()">
                    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.01108 19.9525C4.27565 20.0656 4.58327 19.9712 4.7378 19.7271L11.7691 8.55522C11.8829 8.3744 11.8898 8.14608 11.7868 7.95956C11.6838 7.77245 11.4869 7.65628 11.2735 7.65628H6.58085L8.32608 0.730146C8.39647 0.450341 8.25397 0.161357 7.98905 0.0474897C7.72585 -0.0657916 7.41628 0.0291694 7.26233 0.272919L0.231082 11.4448C0.117215 11.6256 0.11034 11.8539 0.213348 12.0405C0.316355 12.2276 0.513191 12.3437 0.726629 12.3437H5.41928L3.67405 19.2699C3.60366 19.5497 3.74612 19.8387 4.01108 19.9525Z"
                            fill="#000000" />
                    </svg>
                    <span class="window-titles">Entire website</span>
                </button>
                <button class="inside-regenerate-btns d-flex align-items-center gap-1 justify-content-start" (click)="openColor()">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 20 20"
                        width="24" height="24" class="icon w-5 h-5" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                            d="M10 17.5c-1.98912 0-3.89678-.7902-5.3033-2.1967C3.29018 13.8968 2.5 11.9891 2.5 10c0-1.98912.79018-3.89678 2.1967-5.3033S8.01088 2.5 10 2.5c4.1417 0 7.5 2.985 7.5 6.66667 0 .88333-.395 1.73163-1.0983 2.35663-.7034.625-1.6575.9767-2.6517.9767h-2.0833c-.3718-.006-.7349.1125-1.0316.3367-.2967.2241-.5099.541-.6057.9003-.09578.3593-.0687.7403.077 1.0824.1456.3421.4015.6257.7269.8056.1664.1535.2805.3553.3263.5771.0458.2217.021.4522-.071.6591-.0919.2068-.2464.3797-.4416.4943-.1953.1146-.4215.1651-.647.1445Z">
                        </path>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                            d="M6.25 8.75c0 .22101.0878.43298.24408.58926.15628.15628.36824.24407.58925.24407.22102 0 .43298-.08779.58926-.24407.15628-.15628.24408-.36825.24408-.58926 0-.22101-.0878-.43298-.24408-.58926-.15628-.15628-.36824-.24407-.58926-.24407-.22101 0-.43297.08779-.58925.24407C6.3378 8.31702 6.25 8.52899 6.25 8.75Zm3.33333-2.5c0 .22101.0878.43298.24408.58926.15628.15628.36829.24407.58929.24407.221 0 .4329-.08779.5892-.24407.1563-.15628.2441-.36825.2441-.58926 0-.22101-.0878-.43298-.2441-.58926-.1563-.15628-.3682-.24407-.5892-.24407-.221 0-.43301.08779-.58929.24407-.15628.15628-.24408.36825-.24408.58926Zm3.33337 2.5c0 .22101.0878.43298.244.58926.1563.15628.3683.24407.5893.24407.221 0 .433-.08779.5893-.24407.1562-.15628.244-.36825.244-.58926 0-.22101-.0878-.43298-.244-.58926-.1563-.15628-.3683-.24407-.5893-.24407-.221 0-.433.08779-.5893.24407-.1562.15628-.244.36825-.244.58926Z">
                        </path>
                    </svg>
                    <span class="window-titles">Colors</span>
                </button>
                <button class="inside-regenerate-btns d-flex align-items-center gap-1 justify-content-start" (click)="openFonts()">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 20 20"
                        width="24" height="24" class="icon w-5 h-5" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                            d="M4.16667 5.83333c-.44203 0-.86595-.17559-1.17851-.48815C2.67559 5.03262 2.5 4.60869 2.5 4.16667c0-.44203.17559-.86595.48816-1.17851C3.30072 2.67559 3.72464 2.5 4.16667 2.5c.44202 0 .86595.17559 1.17851.48816.31256.31256.48815.73648.48815 1.17851M4.16667 5.83333c.44202 0 .86595-.17559 1.17851-.48815.31256-.31256.48815-.73649.48815-1.17851M4.16667 5.83333v8.33337M5.83333 4.16667h8.33337m0 0c0 .44202.1756.86595.4881 1.17851.3126.31256.7365.48815 1.1785.48815m-1.6666-1.66666c0-.44203.1756-.86595.4881-1.17851.3126-.31257.7365-.48816 1.1785-.48816.4421 0 .866.17559 1.1785.48816.3126.31256.4882.73648.4882 1.17851 0 .44202-.1756.86595-.4882 1.17851-.3125.31256-.7364.48815-1.1785.48815m0 0v8.33337m-9.99997 1.6666c0 .4421-.17559.866-.48815 1.1785-.31256.3126-.73649.4882-1.17851.4882-.44203 0-.86595-.1756-1.17851-.4882-.31257-.3125-.48816-.7364-.48816-1.1785 0-.442.17559-.8659.48816-1.1785.31256-.3125.73648-.4881 1.17851-.4881m1.66666 1.6666c0-.442-.17559-.8659-.48815-1.1785-.31256-.3125-.73649-.4881-1.17851-.4881m1.66666 1.6666h8.33337m0 0c0 .4421.1756.866.4881 1.1785.3126.3126.7365.4882 1.1785.4882.4421 0 .866-.1756 1.1785-.4882.3126-.3125.4882-.7364.4882-1.1785 0-.442-.1756-.8659-.4882-1.1785-.3125-.3125-.7364-.4881-1.1785-.4881m-1.6666 1.6666c0-.442.1756-.8659.4881-1.1785.3126-.3125.7365-.4881 1.1785-.4881M8.33333 8.33333h3.33337M10 11.6667V8.33333">
                        </path>
                    </svg>
                    <span class="window-titles">Fonts</span>
                </button>
                <!-- <button class="inside-regenerate-btns d-flex align-items-center gap-1 justify-content-start">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" width="24" height="24"
                        class="icon w-5 h-5" aria-hidden="true">
                        <path stroke="currentColor" stroke-linecap="round" stroke-width="1.5"
                            d="M11 12h7m-7-8h7m-7 4h7M2 16h16"></path>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                            d="m2 12 .85714-2.28571M8 12l-.85714-2.28571m0 0L5 4 2.85714 9.71429m4.28572 0H2.85714">
                        </path>
                    </svg>
                    Text
                </button> -->
            </div>
            <div class="regenerate-popup d-flex alig-items-center gap-2 flex-column justify-content-center" *ngIf="colorPalletBoolean">
                <div class="palette-header">
                    <div class="palette-title">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 20 20" width="24" height="24" class="icon w-5 h-5 text-gray-700" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 17.5c-1.98912 0-3.89678-.7902-5.3033-2.1967C3.29018 13.8968 2.5 11.9891 2.5 10c0-1.98912.79018-3.89678 2.1967-5.3033S8.01088 2.5 10 2.5c4.1417 0 7.5 2.985 7.5 6.66667 0 .88333-.395 1.73163-1.0983 2.35663-.7034.625-1.6575.9767-2.6517.9767h-2.0833c-.3718-.006-.7349.1125-1.0316.3367-.2967.2241-.5099.541-.6057.9003-.09578.3593-.0687.7403.077 1.0824.1456.3421.4015.6257.7269.8056.1664.1535.2805.3553.3263.5771.0458.2217.021.4522-.071.6591-.0919.2068-.2464.3797-.4416.4943-.1953.1146-.4215.1651-.647.1445Z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6.25 8.75c0 .22101.0878.43298.24408.58926.15628.15628.36824.24407.58925.24407.22102 0 .43298-.08779.58926-.24407.15628-.15628.24408-.36825.24408-.58926 0-.22101-.0878-.43298-.24408-.58926-.15628-.15628-.36824-.24407-.58926-.24407-.22101 0-.43297.08779-.58925.24407C6.3378 8.31702 6.25 8.52899 6.25 8.75Zm3.33333-2.5c0 .22101.0878.43298.24408.58926.15628.15628.36829.24407.58929.24407.221 0 .4329-.08779.5892-.24407.1563-.15628.2441-.36825.2441-.58926 0-.22101-.0878-.43298-.2441-.58926-.1563-.15628-.3682-.24407-.5892-.24407-.221 0-.43301.08779-.58929.24407-.15628.15628-.24408.36825-.24408.58926Zm3.33337 2.5c0 .22101.0878.43298.244.58926.1563.15628.3683.24407.5893.24407.221 0 .433-.08779.5893-.24407.1562-.15628.244-.36825.244-.58926 0-.22101-.0878-.43298-.244-.58926-.1563-.15628-.3683-.24407-.5893-.24407-.221 0-.433.08779-.5893.24407-.1562.15628-.244.36825-.244.58926Z"></path></svg>
                        <!-- <mat-icon>palette</mat-icon> -->
                        Color Palette
                    </div>
                    <div class="done-button" (click)="closePalette()" >
                        <img class="close-img" src="../../../assets/images/close.svg">
                    </div>
                </div>
                <hr class="section-divider">
                <ng-container *ngFor="let theme of themes">
                    <div class="palette" (click)="selectTheme(theme)">
                        <div style="width: 25%;" *ngFor="let style of theme.componentStyles; let i = index">
                            <div class="palette-card" [ngStyle]="{'background-color' : style.bgColor}" [ngClass]="{ 'left-pallet-border': i === 0, 'right-pallet-border': i === 3 }">
                                <div class="button-text">
                                  <div style="height: 10px; width: 10px;border-radius: 50%;" [ngStyle]="{'background-color' : style.accentColor}"></div>
                                  <p style="height: 10px; width: 10px;" >Aa</p>
                                </div>
                              </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="regenerate-popup d-flex alig-items-center gap-2 flex-column justify-content-center" *ngIf="regenerateFontsBoolean">
                <div class="palette-header">
                    <div class="palette-title">
                        <svg _ngcontent-hcr-c301="" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 20 20" width="24" height="24" aria-hidden="true" class="icon w-5 h-5"><path _ngcontent-hcr-c301="" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.16667 5.83333c-.44203 0-.86595-.17559-1.17851-.48815C2.67559 5.03262 2.5 4.60869 2.5 4.16667c0-.44203.17559-.86595.48816-1.17851C3.30072 2.67559 3.72464 2.5 4.16667 2.5c.44202 0 .86595.17559 1.17851.48816.31256.31256.48815.73648.48815 1.17851M4.16667 5.83333c.44202 0 .86595-.17559 1.17851-.48815.31256-.31256.48815-.73649.48815-1.17851M4.16667 5.83333v8.33337M5.83333 4.16667h8.33337m0 0c0 .44202.1756.86595.4881 1.17851.3126.31256.7365.48815 1.1785.48815m-1.6666-1.66666c0-.44203.1756-.86595.4881-1.17851.3126-.31257.7365-.48816 1.1785-.48816.4421 0 .866.17559 1.1785.48816.3126.31256.4882.73648.4882 1.17851 0 .44202-.1756.86595-.4882 1.17851-.3125.31256-.7364.48815-1.1785.48815m0 0v8.33337m-9.99997 1.6666c0 .4421-.17559.866-.48815 1.1785-.31256.3126-.73649.4882-1.17851.4882-.44203 0-.86595-.1756-1.17851-.4882-.31257-.3125-.48816-.7364-.48816-1.1785 0-.442.17559-.8659.48816-1.1785.31256-.3125.73648-.4881 1.17851-.4881m1.66666 1.6666c0-.442-.17559-.8659-.48815-1.1785-.31256-.3125-.73649-.4881-1.17851-.4881m1.66666 1.6666h8.33337m0 0c0 .4421.1756.866.4881 1.1785.3126.3126.7365.4882 1.1785.4882.4421 0 .866-.1756 1.1785-.4882.3126-.3125.4882-.7364.4882-1.1785 0-.442-.1756-.8659-.4882-1.1785-.3125-.3125-.7364-.4881-1.1785-.4881m-1.6666 1.6666c0-.442.1756-.8659.4881-1.1785.3126-.3125.7365-.4881 1.1785-.4881M8.33333 8.33333h3.33337M10 11.6667V8.33333"></path></svg>
                        <!-- <mat-icon>fonts</mat-icon> -->
                        Fonts
                    </div>
                    <div class="done-button" (click)="closeFonts()" >
                        <img class="close-img" src="../../../assets/images/close.svg">
                    </div>
                </div>
                <hr class="section-divider">
                <div class="font-families">
                    <ng-container *ngFor="let item of fontFamilies">
                        <div class="border rounded bg-white px-2 py-2 cursor-pointer" (click)="selectedFontFamily(item)">
                            <div class="font-title" [style.fontFamily]="item?.headingFontValues?.fontFamily">
                                {{item.displayHeading}}
                            </div>
                            <div class="font-desc" [style.fontWeight]="item?.bodyFontValues?.fontWeight">
                                {{item.displayBody}}
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <button class="customize d-flex align-items-center justify-content-start gap-2" (click)="signUp('PUBLISHED')" *ngIf="!staffId || !business.length">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="20"
                height="20" class="icon w-5 h-5" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                    d="M9.53086 16.1224C9.08517 15.0243 8.00801 14.25 6.75 14.25c-1.65685 0-3 1.3431-3 3 0 1.2426-1.00738 2.25-2.25002 2.25-.0507 0-.101-.0017-.15086-.005.77736 1.3478 2.23317 2.255 3.90086 2.255 2.47823 0 4.48856-2.0033 4.49995-4.4789.00005-.007.00007-.014.00007-.0211 0-.3988-.07783-.7795-.21914-1.1276Zm0 0c1.19434-.4071 2.33034-.9519 3.38664-1.6196m-5.0425-.0259c.4073-1.1972.9531-2.3358 1.62224-3.3944m3.42026 3.4203c1.8805-1.1887 3.5084-2.7666 4.7631-4.64874l3.876-5.814c.1261-.18913.1934-.41136.1934-.63867 0-.6359-.5155-1.15139-1.1514-1.15139-.2273 0-.4495.06729-.6387.19338l-5.814 3.87599c-1.8821 1.25476-3.46 2.88267-4.64866 4.76313m3.42026 3.4203c-.6779-1.5195-1.9008-2.7424-3.42026-3.4203">
                </path>
            </svg>
            Customize
        </button>

        <div class="dropdown" *ngIf="srcWidth <= 475">
            <div class="dropdown-toggle my-dropdown-toggle d-flex justify-content-center align-items-center"
                data-bs-toggle="dropdown">
                <img src="../../../assets/images/list-business/three_dots.svg" alt="">
            </div>

            <ul class="dropdown-menu dropdown-menu-dark bg-white">
                <li><a class="dropdown-item text-black d-flex align-items-center"
                        (click)="!staffId ? signUp('PUBLISHED') : createBusiness('PUBLISHED')"><mat-icon
                            class="me-2">publish</mat-icon> Publish</a></li>
                <li><a class="dropdown-item text-black d-flex align-items-center" (click)="shareWebsite()"><mat-icon
                            class="me-2">share</mat-icon> Share</a></li>
            </ul>
        </div>
    </div>
</ng-template>

<div class="offcanvas offcanvas-bottom" style="width: 100%;height: auto!important;" tabindex="-1" id="offcanvasBottom"
    aria-labelledby="offcanvasBottomLabel">
    <div class="regenerate-top-header">
        <img src="../../../assets/images/regeneratename.svg" class="regenerate-image" alt="">
        <div data-bs-dismiss="offcanvas" id="closeRegenerateSheet" data-bs-target="#offcanvasBottom" aria-label="Close"
            class="close-bottom-sheet"><mat-icon>close</mat-icon></div>
    </div>
    <div class="ptb-20 regenerate-bottom">
        <div class="entire-website" (click)="doneGlobalRegenerate()">
            <img src="../../../assets/images/regenerate-website.svg"> <span>Entire Website</span>
        </div>
        <div class="entire-website">
            <img src="../../../assets/images/font-color.svg" (click)="changeSiteStyle()"> <span>Font & Colors</span>
        </div>
    </div>
</div>

<div class="offcanvas offcanvas-bottom page-bottom-sheet" style="width: 100%;height: auto!important;" tabindex="-1"
    id="addPageBottomSheet" aria-labelledby="addPageBottomSheetLabel">
    <div class="heading_section">
        <div class="heading">
            Page
        </div>
        <div class="closing_bar" data-bs-dismiss="offcanvas" data-bs-target="#addPageBottomSheet" aria-label="Close">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="hr_line"></div>
    <div *ngFor="let page of templatePages;let i = index">
        <div class="heading_section">
            <div class="heading">
                {{page.pageName}}
            </div>
            <div class="done_btn">
                <button class="home-icon" *ngIf="page.pageName != 'Home'"
                    (click)="deletePage()"><mat-icon>delete</mat-icon></button>
                <button class="home-icon" (click)="openEditor()"><mat-icon>settings</mat-icon></button>
            </div>
        </div>
    </div>
    <div class="button-parent">
        <button class="cancel-button" id="closeaddPageBottomSheet" data-bs-dismiss="offcanvas"
            data-bs-target="#addPageBottomSheet" aria-label="Close">Cancel</button>
        <!-- <button class="add-new-page" (click)="pageSelectionChange({})">Add New Page</button> -->
    </div>
</div>
