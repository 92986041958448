import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicpickcomponentsService } from 'src/app/services/dynamicpickcomponents.service';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { TemplateService } from 'src/app/services/template.service';
import { ListCustomElementsComponent } from '../../list-custom-elements/list-custom-elements.component';
import { WebisiteService } from '../../../../webisite.service';
import { MobileEditorRightSidePanelComponent } from '../editor-components/editor-right-side-panel/mobile-editor-right-side-panel.component';
import { ActivatedRoute, Router } from '@angular/router';
import { buttonShape, buttonStyle } from 'src/app/constants/common-constant';
import { ShareWebsiteComponent } from 'src/app/our-components/share-website/share-website.component';
import { WarningPopupComponent } from '../popups/warning-popup/warning-popup.component';
import { DeletePopComponent } from '../popups/delete-pop/delete-pop.component';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { COMPONENT_STYLE, PageTheme } from '../../../../model/theme.modal';
import { BUTTON_SHAPE, BUTTON_STYLE } from '../../../../enums/website.enum';
import { Subscription } from 'rxjs';
import { FontFamily } from '../../../../constants/FontFamilty.constant';
import { CustomSnackbarComponent } from 'src/app/helper-components/custom-snackbar/custom-snackbar.component';
import { MatDialogRef } from '@angular/material/dialog';


const _ = require('lodash');
@Component({
  selector: 'app-v2-editor',
  templateUrl: './v2-editor.component.html',
  styleUrls: ['./v2-editor.component.scss', './mq-v2-editor.component.scss']
})
export class V2EditorComponent implements OnInit, AfterViewInit, AfterViewChecked {

  readonly FontFamilyList = FontFamily;

  @ViewChild('displayWebsite', { read: ViewContainerRef })
  displayWebsite: ViewContainerRef | any;

  @ViewChild('offcanvasRight') offcanvasRight: ElementRef | null = null;

  @ViewChild('pagedropdown') pageDropdown;

  textPalletToShow = ["Aa", "Bb", "Cc", "Dd"];

  private _componentEditing: string | null = null;

  private snackbarRef: MatSnackBarRef<CustomSnackbarComponent> | null = null;

  constructor(
    private templateService: TemplateService,
    private pickupService: DynamicpickcomponentsService,
    private eventHelper: EventEmmiterService,
    private _websiteService: WebisiteService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private cdf : ChangeDetectorRef,
    public dialogRef: MatDialogRef<V2EditorComponent>,
  ) {
    this.getScreenSize()

    if(window.localStorage.getItem("pd")){
      this.editorPanelDirection = window.localStorage.getItem('pd');
    }
    else{
      window.localStorage.setItem('pd','LEFT');
      this.editorPanelDirection = "LEFT"
    }
  }

  collpaseEditor: boolean = true;

  editorPanelDirection :any;

  screenType: "BASIC" | "ADVANCE" = "BASIC";
  isPageLevelEdit = false;
  categoryName = '';
  screenWidth: any;
  srcHeight: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.srcHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    //console.log(this.screenWidth);
  }


  ngAfterViewInit(): void {
    this.queryParamSubscription = this.activatedRoute.queryParams.subscribe(res => {
      if (res['action'] == 'openSiteStyle') {
        if (this.screenWidth > 475) {
          let data = document.getElementById('openWebSiteStyle')
          data?.click();
        } else {
          document.getElementById('openMobileSiteStyle')?.click()
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((item, j) => {
        item.unsubscribe();
      });
    }
    if (this.applyToSaveChangesSubscribe) {
      this.applyToSaveChangesSubscribe.unsubscribe();
    }
    if (this.discardChangesSubscription) {
      this.discardChangesSubscription.unsubscribe();
    }
    this.queryParamSubscription?.unsubscribe()
    if(this.changeAnimationSubscribe){
      this.changeAnimationSubscribe.unsubscribe();
    }
    this.unSubscribeNewSectionSubcription();
  }

  currentPageIndex = 0;
  businessDetails: any;
  gettingButtonClickedValuesFromCompnentsSubscribe: any;
  applyToSaveChangesSubscribe: any;
  pageNameChangeSubscribe: any;
  changeAnimationSubscribe = new Subscription();

  queryParamSubscription: Subscription | undefined;
  ngOnInit(): void {
    let bDetails = window.localStorage.getItem('bDetails');
    if (bDetails) {
      this.businessDetails = JSON.parse(bDetails);
      this.templateId = this.businessDetails.templateId;
    }
    this.gettingButtonClickedValuesFromCompnents();
    this.applyToSaveChanges();
    this.pageNameChange();
    this.discardChangers();

    this.getWebsite();
    this.changeAnimation();
    // this.showSaveOptionTab();
  }

  seoData: any = {
    metaTitle: "",
    metaDescription: "",
    keywords:""
  }

  //before save the data getting all page level button values
  gettingButtonClickedValuesFromCompnents() {
    this.gettingButtonClickedValuesFromCompnentsSubscribe = this.eventHelper.gettingButtonClickValues.subscribe(
      (res: any) => {
        // console.log(res);
      }
    )
  }

  templatePages: any[] = [];
  pageSeoslected: any;

  savechangesLoader: boolean = false;

  // totalWebsiteData : any;

  getWebsiteLoader: boolean = true;
  duplicateTemplatePages : any[] = [];
  websiteData: any[] = [];
  templateId: string | null = null;

  getWebsiteDetails() {
    if (!this.templateId)
      return;
    
    this.templateService.getWebsiteTemplate(this.templateId).subscribe((response: any)=> {
      this.websiteData = response.data;
      setTimeout(()=> {
        const instance = this.pickupService.dyanmicComponentPickup(this.displayWebsite, this.websiteData, true, true);
        this.initiateSubscriptionV2(instance);
      }, 500);
    })
  }
  
  async getWebsite() {
   
    // await this.templateService.getWebsiteWithAllPages(this.businessDetails.templateId).toPromise().then(
    //   (res: any) => {
    //     this.currentPageIndex = 0;
    //     // console.log(res.data)
    //     this.duplicateTemplatePages = [];
    //     this.templatePages = res.data;
    //     this.duplicateTemplatePages = JSON.parse(JSON.stringify(res.data));
    //     this.selectedPage = this.templatePages[0].pageName;

    //     this.originalTheme = this.templatePages[0];

    //     this.templatePages.forEach((pages: any) => {
    //       if (pages.seoData === null) {
    //         pages.seoData = JSON.parse(JSON.stringify(this.seoData))
    //       }
    //     });
    //     this.buttonStyles.forEach((btnStyles) => {
    //       if (btnStyles.value === res.data[0].btnStyle) {
    //         this.bStyle = btnStyles.value
    //       }
    //     });

    //     //attaching current button shapes
    //     this.buttonShape.forEach((btnShapes) => {
    //       if (btnShapes.value === res.data[0].btnShape) {
    //         this.bShape = btnShapes.value;
    //       }
    //     });

    //     this.selectedButtonStyle = res.data[0].btnStyle;
    //     //attaching button color and button text color
    //     this.btnColor = res.data[0].btnColor;
    //     this.btnTextColor = res.data[0].btnTextColor;
    //     // this.totalWebsiteData = res.data;
    //     this.pageSeoslected = this.templatePages[0].id;
    //     this.eventHelper.pageLevelEdit.emit({ status: true })
    //     const instance: any = this.pickupService.dyanmicComponentPickup(this.displayWebsite, res.data[0].components, true);
    //     this.initiateSubscription(instance);
    //     this.eventHelper.sendingPageData.emit({ data: this.templatePages[0] })
    //     this.getWebsiteLoader = false;
    //     // if (this.screenWidth < 475) {
    //     //   this.openGlobalMobileEditor();
    //     //   this.initiateSubscription(instance);
    //     // }

    //     this.getWebsiteTheme();
    //     this.getAllThemes();
    //   }
    // ).catch((err) => {
    //   this.getWebsiteLoader = false;
    //   this.templateService.openSnack(
    //     `${err.error.message}`,
    //     'Close'
    //   )
    // })
  }

  openEditorPopup(response: any) {
    this._websiteService.openBottomSheet(MobileEditorRightSidePanelComponent, { index: response.index, data: response.data, samePageComponents: this.templatePages[this.currentPageIndex], pageLevelEdit: false, isEditor: true, })
    // console.log(response)
  }

  openGlobalMobileEditor() {
    this._websiteService.openBottomSheet(MobileEditorRightSidePanelComponent, { totalWebsiteData: this.templatePages, allPages: this.templatePages, samePageComponents: this.templatePages[this.currentPageIndex], pageLevelEdit: true, isEditor: false })
  }

  openMobileDropdown() {
    const data = this._websiteService.openBottomSheet(MobileEditorRightSidePanelComponent, { totalWebsiteData: this.templatePages, allPages: this.templatePages, samePageComponents: this.templatePages[this.currentPageIndex], pageLevelEdit: true, isEditor: false })
    data.afterDismissed().subscribe((res) => {
      if (res.action === 'pageChange') {
        this.pageSelectionChange({ value: res.pageName })
      }
      if (res.action === 'addPage') {
        this.pageSelectionChange({ value: 'addPage' })
      }
      if (res.action === 'deletePage') {
        this.pageDelete(res.pageName)
      }
      if (res.action === 'openEditor') {
        this._websiteService.openBottomSheet(MobileEditorRightSidePanelComponent, { totalWebsiteData: this.templatePages, allPages: this.templatePages, samePageComponents: this.templatePages[res.pageName], pageLevelEdit: true, isEditor: true })
      }
    })
  }

  localNewPageArray: any = [];
  selectedPage: any;
  pageSelectionChange(ev,showEditor?:boolean) {
    this.templatePages.forEach((elem) => {
      if (elem.id === ev.value) {
        this.selectedPage = elem.pageName
      }
    });
    if (this.templatePages.find(tp => tp['id'] === null)) {
      if (this.templatePages[this.templatePages.length - 1].components.length == 3 && this.templatePages[this.templatePages.length - 1].components[1].webComponentName === 'new_page') {
        this.templatePages[0].components[0].itemStyles.splice(this.templatePages[0].components[0].itemStyles.length - 1)
        this.templatePages.splice(this.templatePages.length - 1)
        this.pageSeoslected = this.templatePages[0].id;
        this.eventHelper.pageLevelEdit.emit({ status: true })
        this.eventHelper.sendingPageData.emit({ data: this.templatePages[0] });
        this.displayWebsite.clear();
        const instance: any = this.pickupService.dyanmicComponentPickup(this.displayWebsite, this.templatePages[0].components, true);
        this.initiateSubscription(instance);
        this.selectedPage = this.templatePages[0].pageName;
        this.currentPageIndex = 0;
        if (this.screenWidth < 475) {
          //this.openGlobalMobileEditor();
        }
      } else {
        let warningPopupModel = this.templateService.openDialog(
          WarningPopupComponent,
          '268px',
          '400px',
          { data: null }
        );

        warningPopupModel.afterClosed().subscribe(
          (res: any) => {
            if (res.data === 'DISCARD') {
              this.templatePages[0].components[0].itemStyles.splice(this.templatePages[0].components[0].itemStyles.length - 1)
              this.templatePages.splice(this.templatePages.length - 1)
              this.pageSeoslected = this.templatePages[0].id;
              this.eventHelper.pageLevelEdit.emit({ status: true })
              this.eventHelper.sendingPageData.emit({ data: this.templatePages[0] });
              this.displayWebsite.clear();
              const instance: any = this.pickupService.dyanmicComponentPickup(this.displayWebsite, this.templatePages[0].components, true);
              this.initiateSubscription(instance);
              this.selectedPage = this.templatePages[0].pageName;
              this.currentPageIndex = 0;
              if (this.screenWidth < 475) {
                //this.openGlobalMobileEditor();
              }
            }
            else {
              this.saveChanges();
            }
          }
        )
      }

    }
    else {
      this.eventHelper.pageLevelEdit.emit({ status: true })
      if (ev.value === 'addPage') {
        this.localNewPageArray = [];
        this.localNewPageArray.push(JSON.parse(JSON.stringify(this.templatePages[0].components[0])));
        let newPageObject = {
          webComponentName: 'new_page',
          category: {
            name: 'new_page'
          }
        }
        this.localNewPageArray.push(JSON.parse(JSON.stringify(newPageObject)));
        this.localNewPageArray.push(JSON.parse(JSON.stringify(this.templatePages[0].components[this.templatePages[0].components.length - 1])));
        this.displayWebsite.clear();
        let newPageData = Object.assign({}, JSON.parse(JSON.stringify(this.templatePages[0])));
        newPageData.homePageId = this.templatePages[0].id;
        newPageData.id = null;
        newPageData.pageName = this.templatePages.length < 2 ? 'New Page' : ('New Page ' + String(this.templatePages.length - 1));
        newPageData.path = this.templatePages.length < 2 ? '/New_Page' : '/New_Page_' + String(this.templatePages.length - 1);
        this.selectedPage = newPageData.pageName;
        newPageData.components = this.localNewPageArray;
        newPageData.components[0].itemStyles.push({
          item: {
            displayValue: this.selectedPage,
            redirectTo: newPageData.path,
            redirectionType: 'INTERNAL',
            status: true,
            highlight: false
          }
        });
        newPageData.seoData.metaTitle = '';
        newPageData.seoData.metaDescription = '';
        newPageData.seoData.keywords = '';
        this.templatePages.push(newPageData);

        this.currentPageIndex = this.templatePages.length - 1;
        const instance: any = this.pickupService.dyanmicComponentPickup(this.displayWebsite, this.localNewPageArray, true);
        this.initiateSubscription(instance);
        this.eventHelper.sendingPageData.emit({ data: this.templatePages[this.currentPageIndex] });

        let addingNavbar = {
          displayValue: this.templatePages[this.currentPageIndex].pageName,
          redirectTo: this.templatePages[this.currentPageIndex].path,
          redirectionType: 'INTERNAL',
          status: true,
          highlight: false
        }
        this.templatePages[0].components[0].itemStyles.push({ item: JSON.parse(JSON.stringify(addingNavbar)) });
        this.eventHelper.sendingNewPageData.emit({ data: JSON.parse(JSON.stringify(addingNavbar)) })

        // console.log(this.templatePages)
        if (this.screenWidth < 475) {
          //this.openGlobalMobileEditor()
          this.initiateSubscription(instance);
        }
      }
      else {
        this.displayWebsite.clear()
        for (var i = 0; i < this.templatePages.length; i++) {
          if (this.templatePages[i].id === ev.value) {
            this.currentPageIndex = i;
            if (i != 0) {
              const temp = Array();
              if(!(this.templatePages[i].components[0].category.name === 'Top Nav Bar')){
                temp.push(this.templatePages[0].components[0]);
              }
              temp.push(...this.templatePages[i].components);
              if(!(this.templatePages[i].components[this.templatePages[i].components.length - 1].category.name === 'Footer')){
                temp.push(this.templatePages[0].components[this.templatePages[0].components.length - 1]);
              }

              const instance: any = this.pickupService.dyanmicComponentPickup(this.displayWebsite, temp, true);
              if (this.screenWidth < 475) {
                //this.openGlobalMobileEditor()
                this.initiateSubscription(instance);
              }
              this.initiateSubscription(instance);
            } else {
              const instance: any = this.pickupService.dyanmicComponentPickup(this.displayWebsite, this.templatePages[0].components, true);
              this.initiateSubscription(instance);
              if (this.screenWidth < 475) {
                //this.openGlobalMobileEditor()
                this.initiateSubscription(instance);
              }
            }
            if (this.templatePages[i].seoData.metaTitle === '') {
              this.templatePages[i].seoData = JSON.parse(JSON.stringify(this.seoData));
            }
            this.eventHelper.sendingPageData.emit({ data: this.templatePages[i] });
          }
        }
      }
    }
    this.showEditor = showEditor ? showEditor: false;
  }


  showEditor:boolean = false;
  subscriptions: any[] = [];
  openeditorSectionData : any;
  duplicateOfEditSectionData : any;

  private initiateSubscriptionV2(instance: any) { 
    if (!instance) return;
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
    this.subscriptions.push(
      instance.addNewSectionClick.subscribe((response)=> {
        this.addNewSectionCick(response);
      })
    );
    this.subscriptions.push(
      instance.editSection.subscribe((response)=> {
        this.openEditorPopup(response)
      })
    )
    this.subscriptions.push(
      instance.regenerateSection.subscribe((response)=> {
        this.regenerateComponent(response);
      })
    )
    this.subscriptions.push(
      instance.delete.subscribe((response)=> {
        response.pageLevelEditor = true;
        this.eventHelper.editSection.emit(response);
        this.deleteComponent(response);
      })
    )
  }
  private initiateSubscription(instance: any) {
    if (!instance) return;
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
    this.subscriptions.push(
      instance.mobileEditorOpen.subscribe((response: any) => {
        this.openEditorPopup(response)
      })
    )
    this.subscriptions.push(
      instance.changeContent.subscribe((response: any) => {
        this.changeComponentContent(response);
      })
    )
    this.subscriptions.push(
      instance.componentPositionMoving.subscribe((response: any) => {
        this.componentPositionMove(response);
      })
    )
    this.subscriptions.push(
      instance.componentDelete.subscribe((response: any) => {
        response.pageLevelEditor = true;
        this.eventHelper.editSection.emit(response);
        this.deleteComponent(response);

      })
    )
    this.subscriptions.push(
      instance.regenerateComponent.subscribe((response: any) => {
        this.regenerateComponent(response);
      })
    )
    // this.subscriptions = [];
    this.subscriptions.push(
      instance.addNewSectionCick.subscribe((response: any) => {
        this.addNewSectionCick(response);
      })
    )
    this.subscriptions.push(
      instance.editSection.subscribe((response: any) => {
        this.showEditor = true;
        this._componentEditing = response?.index;
        this.openeditorSectionData = response;
        this.duplicateOfEditSectionData = JSON.parse(JSON.stringify(response));
        this.categoryName = response.data.category.name;
        this.isPageLevelEdit = false;
        this.collpaseEditor = true
        this.eventHelper.editSection.emit(response);
      })
    )
  }

  addNewSectionCick(res: any) {
    this.unSubscribeNewSectionSubcription();
    let index;
    let addingComponentPosition;
    index = res.index;

    if (this._componentEditing && this._componentEditing <= index)
      this._componentEditing = this._componentEditing ? this._componentEditing+1 : this._componentEditing;

    if (res.position != undefined) {
      addingComponentPosition = res.position;
    }
    else {
      addingComponentPosition = 'BELOW';
    }
    if (res.status) {
      let customElements = this.templateService.openDialog(
        ListCustomElementsComponent,
        '674px',
        '1017px',
        'auto'
      );
      this.customElementSubscription = customElements.afterClosed().subscribe(
        (res: any) => {
          this.getWebsiteLoader = true;
          if (res.responseData != '') {
            this.checkHeaderAndFooter()
            if (this.templatePages[this.currentPageIndex]?.components[1].webComponentName === 'new_page') {
              this.displayWebsite.clear();

              this.templatePages[this.currentPageIndex].components.splice(1,1,res.responseData)
              //this.localNewPageArray.splice(1, 1, JSON.parse(JSON.stringify(res.responseData)));

              this.pickupService.dyanmicComponentPickup(this.displayWebsite, this.templatePages[this.currentPageIndex].components, true);

            }
            else {
              if (addingComponentPosition === 'ABOVE') {
                this.templatePages[this.currentPageIndex].components.splice(index, 0, JSON.parse(JSON.stringify(res.responseData)));
                this.pickupService.addComponent(this.displayWebsite, JSON.parse(JSON.stringify(res.responseData)), true, index);
                this.eventHelper.editSection.emit({ index: index, data: res.responseData })
              }
              else {
                this.templatePages[this.currentPageIndex].components.splice(index + 1, 0, JSON.parse(JSON.stringify(res.responseData)));
                this.pickupService.addComponent(this.displayWebsite, JSON.parse(JSON.stringify(res.responseData)), true, index + 1);
                this.eventHelper.editSection.emit({ index: index + 1, data: res.responseData })
              }
            }
            if(this.templatePages[this.currentPageIndex].id){
              this.updateNewSectionSubscription = this.templateService.updateNewSection(addingComponentPosition === 'ABOVE' ? index : index + 1,this.templatePages[this.currentPageIndex].id,res.responseData).subscribe((res:any) => {
              },() => {
                this._snackBar.open('some error occured','close',{duration: 3000});
              })
            }
          }
          else {
            if (res.localElement != '') {
              this.webComponentSubscription = this.templateService.getLocalComponentWithData(addingComponentPosition === 'ABOVE' ? index : index + 1, this.templatePages[0].id, res.localElement.componentId, this.businessDetails.subIndustryId,this.templatePages[this.currentPageIndex].id).subscribe(
                (res: any) => {

                  const randomIndex = Math.floor(Math.random() * (this.websiteTheme?.componentStyle?.length ?? 1));
                  let randomComponentStyle;
                  if (this.websiteTheme?.componentStyle) {
                    randomComponentStyle = this.websiteTheme?.componentStyle[randomIndex] as COMPONENT_STYLE;
                    // console.log(res.data);
                    res.data.bgColor = randomComponentStyle.bgColor;
                    this.applyThemeToComponent(res.data, randomComponentStyle);
                  }


                  // CHANGING TEXT COLOR OF HEADING, DESCRIPTION
                  // var textColor = this.changeColor(res.data.bgColor);
                  // if (res.data.styles) {
                  //   for (const key in res.data.styles) {
                  //     if (res.data.styles[key].color && !key.includes("button")) {
                  //       res.data.styles[key].color = textColor
                  //     }
                  //   }
                  // }

                  // CHANGING TEXT COLOR OF ITEMSTYLES
                  // if (res.data.itemStyles) {
                  //   for (let style of res.data.itemStyles) {
                  //     var styleColor = '';
                  //     if (style.backgroundColor) {
                  //       styleColor = style.backgroundColor === "transparent" ? textColor : this.changeColor(style.bgColor);
                  //     } else {
                  //       style.backgroundColor = 'white'
                  //     }

                  //     for (const key in style) {
                  //       if (style[key].color && !key.includes("button")) {
                  //         style[key].color = styleColor ? styleColor : "#000000";
                  //       }
                  //     }
                  //   }
                  // }

                  if (this.localNewPageArray[1]?.webComponentName === 'new_page') {
                    this.displayWebsite.clear();
                    this.localNewPageArray.splice(1, 1, res.data);
                    // this.localNewPageArray.push(res.data);
                    this.pickupService.dyanmicComponentPickup(this.displayWebsite, this.localNewPageArray, true);
                  }
                  else {
                    if (addingComponentPosition === 'ABOVE') {
                      this.templatePages[this.currentPageIndex].components.splice(index, 0, res.data)
                      this.pickupService.addComponent(this.displayWebsite, res.data, true, index);
                      this.eventHelper.editSection.emit({ index: index, data: res.data })
                    }
                    else {
                      this.templatePages[this.currentPageIndex].components.splice(index + 1, 0, res.data)
                      // console.log()
                      // this.templatePages[this.currentPageIndex].components.forEach((element,tempIndex) => {
                      if (this.templatePages[this.currentPageIndex].components.some(a => a?.webComponentName === 'new_page')) {
                        this.templatePages[this.currentPageIndex].components = this.templatePages[this.currentPageIndex].components.filter(e => e.webComponentName !== 'new_page')
                        let component = [...this.templatePages[this.currentPageIndex].components];
                        if (!this.templatePages[this.currentPageIndex].components[0].webComponentName.includes("nav"))
                          component.splice(0, 0, this.templatePages[0].components[0]);
                        if (!this.templatePages[this.currentPageIndex].components[this.templatePages[this.currentPageIndex].components.length - 1].webComponentName.includes("footer"))
                          component.push(this.templatePages[0].components[this.templatePages[0].components.length - 1])
                        this.pickupService.dyanmicComponentPickup(this.displayWebsite, component, true)
                      }
                      else {
                        this.pickupService.addComponent(this.displayWebsite, res.data, true, index + 1);
                        this.eventHelper.editSection.emit({ index: index + 1, data: res.data });
                      }
                      // });
                      // console.log(this.templatePages[this.currentPageIndex].components)
                    }
                  }
                },
                (err) => {
                  this.templateService.openSnack(
                    `${err.error.message}`,
                    'Close'
                  )
                }
              )

            }
          }
          this.getWebsiteLoader = false;
        }
      )
    }
    //   }
    // )
  }

  updateNewSectionSubscription:Subscription | undefined;
  webComponentSubscription:Subscription | undefined;
  customElementSubscription:Subscription | undefined;
  addNewSectionClickV2(res: any){
    this.unSubscribeNewSectionSubcription();
    let index = res.position === 'ABOVE' ? res.index : res.index + 1;
    if(res.status){

      let customElements = this.templateService.openDialog(
        ListCustomElementsComponent,
        '674px',
        '1017px',
        'auto'
      );

      this.customElementSubscription = customElements.afterClosed().subscribe((element:any) => {
        //this.getWebsiteLoader = true;
        // CHECKING HEADER AND FOOTER IS PRESENT IN CURRENT PAGE IF NOT THEN ADDING
        this.checkHeaderAndFooter()

        // CHECKING USER HAS ADDED ANY COMPONENT OR NOT. responseData = custom component & localElement = web component
        if(element.responseData || element.localElement){

          let noComponentFound = false;
          // THIS MEANS NO COMPONENT IS THERE IN CURRENT PAGE IF NOT THEN REMOVE THE ADD PAGE COMPONENT TO ADD NEW COMPONENT
          if(this.templatePages[this.currentPageIndex].components[1].webComponentName === 'new_page'){
            noComponentFound = true;
            this.displayWebsite.clear();
            this.templatePages[this.currentPageIndex].components.splice(1,1);
          }

          // ADDING CUSTOM COMPONENTS
          if(res.responseData){

            this.templatePages[this.currentPageIndex].components.splice(index,0,res.responseData);
            if(noComponentFound){
              const instance = this.pickupService.dyanmicComponentPickup(this.displayWebsite,this.templatePages[this.currentPageIndex].components,true);
              this.initiateSubscription(instance);
            }else{
              this.pickupService.addComponent(this.displayWebsite,res.responseData,true,index);
            }
            this.eventHelper.editSection.emit({ index: index, data: res.responseData })

            if(this.templatePages[this.currentPageIndex].id){
              this.updateNewSectionSubscription = this.templateService.updateNewSection(index,this.templatePages[this.currentPageIndex].id,res.responseData).subscribe((res:any) => {
              },() => {
                this._snackBar.open('some error occured','close',{duration: 3000});
              })
            }

          }
          // ADDING WEB COMPONENTS
          else{
            this.webComponentSubscription = this.templateService.getLocalComponentWithData(index, this.templatePages[0].id, res.localElement.componentId, this.businessDetails.subIndustryId,this.templatePages[this.currentPageIndex].id).subscribe((data:any) => {
              let newComponent = data.data;

              const randomIndex = Math.floor(Math.random() * (this.websiteTheme?.componentStyle?.length ?? 1));
              let randomComponentStyle;
              if (this.websiteTheme?.componentStyle) {
                randomComponentStyle = this.websiteTheme?.componentStyle[randomIndex] as COMPONENT_STYLE;
                // console.log(res.data);
                newComponent.bgColor = randomComponentStyle.bgColor;
                this.applyThemeToComponent(newComponent, randomComponentStyle);
              }

              // CHANGING TEXT COLOR OF HEADING, DESCRIPTION
              var textColor = this.changeColor(newComponent.bgColor);
              if (newComponent.styles) {
                for (const key in newComponent.styles) {
                  if (newComponent.styles[key].color) {
                    newComponent.styles[key].color = textColor
                  }
                }
              }

              // CHANGING TEXT COLOR OF ITEMSTYLES
              if (newComponent.itemStyles) {
                for (let style of newComponent.itemStyles) {
                  var styleColor = '';
                  if (style.backgroundColor) {
                    styleColor = style.backgroundColor === "transparent" ? textColor : this.changeColor(style.bgColor);
                  } else {
                    style.backgroundColor = 'white'
                  }

                  for (const key in style) {
                    if (style[key].color) {
                      style[key].color = styleColor ? styleColor : "#000000";
                    }
                  }
                }
              }

              this.templatePages[this.currentPageIndex].components.splice(index,0,newComponent);
              if(noComponentFound){
                const instance = this.pickupService.dyanmicComponentPickup(this.displayWebsite,this.templatePages[this.currentPageIndex].components,true);
                this.initiateSubscription(instance);
              } else{
                this.pickupService.addComponent(this.displayWebsite,newComponent,true,index);
              }
              this.eventHelper.editSection.emit({ index: index, data: newComponent });
            })
          }
        }

      })
    }
  }

  checkHeaderAndFooter(){
    // ADDING HEADER IN CURRENT PAGE IF NOT PRESENT
    if(this.templatePages[this.currentPageIndex].components[0].category.name !== 'Top Nav Bar'){
      this.templatePages[this.currentPageIndex].components.splice(0,0,this.templatePages[0].components[0]);
    }
    // ADDING FOOTER IN CURRENT PAGE IF NOT PRESENT
    if(this.templatePages[this.currentPageIndex].components[this.templatePages[this.currentPageIndex].components.length - 1].category.name !== 'Footer'){
      this.templatePages[this.currentPageIndex].components.push(this.templatePages[0].components[this.templatePages[0].components.length - 1]);
    }
  }

  unSubscribeNewSectionSubcription(){
    if(this.updateNewSectionSubscription){
      this.updateNewSectionSubscription.unsubscribe();
    }
    if(this.webComponentSubscription){
      this.webComponentSubscription.unsubscribe();
    }
    if(this.customElementSubscription){
      this.customElementSubscription.unsubscribe();
    }
  }
  changeAnimation(){
    this.changeAnimationSubscribe = this.eventHelper.changeAnimation.subscribe((res) => {
      const instance = this.pickupService.updateComponent(this.displayWebsite,res.index,res.newData)
    })
  }
  changeColor(bgColor:any){
    var bgColor = bgColor
    const threshold = 130; // Adjust this threshold as needed
    const r = parseInt(bgColor.slice(1, 3), 16);
    const g = parseInt(bgColor.slice(3, 5), 16);
    const b = parseInt(bgColor.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return brightness > threshold ? '#000000' : '#ffffff';
  }

  applyToSaveChanges() {
    this.getWebsiteLoader = true
    this.applyToSaveChangesSubscribe = this.eventHelper.saveChanges.subscribe(
      (res: any) => {
        if (res.saveChanges) {
          if(res.data){
            if(this.templatePages[this.currentPageIndex].components[0].webComponentName.includes('nav-bar'))
              this.templatePages[this.currentPageIndex].components[res.index] = res.data;
            else
              this.templatePages[this.currentPageIndex].components[res.index - 1] = res.data;
          }
          this.updatePageData();
          // this.getWebsiteLoader = false
        }
      }
    )
  }

  saveChanges() {
    this.getWebsiteLoader = true;
    // this.templatePages[this.currentPageIndex].components.forEach((component, i) => {
    // console.log(component.styles['button']?.redirectTo,'---',component.category.name);
    // if(component.category.name === 'Top Nav Bar' || component.category.name === 'Contact Us' || component.category.name === 'Footer'){
    // if (component.styles !== null) {
    // Object.keys(component.styles).forEach(key => {
    //   if(component.styles[key]['lm_type'] != null && component.styles[key]['lm_type'].split('_')[0] === 'BUTTON') {
    //     if(component.styles[key]['redirectionType'] != null) {
    //       if(component.styles[key]['redirectTo'] === null || component.styles[key]['redirectTo'] === '') {
    //         console.log("Please enter a valid link for " + component.styles[key]['value'] + " button");
    //         //popup with continue and update
    //         // continue reset and close popup || update close popup
    //       }
    //     }
    //     else{
    //       this.updatePageData();
    //     }
    //   }
    // });
    this.updatePageData();
    // }
    // });
  }

  updatePageDataWithoutLoader() {
    // this.getWebsiteLoader =true
    let data = [
      this.templatePages[this.currentPageIndex]
    ]
    this.templateService.updateData(data).subscribe(
      (res: any) => {
        // console.log(res.data);
        this.templatePages[this.currentPageIndex] = res.data[0];
        // this.getWebsiteLoader = false;
      },
      (err) => {
        // this.getWebsiteLoader = false;
        // console.log(err.error.message)
      }
    )
  }

  updatePageData() {
    this.duplicateTemplatePages = [];
    this.getWebsiteLoader = true
    if (this.templatePages[this.currentPageIndex].path === '') {
    }
    else {
      if(this.templatePages[this.currentPageIndex].components[0].category.name === 'Top Nav Bar'){
        this.templatePages[this.currentPageIndex].components.splice(0, 1);
      }
      if(this.templatePages[this.currentPageIndex].components[this.templatePages[this.currentPageIndex].components.length - 1].category.name === 'Footer'){
        this.templatePages[this.currentPageIndex].components.splice(this.templatePages[this.currentPageIndex].components.length - 1, 1);
      }
      // if (this.templatePages[this.currentPageIndex].components[0].category.name === 'Top Nav Bar' &&
      //   this.templatePages[this.currentPageIndex].components[this.templatePages[this.currentPageIndex].components.length - 1].category.name === 'Footer') {
      //   this.templatePages[this.currentPageIndex].components.splice(0, 1);
      //   this.templatePages[this.currentPageIndex].components.splice(this.templatePages[this.currentPageIndex].components.length - 1, 1);
      // }
    }

    let data = [
      this.templatePages[this.currentPageIndex]
    ]
    if (data[0].id == null || this.isPageNameChanged) {
      let homePageData = [
        this.templatePages[0]
      ]
      this.templateService.updateData(homePageData).subscribe((res: any) => { })
    }
    this.templateService.updateData(data).subscribe(
      (res: any) => {
        this.templatePages[this.currentPageIndex] = res.data[0];
        // const instance: any = this.pickupService.dyanmicComponentPickup(this.displayWebsite, this.templatePages[this.currentPageIndex].components, true);
        // this.initiateSubscription(instance);
        // this.openeditorSectionData = this.openeditorSectionData
        this.duplicateTemplatePages = JSON.parse(JSON.stringify(res.data));
        this.pageSeoslected = this.templatePages[this.currentPageIndex].id;
        this.eventHelper.pageLevelEdit.emit({ status: true })
        this.eventHelper.sendingPageData.emit({ data: this.templatePages[this.currentPageIndex] });
        this.getWebsiteLoader = false;
        this.showEditor = false
      },
      (err) => {
        this.getWebsiteLoader = false;
      }
    )
  }


  // component regenerate
  regenerateComponent(res: any) {
    // this.getWebsiteLoader = true;
    //  this.regenerateSubscribe =  this.eventHelper.regenerateComponent.subscribe(
    //     (res:any) => {
    // console.log(this.websiteData.components[res.indexValue])
    if (this.templatePages[this.currentPageIndex].id != null) {
      setTimeout(() => {
        let data = {
          index: res.indexValue,
          templateId: this.templatePages[this.currentPageIndex].id,
          subIndustryId: this.businessDetails.subIndustryId
        }
        this.templateService.regenerateComponent(data).subscribe(
          (comingData: any) => {
            // ADDING HEADER IN CURRENT PAGE IF NOT PRESENT
            if(this.templatePages[this.currentPageIndex].components[0].category.name !== 'Top Nav Bar'){
              this.templatePages[this.currentPageIndex].components.splice(0,0,this.templatePages[0].components[0]);
            }
            // ADDING FOOTER IN CURRENT PAGE IF NOT PRESENT
            if(this.templatePages[this.currentPageIndex].components[this.templatePages[this.currentPageIndex].components.length - 1].category.name !== 'Footer'){
              this.templatePages[this.currentPageIndex].components.push(this.templatePages[0].components[this.templatePages[0].components.length - 1]);
            }
            this.templatePages[this.currentPageIndex].components.splice(res.indexValue, 1, comingData.data);
            this.pickupService.dyanmicComponentPickup(this.displayWebsite, this.templatePages[this.currentPageIndex].components, true);
            this.eventHelper.editSection.emit({ index: res.indexValue, data: comingData.data })
            this.updatePageDataWithoutLoader();
            // this.pickupService.replaceComponent(this.websiteStructure,this.websiteData.components[res.indexValue],comingData.data,true,false);
            // this.getWebsiteLoader = false;
          },
          (err) => {
            this.templateService.openSnack(
              `${err.error.message}`,
              'Close'
            )
          }
        )
      }, 500);
    }
    else {
      this.getWebsiteLoader = false;
      this._snackBar.open(
        `Please save the page to regenerate`,
        'Ok',
        {
          duration: 1500
        }
      )
    }
    //   }
    // )
  }

  //change component content

  changeComponentContent(res: any) {
    // this.changeComponentContentSubscribe = this.eventHelper.changeContent.subscribe(
    //   (res:any) => {
    if (res.changeContent) {
      let data = {
        index: res.indexValue,
        templateId: this.templatePages[this.currentPageIndex].id,
        subIndustryId: this.businessDetails.subIndustryId
      }
      this.templateService.changeComponentContent(data).subscribe(
        {
          next: (comingData: any) => {
            this.templatePages[this.currentPageIndex].components.splice(res.indexValue, 1, comingData.data);
            this.pickupService.dyanmicComponentPickup(this.displayWebsite, this.templatePages[this.currentPageIndex].components, true);
          },
          error: (err) => {
            this.templateService.openSnack(
              `${err.error.message}`,
              'Close'
            )
          }
        }
      )
    }
    //   }
    // )
  }

  //component positions moving
  componentPositionMove(res: any) {
    // this.componentPositionMoveSubscribe = this.eventHelper.componentPositionMoving.subscribe(
    //   (res:any) => {
    let indexA;
    let indexB;
    if (res.movingDirection === 'DOWN') {
      indexA = res.index;
      indexB = res.index + 1;
      if(indexB == this.templatePages[this.currentPageIndex].components.length - 1){
        this._snackBar.open('Last position reached','close',{duration : 3000})
        return;
      }
    }
    else if (res.movingDirection === 'UP') {
      indexA = res.index;
      indexB = res.index - 1;
    }
    let arr = this.templatePages[this.currentPageIndex].components;
    arr[indexA] = arr.splice(indexB, 1, arr[indexA])[0];
    this.pickupService.dyanmicComponentPickup(this.displayWebsite, this.templatePages[this.currentPageIndex].components, true);
    //   }
    // )

    setTimeout(() => {
      const element = document.getElementById(this.templatePages[this.currentPageIndex].components[indexB].name);
      element?.scrollIntoView({
        behavior: 'smooth'
      });
    }, 100);
  }

  //delete particular index component from the page
  deleteComponent(res: any) {
    let index = res.index;
    // this.deleteComponentSubscribe =  this.eventHelper.componentDelete.subscribe(
    //   (res:any) => {
    if(!this.templatePages[this.currentPageIndex].id){
      this.deleteComponentFromTemplate(res,index);
      return;
    }
     this.templateService.deleteComponent(index,this.templatePages[this.currentPageIndex].id).subscribe((res:any) => {


      // if (this._componentEditing === )
      this.showEditor = this._componentEditing != index && this.showEditor;
      this.deleteComponentFromTemplate(res,index);
    },() => {
        this._snackBar.open('Some Error occurred','close',{duration : 3000});
        return;
    })

  }

  deleteComponentFromTemplate(res:any,index:number){
    if (this.currentPageIndex === 0) {
      this.templatePages[this.currentPageIndex].components.splice(index, 1);
      this.pickupService.dyanmicComponentPickup(this.displayWebsite, this.templatePages[this.currentPageIndex].components, true);
    }
    else {
      // if (this.templatePages[this.currentPageIndex].components[0].category.name === 'Top Nav Bar'
      //   && this.templatePages[this.currentPageIndex].components[this.templatePages[this.currentPageIndex].components.length - 1].category.name === 'Footer') {
      //   this.templatePages[this.currentPageIndex].components.splice(index, 1);
      // }
      // else {
      //   this.templatePages[this.currentPageIndex].components.splice(index - 1, 1);
      //   this.templatePages[this.currentPageIndex].components.splice(0, 0, this.templatePages[0].components[0])
      //   this.templatePages[this.currentPageIndex].components.push(this.templatePages[0].components[this.templatePages[0].components.length - 1])
      // }

      // ADDING HEADER IN CURRENT PAGE IF NOT PRESENT
      if(this.templatePages[this.currentPageIndex].components[0].category.name !== 'Top Nav Bar'){
        this.templatePages[this.currentPageIndex].components.splice(0,0,this.templatePages[0].components[0]);
      }
      // ADDING FOOTER IN CURRENT PAGE IF NOT PRESENT
      if(this.templatePages[this.currentPageIndex].components[this.templatePages[this.currentPageIndex].components.length - 1].category.name !== 'Footer'){
        this.templatePages[this.currentPageIndex].components.push(this.templatePages[0].components[this.templatePages[0].components.length - 1]);
      }
      this.templatePages[this.currentPageIndex].components.splice(index, 1);
      // console.log(this.templatePages[this.currentPageIndex].components.length.push)
      this.pickupService.dyanmicComponentPickup(this.displayWebsite, this.templatePages[this.currentPageIndex].components, true);
    }
    if ((this.templatePages[this.currentPageIndex].components.length === 2 && this.templatePages[this.currentPageIndex].components[0].category.name === 'Top Nav Bar') ||
      (this.templatePages[this.currentPageIndex].components.length === 2 && this.templatePages[this.currentPageIndex].components[1].category.name === 'Footer"')) {
      let newPageObject = {
        webComponentName: 'new_page',
        category: {
          name: 'new_page'
        }
      };
      this.templatePages[this.currentPageIndex].components.splice(1, 0, JSON.parse(JSON.stringify(newPageObject)));
      this.pickupService.dyanmicComponentPickup(this.displayWebsite, this.templatePages[this.currentPageIndex].components, true);
    }
  }

  backToEditor() {
    this._router.navigate(['/admin/website/preview'])
  }


  //site level restyle


  positionRestyleTop: any = '';
  positionRestyleRight: any = '';
  restyleComponent: boolean = false;
  bStyle: any;
  buttonStyles = buttonStyle;
  bShape: any;
  buttonShape = buttonShape;
  btnColor: any;
  btnTextColor: any;

  // openRestyleComponent() {
  //   this.restyleComponent = true;
  //   let a = document.getElementById('restyle_component');
  //   let position = a?.getBoundingClientRect();
  //   this.positionRestyleRight = position?.top;
  //   this.positionRestyleTop = position?.right;
  // }
  cancelRestyleComponent() {
    this.restyleComponent = false;
  }

  restyleWebsite: boolean = false;
  saveGlobalRestyle() {
    this.restyleWebsite = true;
    let data = {
      homePageId: this.templatePages[0].id,
      btnShape: this.bShape,
      btnStyle: this.bStyle,
      btnColor: this.btnColor,
      btnTextColor: this.btnTextColor
    }
    this.templateService.reStylePages(data).subscribe(
      (res: any) => {
        this.restyleWebsite = false
        // console.log(res.data);
        this.displayWebsite.clear();
        this.templatePages = res.data;
        this.pageSeoslected = this.templatePages[0].id;

        this.templatePages = res.data;

        this.templatePages.forEach(tempPages => {
          //attaching current button styles
        this.buttonStyles.forEach((btnStyles) => {
          if (btnStyles.value === tempPages.btnStyle) {
            this.bStyle = btnStyles.value
          }
        });

        //attaching current button shapes
        this.buttonShape.forEach((btnShapes) => {
          if (btnShapes.value === tempPages.btnShape) {
            this.bShape = btnShapes.value;
          }
        });

        //attaching button color and button text color
        this.btnColor = tempPages.btnColor;
        this.btnTextColor = tempPages.btnTextColor;

        });


        // this.dyanmicComponentPickup(this.websiteStructure,res.data,true);
        const instance: any = this.pickupService.dyanmicComponentPickup(this.displayWebsite, res.data[0].components, true)
        this.initiateSubscription(instance);
        this.restyleComponent = false;
        this.updatePageData();
      },
      (err) => {
        this.restyleWebsite = false;
        // console.log(err.error.message);
        this._snackBar.open(
          `${err.error.message}`,
          'Close',
          {
            duration: 1500
          }
        )
      }
    )
  }


  //share website url
  shareWebsite() {
    if (this.screenWidth > 475) {
      this.templateService.openDialog(ShareWebsiteComponent, 'auto', '490px', {
        webId: this.businessDetails.websiteUrl,
      });
    }
    else {
      this.templateService.openDialog(ShareWebsiteComponent, 'auto', '94%', {
        webId: this.businessDetails.websiteUrl,
      });
    }
  }

  //preview website
  previewCurrentWebsite() {
    window.open(this.businessDetails.domainUrl ? this.businessDetails.domainUrl : this.businessDetails.websiteUrl, '_blank')?.focus();
  }
  id: any
  pageDelete(page) {
    if (this.screenWidth > 475) {
      let deletePop = this.templateService.openDialog(
        DeletePopComponent,
        '248px',
        '396px',
        { data: page.id }
      );
      deletePop.afterClosed().subscribe(
        (res: any) => {
          console.log(res)
          if (res.data === 'APPLY_CHANGES') {
            this.getWebsite();
          }
        }
      )
    }
    if (this.screenWidth < 475) {
      let deletePop = this.templateService.openDialog(
        DeletePopComponent,
        'auto',
        '89%',
        { data: page.id }
      );
      deletePop.afterClosed().subscribe(
        (res: any) => {
          console.log(res)
          if (res.data === 'APPLY_CHANGES') {
            this.getWebsite();
          }
        }
      )
    }

  }

  isPageNameChanged = false;
  pageNameChange() {
    this.pageNameChangeSubscribe = this.eventHelper.pageNameChange.subscribe(
      (res: any) => {
        for (let itemStyle of this.templatePages[0].components[0].itemStyles) {
          if (itemStyle.item.displayValue === this.selectedPage) {
            itemStyle.item.displayValue = res.pageName;
            itemStyle.item.redirectTo = '/' + res.pageName.split(' ').join('_')
          }
        }
        if (this.templatePages[this.templatePages.length - 1].id == null) {
          for (let itemStyle of this.templatePages[this.templatePages.length - 1].components[0].itemStyles) {
            if (itemStyle.item.displayValue === this.selectedPage) {
              itemStyle.item.displayValue = res.pageName;
              itemStyle.item.redirectTo = '/' + res.pageName.split(' ').join('_')
            }
          }
        }
        this.selectedPage = res.pageName;
        this.isPageNameChanged = true;
      }
    )
  }

  discardChangesSubscription: any;
  discardChangers() {
    this.discardChangesSubscription = this.eventHelper.localDiscardChanges.subscribe(
      (res: any) => {
        this.getWebsiteLoader = true;
        if (res.discard) {
          // console.log(res.data)
          let cpIndex: any;
          this.checkHeaderAndFooter();
          this.templatePages[this.currentPageIndex].components.forEach((cp, index) => {
            if (cp.webComponentName === res.data.webComponentName) {
              this.templatePages[this.currentPageIndex].components[index] = res.data;
              cpIndex = index;
            }
          });
          // console.log(this.templatePages)
          const instance: any = this.pickupService.dyanmicComponentPickup(this.displayWebsite, this.templatePages[this.currentPageIndex].components, true);
          this.initiateSubscription(instance);
          this.eventHelper.editSection.emit({ index: cpIndex, data: res.data })
          setTimeout(() => {
            this.getWebsiteLoader = false;
          }, 700)
        }
      }
    )
  }

  selectedBodyFont: any;
  selectedHeadingFont: any;
  websiteTheme: PageTheme | null = null;
  private getWebsiteTheme() {

    this._websiteService.getWebsiteThemeById(this.templatePages[0].websiteThemeId).toPromise().then((response) => {
      if (response) {
        this.websiteTheme = response;
        this.selectedButtonShape = response.buttonShape as BUTTON_SHAPE;
        // this.selectedButtonStyle = response.buttonStyle as BUTTON_STYLE;
      }

    })
  }
  selectedButtonStyle: BUTTON_STYLE = BUTTON_STYLE.SOLID;
  selectedButtonShape: BUTTON_SHAPE = BUTTON_SHAPE.ROUNDED;
  buttonShapeList: string[] = Object.values(BUTTON_SHAPE);

  changeWebsiteButton(selectedButton: string) {
    if(selectedButton  == this.selectedButtonStyle){
      return;
    }
    this.selectedButtonStyle = selectedButton as BUTTON_STYLE;
    this.templatePages.forEach((page) => {
      page.components.forEach((component) => {
        Object.keys(component.styles).forEach((styleKey) => {
          if (styleKey.includes('button')) {
            component.styles[styleKey]["lm_type"] = "BUTTON_" + this.selectedButtonStyle + "_" + this.selectedButtonShape;

            let accentColor = this.selectedButtonStyle == BUTTON_STYLE.SOLID ? component.styles[styleKey]['color'] : component.styles[styleKey]['background-color'];

            component.styles[styleKey]["background-color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? 'transparent' : (accentColor ? accentColor : '#000000');
            component.styles[styleKey]["color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? (accentColor ? accentColor : this.changeColor(component.bgColor)) : this.changeColor(component.styles[styleKey]["background-color"]);

            component.styles[styleKey]["border"] = "none";
            if (this.selectedButtonStyle === BUTTON_STYLE.OUTLINE) {
              component.styles[styleKey]["border"] = "2px solid " + (accentColor ? accentColor : '#000000');
            }

            // if (this.getContrastRatio(component.styles[styleKey]["background-color"], component.styles[styleKey]["color"]) < 4.5) {
            //   // Adjust background color for better contrast
            //   component.styles[styleKey]["background-color"]= this.adjustColor(component.styles[styleKey]["background-color"], component.styles[styleKey]["color"]);
            // }
          }
        })
        component.itemStyles?.forEach((itemStyle) => {

          Object.keys(itemStyle).forEach((itemStyleKey) => {

            if (itemStyle[itemStyleKey]["lm_type"]?.includes("BUTTON")) {
              itemStyle[itemStyleKey]["lm_type"] = "BUTTON_" + this.selectedButtonStyle + "_" + this.selectedButtonShape;

              let accentColor = this.selectedButtonStyle == BUTTON_STYLE.SOLID ? itemStyle[itemStyleKey]['color'] : itemStyle[itemStyleKey]['background-color'];

              itemStyle[itemStyleKey]["background-color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? 'transparent' : (accentColor ? accentColor : '#000000');
              itemStyle[itemStyleKey]["color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? (accentColor ? accentColor : this.changeColor(component.bgColor)) : this.changeColor(itemStyle[itemStyleKey]["background-color"]);

              itemStyle[itemStyleKey]["border"] = "none";
              if (this.selectedButtonStyle === BUTTON_STYLE.OUTLINE) {
                itemStyle[itemStyleKey]["border"] = "2px solid " + (accentColor ? accentColor : '#000000');
              }

              // if (this.getContrastRatio(itemStyle[itemStyleKey]["background-color"], itemStyle[itemStyleKey]["color"]) < 4.5) {
              //   // Adjust background color for better contrast
              //   itemStyle[itemStyleKey]["background-color"]= this.adjustColor(itemStyle[itemStyleKey]["background-color"], itemStyle[itemStyleKey]["color"]);
              // }
            }

          })
        })
      })
    })
  }
  changeButtonStyle() {
    this.templatePages.forEach((page) => {
      page.components.forEach((component) => {
        Object.keys(component.styles).forEach((styleKey) => {
          if (styleKey.includes('button')) {
            component.styles[styleKey]["lm_type"] = "BUTTON_" + this.selectedButtonStyle + "_" + this.selectedButtonShape;
            if (this.selectedButtonShape === BUTTON_SHAPE.ROUNDED) {
              component.styles[styleKey]["border-radius"] = "10px";
            } else {
              component.styles[styleKey]["border-radius"] = "0px";
            }
            if(this.selectedButtonStyle != BUTTON_STYLE.OUTLINE){
              if (this.getContrastRatio(component.styles[styleKey]["background-color"], component.styles[styleKey]["color"]) < 4.5) {
                // Adjust background color for better contrast
                component.styles[styleKey]["background-color"]= this.adjustColor(component.styles[styleKey]["background-color"], component.styles[styleKey]["color"]);
              }
            }
          }
        })
        component.itemStyles?.forEach((itemStyle) => {

          Object.keys(itemStyle).forEach((itemStyleKey) => {

            if (itemStyle[itemStyleKey]["lm_type"]?.includes("BUTTON")) {
              itemStyle[itemStyleKey]["lm_type"] = "BUTTON_" + this.selectedButtonStyle + "_" + this.selectedButtonShape;
              itemStyle[itemStyleKey]["background-color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? 'transparent' : (this.websiteTheme?.componentStyle ? this.websiteTheme?.componentStyle[0].accentColor : '#000000');
              itemStyle[itemStyleKey]["color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? (this.websiteTheme?.componentStyle ? this.websiteTheme?.componentStyle[0].accentColor : '#000000') : '#FFFFFF';
              if (this.selectedButtonShape === BUTTON_SHAPE.ROUNDED) {
                itemStyle[itemStyleKey]["border-radius"] = "10px";
              } else {
                itemStyle[itemStyleKey]["border-radius"] = "0px";
              }

              if (this.getContrastRatio(itemStyle[itemStyleKey]["background-color"], itemStyle[itemStyleKey]["color"]) < 4.5) {
                // Adjust background color for better contrast
                itemStyle[itemStyleKey]["background-color"]= this.adjustColor(itemStyle[itemStyleKey]["background-color"], itemStyle[itemStyleKey]["color"]);
              }
            }

          })
        })
      })
    })
  }
  // changeButtonStyle() {
  //   this.templatePages.forEach((page) => {
  //     page.components.forEach((component) => {
  //       Object.keys(component.styles).forEach((styleKey) => {
  //         if (styleKey.includes('button')) {
  //           component.styles[styleKey]["lm_type"] = "BUTTON_" + this.selectedButtonStyle + "_" + this.selectedButtonShape;
  //           if (this.selectedButtonShape === BUTTON_SHAPE.ROUNDED) {
  //             component.styles[styleKey]["border-radius"] = "10px";
  //           } else {
  //             component.styles[styleKey]["border-radius"] = "0px";
  //           }
  //           if (this.getContrastRatio(component.styles[styleKey]["background-color"], component.styles[styleKey]["color"]) < 4.5) {
  //             // Adjust background color for better contrast
  //             component.styles[styleKey]["background-color"]= this.adjustColor(component.styles[styleKey]["background-color"], component.styles[styleKey]["color"]);
  //           }
  //         }
  //       })
  //       component.itemStyles?.forEach((itemStyle) => {
  //         Object.keys(itemStyle).forEach((itemStyleKey) => {
  //           if (itemStyle[itemStyleKey]["lm_type"]?.includes("BUTTON")) {
  //             itemStyle[itemStyleKey]["lm_type"] = "BUTTON_" + this.selectedButtonStyle + "_" + this.selectedButtonShape;
  //             itemStyle[itemStyleKey]["background-color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? 'transparent' : (this.websiteTheme?.componentStyle ? this.websiteTheme?.componentStyle[0].accentColor : '#000000');
  //             itemStyle[itemStyleKey]["color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? (this.websiteTheme?.componentStyle ? this.websiteTheme?.componentStyle[0].accentColor : '#000000') : '#FFFFFF';
  //             if (this.selectedButtonShape === BUTTON_SHAPE.ROUNDED) {
  //               itemStyle[itemStyleKey]["border-radius"] = "10px";
  //             } else {
  //               itemStyle[itemStyleKey]["border-radius"] = "0px";
  //             }
  //             if (this.getContrastRatio(itemStyle[itemStyleKey]["background-color"], itemStyle[itemStyleKey]["color"]) < 4.5) {
  //               // Adjust background color for better contrast
  //               itemStyle[itemStyleKey]["background-color"]= this.adjustColor(itemStyle[itemStyleKey]["background-color"], itemStyle[itemStyleKey]["color"]);
  //             }
  //           }
  //         })
  //       })
  //     })
  //   })
  // }
  
  allThemes: PageTheme[] = [];
  getAllThemes() {
    this._websiteService.getAllThemes().toPromise().then((response) => {
      if (response)
        this.allThemes = response;
    }, (error) => {

    })
  }

  private originalTheme: PageTheme | null = null;

  applyThemeToComponent(component, componentStyle) {
    if(component.styles != null){
      Object.keys(component.styles).forEach((styleKey) => {
        if (component.styles[styleKey]["lm_type"] === "TEXT_TITLE") {
          component.styles[styleKey]["font-family"] = this.websiteTheme?.headingFontFamily;
          component.styles[styleKey]["color"] = (componentStyle ? componentStyle.txtHeadingColor : "#FFFFFF");
        } else if (component.styles[styleKey]["lm_type"] === "TEXT_DESC") {
          component.styles[styleKey]["font-family"] = this.websiteTheme?.bodyFontFamily;
          component.styles[styleKey]["color"] = (componentStyle ? componentStyle.txtDescColor : "#FFFFFF");
        } else if (component.styles[styleKey]["lm_type"] === "TEXT_ITEM_TITLE") {
          component.styles[styleKey]["font-family"] = this.websiteTheme?.headingFontFamily;
          component.styles[styleKey]["color"] = (componentStyle ? componentStyle.txtSectionTitleColor : "#FFFFFF");
        } else if (component.styles[styleKey]["lm_type"] === "NAVIGATION_TEXT") {
          component.styles[styleKey]["font-family"] = this.websiteTheme?.bodyFontFamily;
          component.styles[styleKey]["color"] = (componentStyle ? componentStyle.txtDescColor : "#FFFFFF");
        } else if (component.styles[styleKey]["lm_type"]?.includes("BUTTON")) {
          component.styles[styleKey]["font-family"] = this.websiteTheme?.bodyFontFamily;
          component.styles[styleKey]["lm_type"] = "BUTTON_" + this.selectedButtonStyle + "_" + this.selectedButtonShape;

          component.styles[styleKey]["background-color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? 'transparent' : (componentStyle ? componentStyle.accentColor : '#000000');
          component.styles[styleKey]["color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? (componentStyle ? componentStyle.accentColor : '#000000') : this.changeColor(component.styles[styleKey]["background-color"]);

          if (this.getContrastRatio(component.styles[styleKey]["background-color"], component.styles[styleKey]["color"]) < 4.5) {
            // Adjust background color for better contrast
            component.styles[styleKey]["background-color"]= this.adjustColor(component.styles[styleKey]["background-color"], component.styles[styleKey]["color"]);
          }
        }
      })
    }
    // else{
    //   component.styles.bgImage ={
    //     altName : "Background image",
    //     attachment : "https://dev-beeos.s3.amazonaws.com/prod-images/777196c1704807837470pexels-miguel-%C3%A1-padri%C3%B1%C3%A1n-255379.jpg",
    //     attachment_type : "IMAGE",
    //     backgroundColor : "rgb(255,255,244,0)",
    //     lm_title : "Background Image",
    //     lm_type : "background_attachment",
    //     param : "imageUrl"
    //   }
    // }

    component.itemStyles?.forEach((itemStyle) => {
      if(!itemStyle['bgTransparent'] || !componentStyle){
        itemStyle.bgColor = this.changeColor(component.bgColor);
      }

      Object.keys(itemStyle).forEach((itemStyleKey) => {
        if (itemStyle[itemStyleKey]) {

          if (itemStyle[itemStyleKey]["lm_type"] === "TEXT_TITLE") {
            itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.headingFontFamily;
            itemStyle[itemStyleKey]["color"] = (!itemStyle['bgTransparent'] || !componentStyle) ? this.changeColor(itemStyle.bgColor) : componentStyle.txtHeadingColor

          } else if (itemStyle[itemStyleKey]["lm_type"] === "TEXT_DESC") {
            itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.bodyFontFamily;
            itemStyle[itemStyleKey]["color"] = (!itemStyle['bgTransparent'] || !componentStyle) ? this.changeColor(itemStyle.bgColor) : componentStyle.txtDescColor

          } else if (itemStyle[itemStyleKey]["lm_type"] === "TEXT_ITEM_TITLE") {
            itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.headingFontFamily;
            itemStyle[itemStyleKey]["color"] = (!itemStyle['bgTransparent'] || !componentStyle) ? this.changeColor(itemStyle.bgColor) : componentStyle.txtSectionTitleColor

          } else if (itemStyle[itemStyleKey]["lm_type"] === "NAVIGATION_TEXT") {
            itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.bodyFontFamily;
            itemStyle[itemStyleKey]["color"] = (!itemStyle['bgTransparent'] || !componentStyle) ? this.changeColor(itemStyle.bgColor) : componentStyle.txtDescColor

          } else if (itemStyle[itemStyleKey]["lm_type"]?.includes("BUTTON")) {

            itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.bodyFontFamily;
            itemStyle[itemStyleKey]["lm_type"] = "BUTTON_" + this.selectedButtonStyle + "_" + this.selectedButtonShape;
            itemStyle[itemStyleKey]["background-color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? 'transparent' : (this.websiteTheme?.componentStyle ? this.websiteTheme?.componentStyle[0].accentColor : '#000000');
            itemStyle[itemStyleKey]["color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? (this.websiteTheme?.componentStyle ? this.websiteTheme?.componentStyle[0].accentColor : '#000000') : this.changeColor(itemStyle[itemStyleKey]["background-color"]);

            // if (this.getContrastRatio(itemStyle[itemStyleKey]["background-color"], itemStyle[itemStyleKey]["color"]) < 4.5) {
            //   // Adjust background color for better contrast
            //   itemStyle[itemStyleKey]["background-color"]= this.adjustColor(itemStyle[itemStyleKey]["background-color"], itemStyle[itemStyleKey]["color"]);
            // }
          } else if(Array.isArray(itemStyle[itemStyleKey])){
            itemStyle[itemStyleKey].forEach((data:any) => {
              if(data["lm_type"] === "TEXT_TITLE"){
                data["font-family"] = this.websiteTheme?.headingFontFamily;
                data[itemStyleKey]["color"] = (!itemStyle['bgTransparent'] || !componentStyle) ? this.changeColor(itemStyle.bgColor) : componentStyle.txtHeadingColor
              }
              else if (data["lm_type"] === "TEXT_DESC") {
                data["font-family"] = this.websiteTheme?.bodyFontFamily;
                data["color"] = (!itemStyle['bgTransparent'] || !componentStyle) ? this.changeColor(itemStyle.bgColor) : componentStyle.txtDescColor

              }
              else if (itemStyle[itemStyleKey]["lm_type"] === "TEXT_ITEM_TITLE") {
                data["font-family"] = this.websiteTheme?.headingFontFamily;
                data["color"] = (!itemStyle['bgTransparent'] || !componentStyle) ? this.changeColor(itemStyle.bgColor) : componentStyle.txtSectionTitleColor

              }
            })
          }
        }
      })
      // Object.keys(itemStyle).forEach((itemStyleKey) => {

      //   if (itemStyle[itemStyleKey]) {

      //     if (itemStyle[itemStyleKey]["lm_type"] === "TEXT_TITLE") {

      //       itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.headingFontFamily;
      //       itemStyle[itemStyleKey]["color"] = (componentStyle ? componentStyle.txtHeadingColor : "#FFFFFF");
      //     } else if (itemStyle[itemStyleKey]["lm_type"] === "TEXT_DESC") {

      //       itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.bodyFontFamily;
      //       itemStyle[itemStyleKey]["color"] = (componentStyle ? componentStyle.txtDescColor : "#FFFFFF");
      //     } else if (itemStyle[itemStyleKey]["lm_type"] === "TEXT_ITEM_TITLE") {

      //       itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.headingFontFamily;
      //       itemStyle[itemStyleKey]["color"] = (componentStyle ? componentStyle.txtHeadingColor : "#FFFFFF");
      //     } else if (itemStyle[itemStyleKey]["lm_type"] === "NAVIGATION_TEXT") {

      //       itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.bodyFontFamily;
      //       itemStyle[itemStyleKey]["color"] = (componentStyle ? componentStyle.txtDescColor : "#FFFFFF");
      //     } else if (itemStyle[itemStyleKey]["lm_type"]?.includes("BUTTON")) {

      //       itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.bodyFontFamily;
      //       itemStyle[itemStyleKey]["lm_type"] = "BUTTON_" + this.selectedButtonStyle + "_" + this.selectedButtonShape;
      //       itemStyle[itemStyleKey]["background-color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? 'transparent' : (this.websiteTheme?.componentStyle ? this.websiteTheme?.componentStyle[0].accentColor : '#000000');
      //       itemStyle[itemStyleKey]["color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? (this.websiteTheme?.componentStyle ? this.websiteTheme?.componentStyle[0].accentColor : '#000000') : this.changeColor(itemStyle[itemStyleKey]["background-color"]);

      //       if (this.getContrastRatio(itemStyle[itemStyleKey]["background-color"], itemStyle[itemStyleKey]["color"]) < 4.5) {
      //         // Adjust background color for better contrast
      //         itemStyle[itemStyleKey]["background-color"]= this.adjustColor(itemStyle[itemStyleKey]["background-color"], itemStyle[itemStyleKey]["color"]);
      //       }
      //     }
      //   }
      // })
    })

  }


  selectTheme(theme: PageTheme) {

    this.screenType = "BASIC";
    this.websiteTheme = theme;
    this.websiteTheme.websiteThemeId = theme.getWebsiteThemeId || "";

    let themeComponentIndex = 0;
    this.templatePages.forEach((page) => {
      page.components.forEach((component) => {
        component.bgColor = (theme.componentStyle ? theme.componentStyle[themeComponentIndex % 4].bgColor : "#000000");
        Object.keys(component.styles).forEach((styleKey) => {
          if (component.styles[styleKey]) {
            if (component.styles[styleKey]["lm_type"] === "TEXT_TITLE") {
              component.styles[styleKey]["font-family"] = this.websiteTheme?.headingFontFamily;
              component.styles[styleKey]["color"] = (theme.componentStyle ? theme.componentStyle[themeComponentIndex % 4].txtHeadingColor : "#FFFFFF");
            } else if (component.styles[styleKey]["lm_type"] === "TEXT_DESC") {

              component.styles[styleKey]["font-family"] = this.websiteTheme?.bodyFontFamily;
              component.styles[styleKey]["color"] = (theme.componentStyle ? theme.componentStyle[themeComponentIndex % 4].txtDescColor : "#FFFFFF");
            } else if (component.styles[styleKey]["lm_type"] === "TEXT_ITEM_TITLE") {

              component.styles[styleKey]["font-family"] = this.websiteTheme?.headingFontFamily
              component.styles[styleKey]["color"] = (theme.componentStyle ? theme.componentStyle[themeComponentIndex % 4].txtSectionTitleColor : "#FFFFFF");
            } else if (component.styles[styleKey]["lm_type"] === "NAVIGATION_TEXT") {
              component.styles[styleKey]["font-family"] = this.websiteTheme?.bodyFontFamily;
              component.styles[styleKey]["color"] = (theme.componentStyle ? theme.componentStyle[themeComponentIndex % 4].txtDescColor : "#FFFFFF");
            } else if (component.styles[styleKey]["lm_type"]?.includes("BUTTON")) {

              component.styles[styleKey]["font-family"] = this.websiteTheme?.bodyFontFamily;
              component.styles[styleKey]["lm_type"] = "BUTTON_" + this.selectedButtonStyle + "_" + this.selectedButtonShape;
              component.styles[styleKey]["background-color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? 'transparent' : (theme.componentStyle ? theme.componentStyle[themeComponentIndex % 4].accentColor : '#000000');
              if(component.category.name != 'Footer'){
                component.styles[styleKey]["color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? (theme?.componentStyle ? theme?.componentStyle[themeComponentIndex % 4].accentColor : '#000000') : this.changeColor(component.styles[styleKey]["background-color"]);
              }else{
                component.styles[styleKey]["color"] = this.changeColor(component.bgColor)
              }

              if (this.getContrastRatio(component.styles[styleKey]["background-color"], component.styles[styleKey]["color"]) < 4.5) {
                // Adjust background color for better contrast
                component.styles[styleKey]["background-color"]= this.adjustColor(component.styles[styleKey]["background-color"], component.styles[styleKey]["color"]);
              }

            }
            // NAVBAR_LINK MEANS HEADER NAVIGATION BUTTON COLOR
            else if(component.styles[styleKey]["lm_type"] === 'navbar_link'){
              component.styles[styleKey]['color'] = (theme.componentStyle ? theme.componentStyle[themeComponentIndex % 4].txtDescColor : "#FFFFFF");
            }
          }
        })
        component.itemStyles?.forEach((itemStyle) => {
          if(!itemStyle['bgTransparent'] || !theme.componentStyle){
            itemStyle.bgColor = this.changeColor(component.bgColor);
          }

          Object.keys(itemStyle).forEach((itemStyleKey) => {
            if (itemStyle[itemStyleKey]) {

              if (itemStyle[itemStyleKey]["lm_type"] === "TEXT_TITLE") {
                itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.headingFontFamily;
                itemStyle[itemStyleKey]["color"] = (!itemStyle['bgTransparent'] || !theme.componentStyle) ? this.changeColor(itemStyle.bgColor) : theme.componentStyle[themeComponentIndex % 4].txtHeadingColor

              } else if (itemStyle[itemStyleKey]["lm_type"] === "TEXT_DESC") {
                itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.bodyFontFamily;
                itemStyle[itemStyleKey]["color"] = (!itemStyle['bgTransparent'] || !theme.componentStyle) ? this.changeColor(itemStyle.bgColor) : theme.componentStyle[themeComponentIndex % 4].txtDescColor

              } else if (itemStyle[itemStyleKey]["lm_type"] === "TEXT_ITEM_TITLE") {
                itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.headingFontFamily;
                itemStyle[itemStyleKey]["color"] = (!itemStyle['bgTransparent'] || !theme.componentStyle) ? this.changeColor(itemStyle.bgColor) : theme.componentStyle[themeComponentIndex % 4].txtSectionTitleColor

              } else if (itemStyle[itemStyleKey]["lm_type"] === "NAVIGATION_TEXT") {
                itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.bodyFontFamily;
                itemStyle[itemStyleKey]["color"] = (!itemStyle['bgTransparent'] || !theme.componentStyle) ? this.changeColor(itemStyle.bgColor) : theme.componentStyle[themeComponentIndex % 4].txtDescColor

              } else if (itemStyle[itemStyleKey]["lm_type"]?.includes("BUTTON")) {

                itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.bodyFontFamily;
                itemStyle[itemStyleKey]["lm_type"] = "BUTTON_" + this.selectedButtonStyle + "_" + this.selectedButtonShape;
                itemStyle[itemStyleKey]["background-color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? 'transparent' : (this.websiteTheme?.componentStyle ? this.websiteTheme?.componentStyle[0].accentColor : '#000000');
                itemStyle[itemStyleKey]["color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? (this.websiteTheme?.componentStyle ? this.websiteTheme?.componentStyle[0].accentColor : '#000000') : this.changeColor(itemStyle[itemStyleKey]["background-color"]);

                // if (this.getContrastRatio(itemStyle[itemStyleKey]["background-color"], itemStyle[itemStyleKey]["color"]) < 4.5) {
                //   // Adjust background color for better contrast
                //   itemStyle[itemStyleKey]["background-color"]= this.adjustColor(itemStyle[itemStyleKey]["background-color"], itemStyle[itemStyleKey]["color"]);
                // }
              } else if(Array.isArray(itemStyle[itemStyleKey])){
                itemStyle[itemStyleKey].forEach((data:any) => {
                  if(data["lm_type"] === "TEXT_TITLE"){
                    data["font-family"] = this.websiteTheme?.headingFontFamily;
                    data[itemStyleKey]["color"] = (!itemStyle['bgTransparent'] || !theme.componentStyle) ? this.changeColor(itemStyle.bgColor) : theme.componentStyle[themeComponentIndex % 4].txtHeadingColor
                  }
                  else if (data["lm_type"] === "TEXT_DESC") {
                    data["font-family"] = this.websiteTheme?.bodyFontFamily;
                    data["color"] = (!itemStyle['bgTransparent'] || !theme.componentStyle) ? this.changeColor(itemStyle.bgColor) : theme.componentStyle[themeComponentIndex % 4].txtDescColor

                  }
                  else if (itemStyle[itemStyleKey]["lm_type"] === "TEXT_ITEM_TITLE") {
                    data["font-family"] = this.websiteTheme?.headingFontFamily;
                    data["color"] = (!itemStyle['bgTransparent'] || !theme.componentStyle) ? this.changeColor(itemStyle.bgColor) : theme.componentStyle[themeComponentIndex % 4].txtSectionTitleColor

                  }
                })
              }
            }
          })
          // Object.keys(itemStyle).forEach((itemStyleKey) => {

          //   if (itemStyle[itemStyleKey]) {
          //     var colorTheme = false;
          //     if (itemStyle["backgroundColor"] && itemStyle["backgroundColor"] === "transparent") {
          //       colorTheme = true;
          //     }
          //     if (itemStyle[itemStyleKey]["lm_type"] === "TEXT_TITLE") {
          //       itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.headingFontFamily;
          //       if (colorTheme)
          //         itemStyle[itemStyleKey]["color"] = (theme.componentStyle ? theme.componentStyle[themeComponentIndex % 4].txtHeadingColor : "#FFFFFF");
          //       else
          //         itemStyle[itemStyleKey]["color"] = "#000000"
          //     } else if (itemStyle[itemStyleKey]["lm_type"] === "TEXT_DESC") {

          //       itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.bodyFontFamily;
          //       if (colorTheme)
          //         itemStyle[itemStyleKey]["color"] = (theme.componentStyle ? theme.componentStyle[themeComponentIndex % 4].txtDescColor : "#FFFFFF");
          //       else
          //         itemStyle[itemStyleKey]["color"] = "#000000"
          //     } else if (itemStyle[itemStyleKey]["lm_type"] === "TEXT_ITEM_TITLE") {

          //       itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.headingFontFamily;
          //       if (colorTheme)
          //         itemStyle[itemStyleKey]["color"] = (theme.componentStyle ? theme.componentStyle[themeComponentIndex % 4].txtSectionTitleColor : "#FFFFFF");
          //       else
          //         itemStyle[itemStyleKey]["color"] = "#000000"
          //     } else if (itemStyle[itemStyleKey]["lm_type"] === "NAVIGATION_TEXT") {

          //       itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.bodyFontFamily;
          //       if (colorTheme)
          //         itemStyle[itemStyleKey]["color"] = (theme.componentStyle ? theme.componentStyle[themeComponentIndex % 4].txtDescColor : "#FFFFFF");
          //       else
          //         itemStyle[itemStyleKey]["color"] = "#000000"
          //     } else if (itemStyle[itemStyleKey]["lm_type"]?.includes("BUTTON")) {

          //       itemStyle[itemStyleKey]["font-family"] = this.websiteTheme?.bodyFontFamily;
          //       itemStyle[itemStyleKey]["lm_type"] = "BUTTON_" + this.selectedButtonStyle + "_" + this.selectedButtonShape;
          //       itemStyle[itemStyleKey]["background-color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? 'transparent' : (this.websiteTheme?.componentStyle ? this.websiteTheme?.componentStyle[0].accentColor : '#000000');
          //       itemStyle[itemStyleKey]["color"] = this.selectedButtonStyle == BUTTON_STYLE.OUTLINE ? (this.websiteTheme?.componentStyle ? this.websiteTheme?.componentStyle[0].accentColor : '#000000') : this.changeColor(itemStyle[itemStyleKey]["background-color"]);

          //       if (this.getContrastRatio(itemStyle[itemStyleKey]["background-color"], itemStyle[itemStyleKey]["color"]) < 4.5) {
          //         // Adjust background color for better contrast
          //         itemStyle[itemStyleKey]["background-color"]= this.adjustColor(itemStyle[itemStyleKey]["background-color"], itemStyle[itemStyleKey]["color"]);
          //       }
          //     }
          //   }
          // })
        })
        themeComponentIndex++;
      })

    })
  }

  applyThemeChangesToWebsite() {
    let payload = {
      "homePageId": this.templatePages[0].id,
      "templateStyles": {
        "id": this.websiteTheme?.getWebsiteThemeId,
        "name": this.websiteTheme?.themeName,
        "componentStyles": this.websiteTheme?.componentStyle,
        "headingFonts": [this.websiteTheme?.headingFontFamily],
        "bodyFonts": [this.websiteTheme?.bodyFontFamily],
        "styleTheme": this.websiteTheme?.styleTheme,
        "btnStyle": this.selectedButtonStyle,
        "btnShape": this.selectedButtonShape,
        "roundedButtonCorner": "SMALL",
        "animation": "NONE",
        "animationSpeed": this.websiteTheme?.animationSpeed,
        "browserImage": "string"
      }
    }
    if (!this.websiteTheme) {
      //  Todo : Need to show message
      return;
    }
    this._websiteService.applyThemeChanges(payload).subscribe((response) => {
      this.snackbarRef = this._snackBar.openFromComponent(
        CustomSnackbarComponent,
        {
          data: {
            type: 'SUCCESS',
            message: `Theme Successfully Applied`,
            buttonText: "Close",
            snackbar: this.snackbarRef
          },
          duration: 1500,
          panelClass: 'success-snackbar'
        }
      );
    }, (error) => {
      this.snackbarRef = this._snackBar.openFromComponent(
        CustomSnackbarComponent,
        {
          data: {
            type: 'ERROR',
            message: `Failed to Apply Theme`,
            buttonText: "Close",
            snackbar: this.snackbarRef
          },
          duration: 1500,
          panelClass: 'error-snackbar'
        }
      );
    })
  }
  discardThemeChanges() {
    if (!this.originalTheme)
      return;
    this.selectTheme(this.originalTheme);
    this.selectedButtonShape = this.originalTheme.buttonShape as BUTTON_SHAPE;
    this.selectedButtonStyle = this.originalTheme.buttonStyle as BUTTON_STYLE;
    this.changeButtonStyle();
    this.changeWebsiteButton(this.selectedButtonShape);
  }

  openPageLevelEditor(pageId:any,pageName:String){
    this.showEditor = true
    this.isPageLevelEdit = true;
    if(this.selectedPage === pageName){
      this.eventHelper.pageLevelEdit.emit({ status: true })
      this.eventHelper.sendingPageData.emit({ data: this.templatePages[this.currentPageIndex] });
    } else {
      this.pageSelectionChange({ value: pageId },true)
    }
  }


  //animation related changes

  animationType = [
    {
      key : 'Top',
      value : 'Top'
    },
    {
      key : 'Bottom',
      value : 'Bottom'
    },
    {
      key : 'Left',
      value : 'Left'
    },
    {
      key : 'Right',
      value : 'Right'
    },
    {
      key : 'FadeIn',
      value : 'FadeIn'
    },
    {
      key : 'Zoom',
      value : 'Zoom'
    }
  ];

  selectedAnimationType : any;
  selectedAnimatinoSpeed : any;
  animation:any = {
    lm_type :'animation',
    speed:'Medium',
    type:"None"
  };
  globalAnimationChange(changeType: 'type' | 'speed',value?:any){
    value = value ? value : this.selectedAnimationType;
    for(let page of this.templatePages){
      for(let component of page.components){
        if(component?.styles?.animation){
          component.styles.animation[changeType] = value;
        }
      }
    }
    let component:any = [];

    // CHEKING IF HEADER IS PRESENT OR NOT IN CURRENT PAGE IF NOT ADDING FROM HOME PAGE COMPONENTS
    if(!(this.templatePages[this.currentPageIndex].components[0].category.name === 'Top Nav Bar')){
      component.push(this.templatePages[0].components[0]);
    }
    component.push(...this.templatePages[this.currentPageIndex].components);
    // CHEKING IF FOOTER IS PRESENT OR NOT IN CURRENT PAGE IF NOT ADDING FROM HOME PAGE COMPONENTS
    if(!(this.templatePages[this.currentPageIndex].components[this.templatePages[this.currentPageIndex].components.length - 1].category.name === 'Footer')){
      component.push(this.templatePages[0].components[this.templatePages[0].components.length - 1]);
    }

    const instance = this.pickupService.dyanmicComponentPickup(this.displayWebsite,component,true);
    this.initiateSubscription(instance);
  }

  changePanelDirection(ev){
    // console.log(ev);
    if(ev === 'LEFT'){
      this.editorPanelDirection = 'RIGHT';
    }
    else{
      this.editorPanelDirection = "LEFT";
    }
    window.localStorage.setItem('pd',this.editorPanelDirection);
  }

  showSave : boolean = false;

  //This is for only temporary situtaion only this will effected for performance issue
  ngAfterViewChecked(): void {
    // if(this.templatePages.length != 0){
    //   if(_.isEqual(this.templatePages,this.duplicateTemplatePages)){
    //     this.showSave = false
    //   }
    //   else{
    //     this.showSave = true;
    //   }
    // }
    this.cdf.detectChanges();
  }

  initiateDiscardChanges(){
    this.getWebsiteLoader = true;
      let cpIndex: any;
      this.templatePages[this.currentPageIndex].components.forEach((cp, index) => {
        if (cp.webComponentName === this.duplicateOfEditSectionData.data.webComponentName) {
          this.templatePages[this.currentPageIndex].components[index] = this.duplicateOfEditSectionData.data;
          cpIndex = index;
          this.openeditorSectionData = this.duplicateOfEditSectionData
        }
      });
      const instance: any = this.pickupService.dyanmicComponentPickup(this.displayWebsite, this.templatePages[this.currentPageIndex].components, true);
      this.initiateSubscription(instance);
      this.eventHelper.editSection.emit({ index: cpIndex, data: this.duplicateOfEditSectionData.data })
      setTimeout(() => {
        this.getWebsiteLoader = false;
      }, 700)
      this.showEditor = false
  }



  private adjustColor(bgColor: string, textColor: string): string {
    // You can implement your logic to adjust the background color here
    // For example, you can lighten/darken the background color
    // or choose a contrasting color
    // Here's a simple example of lightening the color
    const lightenedColor = this.lightenColor(bgColor);
    // Check contrast ratio again
    if (this.getContrastRatio(lightenedColor, textColor) < 4.5) {
      // If still not sufficient, you can recursively call adjustColor with new color
      return this.adjustColor(lightenedColor, textColor);
    } else {
      return lightenedColor;
    }
  }
  private  getContrastRatio(color1: string, color2: string): number {
    const lum1 = this.getLuminance(color1);
    const lum2 = this.getLuminance(color2);
    const contrast = (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05);
    return contrast;
  }
  private getLuminance(color: string): number {
    const rgb = this.hexToRgb(color);
    const r = rgb.r / 255;
    const g = rgb.g / 255;
    const b = rgb.b / 255;
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }
  private lightenColor(color: string): string {
    // You can implement your logic to lighten the color here
    // For simplicity, let's just increase the brightness
    const rgb = this.hexToRgb(color);
    const newColor = {
      r: Math.min(rgb.r + 20, 255),
      g: Math.min(rgb.g + 20, 255),
      b: Math.min(rgb.b + 20, 255)
    };
    return `rgb(${newColor.r},${newColor.g},${newColor.b})`;
  }

  private hexToRgb(hex: string): { r: number; g: number; b: number } {
    const bigint = parseInt(hex.substring(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  }

  // showSaveOptionTabSubscription:any;
  // showSaveOptionTab(){
  //   this.showSaveOptionTabSubscription = this.eventHelper.showSaveOption.subscribe(
  //     (res:any) => {
  //       if(_.isEqual(this.templatePages,this.duplicateTemplatePages)){
  //         this.showSave = false
  //       }
  //       else{
  //         this.showSave = true;
  //       }
  //     }
  //   )
  // }
}
