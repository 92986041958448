<section class="main-section">
  <div class="header">
    <p class="heading">Logo </p>
    <mat-icon class="cursor-pointer" (click)="closeDialog()">close</mat-icon>
  </div>

  <div class="content mt-20">
    <div class="tabs">
      <div [ngClass]="{'selected-tab' : !logoData.isImage, 'left-tab-border' : !logoData.isImage}"
        (click)="logoData.isImage = false">Text + Icon</div>
      <div [ngClass]="{'selected-tab' : logoData.isImage, 'right-tab-border' : logoData.isImage}"
        (click)="logoData.isImage = true">Image</div>
    </div>

    <div *ngIf="!logoData.isImage">
      <div class="display-flex js-space">
        <p class="heading">Text</p>
        <div class="form-check form-switch">
          <input class="form-check-input switch-btn" type="checkbox" role="switch" [(ngModel)]="logoData.text.isText">
        </div>
      </div>

      <hr class="line">

      <div class="display-flex js-space">
        <p class="heading">Icon</p>
        <div class="form-check form-switch">
          <input class="form-check-input switch-btn" type="checkbox" role="switch" [(ngModel)]="logoData.text.isIcon">
        </div>
      </div>

      <ng-container *ngIf="logoData.text.isIcon">
        <div class="upload-image" *ngIf="!logoData.text.url" (click)="uploadImage.click()">
          <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/library-media/354514c1711643199684photo.png" alt=""
            style="height: 40px;">
          <p class="upload-file mb-0">Upload a file</p>
          <p class="mb-0">or select an existing one</p>
          <span style="color: #989DA7;">up to 4MB</span>
        </div>
        <div class="sec" *ngIf="logoData.text.url">
          <img [src]="logoData.text.url">
          <div class="replace-delete">
            <div class="repalce-image cursor-pointer" (click)="uploadImage.click()">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="24"
                height="24" class="icon w-4 h-4" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  d="m4 16 4.58579-4.5858c.78104-.781 2.04741-.781 2.82841 0L16 16m-2-2 1.5858-1.5858c.781-.781 2.0474-.781 2.8284 0L20 14m-6-6h.01M6 20h12c1.1046 0 2-.8954 2-2V6c0-1.10457-.8954-2-2-2H6c-1.10457 0-2 .89543-2 2v12c0 1.1046.89543 2 2 2Z">
                </path>
              </svg>
              <span>Replace</span>
            </div>
            <div class="delete-image cursor-pointer" >
              <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#ffffff" viewBox="0 0 24 24" width="24"
                height="24" class="icon h-4 w-4" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  d="m19 7-.8673 12.1425C18.0579 20.1891 17.187 21 16.1378 21H7.86224c-1.04928 0-1.92016-.8109-1.99492-1.8575L5 7m5 4v6m4-6v6m1-10V4c0-.55228-.4477-1-1-1h-4c-.55228 0-1 .44772-1 1v3M4 7h16">
                </path>
              </svg> -->
              <mat-icon style="color: red;cursor: pointer;" (click)="deleteIcon()">delete</mat-icon>
            </div>
          </div>
        </div>
        <input type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" hidden
          (change)="updateIcon($event)" class="browse-btn display_image" id="browse_bg_img" #uploadImage />
        <div class="slider mt-10" *ngIf="logoData.text.url">
          <p class="heading mb-0 fw-600">Size</p>
          <div class="w-71">
            <mat-slider style="width: 100%;" [max]="max" [min]="min" [step]="step">
              <input matSliderThumb [(ngModel)]="logoData.size">
            </mat-slider>
          </div>
          <p class="mb-0 sliderValue">{{logoData.size}}%</p>
        </div>
      </ng-container>

      <hr class="line">

      <div class="preview">
        <p class="heading">Preview</p>
        <div>
          <p class="preview-text" [ngStyle]="{ 'background-color': bgColor }">
            <!-- Preview Text -->
            {{ siteName }}
          </p>
          <!-- preview logo -->
            <img class="preview-image" [src]="logoData.text.url">       
        </div>
      </div>
    </div>

    <div *ngIf="logoData.isImage">

      <!-- <div class="source-heading">
        <p class="heading">Source</p>
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-select [(ngModel)]="selectedOption">
            <mat-option value="branding">Branding</mat-option>
            <mat-option value="upload-file">Upload File</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="branding-section" *ngIf="selectedOption === 'branding'">
        <div class="display-flex js-space">
          <div class="para">You don't have a brand logo</div>
          <div class="para-1">Edit branding</div>
        </div>
      </div> -->
      <div class="display-flex js-space">
        <p class="heading">Image</p>
        <div class="form-check form-switch">
          <input class="form-check-input switch-btn" type="checkbox" role="switch" [(ngModel)]="logoData.text.isIcon">
        </div>
      </div>
      <div class="upload-image" *ngIf="!logoData.image.url" (click)="updateImage()">
        <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/library-media/354514c1711643199684photo.png" alt=""
          style="height: 40px;">
        <p class="upload-file mb-0">Upload a file</p>
        <p class="mb-0">or select an existing one</p>
        <span style="color: #989DA7;">up to 32MB</span>
      </div>
      <div class="sec" *ngIf="logoData.image.url">
        <img [src]="logoData.image.url">
        <div class="replace-delete">
          <div class="repalce-image cursor-pointer" (click)="updateImage()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="24"
              height="24" class="icon w-4 h-4" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                d="m4 16 4.58579-4.5858c.78104-.781 2.04741-.781 2.82841 0L16 16m-2-2 1.5858-1.5858c.781-.781 2.0474-.781 2.8284 0L20 14m-6-6h.01M6 20h12c1.1046 0 2-.8954 2-2V6c0-1.10457-.8954-2-2-2H6c-1.10457 0-2 .89543-2 2v12c0 1.1046.89543 2 2 2Z">
              </path>
            </svg>
            <span>Replace</span>
          </div>
          <div class="delete-image cursor-pointer" (click)="deleteImage()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#ffffff" viewBox="0 0 24 24" width="24"
              height="24" class="icon h-4 w-4" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                d="m19 7-.8673 12.1425C18.0579 20.1891 17.187 21 16.1378 21H7.86224c-1.04928 0-1.92016-.8109-1.99492-1.8575L5 7m5 4v6m4-6v6m1-10V4c0-.55228-.4477-1-1-1h-4c-.55228 0-1 .44772-1 1v3M4 7h16">
              </path>
            </svg>
          </div>
        </div>
      </div>
      <div class="slider mt-10" *ngIf="logoData.image.url">
        <p class="heading mb-0 fw-600">Size</p>
        <div class="w-71">
          <mat-slider style="width: 100%;" [max]="max" [min]="min" [step]="step">
            <input matSliderThumb [(ngModel)]="logoData.size">
          </mat-slider>
        </div>
        <p class="mb-0 sliderValue">{{logoData.size}}%</p>
      </div>
    </div>
    <div class="loader-overlay" *ngIf="isLoading">
      <i class="fas fa-spinner fa-spin"></i>&nbsp;Loading
    </div>
  </div>
</section>
