<section class="main_section">
    <div class="nav-section">
        <nav class="navbar navbar-expand-lg bg-light">
            <div class="container-fluid">
                <div class="simpo_logo">
                    <img src="./../../../../../assets/images/common/simpo_logo.svg" alt="simpo_logo">
                </div>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <div class="profile dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <div class="profile_icon">
                        <!-- <img src="./../../../../assets/images/common/dummy_profile.svg" alt="dummy_profile"> -->
                        {{name.split('')[0] | uppercase}}
                      </div>
                      <div class="profile_name">
                        {{name.split(' ')[0] | titlecase}}
                      </div>
                    </div>
                    <div class="showing_section">
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li>
                          <div class="icon_businessname">
                            <div class="icon_value">
                              <img src="./../../../../assets/images/common/dummy_profile.svg" alt="dummy_profile">
                            </div>
                            <div class="business_details">
                              {{name.split(' ')[0] | titlecase}}
                              <div class="mail_id">
                                {{ contactData?.contact?.email}}
                              </div>
                            </div>
                          </div>
                        </li>
                        <hr>
                        <li><a class="dropdown-item logout" (click)="changePassword()">
                          <div class="img_text">
                            <div class="img">
                              <img src="./../../../../assets/images/sidepanel/change_password.svg" alt="changePasswor">
                            </div>
                            <div class="logout_text">Change Password</div>
                          </div>
                          <div class="logout_arrow_icon">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                          </div>
                          <!-- Logout -->
                        </a></li>
                        <hr>
                        <li><a class="dropdown-item logout" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                          <div class="img_text">
                            <div class="img">
                              <img src="./../../../../assets/images/sidepanel/logout.svg" alt="logout">
                            </div>
                            <div type="button" class="logout_text">
                              Logout
                            </div>
                          </div>
                          <div class="logout_arrow_icon">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                          </div>
                          <!-- Logout -->
                        </a></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
    </div>
    <div class="bottom_section">
      <div class="bottom_top_section">
        <div class="bottom_title">My Businesses</div>
        <div class="create-btn" *ngIf="!listBusinessLoader">
          <button (click)="createNewBusiness()">Create New Business</button>
        </div>
      </div>
      <div class="listing_section" *ngIf="!listBusinessLoader">
        <ng-container *ngFor="let item of listBusiness; let i = index">
          <div class="single_business">
            <!-- <div class="website_display"> -->
              <div class="top_website_layer">
                <div class="img_section" *ngIf="item?.logo">
                  <img [src]="item?.logo" alt="" *ngIf="item.logo" class="logo-img">
                </div>
                <div class="person-img" *ngIf="!item?.logo" (click)="goToBusiness(item)">
                  {{item?.name.split("")[0] | titlecase}}

                </div>
                <div class="business_name_industry" ><!--(click)="goToBusiness(item)">-->
                  <div class="business_name">
                    <span  (click)="goToBusiness(item)">
                      {{item.name}}
                    </span>
                  <span (click)="openSeparatePage(item)"><img src="./../../../../../assets/images/list-business/export.svg" alt="export"></span></div>
                  <!-- <div class="sub_industry"(click)="goToBusiness(item)">
                    <img src="./../../../../../assets/images/list-business/sub_industry.svg" alt="sub industry"><span class="sub_industry_name">{{item.subIndustryName}}</span>
                  </div> -->
                  <div>
                    <div class="default-text" *ngIf="item.favourite === true">
                      <img src="./../../../../../assets/images/list-business/colored_heart.png" alt="default" style="width: 22px;">
                      <span>DEFAULT</span>
                    </div>
                  </div>
                </div>
                <div class="three_dots" [id]="i" (click)="openPopupItem(i,item)">
                  <img src="./../../../../../assets/images/list-business/three_dots.svg" alt="three_dots" >
                </div>
                <div class="box" *ngIf="item.openPopup" [ngStyle]="getPositionStyles()" >
                  <div class="box-arrow-border">
                    <div class="box-arrow-background"></div>
                  </div>
                  <div class="box-content">
                    <div class="go_business_1" (click)="markAsDefault(item.id)">
                      <div class="title_icon">
                        <div class="star_icon_1">
                          <!-- <img [src]="item.favourite ? './../../../../../assets/images/list-business/colored_heart.png' : './../../../../../assets/images/list-business/back_heart.png'" alt="heart" class="heart_symbol"> -->
                          <img [src]="item.favourite === true ? './../../../../../assets/images/list-business/colored_heart.png' : '' ||
                          item.favourite === false ? './../../../../../assets/images/list-business/back_heart.png' : '' " alt="">
                        </div>
                        <div class="go_title">
                          Mark as default
                        </div>
                      </div>
                    </div>
                    <div class="go_business_2" *ngIf="!showDeleteIcon" (click)="deleteBusiness(item)">
                      <div class="title_icon">
                        <div class="star_icon_2">
                          <img src="../../../../../assets/images/crm/mingcute_delete-line.png" alt="">
                        </div>
                        <div class="go_title">
                          Delete
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="bottom_line"></div> -->
              <div class="go_business" (click)="goToBusiness(item)">
                <div class="title_icon">
                  <div class="star_icon">
                    <img src="./../../../../../assets/images/list-business/black_star.svg" alt="black_star">
                  </div>
                  <div class="go_title">
                    Go to business
                  </div>
                </div>
                <div class="last_icon">
                  <div class="fav-text" *ngIf="item.isFav">Default</div>
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
              </div>
              <div class="last_go_business" (click)="goToBusinessDetails(item)">
                <div class="title_icon">
                  <div class="star_icon">
                    <img src="./../../../../../assets/images/sidepanel/account.svg" alt="black_star">
                  </div>
                  <div class="go_title">
                    Manage Business
                  </div>
                </div>
                <div class="last_icon">
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
              </div>
            <!-- </div> -->
            <!-- <div class="business_bottom_section">
              <div class="name_button">
                <div class="business_name">
                  {{item.name}}
                </div>
                <div class="upgrade_btn" *ngIf="item.subscriptionUpgrade">
                  <button>Upgrade</button>
                </div>
              </div>
              <div class="go_business" (click)="goToBusiness(item)">
                <div class="title_icon">
                  <div class="star_icon">
                    <img src="./../../../../../assets/images/list-business/black_star.svg" alt="black_star">
                  </div>
                  <div class="go_title">
                    Go to business
                  </div>
                </div>
                <div class="last_icon">
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
              </div>
              <div class="bottom_line"></div>
              <div class="go_business" (click)="viewWebsite(item.websiteUrl)">
                <div class="title_icon">
                  <div class="star_icon">
                    <mat-icon>remove_red_eye</mat-icon>
                  </div>
                  <div class="go_title">
                    View Website
                  </div>
                </div>
                <div class="last_icon">
                  <img src="./../../../../../assets/images/list-business/view_external.svg" alt="external_icon">
                </div>
              </div>
              <div class="bottom_line"></div>
              <div class="go_business" (click)="markAsDefault(item.id)">
                <div class="title_icon">
                  <div class="star_icon">
                    <img [src]="item.isFav ? './../../../../../assets/images/list-business/colored_heart.png' : './../../../../../assets/images/list-business/back_heart.png'" alt="heart" class="heart_symbol">
                  </div>
                  <div class="go_title">
                    Mark as default
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </ng-container>
      </div>
      <section class="loader_section" *ngIf="listBusinessLoader">
        <!-- <div class="api_loader">
            <div class="dots-container">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div> -->
        <app-anime-loader></app-anime-loader>
    </section>
    </div>
    <div class="modal" id="staticBackdrop" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog centered">
        <div class="modal-content">
          <div>
            <img src="../../../../../../assets/images/invoice/delete-invoice.svg" alt class="delete-img">
          </div>
          <div class="logout_conformation">
            Are you sure to logout?
          </div>
          <div class="logout_conformation_text">
            You will no longer be logged in other selected devices.
          </div>
          <div class="logout_btn">
            <div class="delete-btn">
              <button class="logout-btn" (click)="logout()">Logout</button>
            </div>
            <div class="delete-btn">
              <button type="button" class="cancel-btn" data-bs-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
