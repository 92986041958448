import { Component, HostListener, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InvoiceServiceService } from '../../service/invoice-service.service';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-v3-add-section',
  templateUrl: './v3-add-section.component.html',
  styleUrls: ['./v3-add-section.component.scss']
})
export class V3AddSectionComponent {
  selectedOption: any;
  searchComponents: string = '';
  componentLibrary: any = [];
  filteredComponents: any = [];
  noData: any;
  constructor(
    public dialogRef: MatDialogRef<V3AddSectionComponent>,
    private bottomSheet: MatBottomSheetRef<V3AddSectionComponent>,
    private invoiceService: InvoiceServiceService,
  ) {
    this.selectedOption = this.options[0].value;
    this.screenSize()
  }
  options = [
    { value: 'RECOMMENDED', viewValue: 'Recommended', status: 'true' },
    { value: 'MOST_POPULAR', viewValue: 'Most Popular', status: 'true' },
  ]
  imagesArray: any = {
    "Banner": 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/390660c1717782685138Group%2038298%20%2817%29.png',
    "Text": 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/765967c1717781419321Group%2038298%20%2814%29.png',
    "Image": 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/713176c1717781049352Group%2038298%20%286%29.png',
    "Text + Image": 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/363732c1717780926933Group%2038298%20%284%29.png',
    "Service": 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/363732c1717780926933Group%2038298%20%284%29.png',
    "Testimonial": 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/529840c1717780979381Group%2038298%20%285%29.png',
    "Image Grid Section": 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/900766c1717780818817Group%2038298%20%283%29.png',
    "Image Carousel Section": 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/713176c1717781049352Group%2038298%20%286%29.png',
    "Video Section": 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/213784c1717781142666Group%2038298%20%2810%29.png',
    "Team Members": 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/353383c1717781077593Group%2038298%20%288%29.png',
    "Contact form": "https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/716437c1717781105433Group%2038298%20%289%29.png",
    "FAQ": 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/432606c1717781323956Group%2038298%20%2811%29.png',
    "Location": 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/658282c1717781447968Group%2038298%20%2815%29.png',
    "Banner Grid" : "https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/158958c1717781345600Group%2038298%20%2812%29.png",
    "Header Text" : "https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/765967c1717781419321Group%2038298%20%2814%29.png",
    "Pricing": "https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/197189c1717781396686Group%2038298%20%2813%29.png",
    "Choose Us": "https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/103545c1717781557364Group%2038298%20%2816%29.png",
    "Recent blog posts": "https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/126108c1719838235274Group%2038298%20%2818%29.png",
    "Process": "https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/461671c1719838405335Group%2038298%20%2819%29.png",
    "Feature": "https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/355946c1719838547092feature.png",
    "Testimonial Fullwidth": "https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/911478c1719838585830test-full.png",
    "Logo Showcase": "https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/699054c1719838632645logo.png",
    "Process Modern": "https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/408700c1719838696420process-modern.png",
  }
  sectionName: any = ""
  desc: any = ""
  imgUrl: any = ""
  // componentLibrary  : any = []
  pageLoader: boolean = false;
  @HostListener('window:resize', ['$event'])
  screenSize(event?: any) {
    this.screenWidth = window.innerWidth;
  }
  screenWidth: any;
  componentSubscription?: Subscription;
  loader: boolean = true;
  businessType: 'STATIC' | 'E_COMMERCE' = 'STATIC';
  ngOnInit() {
    this.businessType = JSON.parse(localStorage.getItem('bDetails') || '')?.type

    this.componentSubscription = this.invoiceService.getAllComponents().subscribe(
      (res: any) => {
        console.log(res.data);
        this.componentLibrary = res.data.map(item => ({
          ...item,
          imgUrl: this.imagesArray[item.sectionName]
        }));
        this.filterComponents();
        this.loader = false;
      },
      (err) => {
        this.loader = false
        console.log(err);
      }
    );
  }

  ngOnDestroy(){
    this.componentSubscription?.unsubscribe()
  }

  filterComponents() {
    if (!this.searchComponents) {
      this.filteredComponents = this.componentLibrary;
    } else {
      this.filteredComponents = this.componentLibrary.filter((item: any) =>
        item.sectionName.toLowerCase().includes(this.searchComponents.toLowerCase())
      );
      this.noData = this.filteredComponents.length === 0
    }
  }

  closePopup() {
    if (this.screenWidth > 475) {
      this.dialogRef.close();
    }
    if (this.screenWidth < 475) {
      this.bottomSheet.dismiss();
    }
  }

  selectComponent(componentId: any, sectionType: any) {
    this.pageLoader = true;

    if (this.screenWidth > 475) {
      this.dialogRef.close({ id: componentId, sectionType: sectionType });
    }
    if (this.screenWidth < 475) {
      this.bottomSheet.dismiss({ id: componentId, sectionType: sectionType });
    }

    this.pageLoader = false;

  }

  componentName(sectionName: string, sectionType: 'STATIC' | 'E_COMMERCE'): boolean {
    return  sectionName === "Header Section" || sectionName === "Footer Section" || sectionName === 'Blog list' ||
      sectionName === 'Checkout' || sectionName === 'Product List' || sectionName === 'Wishlist Section' || sectionName === 'Authenticate Section'
      || sectionName === 'Product Description' || sectionName === 'User Profile' || sectionName === 'Cart' || sectionName === 'Verify Verification Section' || sectionName === 'Product Category List' || (sectionType === 'E_COMMERCE' && (!this.businessType || this.businessType === 'STATIC'));
  }
}
