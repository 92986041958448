import { Component } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogServiceService } from '../../blog-service.service';
import { DeleteBlogComponent } from '../../delete-blog/delete-blog.component';
import { MetaTagsPopupComponent } from '../../meta-tags-popup/meta-tags-popup.component';
import { Blog } from '../../blog';

@Component({
  selector: 'app-view-master-blog',
  templateUrl: './view-master-blog.component.html',
  styleUrls: ['./view-master-blog.component.scss']
})
export class ViewMasterBlogComponent {
  constructor(
    private blogService:BlogServiceService,
    private route:ActivatedRoute,
    private router:Router,
    private metaService: Meta,
    private titleService: Title,
  ) { 
    this.subscribeQueryParams();
  }

  ngOnInit(): void {
    this.showApiData();
  }

  ngAfterViewInit() {
    // this.metaService.addTag({
    //   name: 'description',
    //   content: 'ajay'
    // });
    //console.log(this.blog.description);
  }
  blogId:any="";
  subscribeQueryParams(){
    this.route.queryParams.subscribe((params:any)=>{
        this.blogId=params.blogId;
        console.log(this.blogId);
        
    })
  }

  blog:Blog = new Blog();
  showApiData(){
    this.blogService.getMaterBlogById(this.blogId).subscribe(
      (res:any)=>{
        //console.log("json Response:-",res);
        this.blog=res.data;
        this.metaService.addTags([this.blog.blogMeta.metaDescription as MetaDefinition, this.blog.keyWords as MetaDefinition, this.blog.author as MetaDefinition]);
        this.titleService.setTitle(this.blog?.blogMeta.metaTitle)
        //console.log("blog",this.blog);
      }
    )
    // this.barTransy?.reinit();
  }


  back(){
    this.router.navigate(["/admin/website/blogs/master/listing"]);
  }

  filterBtn() {
    let blogModel = this.blogService.filterDialog(
      MetaTagsPopupComponent,
      'filter-dialog-container',
      '73vh',
      '300px',
      'relative',
      {
        data:{blogData:this.blog,typeRequest:"api",blogType:'business'}
      }
    );
     blogModel.afterClosed().subscribe((data:any) => {
      //console.log(data);
      
    });
  }

  editBlog() {
    this.blogService.setBlogData(this.blog);
    this.router.navigate(["/master/create-blog"], { queryParams: { edit: true } });
  }

   deleteBlog(){
    let deleteDepartmentModel = this.blogService.openDeleteDialog(
      DeleteBlogComponent,
      'auto',
      '600px',
      { blogId:this.blog.id},
      '97vw'
    );
    deleteDepartmentModel.afterClosed().subscribe((data) => {
      if(data.ended=="success"){
        this.router.navigate(['/admin/website/blogs/master/listing']);
      }
    });
  }
}
