<section class="entire-section">
  <div class="header">
    <p class="heading">Colors</p>
    <mat-icon class="cursor-pointer" (click)="closeDialog()">close</mat-icon>
  </div>

  <div class="content-section">
    <div class="tabs">
      <div [ngClass]="{'selected-tab' : selectedTab === 'Theme', 'left-tab-border' : selectedTab === 'Theme'}" (click)="changeTabs('Theme')">From theme</div>
      <div [ngClass]="{'selected-tab' : selectedTab === 'Custom', 'right-tab-border' : selectedTab === 'Custom'}" (click)="changeTabs('Custom')">Custom</div>
    </div>

    <ng-container *ngIf="selectedTab === 'Theme'">
      <div *ngFor="let theme of themes" class="mt-20" (click)="selectTheme(theme.id)">
        <div class="color-pallet" [ngStyle]="{'background-color' : theme.color}" [ngClass]="{'selectedTheme': theme.selected}">
          <div class="accent-color">
            <div [ngStyle]="{'background-color' : theme.accentColor}"></div>
            <p>Dd</p>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedTab === 'Custom'">
      <div class="mt-20">
        <p class="heading">Background</p>
        <div class="text-background">
          <input type="color" class="color" [(ngModel)]="backgroundData.color"
            (ngModelChange)="changeBackground()">
          <input type="text" class="color-code" [(ngModel)]="backgroundData.color" disabled>
        </div>
      </div>
      <hr class="line">
      <div class="mt-20">
        <p class="heading">Accent</p>
        <div class="text-background">
          <input type="color" class="color" [(ngModel)]="backgroundData.accentColor"
            (ngModelChange)="changeAccentColor()">
          <input type="text" class="color-code" [(ngModel)]="backgroundData.accentColor" disabled>
        </div>
      </div>
      <hr class="line">
      <div>
        <p class="heading">Preview</p>
        <div class="color-pallet" [ngStyle]="{'background-color' : backgroundData.color}">
          <div class="accent-color">
            <div [ngStyle]="{'background-color' : backgroundData.accentColor}"></div>
            <p>Custom</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</section>
