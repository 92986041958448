import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageKeys } from 'src/app/shared-module-files/simpo.constant';

@Injectable({
  providedIn: 'root'
})
export class StaffServiceService {

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private snackbar: MatSnackBar,
  ) { }

  openServiceDialog(componentName:any, height:any, width:any, maxWidth:any, dataModel:any, position: any = {right:'0px',top:'0px'}) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      maxWidth:maxWidth,
      data: dataModel,
      disableClose: true,
      position: position,
    });
  }
  openDialog(componentName:any, height:any, width:any, dataModel:any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
    });
  }

  openSocialBrand(componentName: any, height: any, width: any,data:any) {
    return this.dialog.open(componentName, {
      height: height,
      width: width,
      data:data,

    });
  }
  openSnack(message: any, action: any) {
    this.snackbar.open(message, action, { duration: 5000 });
  }

  createStaff(data:any){
    return this.http.post(
      environment.baseUrl+`staff/staff`,data
    )
  }

  updateStaff(staffId:any,data:any){
    return this.http.put(
      environment.baseUrl + `staff/staff/update?staffId=${staffId}`,data
    )
  }

  getStaffListing(businessId:any, pageNo:number, size:number , status:any){
    return this.http.get(
      environment.baseUrl+`staff/staff/list/${businessId}?page=${pageNo}&size=${size}&status=${status}`
    )
  }

  getStaffPermissionByStaffId(staffId:any,businessId:any){
    return this.http.get(
      environment.baseUrl + `staff/staff/get/permission?staffId=${staffId}&businessId=${businessId}`
    )
  }
  staffFilter(pageNo:any, size:any ,data:any){
    return this.http.put(
      environment.baseUrl + `staff/staff/filter?page=${pageNo}&size=${size}`,data
    )
  }

  deleteStaff(staffId: any, businessId: any){
    return this.http.delete(
      environment.baseUrl + `staff/staff/inactivate/${staffId}?businessId=${businessId}`
    )
  }

  getStaffDetailsByStaffId(staffId:any){
    return this.http.get(
      environment.baseUrl + `staff/staff/${staffId}`
    )
  }

  changeToggleStaff(data:any , staffId: any , status: any){
    return this.http.put(
      environment.baseUrl + `staff/staff/status/${staffId}?status=${status}`,data
    )
  }

  // for assign the staff
  assignStaff(data:any){
    return this.http.post(
      environment.baseUrl + `staff/permission/assign`,data
    )
  }
  getStaffPermission(module: any, access?: 'VIEW' | 'EDIT' | 'CREATE' | 'DELETE'){

    let permission = JSON.parse(String(localStorage.getItem(StorageKeys.PERMISSIONS)));

    if(access) {
      if (
        permission?.admin ||
        (permission?.staffPermission?.[module] && permission.staffPermission[module].includes(access))
      )
        return true
    }

    if(!access) {
      if(permission?.admin ||
        (permission?.staffPermission?.[module] && permission?.staffPermission?.[module].length > 0)
      )
        return true;
    }
    return false;
  }
}
