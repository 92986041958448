import { BUTTON_SHAPE, BUTTON_TYPE } from "simpo-component-library";

interface type<T> {
  type: T;
}

export const buttonType: Array<type<BUTTON_TYPE>> = [
  {
    type : 'Outline'
  },
  {
    type : 'Solid'
  }
]

export const buttonShape: Array<type<BUTTON_SHAPE>> = [
  {
    type : 'Round'
  },
  {
    type : 'Square'
  }
]
