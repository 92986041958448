<section class="main-section">
  <div class="header-container">
    <div class="header-section">
      <div class="header-text">
        Add Page
      </div>
      <div class="close-icon" (click)="closeBtn()">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <hr class="line">
  </div>

  <div class="d-flex flex-wrap gap-3">
    <div class="page-list" *ngFor="let page of pageList" (click)="addPage(page)">
      <div class="page-image">
        <img class="img" [src]="page.imgUrl">
      </div>
      <div class="page-list-bottom">
        <div class="page-details">
          <div class="page-name">
            {{ page.name }}
          </div>
          <div class="page-description">
            {{ page.description }}
          </div>
        </div>
        <div class="add-button">
          Add
        </div>
      </div>
    </div>
  </div>
</section>
