<section class="d-flex h-100">
  <div class="left-section d-flex">
    <div class="logo-image">
      <img class="logo-img" src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/764894c1720088943744Group%201707481973%20%281%29.png" alt="logo">
    </div>
    <div class="second-image">
      <img class="second-img" src="../../../assets/images/generate-popup-image.png" alt="logo">
    </div>
  </div>
  <div class="main-section h-100">
    <div class="main-container">
        <div class="heading">
            Create your business in no time with an AI-power website builder
        </div>
        <div class="description">
          Share your details below and generate your business website
        </div>
        <div class="mobile-section">
          <div class="input-label">Name <span>*</span></div>
          <mat-form-field class="full-width" appearance="outline">
            <input type="text" class="inputStyle"
             matInput required placeholder="Name" [(ngModel)]="name">
          </mat-form-field>
       </div>
        <div class="field">
          <div class="input-label">Mobile Number<span>*</span></div>
          <div class="code_number">
            <!-- <div class="country_code">
              <mat-form-field class="taking_input" appearance="outline">
              <mat-select formControlName="dialCode">
                  <mat-option [value]="item.shortCode" *ngFor="let item of countryCodes">
                    {{item.name}}({{item.dialingCode}})
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->
            <div class="mobile_number">
              <mat-form-field class="full-width" appearance="outline">
                <input type="number" class="inputStyle"
                 matInput required #mobileCheck="ngModel" onKeyPress="if(this.value.length==15) return false;"
                 pattern="^[6-9]\d{9}$|^[1-9]\d{9}$" placeholder="Mobile Number" [(ngModel)]="mobile">
              </mat-form-field>
            </div>
          </div> 
        </div>
        <div class="email-section">
            <div class="input-label">Email Address <span>*</span></div>
            <mat-form-field class="full-width" appearance="outline">
              <input class="inputStyle" matInput placeholder="Email Address" [(ngModel)]="email"
                required
                pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" >
            </mat-form-field>

        </div>
        <div class="button-section">
          <button (click)="getWebsiteGenerate()" [disabled]="!(mobile && mobile.toString().length >= 7 && email.includes('@') && (email.endsWith('.in') || email.endsWith('.com')))">
             <span *ngIf="submitLoader">
              <i class="fas fa-spinner fa-spin"></i>
              &nbsp;Loading
            </span>
            <span *ngIf="!submitLoader">Generate Website</span>
          </button>
        </div>

    </div>
  </div>
</section>
