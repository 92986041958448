export enum StorageKeys {
    "PREVIOUS_URL"="prevUrl",
    "LATITUDE"="lat",
    "LONGITUDE"="lng",
    "ADDRESS"="address",
    "LOCALITY"="locality",
    "STATE"="state",
    "SHORT_CODE"="shortcode",
    "STAFF_ID"="staffId",
    "STAFF_NAME"="staffName",
    "INDUSTRY_DETAILS"="industry",
    "BUSINESS_NAME"="businessName",
    "WEBSITE_ID"="webId",
    "GOOGLETOKENID"="GoogleTokenId",
    "dialCodeDropdown"="dialCode",
    "WEB_CODE"="webCode",
    "WEBSITE_STATUS" = 'status',
    "PERMISSIONS"= "permission",
    "SUBINDUSTRYID"= "subIndustryId",
   // "ADDRESS" = 'address',
  "CITY" = 'city',
 // "STATE" = 'state',
  "COUNTRY" = 'country',
  "POSTAL_CODE" = 'postalCode',
  "ZIPCODE" = 'zipcode',
  "WEBSITE_TYPE" = "websiteType"
}

export interface Industry {
    name: string;
    industryId: string;
    industryName: string;
    subIndustryId: string;
    subIndustryName: string;
}
