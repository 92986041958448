import { Component, HostListener, Inject, Optional } from '@angular/core';
import { InvoiceServiceService } from '../../invoice/service/invoice-service.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-custom-delete',
  templateUrl: './custom-delete.component.html',
  styleUrls: ['./custom-delete.component.scss']
})
export class CustomDeleteComponent {
  srcHeight: number=0;
  srcWidth: number=0;
  constructor(
    private invoiceService:InvoiceServiceService,
    private router: Router,
    public dialogRef: MatDialogRef<CustomDeleteComponent>,
    // private _bottomSheetRef: MatBottomSheetRef<CustomDeleteComponent>,
    // @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData:any,
    @Optional() @Inject(MAT_DIALOG_DATA) public data:any,
  ){
    this.getScreenSize();
    // if(this.srcWidth<475){
    //     this.data=bottomSheetData;
    //     console.log("data",this.data);
    // }
   
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    ////console.log(this.scrHeight, this.scrWidth);
  }

  deleteoption(){
  this.dialogRef.close({response:'success'})
  }
  cancel(){
      this.dialogRef.close({response:'cancel'});
    // else{
    //   this. _bottomSheetRef.dismiss();
    // }
  }
}
