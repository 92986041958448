<section>
  <!-- <p class="heading">Button</p> -->

  <div *ngIf="buttonData.hasOwnProperty('linkType')">
    <label class="input-label" style="margin-top: 3%;">Link Type</label>
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-select [(ngModel)]="buttonData.linkType">
        <ng-container *ngFor="let item of RedirectionLinkType">
          <mat-option [value]="item.type">{{item.type}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="display" *ngIf="buttonData.hasOwnProperty('label')">
    <label class="input-label">Label</label>
    <input type="text" class="input-text" [(ngModel)]="buttonData.label">
  </div>

  <div class="display mt-10" *ngIf="buttonData.hasOwnProperty('linkType') && buttonData.linkType != RedirectionLink.Page && buttonData.linkType != RedirectionLink.section">
    <label class="input-label" *ngIf="buttonData.linkType === RedirectionLink.External">Website</label>
    <label class="input-label" *ngIf="buttonData.linkType === RedirectionLink.Email">Email Address</label>
    <label class="input-label" *ngIf="buttonData.linkType === RedirectionLink.Phone">Phone Number</label>
    <input type="text" class="input-text" [(ngModel)]="buttonData.redirectionUrl" [placeholder]="buttonData.linkType === RedirectionLink.External ? 'example : https://facebook.com': ''">
  </div>

  <div *ngIf="buttonData.linkType === RedirectionLink.Page">
    <label class="input-label" style="margin-top: 3%;">Page</label>
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-select [(ngModel)]="buttonData.pageId">
        <mat-option *ngFor="let page of templatePage" [value]="page.id" (click)="buttonData.redirectionUrl=page.path">{{page.pageName}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="buttonData.linkType === RedirectionLink.section">
    <label class="input-label" style="margin-top: 3%;">Section</label>
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-select [(ngModel)]="buttonData.pageId">
        <mat-option *ngFor="let section of templatePage[currentPageIndex].components" [value]="section.id">{{section.sectionName}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="display-flex mt-10" *ngIf="buttonData.hasOwnProperty('newTab')">
    <label class="input-label">Open in a new browser tab</label>
    <div class="form-check form-switch ml-auto">
      <input class="form-check-input switch-btn" type="checkbox" [(ngModel)]="buttonData.newTab"
         role="switch">
    </div>
  </div>
</section>
