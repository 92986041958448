<section class="main-section">
    <!-- header-section  -->
    <div class="main-header-section">
      <!-- header-left-section  -->
      <div class="header-left-section">
        <div class="header-left-main-heading">
          Blogs <span class="header-span">{{blogs.length}} Blogs</span>
        </div>
        <div class="header-left-sub-heading">
          create and manage your blogs
        </div>
      </div>
      <!-- header-right-section  -->
      <div class="header-right-section">
        <div class="add-business-section" (click)="createBlog()">
          + Create New Post
        </div>
      </div>
    </div>
  
    <!-- dropdown-section  -->
    <div class="dropdown-section">
      <ng-container *ngFor="let blog of Blogs">
        <div class="blog-list" (click)="getBlogList(blog.value)" [ngClass]="{ 'blog-active': blog.value === blogData }">
          {{blog.name}}
        </div>
      </ng-container>
    </div>
  
    <!-- search and filter section  -->
    <div class="search-filter-main-section">
      <div class="search-bar">
        <mat-form-field class="sfull-width custom-mat-form-field" appearance="outline">
          <input matInput placeholder="Search" [(ngModel)]="searchText" (keyup)="_searchBlog()" />
          <mat-icon matPrefix style="padding: 8px;">search</mat-icon>
        </mat-form-field>
      </div>
      <!-- <div class="filter-section">
        <div class="filter-icon">
          <img src="../../../../../../assets/images/master/filter_icon.svg" alt="filter_Svg" />
        </div>
        <div>Filters</div>
      </div> -->
    </div>
  
  
    <!-- Blog Listing  -->
    <ng-container *ngIf="!emptyloader; else editLoader">
      <ng-container *ngIf="blogs.length > 0; else emptyscreen">
        <div class="blog-section">
          <div class="blog-listing-main-section" *ngFor="let Blog of blogs">
            <div class="blog-left-side">
              <div class="blog-main-heading cursor-pointer" (click)="clickCards(Blog.id)">
                {{ Blog.postTitle || 'No Title' }}
              </div>
              <div class="subInstryName">
                <div class="author-side d-flex align-items-center">
                  <div class="left-side">
                    SubIndustry Name : 
                  </div>
                  <div class="right-side ms-1">
                    {{ Blog.subIndustryName ? (Blog.subIndustryName | titlecase ) : 'N/A'}}
                  </div>
                </div>
               <!-- <span class="blog-main-heading"></span>  -->
              </div>
              <div class="author-tag-section">
                <div class="author-side d-flex align-items-center">
                  <div class="left-side">
                    Author :
                  </div>
                  <div class="right-side ms-1">
                    {{ (Blog?.author?.content | titlecase) || 'N/A'}}
                  </div>
                </div>
                <div class="tag-side d-flex align-items-center">
                  <div class="left-side">
                    Tag :
                  </div>
                  <div class="right-side ms-1">
                    {{ (Blog?.keyWords?.content | titlecase) || 'N/A' }}
                  </div>
                </div>
              </div>
              <div class="blog-position-section">
                <div class="position-section" *ngIf="Blog.blogStatus === 'PUBLISH' || Blog.blogStatus === 'ALL'">
                  Published On {{Blog.blogScheduledDate | date : 'mediumDate'}}
                </div>
                <div class="position-section-draft" *ngIf="Blog.blogStatus === 'SAVE'">
                  Draft
                </div>
                <div class="position-section-scheduled" *ngIf="Blog.blogStatus === 'SCHEDULED'">
                  Scheduled On {{Blog.blogScheduledDate | date: 'mediumDate'}}
                </div>
                <div class="words-section">
                  {{Blog.wordCount}} words
                </div>
                <div class="read-section">
                  {{Blog.readTime?Blog.readTime:'0'}} mins read
                </div>
                <div class="edited-section">
                  Edited &nbsp;{{Blog.updatedTimeStamp | date : 'mediumDate'}}
                </div>
              </div>
  
            </div>
            <div class="blog-right-side">
              <div class="image-section" *ngIf="Blog.blogStatus !== 'PUBLISH'">
                <img src="./../../../../../../../../assets/images/master/edit_icon.svg" alt="edit_svg"
                  (click)="editBlog(Blog)" />
              </div>
              <div class="blog-visitor-section" *ngIf="Blog.blogStatus === 'PUBLISH' ||  Blog.blogStatus === 'ALL'">
                <div class="visitor-left-side">
                  <div class="visitor-heading">
                    Visitor
                  </div>
                  <div class="visitor-text">
                    3.2K
                  </div>
                </div>
                <div class="visitor-right-side">
                  <div class="visitor-heading">
                    Session Duration
                  </div>
                  <div class="visitor-text">
                    5m 1s
                  </div>
                </div>
              </div>
            </div>
  
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #editLoader>
      <div class="img-section">
        <img src="./../../../../../../../../../assets/images/loader/beeos_loader.gif" alt="loader.svg" />
      </div>
    </ng-template>
    <ng-template #emptyscreen>
      <div class="empty-part">
        No Blog Yet!!
      </div>
    </ng-template>
  </section>