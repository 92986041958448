import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { Blog, BlogFilterRequest, Blog_Cachekeys } from './blog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Injectable({
  providedIn: 'root'
})
export class BlogServiceService {

  constructor(
    private readonly http: HttpClient,
    private readonly snackbar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly bottomSheet: MatBottomSheet
  ) { }

  debounce(fn: any, delayTime: number = 500) {
    let timeout: ReturnType<typeof setTimeout>;

    return () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => fn(), delayTime);
    }
  }

  uploadBlog(data: any) {
    return this.http.post(
      environment.baseUrl + `business/blog/create`, data
    );
  }

  createMasterBlog(data:any){
    return this.http.post(
      environment.serverIp+`blog/create`,data
    )
  }

  getMasterBlog(){
      return this.http.get(
        environment.serverIp+`blog/get/master`
      )
  }

  getImage() {
    return this.http.get(
      environment.baseUrl + `business/blog/getAll`
    )
  }

  getBlogById(blogId: any) {
    return this.http.get(
      environment.baseUrl + `business/blog/${blogId}`
    )
  }

  getMaterBlogById(blogId: any){
    return this.http.get(
      environment.serverIp+`blog/${blogId}`
    )
  }

  UpdateMasterBlog(data:any){
    return this.http.put(
      environment.serverIp+`blog/master/update`,data
    )
  }

  deleteImage(blogId: any) {
    return this.http.delete(
      environment.baseUrl + `business/blog/delete/${blogId}`
    )
  }

  setBlogData(blog: Blog) {
    localStorage.setItem(Blog_Cachekeys.BLOG_SETTINGS, JSON.stringify(blog));
  }

  getBlogData(): Blog {
    const blogData: string | null = localStorage.getItem(Blog_Cachekeys.BLOG_SETTINGS);

    return blogData ? new Blog(JSON.parse(blogData)) : new Blog();;
  }

  removeBlogData() {
    localStorage.removeItem(Blog_Cachekeys.BLOG_SETTINGS);
  }

  createBlog(data: Blog) {
    return this.http.post(
      environment.baseUrl + `business/blog/create`, data
    )
  }

  updateBlog(data: Blog) {
    return this.http.put(
      environment.baseUrl + `business/blog/update`, data
    )
  }

  openSnack(message: any, action: any) {
    this.snackbar.open(message, action, { duration: 5000 });
  }

  openDialog(componentName: any, height: any, width: any, dataModel: any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
    });
  }

  openDeleteDialog(componentName: any, height: any, width: any, dataModel: any, maxwidth: any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth: maxwidth,
    });
  }

  filterDialog(componentName: any, panelClass: string, height: string, width: string, position: any, data: any, maxWidth: string = '95vw', maxHeight: string = '90vh') {
    return this.dialog.open(componentName, {
      panelClass: panelClass,
      width: width,
      height: height,
      position: position,
      data: data,
      disableClose: true,
      maxHeight: maxHeight,
      maxWidth: maxWidth
    });
  }

  openBottomSheet(componentName, panelClass, data: any) {
    return this.bottomSheet.open(componentName, {
      panelClass: panelClass,
      data: data
    });
  }

  // openMobileBottomSheet(componentName,data){
  //   return this.bottomSheet.open(componentName, {
  //       data: data,
  //   });
  // }

  openAddAuthor(componentName: any, data: any) {
    return this.bottomSheet.open(componentName, {
      data: data,
    });
  }


  getBlogByBuisnessId(businessId: any) {
    return this.http.get(
      environment.baseUrl + `business/blog/businessId/${businessId}`
    )
  }

  getBlogByBuisness(blogFilterRequest: BlogFilterRequest) {
    return this.http.post(
      environment.baseUrl + `business/blog/business`, blogFilterRequest
    )
  }

  getBlogsByBuisnessId(businessId: any , blogStauts : any) {
    return this.http.get(
      environment.baseUrl + `business/blog/businessId/${businessId}?blogStatus=${blogStauts}`
    )
  }

  createBlogTags(blog: any) {
    return this.http.post(
      environment.baseUrl + `business/blog/create/blogTag`, blog
    )
  }

  getBlogsTagsByBusinessId(businessId: any) {
    return this.http.get(
      environment.baseUrl + `business/blog/getAll/tags/${businessId}`
    )
  }

  searchBlogTagsByBusiness(tagName: string, businessId: string) {
    return this.http.get(
      environment.baseUrl + `business/blog/getBlogsTags/${tagName}?businessId =${businessId}`
    )
  }

  searchBlogsTagByBusinessId(tagName: any, businessId: any) {
    return this.http.get(
      environment.baseUrl + `business/blog/tag/search/${tagName}?businessId=${businessId}`
    )
  }

  updateBlogTag(BlogTag: any) {
    return this.http.put(
      environment.baseUrl + `business/blog/tag/update`, BlogTag
    )
  }

  getViewByTagId(tagId:any){
    return this.http.get(
      environment.baseUrl + `business/blog/getBlogTag/${tagId}`
    )
  }

  deleteBlogTag(tagId: any){
    return this.http.delete(
      environment.baseUrl + `business/blog/delete/tag/${tagId}`
    )
  }

  getMasterBlogById(blogId:any){
    return this.http.get(
      environment.serverIp+`blog/${blogId}`
    )
  }


  // Author API


  getAllBlogAuthors(businessId:any){
    return this.http.get(
      environment.baseUrl + `business/blog/getAll/authors/${businessId}`
    )
  }


  searchBlogsAuthorByBusinessId(authorName: string, businessId: string) {
    return this.http.get(
      environment.baseUrl + `business/blog/author/search/${authorName}?businessId=${businessId}`)
  }

  createBlogAuthor(blog: any) {
    return this.http.post(
      environment.baseUrl + `business/blog/create/blogAuthor`,blog
    )
  }

  updateBlogAuthor(BlogTag: any) {
    return this.http.put(
      environment.baseUrl + `business/blog/author/update`,BlogTag
    )
  }

  deleteBlogAuthor(authorId: any){
    return this.http.delete(
      environment.baseUrl + `business/blog/delete/author/${authorId}`
    )
  }

  getAuthorByAuthorId(authorId:any){
    return this.http.get(
      environment.baseUrl + `business/blog/getBlogAuthor/${authorId}`
    )
  }


  //master Author APIs

  getAllAuthors(){
    return this.http.get(
      environment.serverIp+`blog/getAll/authors`
    )
  }

  getAllMasterTags(){
    return this.http.get(
      environment.serverIp+`blog/getAll/tags`
    )
  }

  getBlogsBySubInstryId(subIndustryId:any){
    return this.http.get(
      environment.serverIp+`blog/getBlog/${subIndustryId}`
    )
  }

  async getBlogSynchronousByBuisnessId(businessId?: any, subIndustryId?: any): Promise<any> {
    if(businessId) {
      return await this.http.get<any>(
        environment.baseUrl + `business/blog/by/business?businessId=${businessId}`
      ).toPromise()
    } else {
      return await this.http.get<any>(
        environment.baseUrl + `business/blog/by/business?subId=${subIndustryId}`
      ).toPromise()
    }
  }

  async getBlogByBlogId(blogId: any){
    return await this.http.get(
      environment.baseUrl + `business/blog/${blogId}`
    ).toPromise()
  }
}
