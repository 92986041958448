import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, Observable, startWith } from 'rxjs';
import { DbService } from 'src/app/services/db-service/db.service';
import { MapService } from 'src/app/services/map.service';
import { TemplateService } from 'src/app/services/template.service';
import { BusinessServiceService } from '../services/business-service.service';
import { Router } from '@angular/router';
import { Industry, StorageKeys } from 'src/app/shared-module-files/simpo.constant';
import { EcommerceService } from 'src/app/services/ecommerce.service';

@Component({
  selector: 'app-create-new-business',
  templateUrl: './create-new-business.component.html',
  styleUrls: ['./create-new-business.component.scss']
})
export class CreateNewBusinessComponent implements OnInit {

  selectedLocation: any;
  constructor(
    public dialogRef: MatDialogRef<CreateNewBusinessComponent>,
    private templatesService: TemplateService,
    private _mapservice: MapService,
    private _dbService: DbService,
    private snackBar: MatSnackBar,
    private _businessService: BusinessServiceService,
    private _router: Router,
    private templateService: TemplateService,
    private ecommerceService: EcommerceService
  ) { }
  industry: Industry = {
    name: '',
    industryId: '',
    subIndustryId: '',
    industryName: '',
    subIndustryName: ''
  };
  websiteType: 'STATIC' | 'E_COMMERCE' = 'STATIC';
  ngOnInit() {
    this.getAllStaticIndustries();
    this.getAllEcommerceIndustries();
  }

  close() {
    this.dialogRef.close()
  }


  searchLocation: any = null;
  searchResults: Observable<string[]> | any;
  locations: any = [];
  searchBox = new FormControl();
  filteredIndustries: Observable<Industry[]> | any;
  filteredEcommerceIndustries: Observable<Industry[]> | any;
  ecommerceIndustrySelectControl = new FormControl();

  industrySelectControl = new FormControl();
  businessNameControl = new FormControl();
  filteredBusinessName: Observable<any[]> | any;
  industries: Industry[] = [];
  bType: string | null = null;

  //location change
  search() {
    this._mapservice.searchAddress(this.searchLocation).subscribe(
      (res: any) => {
        let locations: any = [];
        if (res.features.length > 0) {
          res.features.forEach((feature) => {
            feature.context.forEach(context => {
              // let searchedLocality = feature.place_name;
              if (context.id.includes('country')) {
                locations.push({
                  displayName: feature.place_name + ', ' + context.text,
                  latlng: feature.center,
                  short_code: context.short_code
                })
              }
            });
          });
          this.locations = locations;
          this.searchResults = this.searchBox.valueChanges.pipe(
            startWith(''),
            map((value) => this._searchFilter(value))
          );
        }
      },
      (err) => {
        ////console.log(err);
        this.snackBar.open(
          `${err.error.message}`,
          'Close',
          {
            duration: 1500
          }
        )
      }
    );
  }
  private _searchFilter(value: any) {
    const filterValue = value.toLowerCase();

    return this.locations.filter((option: any) =>
      option.displayName.toLowerCase().includes(filterValue)
    );
  }

  selectedLocations(option: any) {
    console.log(option)
    this.templatesService.getAddress(option.latlng[1], option.latlng[0]).subscribe(
      (res: any) => {
        console.log(res);
        res.results.forEach((location: any) => {
          if (location.types[0] === 'country') {
            this.selectedLocation = {
              short_code: location.address_components[0].short_name
            }
            window.localStorage.setItem('count',location.address_components[0].long_name);
          }
          if (location.types[0] === 'locality') {
            localStorage.setItem(StorageKeys.LOCALITY, location.address_components[0].long_name);
          }
          if (location.types[0] === 'administrative_area_level_1') {
            localStorage.setItem(StorageKeys.STATE, location.address_components[0].long_name);
          }
          if(location.types[0] === 'street_address'){
            window.localStorage.setItem('address',`${location.address_components[0].long_name},${location.address_components[1].long_name}`)
          }
          if(location.types[0]==='postal_code'){
            window.localStorage.setItem('postalCode',location.address_components[0].long_name)
          }
        });
        this.getAllCountries();
      },
      (err) => {
        console.log(err)
      }
    )
    // this.selectedLocation = option;
    let splitedLocation = this.selectedLocation.displayName.split(',');
  }

  getAllCountries() {
    this._businessService.getAllCountries().subscribe(
      (res: any) => {
        res.data.data.forEach((country) => {
          if ((country.shortCode) === this.selectedLocation.short_code) {
            window.localStorage.setItem('countryCode', country.shortCode);
            window.localStorage.setItem('countryId', country.id);
          }
        });
      },
      (err) => {
        this.snackBar.open(
          `${err.error.message}`,
          'Close',
          {
            duration: 1500
          }
        )
      }
    )
  }

  //industry type change

  // getAllTypes() {
  //   this.templatesService.getSubIndustryByStatus(0, 1000, true).subscribe(
  //     (res: any) => {
  //       this.industries = (res.data || []).map((data: any) => ({
  //         name: data.parent.name + ' - ' + data.name,
  //         industryId: data.parent.id,
  //         subIndustryId: data.id,
  //         industryName: data.parent.name,
  //         subIndustryName: data.name,
  //       }));

  //        this.industries.sort((A, B) => A.name.localeCompare(B.name));

  //       this.filteredIndustries = this.industrySelectControl.valueChanges.pipe(
  //         startWith(''),
  //         map((value) => this._filterIndustries(value))
  //       );
  //     },
  //     (err) => {
  //       // ////console.log(err)
  //       this.snackBar.open(`${err.error.message}`, 'Close');
  //     }
  //   );
  // }

  getAllStaticIndustries(){
    this.templatesService.getSubIndustry(0, 1000).subscribe(
      (res: any) => {
        this.industries = (res.data || []).map((data: any) => ({
          name: data.parent.name + ' - ' + data.name,
          industryId: data.parent.id,
          subIndustryId: data.id,
          industryName: data.parent.name,
          subIndustryName: data.name,
        }));
        this.filteredIndustries = this.industrySelectControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterIndustries(value))
        );
      },
      (err) => {
        this.snackBar.open(`${err.error.message}`, 'Close');
      }
    );
  }

  ecommerceIndustries: any[] = [];
  getAllEcommerceIndustries(){
    this.ecommerceService.getAllIndustries().subscribe((res:any) => {
        this.ecommerceIndustries = (res.data || []).map((data: any) => ({
          name: data.name,
          industryId: data.parent.id,
          subIndustryId: data.id,
          industryName: data.parent.name,
          subIndustryName: data.name,
        }));

        this.filteredEcommerceIndustries = this.ecommerceIndustrySelectControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterEcommerceIndustries(value))
        );
    })
  }

  private _filterEcommerceIndustries(value: any) {
    const filterValue = value.toLowerCase();

    return this.ecommerceIndustries.filter((option: any) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }



  //All business suggestions
  getAllBusinessNames() {
    this.suggestedNames = [];
    let industry: any = localStorage.getItem(StorageKeys.INDUSTRY_DETAILS);
    this.industry = industry ? JSON.parse(industry) : this.industry;

    this.templateService.getAllBusinessName(this.industry.subIndustryId).subscribe(
      (res: any) => {
        // this.suggestedNames = (res.data || []).map((bName: string) => {
        //   bName : bName
        // });
        res.data.forEach(element => {
          this.suggestedNames.push({ bName: element })
        });
        console.log(this.suggestedNames)
      },
      (err) => {
        //console.log(err)
      }
    )
  }
  private _filterIndustries(value: any) {
    const filterValue = value.toLowerCase();

    if (!filterValue) {
      this.bType = '';
      localStorage.removeItem(StorageKeys.INDUSTRY_DETAILS)
    }

    return this.industries.filter((option: any) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }


  matOptionSelected : boolean = false;
  isIndustryTypeSelected: boolean = false;
  isBusinessNameClicked: boolean = false;
  isSuggestedNamesClicked: boolean = false;

  onBusinessNameFocus() {
    this.isBusinessNameClicked = true;
    this.isSuggestedNamesClicked = false;
  }
  selectBusinessType(ev: Industry) {
    if(ev){
      this.matOptionSelected = true;
      this.isIndustryTypeSelected = true;
      // this.matOptionSelected = false;
    }
    else {
      this.matOptionSelected = false;
      this.isIndustryTypeSelected = false;
    }
    localStorage.setItem(StorageKeys.INDUSTRY_DETAILS, JSON.stringify(ev));
    this.getAllBusinessNames();
  }

  selectBusinessName(ev: any) {
    console.log(ev);
  }



  //businessName change
  bName: any;
  createBusinessLoader: boolean = false;

  generateWebsite() {
    if (this.matOptionSelected) { }
    else {
      this.industries.forEach(element => {
        if ((element.industryName).toLowerCase() === 'others') {
          localStorage.setItem(StorageKeys.INDUSTRY_DETAILS, JSON.stringify(element));
        }
      });
    }
    if (this.bName === undefined) {
      this.snackBar.open(
        'Please fill all mandatory fields',
        'Close',
        {
          duration: 1500
        }
      )
    }
    else {
      this.createBusinessLoader = true;
      localStorage.setItem(StorageKeys.BUSINESS_NAME, this.bName);
      this._router.navigate(['/v3-generator']);
      this.close();
      this.createBusinessLoader = false;
    }
    this.matOptionSelected = false;
  }
  suggestNames: any = [];
  suggestedNamesScreen: boolean = false;
  suggestedNames: any = [];
  x = 8;
  seeSuggestedNames() {
    this.isSuggestedNamesClicked = true;
    this.suggestNames = [];
    this.suggestedNamesScreen = true;
    let n = this.suggestedNames.length;
    this.x = this.x % n;
    let first_x_elements = this.suggestedNames.slice(0, this.x);
    let remaining_elements = this.suggestedNames.slice(this.x, n);
    this.suggestedNames = [...remaining_elements, ...first_x_elements];

    for (let i = 0; i < this.suggestedNames.length; i++) {
      if (i < 8) {
        this.suggestNames.push(this.suggestedNames[i]);
      }

      if (i === 8) {
        break;
      }
    }
  }
  enterOwnBName() {
    this.suggestedNamesScreen = false;
    this.suggestNames = [];
  }
  seeMore() {
    this.suggestNames = [];
    let n = this.suggestedNames.length;
    this.x = this.x % n;
    let first_x_elements = this.suggestedNames.slice(0, this.x);
    let remaining_elements = this.suggestedNames.slice(this.x, n);
    this.suggestedNames = [...remaining_elements, ...first_x_elements];

    for (let i = 0; i < this.suggestedNames.length; i++) {
      if (i < 8) {
        this.suggestNames.push(this.suggestedNames[i]);
      }

      if (i === 8) {
        break;
      }
    }
  }
  clickedSuggestions(selectedName: any) {
    this.bName = selectedName.bName;
    this.suggestedNamesScreen = false;
  }
}
