<div class="complete-section">
  <div class="header-section">
    <div class="header-text">
      Create Folder
    </div>
    <div class="close-btn" (click)="closeWindow()">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="middle-top-section">
    <div class="middle-text">
      <div class="middle-text-left">
        Label
      </div>
    </div>
    <input type="text" class="middle-input" [(ngModel)]="folderData.folderName">
    <div class="middle-description">
      This is the word shown in navigation.
    </div>
  </div>

  <div class="bottom-section" *ngIf="folderData.folderName != folderName">
    <!-- <p>Unsaved</p> -->
    <!-- <div class="discard-save"> -->
      <button class="discard-btn" (click)="closeWindow()">Discard</button>
      <button class="save-btn" (click)="createFolder()">Save</button>
    <!-- </div> -->
  </div>
</div>
