<div class="main-section">
    <div class="top-section">
      <div>
        <img _ngcontent-mat-c279 src="../../../../../../assets/images/invoice/delete-invoice.svg" alt class="delete-img">
    </div>
    <!-- <div class="popup-profile-name">This action can discard all the changes/editions you added.</div><br/> -->
    <div class="sub-text">Are you sure to exit</div>
    </div>
    <div class="light-text">Changes will be discarded if you exit.</div>
    <div class="button-section">
        <div class="delete-btn">
            <button class="sec-btn" (click)="back()">Yes</button>
        </div>
        <div class="delete-btn">
            <button class="btn-name" (click)="cancel()" [disabled]="isLoading">No</button>
        </div>
    </div>
</div>
