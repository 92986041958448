<div class="mainSection">
    <div class="title">
        Your domain process is in progress
    </div>
    <div class="animationSize">
        <img src="./../../../../../../assets/images/rocket_rectangle.gif" alt="success-flying">
    </div>
    <div class="desc">
        It may take sometime for domain to activate
    </div>
    <div class="notifyText" (click)="notify()">
        We’ll notify you when the setup is completed
    </div>
</div>