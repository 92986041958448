import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionModel, BackgroundModel, EventsService } from 'simpo-component-library';

@Component({
  selector: 'app-background-color-editor',
  templateUrl: './background-color-editor.component.html',
  styleUrls: ['./background-color-editor.component.scss']
})
export class BackgroundColorEditorComponent {
  constructor(public dialogRef: MatDialogRef<BackgroundColorEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventService: EventsService){}

  selectedTab : 'Theme' | 'Custom' = "Theme";

  backgroundData!: BackgroundModel;
  actionData! : ActionModel
  componentId?: string;
  ngOnInit(){
    if(this.data){
      this.backgroundData = this.data.background;
      this.componentId = this.data.id;
      this.actionData = this.data.action
    }
    if(!this.checkSelectedTheme()){
      this.selectedTab = 'Custom'
    }
  }

  checkSelectedTheme(){
    let foundSelectedTheme = false;

    for(let theme of this.themes){
      if(theme.color === this.backgroundData?.color && theme.accentColor === this.backgroundData?.accentColor){
        foundSelectedTheme = true;
        theme.selected = true;
      } else {
        theme.selected = false;
      }
    }

    return foundSelectedTheme;
  }

  closeDialog(){
    this.dialogRef.close();
  }

  themes = [
    {
      id: 1,
      color: '#F8F1E7',
      accentColor: '#1C4324',
      selected: true
    },
    {
      id: 2,
      color: '#FCFAF6',
      accentColor: '#1C4324',
      selected: false
    },
    {
      id: 3,
      color: '#E6EFE8',
      accentColor: '#1C4324',
      selected: false
    },
    {
      id: 4,
      color: '#DDE9E0',
      accentColor: '#1C4324',
      selected: false
    }
  ]

  changeTabs(selectedTab: 'Theme' | 'Custom'){
    this.selectedTab = selectedTab;
    if(selectedTab === 'Theme' && !this.checkSelectedTheme()){
      this.selectTheme(this.themes[0].id)
    }
  }

  selectTheme(id:any){
    for(let theme of this.themes){
      if(theme.id === id){
        theme.selected = true;
        if(this.backgroundData){
          this.backgroundData.color = theme.color;
          this.backgroundData.accentColor = theme.accentColor;
          this.eventService.backgroundImageChangeCheck.emit({id: this.componentId, data: this.backgroundData})
        }
      } else {
        theme.selected = false;
      }
    }
  }

  changeBackground(){
    this.eventService.backgroundImageChangeCheck.emit({id: this.componentId, data: this.backgroundData})
  }

  changeAccentColor(){
    for(let button of this.actionData.buttons){
      this.eventService.buttonStyleChangeChecks.emit({id: this.componentId, style: button.styles, color: this.backgroundData.accentColor});
    }
  }
}
