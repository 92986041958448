<section class="main-section">
    <!-- header-section  -->
    <div class="main-header-section" *ngIf="screenWidth > 475">
        <!-- header-left-section  -->
        <div class="header-left-section">
            <div class="header-left-main-heading">
                Authors <span class="header-span">{{dataSource.length}} Authors</span>
            </div>
            <div class="header-left-sub-heading">
                View,create and manage your blog authors
            </div>
        </div>
        <div class="search-bar">
            <mat-form-field class="sfull-width custom-mat-form-field" appearance="outline">
                <input matInput placeholder="Search" [(ngModel)]=authorName (keyup)="_searchAuthor()" />
                <mat-icon matPrefix style="padding: 8px;">search</mat-icon>
            </mat-form-field>
        </div>
        <!-- header-right-section  -->
        <div class="header-right-section">
            <div class="add-business-section" (click)="addAuthor()">
                + Add Author
            </div>
        </div>
    </div>
    <!-- search and filter section  -->
    <div class="search-filter-main-section" *ngIf="screenWidth > 475">
        <!-- <div class="search-bar">
            <mat-form-field class="sfull-width custom-mat-form-field" appearance="outline">
                <input matInput placeholder="Search" [(ngModel)]=authorName (keyup)="_searchAuthor()" />
                <mat-icon matPrefix style="padding: 8px;">search</mat-icon>
            </mat-form-field>
        </div> -->
        <!-- <div class="filter-section">
            <div class="filter-icon">
                <img src="../../../../../../assets/images/master/filter_icon.svg" alt="filter_Svg" />
            </div>
            <div>Filters</div>
        </div> -->
    </div>

    <!-- table section  -->
    <ng-container *ngIf="!emptyloader; else editLoader">
        <!-- for desktop screen  -->
        <ng-container *ngIf="screenWidth > 475">
            <ng-container *ngIf="dataSource.length > 0 && viewAccess; else emptyscreen">
                <div class="table-section">
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <!-- Position Column -->
                        <ng-container matColumnDef="position">
                            <th mat-header-cell *matHeaderCellDef>

                                Author
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="td-first">

                                    <div class="profile-photo">
                                        <ng-template #avatar>
                                            <img [src]="element.imageUrl" />
                                        </ng-template>
                                        <div class="only-letter" *ngIf="
                                    element.imageUrl === '' ||
                                      element.imageUrl == null ||
                                      element.imageUrl == 'string';
                                    else avatar
                                  ">
                                            <div class="display-letter">
                                                {{ element?.authorName.split("")[0] | uppercase }}
                                            </div>
                                        </div>
                                    </div>
                                    {{element.authorName?element.authorName:'N/A'}}
                                </div>
                            </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef> Mail Id </th>
                            <td mat-cell *matCellDef="let element"> {{element.email?element.email:'N/A'}} </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="count">
                            <th mat-header-cell *matHeaderCellDef> No of Blogs</th>
                            <td mat-cell *matCellDef="let element"> {{element.blogCount?element.blogCount:0}} </td>
                        </ng-container>


                        <!-- date Column -->
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef> Created </th>
                            <td mat-cell *matCellDef="let element"> {{element.createdTimeStamp | date: 'mediumDate'}}
                            </td>
                        </ng-container>


                        <!-- Action Column -->
                        <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef> Action </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="action-icon">
                                    <div class="eye-icon">
                                        <img src="../../../../../../assets/images/master/Eye.svg" alt="eye-icon"
                                            (click)="viewTag(element.authorId)" />
                                    </div>
                                    <div class="edit-icon">
                                        <img src="../../../../../../assets/images/master/edit_icon.svg" alt="edit-icon"
                                            (click)="editAuthor(element)">
                                    </div>
                                    <div class="delete-icon">
                                        <img src="../../../../../../assets/images/master/delete_icon.svg"
                                            alt="delete_icon" (click)="deleteAuthor(element.authorId)">
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>

                </div>
            </ng-container>
        </ng-container>
        <!-- for mobile screen  -->
        <ng-container *ngIf="screenWidth < 475">
            <div class="search-filter-resp">
                <div class="search-filter-left-resp">
                    <!-- <div class="search-img-resp" (click)="search()">
                        <img src="../../../../../../../../assets/images/common/search_icon.svg" alt="searchicon"
                            class="search-img">
                    </div> -->
                    <div class="search-bar">
                      <mat-form-field class="sfull-width custom-mat-form-field" appearance="outline">
                        <input matInput placeholder="Search" (click)="search()" [(ngModel)]=authorName (keyup)="_searchAuthor()" />
                        <mat-icon matPrefix style="padding: 8px;">search</mat-icon>
                      </mat-form-field>
                    </div>
                    <!-- <div class="search_input" *ngIf="searchbar">
                        <mat-form-field class="sfull-width" appearance="outline">
                            <img src="../../../../../../../../assets/images/common/search_icon.svg" alt="searchicon"
                                class="search-icon">
                            <input matInput placeholder="Search" [(ngModel)]=authorName (keyup)="_searchAuthor()" />
                            <img src="../../../../../../../../assets/images/close.svg" alt="close"
                                (click)="closesearch()">
                        </mat-form-field>
                    </div> -->
                    <!-- <div class="filter-section-resp">
                        <img src="../../../../../../../../assets/images/master/filter_icon.svg" alt=""
                            class="filter-img">
                    </div> -->
                </div>
                <div class="search-filter-right-resp">
                    <div class="add-business-section" (click)="addAuthor()">
                        <div class="add-author">
                          + Add Author
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="dataSource.length > 0 && viewAccess; else emptyscreen">
                <div class="author-mobile-listing">
                    <div class="author-list-responsivess" *ngFor="let element of dataSource">
                        <div class="author-image-section">
                            <div class="left-side">
                                <!-- <div class="left-side-image">
                                    <div class="profile-photo">
                                        <ng-template #avatar>
                                            <img [src]="element.imageUrl" />
                                        </ng-template>
                                        <div class="only-letter" *ngIf="
                                        element.imageUrl === '' ||
                                          element.imageUrl == null ||
                                          element.imageUrl == 'string';
                                        else avatar
                                      ">
                                            <div class="display-letter">
                                                {{ element?.authorName.split("")[0] | uppercase }}
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="left-side-author-text">
                                    <div class="left-side-author-name">
                                        {{element.authorName | titlecase}}
                                    </div>
                                    <div class="left-side-author-mail">
                                        {{element.email}}
                                    </div>

                                </div>
                            </div>
                            <div class="blog-author-section">
                              <div class="blog-right-side">
                                  <!-- <div class="right-left-side">
                                      Created on :
                                  </div> -->
                                  <div class="right-right-side">
                                      {{element.createdTimeStamp | date: 'mediumDate'}}
                                  </div>
                              </div>
                              <div class="blog-left-side">
                                <div class="left-right-side">
                                  {{element.blogCount}}
                              </div>
                                <div class="left-left-side">
                                    Blogs
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="right-side">
                          <div class="eye-icon" (click)="viewTag(element.authorId)">
                            <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/208646c1711777403577Layer_2_00000111876129534530335130000017802684315469108106_.png
                            " alt="view-icon" />
                            <div class="view-text" >
                              View
                            </div>
                          </div>
                          <div class="right-side-edit-button" (click)="editAuthor(element)">
                              <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/756499c1711777462099edit.png" alt="edit_icon" />
                              <div class="edit-text">
                                Edit
                              </div>
                          </div>
                          <div class="right-side-delete-button" (click)="deleteAuthor(element.authorId)">
                              <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/943682c1711777511307delete.png" alt="delete_icon" />
                              <div class="delete-text">
                                Delete Author
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-template #editLoader>
      <div class="api_loader">
        <!-- <div class="dots-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div> -->
        <app-anime-loader></app-anime-loader>
    </div>
    </ng-template>
    <ng-template #emptyscreen>
      <div *ngIf="!viewAccess" class="not-access-text">You don't have view access</div>
        <div  *ngIf="viewAccess && dataSource.length == 0" class="empty-part">
          No authors found. Author details will be displayed here as you publish blogs.
        </div>
    </ng-template>
</section>
