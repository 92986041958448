import { Component, Input, OnInit } from '@angular/core';
import { ElementHelper } from '../../../../elements/elements_helper';

@Component({
  selector: 'app-service-section-process',
  templateUrl: './service-section-process.component.html',
  styleUrls: ['./service-section-process.component.scss']
})
export class ServiceSectionProcessComponent implements OnInit{
  @Input() responseData : any;
  constructor(
    private _elementHelper : ElementHelper
  ){}
  webComponentName : any  = 'service_section_process';
  ngOnInit(): void {
   this.data=this.responseData.styles;
   this.itemData=this.responseData.styles;
  }
data : any = 
{
  "welcometext":this._elementHelper.getTextDescElement('PROCESS','#7153d9',''),
  "title":this._elementHelper.getTextTitleElement('How We Work to Bring the Best','',''),
  "description":this._elementHelper.getTextDescElement('Ut enim ad minim veniam, quis nostrud exercitation ullamco labor is tetur adipisicing elit, sed do eiusmod','',''),
}
itemData : any = 
[
  {
    "number":this._elementHelper.getNumberElement(1),
    "title":this._elementHelper.getTextItemTitleElement('Digital Marketing','',''),
    "description":this._elementHelper.getTextDescElement('Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.','',''),
  },
  {
    "number":this._elementHelper.getNumberElement(2),
    "title":this._elementHelper.getTextItemTitleElement('Website Development','',''),
    "description":this._elementHelper.getTextDescElement('Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.','',''),
  },
  {
    "number":this._elementHelper.getNumberElement(3),
    "title":this._elementHelper.getTextItemTitleElement('Search Engine Optimization','',''),
    "description":this._elementHelper.getTextDescElement('Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.','',''),
  },
  {
    "number":this._elementHelper.getNumberElement(4),
    "title":this._elementHelper.getTextItemTitleElement('Cloud Hosting','',''),
    "description":this._elementHelper.getTextDescElement('Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.','',''),
  }
]
}
