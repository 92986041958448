import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnalyticsComponent } from './analytics/analytics.component';
import { EditWebsiteComponent } from './edit-website/edit-website/edit-website.component';
import { ListAuthorComponent } from './edit-website/website-generationcomponent/blogs/authorscomponents/list-author/list-author.component';
import { CreateBlogsComponent } from './edit-website/website-generationcomponent/blogs/create-blogs/create-blogs.component';
import { ListBlogsComponent } from './edit-website/website-generationcomponent/blogs/list-blogs/list-blogs.component';
import { CreateMasterBlogsComponent } from './edit-website/website-generationcomponent/blogs/masterBlogs/create-master-blogs/create-master-blogs.component';
import { ListMasterBlogsComponent } from './edit-website/website-generationcomponent/blogs/masterBlogs/list-master-blogs/list-master-blogs.component';
import { ViewMasterBlogComponent } from './edit-website/website-generationcomponent/blogs/masterBlogs/view-master-blog/view-master-blog.component';
import { ListTagsComponent } from './edit-website/website-generationcomponent/blogs/tagsComponents/list-tags/list-tags.component';
import { ViewBlogsComponent } from './edit-website/website-generationcomponent/blogs/view-blogs/view-blogs.component';
import { PreviewComponent } from './preview/preview.component';
import { SeoComponent } from './seo/seo.component';
import { SettingsComponent } from './settings/settings.component';
import { ContactLeadsComponent } from './contact-leads/contact-leads.component';
import { BlogPageComponent } from './edit-website/website-generationcomponent/blogs-components/blog-page/blog-page.component';
import { AdditionalComponentsComponent } from './additional-components/additional-components.component';

const routes: Routes = [
  {
    path:'preview',
    component:PreviewComponent
  },
  {
    path:'analytics',
    component:AnalyticsComponent
  },
  {
    path:'seo',
    component:SeoComponent
  },
  {
    path:'settings',
    component:SettingsComponent
  },
  {
    path:'contact-leads',
    component:ContactLeadsComponent
  },

  // Blogs Routing
  {
    path:'blogs/listing',
    component:ListBlogsComponent
  },
  {
    path:'blogs/view-blog',
    component:ViewBlogsComponent
  },
  {
    path:'blogs/tags/listing',
    component:ListTagsComponent
  },
  {
    path:'blogs/authors/listing',
    component:ListAuthorComponent
  },

  //master blog routing
  {
    path:'master/create-blog',
    component:CreateMasterBlogsComponent
  },
  {
    path:'blogs/master/listing',
    component:ListMasterBlogsComponent
  },
  {
    path:'blogs/master/view-blog',
    component:ViewMasterBlogComponent
  },
  {
    path:'blog',
    component:BlogPageComponent
  },{
    path: 'more',
    component: AdditionalComponentsComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WebsiteRoutingModule { }
