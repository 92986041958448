import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BlogServiceService } from '../../blog-service.service';
import { Blog, BlogStatus } from '../../blog';

@Component({
  selector: 'app-list-master-blogs',
  templateUrl: './list-master-blogs.component.html',
  styleUrls: ['./list-master-blogs.component.scss']
})
export class ListMasterBlogsComponent {
  search: string = "";
  constructor(
    private blogService: BlogServiceService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.showApiData();
  }


  Blogs = [
    { name: 'All', value: BlogStatus.ALL },
    { name: 'Drafts', value: BlogStatus.SAVE },
    // { name: 'Scheduled', value: BlogStatus.SCHEDULED },
    { name: 'Published', value: BlogStatus.PUBLISH },
  ]

  blogData: BlogStatus = BlogStatus.ALL;

  getBlogList(value: any) {
    if (value === this.blogData) return;
    
    this.blogData = value;
    this.showApiData();

  }
  blogs: Blog[] = [];
  tempBlogs: Blog[] = [];
  emptyloader: boolean = false;
  showApiData() {
    this.emptyloader = true;
    this.blogService.getMasterBlog().subscribe
      ({
        next: (res: any) => {
          this.emptyloader = false;
          this.blogs = res.data;
          this.tempBlogs = this.blogs.slice();
        },
        error: (err: any) => {
          this.emptyloader = false;
          this.blogService.openSnack(err.error.message, "close");
        }
      })
  }

  // search api integration 

  searchText: any;
  _searchBlog() {
    if (this.searchText) {
      this.blogs = this.tempBlogs.filter((blog: Blog) => blog.postTitle.toLowerCase().includes(this.searchText.toLowerCase()));
    } else {
      this.blogs = this.tempBlogs.slice();
    } 
  }

  searchBlog() {

  }

  clickCards(blogId: any) {
    //console.log(cards);
    this.router.navigate(['/admin/website/blogs/master/view-blog'], { queryParams: { blogId: blogId } })
  }


  createBlog() {
    this.router.navigate(["/master/create-blog"]);
  }

  // deleteBlog(position:any,ev:any){
  //   ev.stopPropagation();
  //   let deleteDepartmentModel = this.blogService.openDeleteDialog(
  //     DeleteBlogComponent,
  //     'auto',
  //     '600px',
  //     { blogId:position.id },
  //     '97vw'
  //   );
  //   deleteDepartmentModel.afterClosed().subscribe((data) => {
  //     if(data.ended=="success"){
  //       this.showApiData();
  //     }
  //   });
  // }

  editBlog(blog: any) {
    this.blogService.setBlogData(blog);
    this.router.navigate(["/master/create-blog"], { queryParams: { edit: true } });
  }
}
