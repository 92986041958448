import { SelectionModel } from '@angular/cdk/collections';
import { Component, HostListener } from '@angular/core';
import { TemplateService } from 'src/app/services/template.service';
import { BlogServiceService } from '../../blog-service.service';
import { AddTagsComponent } from '../add-tags/add-tags.component';
import { DeleteTagsComponent } from '../delete-tags/delete-tags.component';
import { ViewTagsComponent } from '../view-tags/view-tags.component';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { ActivatedRoute } from '@angular/router';
import { StaffServiceService } from 'src/app/master-config-components/micro-apps/staff/service/staff-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
export interface PeriodicElement {
  name: string;
  position: string;
  country: string;
  date: string;
  symbol: string;
  Action : any;
}
@Component({
  selector: 'app-list-tags',
  templateUrl: './list-tags.component.html',
  styleUrls: ['./list-tags.component.scss','./mq-list-tags.component.scss']
})
export class ListTagsComponent {

  displayedColumns: string[] =
    [
      'position',
      'name',
      'date',
      'Action'
    ];
  dataSource:any = [];
  ischecked: boolean = true;


  constructor(
    private templateService: TemplateService,
    public blogService: BlogServiceService,
    private _eventHelper : EventEmmiterService,
    private readonly activatedRoute: ActivatedRoute,
    private staffService: StaffServiceService,
    private snackBar: MatSnackBar
  ) {
    this.getScreenSize();
  }

  viewAccess: boolean = true;
  editAccess: boolean = true;
  createAccess: boolean = true;
  deleteAccess: boolean = true;

  ngOnInit() {
    this.getStaffAccess();
    this.activatedRoute.queryParams.subscribe((qParam)=> {
      if (qParam['action'] === "ADD") {
        this.addTag();
      }
    })

    this.getAllListing();
    this.checkingSwithBusiness();
  }

  getStaffAccess() {
    this.viewAccess = this.staffService.getStaffPermission('BLOGS', 'VIEW')
    this.editAccess = this.staffService.getStaffPermission('BLOGS', 'EDIT')
    this.createAccess = this.staffService.getStaffPermission('BLOGS', 'CREATE')
    this.deleteAccess = this.staffService.getStaffPermission('BLOGS', 'DELETE')
  }

  ngOnDestroy(){
    if(this.checkSiwtchBusiess){
      this.checkSiwtchBusiess.unsubscribe();
    }
  }

  checkSiwtchBusiess : any;
  checkingSwithBusiness(){
    this.checkSiwtchBusiess = this._eventHelper.switchBusiness.subscribe(
      (res:any) => {
        if(res.switchBusiness){
          this.getStaffAccess();
          // setTimeout(() => {
            this.getAllListing()
          // }, 500);
        }
      }
    )
  }

  searchbar: boolean = false;
  search() {
    this.searchbar = !this.searchbar;
  }
  closesearch() {
    this.searchbar = false;
    this.tagName = '';
    this.getAllListing();
  }


  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  addTag() {
    if(!this.createAccess) {
      this.snackBar.open('You dont have create access', 'close', {duration: 2000});
      return;
    }

    if (this.screenWidth > 475){
      let dialog = this.blogService.openDeleteDialog(
        AddTagsComponent,
        'auto',
        '400px',
        { edit: false },
        '80vw'
      );
      dialog.afterClosed().subscribe((data: any) => {
        if (!data) return;

        this.getAllListing();
      });
    }
    // if (this.screenWidth < 475){
    //   let dialog = this.blogService.openBottomSheet(
    //     AddTagsComponent,
    //     'auto',
    //     { edit: false },
    //   );
    //   dialog.afterDismissed().subscribe((data: any) => {
    //     if (!data) return;

    //     this.getAllListing();
    //   });
    // }
    if (this.screenWidth < 475){
      let dialog = this.blogService.openDeleteDialog(
        AddTagsComponent,
        '100vh',
        '400px',
        { edit: false },
        '100vh'
      );
      dialog.afterClosed().subscribe((data: any) => {
        if (!data) return;

        this.getAllListing();
      });
    }
  }

  editTag(tag: any) {
    if(!this.editAccess) {
      this.snackBar.open('You dont have edit access', 'close', {duration: 2000});
      return;
    }
    if (this.screenWidth > 475){
      let dialog = this.blogService.openDeleteDialog(
        AddTagsComponent,
        'auto',
        '400px',
        { edit: true, data: tag },
        '80vw'
      );
      dialog.afterClosed().subscribe((data: any) => {
        if (!data) return;

        this.getAllListing();
      });
    }

    // if (this.screenWidth < 475){
    //   let dialog = this.blogService.openBottomSheet(
    //     AddTagsComponent,
    //     'auto',
    //     { edit: true, data: tag },
    //   );
    //   dialog.afterDismissed().subscribe((data: any) => {
    //     if (!data) return;

    //     this.getAllListing();
    //   });
    // }

    if (this.screenWidth < 475){
      let dialog = this.blogService.openDeleteDialog(
        AddTagsComponent,
        '100vh',
        '400px',
        { edit: true, data: tag },
        '100vh'
      );
      dialog.afterClosed().subscribe((data: any) => {
        if (!data) return;

        this.getAllListing();
      });
    }
  }


  deleteTag(tagId: any) {
    if(!this.deleteAccess) {
      this.snackBar.open('You dont have delete access', 'close', {duration: 2000});
      return;
    }
    if (this.screenWidth > 475){
      let dialog = this.blogService.openDeleteDialog(
        DeleteTagsComponent,
        '250px',
        '350px',
        { data: tagId },
        '80vw'
      );
      dialog.afterClosed().subscribe((data: any) => {
        if (!data) return;
        this.getAllListing();
      });
    }

    if (this.screenWidth < 475){
      let dialog = this.blogService.openBottomSheet(
        DeleteTagsComponent,
        '250px',
        { data: tagId },
      );
      dialog.afterDismissed().subscribe((data: any) => {
        if (!data) return;
        this.getAllListing();
      });
    }
  }
  page = 0;
  size = 1000000;
  tagName: any;
  emptyloader: boolean = false;
  getAllListing() {
    this.emptyloader = true;
    this.blogService.getBlogsTagsByBusinessId(JSON.parse(window.localStorage.getItem('bDetails') || "").id).subscribe
      ({
        next: (res: any) => {
          this.emptyloader = false;
          this.dataSource = res.data;
        },
        error: (err: any) => {
          this.emptyloader = false;
          // this.blogService.openSnack(err.error.message, "close");
          this.dataSource = [];
        }
      })
  }


  _searchIndustry() {
    if (this.tagName.length > 2) this.searchIndustry();
    else if (this.tagName === '') this.getAllListing();
  }
  // search
  searchIndustry() {
    this.emptyloader = true;
    this.blogService.searchBlogsTagByBusinessId(this.tagName, JSON.parse(window.localStorage.getItem('bDetails') || "").id).subscribe
      ({
        next: (res: any) => {
          this.emptyloader = false;
          this.dataSource = res.data;
        },
        error: (err: any) => {
          this.emptyloader = false;
          this.blogService.openSnack(err.error.message, "close");
          this.dataSource = [];
        }
      })
  }

  // checkbox
  exportenquiry: any[] = [];
  enquirycheck(item: any, active: any, event: any) {
    event.stopPropagation();
    // if (!active) {
    //   this.exportenquiry.push(item);
    // } else {
    //   this.exportenquiry.forEach((element, i) => {
    //     if (item.leadId === element.leadId) {
    //       this.exportenquiry.splice(i, 1);
    //     }
    //   });
    // }
  }
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1
      }`;
  }
  selection = new SelectionModel<PeriodicElement>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.exportenquiry = [];
      return;
    }

    this.selection.select(...this.dataSource);
    this.exportenquiry = [];
    this.dataSource.forEach((element) => {
      this.exportenquiry.push(element);
    });
  }

  // toggle button
  isToggleChecked: boolean = false;
  activestatus(element: any, index: any) {
    this.isToggleChecked = true;
    // element.tt = true;
    element.active = !element.active;
  }


  viewTag(tagId: any) {
    let width = window.innerWidth > 475 ? '50%' : '100%';
    let maxWidth = window.innerWidth > 475 ? '80vw' : '100vw';
    let dialog = this.blogService.openDeleteDialog(
        ViewTagsComponent,
        '100vh',
        width,
        { data: tagId },
        maxWidth
    );
    dialog.afterClosed().subscribe((data: any) => {
        if (!data) return;
        this.getAllListing();
    });
  }
}

