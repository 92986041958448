import { Component, HostListener } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-anime-loader',
  templateUrl: './anime-loader.component.html',
  styleUrls: ['./anime-loader.component.scss']
})
export class AnimeLoaderComponent {
  constructor(){
    this.getScreenSize();
  }
  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }
  options: AnimationOptions = {
    path: './../../../../assets/animations/animation.json',
  };

  animationCreated(animationItem: AnimationItem): void {
  }
}
