import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CrmServiceService } from '../../../../service/crm-service.service';
import { AddMeetingParticipantsComponent } from '../add-meeting-participants/add-meeting-participants.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DbService } from 'src/app/services/db-service/db.service';
import { Observable, map, startWith } from 'rxjs';
import { FormControl } from '@angular/forms';
import { TemplateService } from 'src/app/services/template.service';
import { duration } from 'moment';
import { StorageKeys } from 'src/app/shared-module-files/simpo.constant';
import { MapService } from 'src/app/services/map.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: "DD-MM-YYYY"
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
  }
};
@Component({
  selector: 'app-create-meeting',
  templateUrl: './create-meeting.component.html',
  styleUrls: ['./create-meeting.component.scss', './mq-create-meeting.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe
  ]
})
export class CreateMeetingComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CreateMeetingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private crmService: CrmServiceService,
    private snackbar: MatSnackBar,
    private dbService: DbService,
    private templateService: TemplateService,
    private mapService: MapService,
    private snackBar: MatSnackBar,
  ) {
    this.getScreenSize();
  }
  ngOnInit(): void {
    if (this.data.data.edit && this.data.data.type !== 'ACTIVITY') {
      this.meetDetails = JSON.parse(JSON.stringify(this.data.data.data));
      this.meetDetails.startDateTime = new Date(this.meetDetails.startDateTime);
      this.meetDetails.endDateTime = new Date(this.meetDetails.endDateTime);
      this.meetDetails.endDateTime.setHours(this.meetDetails.endDateTime.getHours() + 5);
      this.meetDetails.endDateTime.setMinutes(this.meetDetails.endDateTime.getMinutes() + 30);
      this.getStartTime(this.meetDetails.startDateTime);
      this.getEndTime(this.meetDetails.endDateTime)
      this.filterTimes();
    }
    if (this.data.data.type == 'ACTIVITY' && this.data.data.edit) {
      this.getMeetingById()
    }
    if ((this.data.data.type == 'MEETINGS' || this.data.data.type == 'ACTIVITY' || this.data.data.type == 'ALL_MEETINGS') && !this.data.data.edit) {
      this.meetDetails.leadName = this.dbService.getLeadDetails().personalInfo?.name;
      this.meetDetails.leadId = this.dbService.getLeadDetails().id;
      this.meetDetails.participantsList[0].leadMail = this.dbService.getLeadDetails()?.personalInfo?.email;
      this.meetDetails.participantsList[0].companyName = this.dbService.getLeadDetails().personalInfo?.companyName;
    }
    if (this.data.data.edit && this.data.data.type === 'ALL_MEETINGS') {
      this.meetDetails = JSON.parse(JSON.stringify(this.data.data.data));

      this.meetDetails.startDateTime = new Date(this.meetDetails.startDateTime);
      this.meetDetails.endDateTime = new Date(this.meetDetails.endDateTime);
      this.selectedContact = this.meetDetails.leadName;
    }


if(this.meetDetails.meetingType === 'VIRTUAL'){
  this.meetDetails.meetingLink = this.data.data.meetDetails.meetingLink;
}else {
      this.meetDetails.meetingLocated =
        `${localStorage.getItem(StorageKeys.LOCALITY)}` + ',' + `${localStorage.getItem(StorageKeys.STATE)}`

      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );

    }
  }

  screenWidth: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth);
  }

  minDate: Date = new Date();
  participantType: any = "LEAD"
  type = [
    { value: "LEAD", viewValue: "Leads" },
    { value: "CUSTOMER", viewValue: "Customers" }
  ]
  options: any[] = [];
  filteredOptions: Observable<any> | undefined;
  myControl = new FormControl('');
  selectedContact = ""

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.personalInfo.name.toLowerCase().includes(filterValue));
  }


  priorityList = [
    { value: "NONE", viewValue: "None" },
    { value: "HIGH", viewValue: "High" },
    { value: "MEDIUM", viewValue: "Medium" },
    { value: "LOW", viewValue: "Low" },
  ]

  status = [
    { value: "NEW", viewValue: "New" },
    { value: "SCHEDULED", viewValue: "Scheduled" },
    { value: "IN_PROGRESS", viewValue: "In progress" },
    { value: "COMPLETED", viewValue: "Completed" },
    { value: "CANCELLED", viewValue: "Cancelled" },
    { value: "RESCHEDULED", viewValue: "Rescheduled" },
  ]

  reminder = [
    { value: 0, viewValue: 'No Reminder' },
    { value: 10, viewValue: '10 minutes Before' },
    { value: 20, viewValue: '20 minutes Before' },
    { value: 30, viewValue: '30 minutes Before' },
    { value: 40, viewValue: '40 minutes Before' },
    { value: 50, viewValue: '50 minutes Before' },
    { value: 60, viewValue: '1 hour Before' },
    { value: 120, viewValue: '2 hours Before' },
    { value: 180, viewValue: '3 hours Before' },
    { value: 240, viewValue: '4 hours Before' },
    { value: 1440, viewValue: '1 day Before' },
    { value: 2880, viewValue: '2 days Before' }
  ]
  timeArray = [
    
    { hr: 0, min: 0, viewValue: "00 : 00 AM", isDisabledForEndTime: false }, { hr: 0, min: 30, viewValue: " 00 : 30 AM", isDisabledForEndTime: false },
    { hr: 1, min: 0, viewValue: "01 : 00 AM", isDisabledForEndTime: false }, { hr: 1, min: 30, viewValue: "01 : 30 AM", isDisabledForEndTime: false },
    { hr: 2, min: 0, viewValue: "02 : 00 AM", isDisabledForEndTime: false }, { hr: 2, min: 30, viewValue: "02 : 30 AM", isDisabledForEndTime: false },
    { hr: 3, min: 0, viewValue: "03 : 00 AM", isDisabledForEndTime: false }, { hr: 3, min: 30, viewValue: "03 : 30 AM", isDisabledForEndTime: false },
    { hr: 4, min: 0, viewValue: "04 : 00 AM", isDisabledForEndTime: false }, { hr: 4, min: 30, viewValue: "04 : 30 AM", isDisabledForEndTime: false },
    { hr: 5, min: 0, viewValue: "05 : 00 AM", isDisabledForEndTime: false }, { hr: 5, min: 30, viewValue: "05 : 30 AM", isDisabledForEndTime: false },
    { hr: 6, min: 0, viewValue: "06 : 00 AM", isDisabledForEndTime: false }, { hr: 6, min: 30, viewValue: "06 : 30 AM", isDisabledForEndTime: false },
    { hr: 7, min: 0, viewValue: "07 : 00 AM", isDisabledForEndTime: false }, { hr: 7, min: 30, viewValue: "07 : 30 AM", isDisabledForEndTime: false },
    { hr: 8, min: 0, viewValue: "08 : 00 AM", isDisabledForEndTime: false }, { hr: 8, min: 30, viewValue: "08 : 30 AM", isDisabledForEndTime: false },
    { hr: 9, min: 0, viewValue: "09 : 00 AM", isDisabledForEndTime: false }, { hr: 9, min: 30, viewValue: "09 : 30 AM", isDisabledForEndTime: false },
    { hr: 10, min: 0, viewValue: "10 : 00 AM", isDisabledForEndTime: false }, { hr: 10, min: 30, viewValue: "10 : 30 AM", isDisabledForEndTime: false },
    { hr: 11, min: 0, viewValue: "11 : 00 AM", isDisabledForEndTime: false }, { hr: 11, min: 30, viewValue: "11 : 30 AM", isDisabledForEndTime: false },
    { hr: 12, min: 0, viewValue: "12 : 00 PM", isDisabledForEndTime: false }, { hr: 12, min: 30, viewValue: "12 : 30 PM", isDisabledForEndTime: false },
    { hr: 13, min: 0, viewValue: "1 : 00 PM", isDisabledForEndTime: false }, { hr: 13, min: 30, viewValue: "1 : 30 PM", isDisabledForEndTime: false },
    { hr: 14, min: 0, viewValue: "2 : 00 PM", isDisabledForEndTime: false }, { hr: 14, min: 30, viewValue: "2 : 30 PM", isDisabledForEndTime: false },
    { hr: 15, min: 0, viewValue: "3 : 00 PM", isDisabledForEndTime: false }, { hr: 15, min: 30, viewValue: "3 : 30 PM", isDisabledForEndTime: false },
    { hr: 16, min: 0, viewValue: "4 : 00 PM", isDisabledForEndTime: false }, { hr: 16, min: 30, viewValue: "4 : 30 PM", isDisabledForEndTime: false },
    { hr: 17, min: 0, viewValue: "5 : 00 PM", isDisabledForEndTime: false }, { hr: 17, min: 30, viewValue: "5 : 30 PM", isDisabledForEndTime: false },
    { hr: 18, min: 0, viewValue: "6 : 00 PM", isDisabledForEndTime: false }, { hr: 18, min: 30, viewValue: "6 : 30 PM", isDisabledForEndTime: false },
    { hr: 19, min: 0, viewValue: "7 : 00 PM", isDisabledForEndTime: false }, { hr: 19, min: 30, viewValue: "7 : 30 PM", isDisabledForEndTime: false },
    { hr: 20, min: 0, viewValue: "8 : 00 PM", isDisabledForEndTime: false }, { hr: 20, min: 30, viewValue: "8 : 30 PM", isDisabledForEndTime: false },
    { hr: 21, min: 0, viewValue: "9 : 00 PM", isDisabledForEndTime: false }, { hr: 21, min: 30, viewValue: "9 : 30 PM", isDisabledForEndTime: false },
    { hr: 22, min: 0, viewValue: "10 : 00 PM", isDisabledForEndTime: false }, { hr: 22, min: 30, viewValue: "10 : 30 PM", isDisabledForEndTime: false },
    { hr: 23, min: 0, viewValue: "11 : 00 PM", isDisabledForEndTime: false }, { hr: 23, min: 30, viewValue: "11 : 30 PM", isDisabledForEndTime: false },
  ]
  
  startDate: any
  startTime: any
  callStartTime: any
  filteredStartTimeArray: any[] = [];
  filteredEndTimeArray: any[] = [];
  filteredTimeArray: any[] = [];


  getStartTime(date: any) {
    let d = new Date(date);
    let hours = d.getHours();
    let min = d.getMinutes();
    this.timeArray.forEach((element: any) => {
      if (element.hr === hours && element.min === min) {
        this.callStartTime = element.viewValue
      //  console.log(this.callStartTime)
      }
    })
  }
  getEndTime(date: any) {
    let d = new Date(date);
    let hours = d.getHours();
    let min = d.getMinutes();
    this.timeArray.forEach((element: any) => {
      if (element.hr === hours && element.min === min) {
        this.callEndTime = element.viewValue
      //  console.log(this.callEndTime)
      }
    })
  }
  setStartTime() {
    if (this.allDayCheckbox) {
      this.startDate = new Date(this.meetDetails.startDateTime);
      this.startTime = this.startDate.setHours(0, 0);
      this.meetDetails.startDateTime = new Date(this.startTime);
      // console.log(this.meetDetails.startDateTime)
    }
    else {
      this.timeArray.forEach((item: any) => {
        if (item.viewValue === this.callStartTime) {
          this.startDate = new Date(this.meetDetails.startDateTime);
          this.startTime = this.startDate.setHours(item.hr, item.min);
          this.meetDetails.startDateTime = new Date(this.startTime);
          // console.log(this.meetDetails.startDateTime)
        }
      })
    }

  }
  endDate: any
  endTime: any
  callEndTime: any
  setEndTime() {
    this.timeArray.forEach((item: any) => {
      if (item.viewValue === this.callEndTime) {
        this.endDate = new Date(this.meetDetails.endDateTime);
        this.endTime = this.endDate.setHours(item.hr, item.min);
        this.meetDetails.endDateTime = new Date(this.endTime);
       // console.log(this.meetDetails.endDateTime)
      }
    })
  }
  allDayCheckbox: any;

  changeMeetingType() {
    this.meetDetails.meetingLocated = '';
  }

  leadDetails: any = this.dbService.getLeadDetails();
  meetDetails: any = {
    businessId: this.dbService.getBusinessId(),
    leadId: "",
    leadName: "",
    title: "",
    meetingType: 'PHYSICAL',
    meetingLocated: "",
    meetingLink: "",
    startDateTime: "",
    endDateTime: "",
    meetingStatus: "NEW",
    remainder: 0,
    participantsList: [],
    emailData: [],
    participantsRemainder: 0,
    description: "",
    // createdTimeStamp: new Date(),
    // updatedTimeStamp: new Date(),
    createdById: localStorage.getItem(StorageKeys.STAFF_ID),
    createdByName: localStorage.getItem(StorageKeys.STAFF_NAME)
  }
  addLoader: boolean = false;
  createLoader: boolean = false;
  createMeet() {
    this.createLoader = true
    // this.meetDetails.startDateTime.setDate(this.meetDetails.startDateTime.getDate() + 1);
    this.meetDetails.startDateTime.setHours(this.meetDetails.startDateTime.getHours() + 5);
    this.meetDetails.startDateTime.setMinutes(this.meetDetails.startDateTime.getMinutes() + 30);
    // this.meetDetails.endDateTime.setHours(this.meetDetails.endDateTime.getHours() + 5);
    // this.meetDetails.endDateTime.setMinutes(this.meetDetails.endDateTime.getMinutes() + 30);

    // this.meetDetails.endDateTime.setDate(this.meetDetails.endDateTime.getDate() + 1);
    this.crmService.createMeeting(this.meetDetails).subscribe(
      (res: any) => {
        this.dialogRef.close("success");
        this.createLoader = false;
      },
      (err) => {
        this.createLoader = false
        this.snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration: 1500
          }
        )
      }
    )
    this.dialogRef.close("success");
  }
  cancel() {
    this.dialogRef.close()
  }

  editMeet() {
    this.createLoader = true;
    this.meetDetails.startDateTime.setHours(this.meetDetails.startDateTime.getHours() + 5);
    this.meetDetails.startDateTime.setMinutes(this.meetDetails.startDateTime.getMinutes() + 30);
    this.meetDetails.endDateTime.setHours(this.meetDetails.endDateTime.getHours() + 5);
    this.meetDetails.endDateTime.setMinutes(this.meetDetails.endDateTime.getMinutes() + 30);

    this.crmService.editMeeting(this.meetDetails).subscribe(
      (res: any) => {
        this.createLoader = false;
        this.dialogRef.close("success");
      },
      (err) => {
        this.snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration: 1500
          }
        )

        this.createLoader = false;
      }
    )

  }


  searchResults: Observable<string[]> | any;
  searchBox = new FormControl();
  locations: any = [];
  search() {
    this.mapService.searchAddress(this.meetDetails.meetingLocated).subscribe(
      (res: any) => {
        let locations: any = [];
        res.features.forEach((location: any) => {
          locations.push({
            displayName:
              location?.place_name.split(', ')[0] +
              ',' +
              location?.context[0]?.text +
              ',' +
              location?.context[2]?.text,
            latlng: location?.center,
          });
        });
        this.locations = locations;
        this.searchResults = this.searchBox.valueChanges.pipe(
          startWith(''),
          map((value) => this._searchFilter(value))
        );
      },
    );
  }

  private _searchFilter(value: any) {
    const filterValue = value.toLowerCase();

    return this.locations.filter((option: any) =>
      option.displayName.toLowerCase().includes(filterValue)
    );
  }

  selectedLocation: any;
  selectedLocations(option: any) {
    this.selectedLocation = option;
    let splitedLocation = this.selectedLocation.displayName.split(',');
    localStorage.setItem(StorageKeys.LOCALITY, splitedLocation[0])
    localStorage.setItem(StorageKeys.STATE, splitedLocation[1]);
  }


  addParticipants() {
    let dialog = this.crmService.openServiceDialog(
      AddMeetingParticipantsComponent,
      '100vh',
      this.screenWidth > 475 ? '36%' : '100%',
      '100vw',
      { participantsList: this.meetDetails?.participantsList ?? [], emailData: this.meetDetails?.emailData ?? [], type: 'Create' }
    );
    dialog.afterClosed().subscribe((res: any) => {
      if (!res) return;

      this.meetDetails.emailData = res.emailData ?? [];
      this.meetDetails.participantsList = res.participantList ?? [];
      // console.log(res)
    });
  }

  getContacts() {
    this.crmService.searchLeadsCustomers(this.dbService.getBusinessId(), 0, 50, this.participantType, this.selectedContact).subscribe(
      (res: any) => {
        this.options = [];
        this.options = res.data.data;

        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || '')),
        );
      },
      (err) => {
        this.snackbar.open(
          `${err.error.message}`,
          'Close',
          { duration: 1500 }
        )
      }
    )
  }

  // for the leads and customer filter
  pageNo = 0;
  size = 100;
  getdata() {
    this.crmService.getLeadApis(this.dbService.getBusinessId(), this.pageNo, this.size, this.participantType).subscribe(
      (res: any) => {
        this.options = res.data.data;

        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || '')),
        );
      },
      (err) => {
        this.snackbar.open(
          `${err.error.message}`,
          'Close',
          { duration: 1500 }
        )
      }
    )
  }
  contactSelected(contactData: any) {
    this.meetDetails.leadName = contactData.personalInfo.name;
    this.meetDetails.leadId = contactData.id;
  }
  closeparticipant(i: any, isLeadOrCustomer: boolean) {
    if (isLeadOrCustomer) {
      this.meetDetails.participantsList.splice(i, 1);
    } else {
      this.meetDetails.emailData.splice(i, 1);
    }
  }


  getMeetingById() {
    this.crmService.getMeetingDetails(this.data.data.meetId).subscribe(
      (res: any) => {
        this.meetDetails = res.data;
        this.getStartTime(this.meetDetails.startDateTime);
        this.getEndTime(this.meetDetails.endDateTime)
        // console.log(this.meetDetails)
      }
    )
  }
  clipboard: boolean = false;
  clipboardCopy() {
    this.clipboard = true;
    navigator.clipboard.writeText(this.meetDetails.meetingLocated);
    this.templateService.openSnack("link copied successfully", "Close"),
      { duration: 1500 }
    this.clipboard = false;
  }

  filterEndTime() {
    const startDateTime = new Date(this.meetDetails.startDateTime);
    const endDateTime = new Date(this.meetDetails.endDateTime);
    if (startDateTime.setHours(0, 0, 0) === endDateTime.setHours(0, 0, 0)) {
      this.timeArray.forEach((time) => {
        time.isDisabledForEndTime = (time.viewValue < this.callStartTime);
      })
    } else {
      this.timeArray.forEach((time) => {
        time.isDisabledForEndTime = false;
      })
    }
  }
  filterTimes() {
    const now = new Date();
    const selectedStartDate = new Date(this.meetDetails.startDateTime);
    const selectedEndDate = new Date(this.meetDetails.endDateTime);
    
    this.filteredStartTimeArray = this.timeArray.map(time => {
      const itemDate = new Date(selectedStartDate);
      itemDate.setHours(time.hr, time.min);
      
      const isPastTime = itemDate < now;
      return { ...time, isDisabled: isPastTime };
    });

    this.filterEndTimes();
  }

  filterEndTimes() {
    const selectedStartDate = new Date(this.meetDetails.startDateTime);
    const selectedEndDate = new Date(this.meetDetails.endDateTime);
    const isSameDay = selectedStartDate.toDateString() === selectedEndDate.toDateString();
    
    const startTime = this.timeArray.find(t => t.viewValue === this.callStartTime);
    
    this.filteredEndTimeArray = this.timeArray.map(time => {
      if (isSameDay && startTime) {
        const isBeforeOrEqualStart = time.hr < startTime.hr || (time.hr === startTime.hr && time.min <= startTime.min);
        return { ...time, isDisabled: isBeforeOrEqualStart };
      } else {
        return { ...time, isDisabled: false };
      }
    });
  }

  onStartDateChange() {
    this.filterTimes();
    if (this.filteredStartTimeArray.find(t => t.viewValue === this.callStartTime)?.isDisabled) {
      this.callStartTime = '';
    }
    this.filterEndTimes();
  }

  onStartTimeChange() {
    this.filterEndTimes();
    if (this.filteredEndTimeArray.find(t => t.viewValue === this.callEndTime)?.isDisabled) {
      this.callEndTime = '';
    }
  }
  onEndDateChange() {
    this.filterEndTimes();
    if (this.filteredEndTimeArray.find(t => t.viewValue === this.callEndTime)?.isDisabled) {
      this.callEndTime = '';
    }
  }
}
