import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-rest-password',
  templateUrl: './rest-password.component.html',
  styleUrls: ['./rest-password.component.scss']
})
export class RestPasswordComponent implements OnInit {

  constructor(
    private _authService : AuthServiceService,
    private _snakBar : MatSnackBar,
    private _router : Router
  ){}

  password : any = '';
  confirmPassword : any  = '';
  staffId : any = '';
  emailId : any = '';
  token: any =''
  passwordHide = true;
  cnfirmPasswordHide = true;
  loader : boolean = false;

  ngOnInit(): void {
    this.checkParams();
  }

  // password

  pwd : FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(8), this.validatePassword()]),
  });
  get passwordInput() {
    return this.pwd.get('password');
  }

  private validatePassword(): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      const oneLetterCapitalPattern = new RegExp(/[A-Z]/g);
      const oneSymbolPattern = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g);
      const oneNumberPattern = new RegExp(/[0-9]/g);

      const errorObj = {
        oneLetterCapital: oneLetterCapitalPattern.test(c.value),
        oneSymbolPattern: oneSymbolPattern.test(c.value),
        oneNumberPattern: oneNumberPattern.test(c.value),
        minimumLength: c.value.length >= 8
      }

      if (!errorObj.minimumLength || !errorObj.oneLetterCapital || !errorObj.oneNumberPattern || !errorObj.oneSymbolPattern) {
        return errorObj;
      }

      return null;
    }
  }

  //confirm password
  cnfirmPwd:FormGroup = new FormGroup({
    confirmPassword: new FormControl('', [Validators.required, Validators.min(3)]),
  });
  get confirmPasswordInput() {
    return this.cnfirmPwd.get('password');
  }

  checkParams(){
    let url = window.location.href.split("?")[1];
    let queryString = new URLSearchParams(url);
    for (let pair of queryString.entries()) {
      if(pair[0] === 'uid'){
        this.staffId = pair[1];
      }
      if(pair[0] === 'email'){
        this.emailId = pair[1];
      }
      if(pair[0] === 'token'){
        this.token = pair[1];
      }
    }
  }

  confirmPasswordBoolean : boolean = false;

  checkingConfirmPassword(){
    if(this.password === this.confirmPassword){
      this.confirmPasswordBoolean = false;
    }
    else{
      this.confirmPasswordBoolean = true;
    }
  }

  submit(){
    if(!this.pwd.valid) {
      this._snakBar.open("Password strength is weak", 'close', {duration: 2000});
      return
    }
    this.loader = true;
    if(this.password === this.confirmPassword){
      let data = {
        staffId:this.staffId,
        newPassword:this.confirmPassword,
        emailId:this.emailId,
        token: this.token
      }

      this._authService.resetPassword(data).subscribe(
        (res:any) => {
          this._snakBar.open(
            'Password Changed Successfully',
            'Ok',
            {
              duration:1500
            }
          );
          this._router.navigate(['']);
          this.loader = false;
        },
        (err:any) => {
          this._snakBar.open(
            // 'Something went wrong, Please try again',
            err.error.message,
            'Close',
            {
              duration:5000
            }
          );
          this.loader = false;
        }
      )
    }
    else{
      this.confirmPasswordBoolean = true;
      this.loader = false;
    }
  }
}
