import { Component, HostListener } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogServiceService } from '../blog-service.service';
import { DeleteBlogComponent } from '../delete-blog/delete-blog.component';
import { MetaTagsPopupComponent } from '../meta-tags-popup/meta-tags-popup.component';
import { Blog } from '../blog';
import { StaffServiceService } from 'src/app/master-config-components/micro-apps/staff/service/staff-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-view-blogs',
  templateUrl: './view-blogs.component.html',
  styleUrls: ['./view-blogs.component.scss', './mq-view-blogs.component.scss']
})
export class ViewBlogsComponent {
  constructor(
    private blogService:BlogServiceService,
    private route:ActivatedRoute,
    private router:Router,
    private metaService: Meta,
    private titleService: Title,
    private staffService: StaffServiceService,
    private snackBar: MatSnackBar
  ) {
    this.subscribeQueryParams();
  }

  screenWidth:any;
  @HostListener("window: resize", ["$event"])
  getScreenSize(){
    this.screenWidth = window.innerWidth;
  }

  editAccess: boolean = true;
  deleteAccess: boolean = true;
  ngOnInit(): void {
    this.editAccess = this.staffService.getStaffPermission('BLOGS', 'EDIT')
    this.deleteAccess = this.staffService.getStaffPermission('BLOGS', 'DELETE')
    this.showApiData();
    this.getScreenSize();
  }

  ngAfterViewInit() {
    // this.metaService.addTag({
    //   name: 'description',
    //   content: 'ajay'
    // });
    //console.log(this.blog.description);
  }
  blogId:any="";
  subscribeQueryParams(){
    this.route.queryParams.subscribe((params:any)=>{
        this.blogId=params.blogId;
        console.log(this.blogId);

    })
  }

  blog : Blog = new Blog();
  showApiData(){
    this.blogService.getBlogById(this.blogId).subscribe(
      (res:any)=>{
        this.blog=res.data;
        // this.metaService.addTags([this.blog?.blogMeta.metaDescription as MetaDefinition, this.blog?.keyWords as MetaDefinition, this.blog?.author as MetaDefinition]);
        // this.titleService.setTitle(this.blog?.blogMeta.metaTitle);
      }
    )
    // this.barTransy?.reinit();
  }

  editBlog() {
    //;
    if(!this.editAccess) {
      this.snackBar.open('You dont have edit access', 'close', {duration: 2000})
      return;
    }
    this.blogService.setBlogData(this.blog);
    this.router.navigate(["/create-blog"], { queryParams: { edit: true } });
  }


  back(){
    this.router.navigate(["/admin/website/blogs/listing"]);
  }

  filterBtn() {
    let blogModel = this.blogService.filterDialog(
      MetaTagsPopupComponent,
      'filter-dialog-container',
      '73vh',
      '300px',
      'relative',
      {
        data:{blogData:this.blog,typeRequest:"api",blogType:'master'}
      }
    );
     blogModel.afterClosed().subscribe((data:any) => {
      //console.log(data);

    });
  }

   deleteBlog(){
    if(!this.deleteAccess) {
      this.snackBar.open('You dont have delete access', 'close', {duration: 2000})
      return;
    }
    if (this.screenWidth > 475){
      let deleteDepartmentModel = this.blogService.openDeleteDialog(
        DeleteBlogComponent,
        'auto',
        '370px',
        { blogId:this.blog.id},
        '80vw'
      );
      deleteDepartmentModel.afterClosed().subscribe((data) => {
        if(data.ended=="success"){
          this.router.navigate(['/admin/website/blogs/listing']);
        }
      });
    }
    if (this.screenWidth < 475){
      let deleteDepartmentModel = this.blogService.openBottomSheet(
        DeleteBlogComponent,
        'auto',
        { blogId:this.blog.id},
      );
      deleteDepartmentModel.afterDismissed().subscribe((data) => {
        if(data.ended=="success"){
          this.router.navigate(['/admin/website/blogs/listing']);
        }
      });
    }
  }
}
