export enum STYLE_THEME {
    "LIGHT" = "LIGHT",
    "DARK" = "DARK"
}
export enum BUTTON_STYLE {
    "OUTLINE" = "OUTLINE",
    "SOLID" = "SOLID"
}
export enum BUTTON_SHAPE {
    "ROUNDED" = "ROUNDED",
    "SHARP" = "SHARP"
}
export enum ANIMATION_SPEED {
    "SLOW" = "SLOW",
    "FAST" = "FAST",
    "MEDIUM" = "MEDIUM"
}

export enum HEADING_FONTFAMILY {

}
export enum BODY_FONTFAMILY {
    
}