import { Component, Inject, Optional, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SeoData } from '../editor.modal';
import { TemplateService } from 'src/app/services/template.service';
import { MediaSelectorComponent } from '../popups/media-selector/media-selector.component';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { F } from '@angular/cdk/keycodes';
import { StaffServiceService } from 'src/app/master-config-components/micro-apps/staff/service/staff-service.service';
import {  Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-page-edit',
  templateUrl: './page-edit.component.html',
  styleUrls: ['./page-edit.component.scss']
})
export class PageEditComponent {
  constructor(
    private dialog: MatDialogRef<PageEditComponent>,
    private templateService: TemplateService,
    private eventEmmiterService: EventEmmiterService,
    @Optional() @Inject(MAT_DIALOG_DATA) public matData,
    private _bottomSheet: MatBottomSheetRef,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData,
    private snackBar: MatSnackBar,
    private staffService: StaffServiceService,
    private metaService: Meta,
    private titleService: Title,

  ){}

  data: any;
  profileImage: any;
  isChecked: boolean = false;
  siteMapChecked: boolean= false;
  navbarButton: any = [];
  folderList: any[] = [];
  folderId: any ;
  addToFolder: boolean = false;

  viewAccess: boolean = true;
  editAccess: boolean = true;
  ngOnInit(){
    this.viewAccess = this.staffService.getStaffPermission('SEO', 'VIEW');
    this.editAccess = this.staffService.getStaffPermission('SEO', 'EDIT');
    console.log('folderlist is',this.folderList)

    if(window.innerWidth > 475){
      this.data = this.matData.pageData
      this.navbarButton = this.matData.navbarButton
      this.folderList = this.matData.folder;
    }
    else{
      this.data = this.bottomSheetData.pageData
      this.navbarButton = this.bottomSheetData.navbarButton
      this.folderList = this.bottomSheetData.folder;
    }
    this.folderId = this.data?.folderId
    this.addToFolder = this.data?.addToFolder;
    if(!this.data?.seoData){
      this.data.seoData = new SeoData();
    }
    this.isChecked = this.data?.seoData?.canonical === '1' ? true : false;
    this.siteMapChecked = this.data?.seoData?.siteMap === '1' ? true : false;


    // const ogImageUrl = this.data.seoData.image || 'default_image.jpg';
    // this.metaService.addTags([
    //   { name: 'og:image', content: ogImageUrl }
    // ]);


    this.getScreenSize()

  }


  scrWidth: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.scrWidth = window.innerWidth;
  }

  closeTab(){
    if(this.scrWidth > 475){
      this.dialog.close();
    }
    if(this.scrWidth < 475){
      this._bottomSheet.dismiss();
    }
  }

  replaceImage(){
    if(!this.editAccess) {
      this.snackBar.open('You dont have edit access', 'close', {duration: 2000});
      return;
    }
    let width = window.innerWidth > 475 ? '50%' : 'auto'
    let dialog = this.templateService.openDialog(
      MediaSelectorComponent,
      '90vh',
      width,
      '',
    )
    dialog.afterClosed().subscribe(
      (res:any)=>{
      this.data.seoData.image = res[0].assets[0].url;
      }
    )

  }

  deleteImage(){
    this.data.seoData.image = ''

  }

  markAsCanonical() {
    this.data.seoData.canonical = this.isChecked ? '1' : '0';
  }

  removeSiteMap(){
    this.data.seoData.siteMap = this.siteMapChecked ? '1': '0';
  }

  changeNameCheck(){
    for(let button of this.navbarButton){
      if(!button.folder) {
        if(button.pageId === this.data.id) {
          button.label = this.data.pageName
        }
      } else {
        for(let page of button.folder?.pages) {
          if(page.pageId === this.data.id) {
            page.label = this.data.pageName;
          }
        }
      }
    }
  }

  discardData(){
    this.data.folderId = this.folderId;
    this.data.addToFolder = this.addToFolder;
  }

  changeAddToFolder(){
    if(!this.data.addToFolder) {
      this.data.folderId = undefined;
    }
  }

  saveData(){
    if(this.data.addToFolder && !this.data.folderId) {
      this.snackBar.open('please select folder', 'close', {duration: 1500});
      return;
    }
    let action = '';
    if(!this.folderId && this.data.folderId)
      action = 'add data';
    else if(this.folderId && !this.data.folderId)
      action = 'Remove data';
    else if(this.data.folderId && this.data.folderId != this.folderId)
      action = 'Update data';

    if(window.innerWidth > 475)
      this.dialog.close({action: action, folderId: this.folderId})
    else
      this._bottomSheet.dismiss({action: action, folderId: this.folderId})
  }

  disablePageName(pageName: any) {
    return pageName == 'Home' || pageName === 'Product List' || pageName === 'Product Description' || pageName === 'Cart' || pageName === 'Checkout'
      || pageName === 'Wishlist' || pageName === 'User Profile' || pageName === 'Auth' || pageName === 'Payment Verification' || pageName === 'Authentication Required'
  }
}
