import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChannelType } from 'simpo-component-library';
import { FooterSocialChannelModel } from 'simpo-component-library/lib/sections/footer-section/footer-section.modal';

@Component({
  selector: 'app-add-social-channels',
  templateUrl: './add-social-channels.component.html',
  styleUrls: ['./add-social-channels.component.scss']
})
export class AddSocialChannelsComponent {
  constructor(public dialogRef: MatDialogRef<AddSocialChannelsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){}


  socialLink : FooterSocialChannelModel = {
    type: ChannelType.FACEBOOK,
    url: '',
    link: ''
  };
  ngOnInit(){
    if(this.data){
      this.socialLink = this.data.socialLink;
    }
  }

  channels: Array<type> = [
    {
      type: ChannelType.FACEBOOK
    },
    {
      type: ChannelType.INSTAGRAM
    },
    {
      type: ChannelType.LINKEDIN
    },
    {
      type: ChannelType.TELEGRAM
    },
    {
      type: ChannelType.THREADS
    },
    {
      type: ChannelType.TWITTER
    },
    {
      type: ChannelType.YOUTUBE
    }
  ]

  closeDialog(){
    this.dialogRef.close();
  }

  addNewChannel(){
    this.dialogRef.close({data: this.socialLink});
  }
}

interface type {
  type: ChannelType;
}
