import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from './../../services/template.service';
import { Industry, StorageKeys } from 'src/app/shared-module-files/simpo.constant';

@Component({
  selector: 'app-business-name',
  templateUrl: './business-name.component.html',
  styleUrls: ['./business-name.component.scss' , './mq-business-name.component.scss']
})
export class BusinessNameComponent implements OnInit {

  businessName = '';
  suggestedNamesScreen : boolean = false;
  suggestedNames : any = [];
  isHidden = false;

  constructor(
    private router : Router,
    private templateService : TemplateService,
    private _dbService : DbService
  ) { }

  loaderSection : boolean = false;
  industry: Industry = {
    name: '',
    industryId: '',
    subIndustryId: '',
    industryName: '',
    subIndustryName: ''
  };

  ngOnInit(): void {
    localStorage.setItem(StorageKeys.BUSINESS_NAME, '');
    localStorage.setItem(StorageKeys.WEBSITE_ID, '')
    this.getAllBusinessNames();
    this.getBusinessCategoryImages();
    this.getScreenSize();
  }

  
  scrWidth:any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?:any) {
    this.scrWidth = window.innerWidth;
  }

  slideConfig = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "infinite":false,
    "autoplay":true,
    "autoplaySpeed":3500,
    "dots":false,
    "arrows":false,
    "pauseOnHover":false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  mobileslideConfig = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "infinite":false,
    "autoplay":false,
    "autoplaySpeed":3500,
    "dots":false,
    "arrows":true,
    "pauseOnHover":true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  suggestNames : any = [];
  x  = 8;

  seeSuggestedNames() {
    this.suggestNames = [];
    this.suggestedNamesScreen = true;
    let n = this.suggestedNames.length;
    this.x = this.x % n;
    let  first_x_elements = this.suggestedNames.slice(0,this.x);
    let remaining_elements = this.suggestedNames.slice(this.x,n);
    this.suggestedNames = [...remaining_elements,...first_x_elements];

    for (let i = 0; i < this.suggestedNames.length; i++) {
      if (i < 8){
        this.suggestNames.push(this.suggestedNames[i]);
      }

      if (i === 8) {
        break;
      }
    }
  }
  enterOwnBName(){
    this.suggestedNamesScreen = false; 
    this.suggestNames = [];
  }
  seeMore(){
    this.isHidden = true;

    setTimeout(() => {
      this.suggestNames = [];
      let n = this.suggestedNames.length;
      this.x = this.x % n;
      let first_x_elements = this.suggestedNames.slice(0, this.x);
      let remaining_elements = this.suggestedNames.slice(this.x, n);
      this.suggestedNames = [...remaining_elements, ...first_x_elements];

      for (let i = 0; i < this.suggestedNames.length; i++) {
        if (i < 8) {
          this.suggestNames.push(this.suggestedNames[i]);
        }

        if (i === 8) {
          break;
        }
      }

      this.isHidden = false;
    }, 500);
  }

  backToScreen(){
    this.router.navigate(['business-type'])
  }
  generateWebsite() {
    localStorage.setItem(StorageKeys.BUSINESS_NAME, this.businessName);
    this.router.navigate(['/v3-generator']);
  }

  getAllBusinessNames() {
    let industry: any = localStorage.getItem(StorageKeys.INDUSTRY_DETAILS);
    this.industry = industry ? JSON.parse(industry) : this.industry;

    this.templateService.getAllBusinessName(this.industry.subIndustryId).subscribe(
      (res:any) => {
        this.suggestedNames = (res.data || []).map((bName: string) => bName);
      },
      (err) => {
        //console.log(err)
      }
    )
  }

  businessCategoryImages:any[]=[];
  getBusinessCategoryImages(){
    this.loaderSection = true

    this.templateService.getBusinessCategoryImages(this.industry.subIndustryId).subscribe(
      {
        next:(value:any)=>{
          this.businessCategoryImages=value.data.imageUrls;
          setTimeout(() => {
            this.loaderSection = false;
          }, 2000);
        },
        error:(err)=>{
          this.loaderSection = false;
         //console.log("template image url error",err);
             
        },
      }
    )
  }

  clickedSuggestions(selectedName: string){
    this.businessName = selectedName;
    this.suggestedNamesScreen = false;
  }
}
