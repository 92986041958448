import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WebisiteService } from '../../webisite.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-link-domain',
  templateUrl: './link-domain.component.html',
  styleUrls: ['./link-domain.component.scss']
})
export class LinkDomainComponent {

  constructor(
    public dialogRef: MatDialogRef<LinkDomainComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private _websiteService : WebisiteService,
    private _snackbar : MatSnackBar
  ){
  }

  linkingDomain :any = '';
  businessDetails : any ;
  domainData:any;
  ngOnInit(){
    let bDetails = window.localStorage.getItem('bDetails');
    if(bDetails){
      this.businessDetails = JSON.parse(bDetails);
    }
  }

  close(){
    this.dialogRef.close({data:null});
  }

  submitLoader : boolean = false;

  submitVerify(){
    this.submitLoader = true;
    let data = {
      businessId : this.businessDetails.id,
      domainUrl : (this.linkingDomain).toLowerCase(),
      force:false
    }

    this._websiteService.gettinEntriToken(data).subscribe(
      (res:any) => {
        this.submitLoader = false;
        this.dialogRef.close({data:res.data,domainUrl:this.linkingDomain,businessId:data.businessId})
      },
      (err) => {
        this.submitLoader = false;
        this._snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
  }
}
