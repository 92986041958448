import { Component, Input, OnInit, HostListener } from '@angular/core';
import { BackgroundModel, EventsService } from 'simpo-component-library';
import { MediaSelectorComponent } from '../../popups/media-selector/media-selector.component';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-background-image-editor',
  templateUrl: './background-image-editor.component.html',
  styleUrls: ['./background-image-editor.component.scss']
})
export class BackgroundImageEditorComponent implements OnInit{

  @Input() data : BackgroundModel = {
    color: '',
    accentColor: '',
    image: '',
    altName: '',
    opacity: 0,
    showImage: false,
    position: {
      x: 0,
      y: 0
    }
  };
  @Input() componentId?: string;

  constructor(
    public eventEmitter : EventsService,
    private templateService: TemplateService,
  ){
    this.screenSize()
  }

  min = 0;
  max = 100;

  ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event'])
  screenSize(event?: any){
    this.screenWidth= window.innerWidth
  }

  screenWidth: any;

  deleteBGImage(){
    this.data.image = ''
    this.eventEmitter.backgroundImageChangeCheck.emit({id: this.componentId, data:this.data})
  }

  addBGImage(){
    let elementId = document.getElementById('browse_bg_img');
    elementId?.click();
  }
  async updateBGImage(ev:any){
    // const file = ev.srcElement.files[0];
    // console.log(file);

    if(this.screenWidth > 475){
      let dialog = this.templateService.openDialogWithData(
        MediaSelectorComponent,
        '90vh',
        '50%',
        {multiple:false}
      )

      dialog.afterClosed().subscribe(
        (res:any)=>{
          this.data.image = res[0].assets[0].url;
          this.eventEmitter.backgroundImageChangeCheck.emit({id: this.componentId, data:this.data})
        }
      )
    }

    if(this.screenWidth < 475){
      let dialog = this.templateService.openDialogWithData(
        MediaSelectorComponent,
        '90vh',
        'auto',
        {multiple:false}
      )

      dialog.afterClosed().subscribe(
        (res:any)=>{
          this.data.image = res[0].assets[0].url;
          this.eventEmitter.backgroundImageChangeCheck.emit({id: this.componentId, data:this.data})
        }
      )
    }
  }

  verticleScroll(){
    this.eventEmitter.backgroundImageChangeCheck.emit({id: this.componentId,data:this.data})
  }
  opacityIncrease(){
    this.eventEmitter.colorOverlayChangeChecks.emit({id: this.componentId, data:this.data})
  }

}
