export const FontFamily = [
    {
        name: "'Courier New', Courier, monospace",
    },
    {
        name: "'Times New Roman', Times, serif",
    },
    {
        name: "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
    },
    {
        name: "'Georgia', 'sans-serif'",
    },

]