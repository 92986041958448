import { Component, HostListener } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-lottie-loader',
  templateUrl: './lottie-loader.component.html',
  styleUrls: ['./lottie-loader.component.scss']
})
export class LottieLoaderComponent {

  constructor(){
    this.getScreenSize();
  }


  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }
  options: AnimationOptions = {
    path: './../../../../assets/animations/animation.json',
  };

  animationCreated(animationItem: AnimationItem): void {
  }
}
