<section class="main_section">
    <div class="title">
        Connect existing domain
        <div><mat-icon (click)="close()">close</mat-icon></div>
    </div>
    <div class="hr-line"></div>
    <div class="registerDomain">
        Register Domain
    </div>
    <div class="form_field business-field-section">
        <mat-form-field class="sfull-width" appearance="outline">
            <input placeholder="Ex : abc.com" matInput [(ngModel)]="linkingDomain"  pattern="[a-z.]+"/>
        </mat-form-field>
    </div>
    <div class="btnRightAlign">
        <button class="buttonStyle" (click)="submitVerify()" *ngIf="!submitLoader">Submit & Verify</button>
        <button class="buttonStyle" *ngIf="submitLoader">
            <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading..
        </button>
    </div>
</section>
