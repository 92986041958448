<section class="popup_section">
    <div class="popup_title_close_btn" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="popup_title">Edit List</div>
        <div class="close_btn" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="hr_line"></div>
    <div class="popup_bottom_section" *ngIf="!pageLoader" >

        <!-- if dynamic item having image it'll show  -->


        <section class="main_section" *ngIf="data['image'] != null">
            <ng-container  *ngIf="!imageUploadingLoader">
            <div class="inside-div" *ngIf="data['image'].attachment ==='';else showImage">
                <div class="upper-div">
                        <div class="upload-icon">
                            <img src="./../../../../../../../../../assets/images/editor/uploadIcons.svg" alt="upload-icon" (click)="addImage()">
                        </div>
                        <div class="sub-image-text">
                        <span class="text-color" (click)="addImage()">Click to upload</span> or drag and drop PNG or JPG (atleast. 32x32px)
                        </div>
                </div>
                <input id="vendorImage" type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" class="browse-btn" id="browse_img"
                (change)="updateImage($event)"/>
            </div>
            <ng-template #showImage >
                <div class="image_fill">
                    <img [src]="data['image'].attachment" [alt]="data['image'].altName">
                    <div class="show-reUpload">
                        <div class="updateImage"
                        (click)="addImage()" >
                            Change Image
                        </div>
                        <input id="vendorImage" type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" class="browse-btn display_image"
                         id="browse_img"
                         (change)="updateImage($event)"/>
                    </div>
                </div>
            </ng-template>
        </ng-container>
        </section>


        <!-- if dynamic item having title it'll show -->

        <section class="title_edit_section" *ngIf="data['title'] != null">
            <app-text-editor-card [data]="data['title']"
            [dynamicItemHrbar]="true" [selectedComponent]="componentData"></app-text-editor-card>
        </section>

        <!-- if dynamic item having redirection urls -->

        <section class="title_edit_section" *ngIf="data['redirection'] != null">
            <app-text-editor-card [data]="data['redirection']"
            [dynamicItemHrbar]="true" [selectedComponent]="componentData"></app-text-editor-card>
        </section>

        <section class="title_edit_section" *ngIf="data['price'] != null">
            <app-text-editor-card [data]="data['price']"
            [dynamicItemHrbar]="true" [selectedComponent]="componentData"></app-text-editor-card>
          </section>

          <section class="title_edit_section" *ngIf="data['benefits'] != null">
            <ng-container *ngFor="let benifit of data['benefits']">
              <app-text-editor-card [data]="benifit"
              [dynamicItemHrbar]="true" [selectedComponent]="componentData"></app-text-editor-card>
            </ng-container>
          </section>

        <!-- if dynamic items having description it'll show -->

        <section class="description_edit_section" *ngIf="data['description'] != null">
            <app-text-editor-card [data]="data['description']"
            [dynamicItemHrbar]="true" [selectedComponent]="componentData"></app-text-editor-card>
        </section>

        <section class="description_edit_section" *ngIf="data['designation'] != null">
            <app-text-editor-card [data]="data['designation']"
            [dynamicItemHrbar]="true"[selectedComponent]="componentData"></app-text-editor-card>
        </section>

        <section class="description_edit_section" *ngIf="data['name'] != null">
            <app-text-editor-card [data]="data['name']"
            [dynamicItemHrbar]="true"[selectedComponent]="componentData"></app-text-editor-card>
        </section>

        <section class="descripiton_edit_section" *ngIf="data['number'] != null">
            <app-text-editor-card [data]="data['number']"
            [dynamicItemHrbar]="true"[selectedComponent]="componentData"></app-text-editor-card>
        </section>

        <!-- if dynamic items having button -->
        <div *ngIf="data['button']">
            <app-button-editor-card [data]="data['button']"
            [samePageComponents]="samepageComops"></app-button-editor-card>
        </div>

        <div class="page_level_style" *ngIf="!data.bgTransparent">
            <div class="label_heading">Background Color</div>
            <div class="bgcolor input-group">
                <input type="color" name="bgColor" class="color_box"   placeholder="text" [(ngModel)]="data.bgColor" (ngModelChange)="changeTextColor()">
                <input type="text" class="color_code" [(ngModel)]="data.bgColor">
            </div>
        </div>

    </div>
    <div class="button_section" *ngIf="!edit">
        <button (click)="updateList()">Update List</button>
    </div>
</section>

<section class="loader_section" *ngIf="imageUploadingLoader">
    <div class="api_loader">
        <div class="dots-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
</section>
