<section class="main-section">
    <!-- header-section  -->
    <div class="header-main-section">
        <div class="header-left-side">
            Member Detail
        </div>
        <div class="header-right-side">
            <div class="left-side-btn">
                <div class="delete-btn"><img src="../../../../../../../assets/images/master/delete_icon.svg"
                        alt="delete_icon" (click)="deleteTag(dataSource)" /></div>
                <div class="vertical-line"></div>
                <div class="edit-btn"><img src="../../../../../../../assets/images/master/edit_icon.svg" alt="edit_icon"
                        (click)="editTag(dataSource)" /></div>

            </div>
            <div class="close-btn">
                <img src="../../../../../../../assets/images/close.svg" alt="close_svg" (click)="closeBtn()" />
            </div>
        </div>
    </div>

    <!-- header middle heading  -->
    <ng-container *ngIf="!emptyloader; else editLoader">
    <div class="header-middle-section">
        <div class="left-section">
            <div class="profile-photo">
                <ng-template #avatar>
                    <img [src]="dataSource. blogAuthor.imageUrl" />
                </ng-template>
                <div class="only-letter" *ngIf="
                dataSource. blogAuthor.imageUrl === '' ||
                dataSource.blogAuthor.imageUrl == null ||
                dataSource.blogAuthor.imageUrl == 'string';
                    else avatar
                  ">
                    <div class="display-letter">
                        {{ dataSource?.blogAuthor?.authorName?.split("")[0] | uppercase }}
                    </div>
                </div>
            </div>
        </div>
        <div class="right-section">
            <div class="header-sub-heading">
                {{dataSource?.blogAuthor?.authorName ? dataSource?.blogAuthor?.authorName:'N/A'}}
            </div>
            <div class="header-sub-text">
                {{dataSource?.blogAuthor?.email ? dataSource?.blogAuthor?.email: 'N/A' }}
            </div>
        </div>
    </div>

    <!-- blog section  -->
    <div class="blog-main-section">
        <div class="blog-section">
            <div class="blog-left-section">
                Blogs Created :
            </div>
            <div class="blog-right-section">
                {{dataSource?.blogAuthor?.blogCount ? dataSource?.blogAuthor?.blogCount : 'N/A'}}
            </div>
        </div>
        <div class="blog-section">
            <div class="blog-left-section">
                Created On :
            </div>
            <div class="blog-right-section">
                {{dataSource?.blogAuthor?.createdTimeStamp | date : 'mediumDate'}}
            </div>
        </div>
        <!-- <div class="blog-section">
            <div class="blog-left-section">
                Permissions :
            </div>
            <div class="blog-right-section">
                Create,Edit,Delete
            </div>
        </div>
        <div class="blog-section">
            <div class="blog-left-section">
                Lobies :
            </div>
            <div class="blog-right-section">
                N/A
            </div>
        </div> -->
    </div>

    <hr>

    <!-- blog-bottom-section  -->
    <ng-container *ngIf="dataSource.blogList?.length > 0  ; else emptyScreen">
    <div class="blog-bottom-section">
        <div class="blog-bottom-heading">
            Blogs
        </div>
        <div class="blog-bottom-text">
             Blogs created by {{dataSource?.blogAuthor?.authorName}}
        </div>
        <div class="blog-bottom-form" *ngFor="let blogs of dataSource.blogList">
            <!-- <img src="../../../../../../../../../assets/images/blogs/blog_image.svg" alt="blog_svg" /> -->
            <div class="blog-form-heading">
                {{blogs?.postTitle ?  blogs?.postTitle : 'N/A' | titlecase}}
            </div>
            <div class="form-author-section">
                <div class="form-left">
                    <div class="form-author-left">
                        Author :
                    </div>
                    <div class="form-author-right">
                        {{blogs.author.content ? blogs.author.content : 'N/A'}}
                    </div>
                </div>
                <div class="form-right">
                    <div class="form-right-left">
                        <img src="../../../../../../../../../assets/images/blogs/Vector.svg" alt="vector_svg" />
                    </div>
                    <div class="form-right-right">
                        {{blogs?.blogStatus ? blogs?.blogStatus : 'N/A'}}
                    </div>
                </div>
            </div>
            <div class="blog-position-section">
              <div class="position-section">
                {{blogs.createdTimeStamp | date : 'mediumDate'}}
               </div>
               <div class="words-section">
                  {{blogs?.wordCount ? blogs?.wordCount : 'N/A'}} Words
               </div>
               <div class="read-section">
                   {{blogs?.readTime ? blogs?.readTime : 'N/A'}} mins read
               </div>
               <div class="edited-section">
                Edited  on {{blogs?.updatedTimeStamp | date : 'mediumDate'}}
              </div>
            </div>
        </div>
    </div>
    </ng-container>
    </ng-container>
    <ng-template #editLoader>
        <div class="img-section">
            <img src="./../../../../../../../../../assets/images/loader/beeos_loader.gif" alt="loader.svg" />
        </div>
    </ng-template>
    <ng-template #emptyScreen>
        <div class="empty-screen-section">
            No Any Blog Yet !!
        </div>
    </ng-template>

</section>
