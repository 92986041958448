export class PageData {
  id: string = '';
  websiteCode: string = ''; // SHORT CODE FOR VIEWING PURPOSE
  homePageId: string = '';
  websiteThemeId: string = '';
  components: Section[] = [];
  businessName: string = '';
  path: string = '';
  pageName: string = '';
  updatedTimeStamp: Date = new Date();
  seoData: SeoData = new SeoData();
}

export class Section {
  componentId: string = '';
  id: string = ''; // unique component id in page
  sectionType: string = '';
  sectionName: string = '';
  desc: string = '';
  content: any = null;
  styles: any = null;
  action: any = null;
}

export class SeoData {
  metaTitle: string = '';
  metaDescription: string = '';
  keywords: string = '';
  image: string = ''
  canonicalUrl:string | null = null
  canonical: string = '0';
  siteMap:string = '0';
  h1TagData:string | null = null
}
