<section class="main_section" >
    <div class="headPart">
        <div class="title">{{data['lm_title']}}</div>
    </div>
    <ng-container *ngIf="!pageLoader">
    <div class="inside-div" *ngIf="data.attachment ==='';else showImage">
        <div class="upper-div">
                <div class="upload-icon">
                    <img src="./../../../../../../../../../assets/images/editor/uploadIcons.svg" alt="upload-icon">
                </div>
                <div class="sub-image-text">
                <span class="text-color">Click to upload</span> or drag and drop PNG or JPG (atleast. 32x32px)
                </div>
        </div> 
        <input id="vendorImage" type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" class="browse-btn" id="browse_img"
        (change)="updateImage($event)"/>
    </div>
    <ng-template #showImage>
        <div class="image_fill">
            <!-- <img [src]="item.imageUrl" alt="about-us" *ngIf="item.imageUrl"> -->
            <img [src]="data.attachment" [alt]="data.altName">
            <div class="show-reUpload">
                <div class="updateImage" (click)="addImage(data)">
                    Update Image
                </div>
                <input id="vendorImage" type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" class="browse-btn display_image" id="browse_img"
                (change)="updateImage($event)"/>
                <!-- <div class="remove-text">Remove</div> -->
            </div>
        </div>
    </ng-template>
</ng-container>
</section>

<section class="loader_section" *ngIf="pageLoader">
    <div class="api_loader">
        <div class="dots-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
</section>