<section class="popup_section">
    <div class="popup_title_close_btn">
        <div class="popup_title">Edit Contact Details</div>
        <div class="close_btn" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="hr_line"></div>
    <div class="popup_bottom_section">
        <ng-container *ngFor="let item of gettingArray">
            <app-text-editor-card [data]="item"
            [dynamicItemHrbar]="true"></app-text-editor-card>
        </ng-container>
    </div>
</section>