<section class="main-section">
    <div class="title">
        Contact Us
        <div><mat-icon (click)="close()">close</mat-icon></div>
    </div>

    <div class="content-box">
        <!-- <div class="content-heading">Notes</div> -->
        <textarea
        placeholder="Add notes"
        cols="120"
        rows="4"
        class="width-text"></textarea>
    </div>

    <div class="button-container">
        <div class="cancel" (click)="close()">Cancel</div>
        <div class="apply">Submit </div>
    </div>

</section>
