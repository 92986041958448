import { Component, Inject, Optional, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-blog-plugin',
  templateUrl: './blog-plugin.component.html',
  styleUrls: ['./blog-plugin.component.scss']
})
export class BlogPluginComponent {

  data: any;
  constructor(
    @Optional() public dialogRef: MatDialogRef<BlogPluginComponent>,
    @Optional() private _bottomSheetRef: MatBottomSheetRef<BlogPluginComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
    private _snakBar: MatSnackBar,
  ) {
    this.getScreenSize();

    if (window.innerWidth > 475) {
      this.data = dialogData;
      this.dialogRef.updatePosition({ top: '250px', right: '98px' });
    } else {
      this.data = bottomSheetData;
    }
  }

  ngOnInit(){
    console.log(this.data);

    this.indexHtmlUrl = environment.indexHtmlUrl;
    this.allBlog = `<div id="all-blogs" accesskey="${this.data.data}"></div>`;
    this.homePageDisplay = `<div id="homePageDispaly" accesskey="${this.data.data}"></div>`;
  }

  screenWidth: any;

  @HostListener("window:resize",["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }


  close() {
    if (this.screenWidth > 475) {
      this.dialogRef.close();
    } else if (this.screenWidth < 475) {
      this._bottomSheetRef.dismiss();
    }
  }

  indexHtmlUrl;
  allBlog;
  homePageDisplay;
  copyIndexUrl(){
    // Create a temporary input element
    var tempInput = document.createElement("input");
    // Set its value to the current URL
    tempInput.value = this.indexHtmlUrl;
    // Append it to the body
    document.body.appendChild(tempInput);
    // Select the input's content
    tempInput.select();
    // Copy the selected content to the clipboard
    document.execCommand("copy");
    // Remove the temporary input
    document.body.removeChild(tempInput);

    this._snakBar.open(
      'URL copied',
      'Ok',
      {
        duration:1500
      }
    )
  }
  copyAllBlogsUrl(){
    // Create a temporary input element
    var tempInput = document.createElement("input");
    // Set its value to the current URL
    tempInput.value = this.allBlog;
    // Append it to the body
    document.body.appendChild(tempInput);
    // Select the input's content
    tempInput.select();
    // Copy the selected content to the clipboard
    document.execCommand("copy");
    // Remove the temporary input
    document.body.removeChild(tempInput);

    this._snakBar.open(
      'URL copied',
      'Ok',
      {
        duration:1500
      }
    )
  }
  copyLimitedBlogsUrl(){
    // Create a temporary input element
    var tempInput = document.createElement("input");
    // Set its value to the current URL
    tempInput.value = this.homePageDisplay;
    // Append it to the body
    document.body.appendChild(tempInput);
    // Select the input's content
    tempInput.select();
    // Copy the selected content to the clipboard
    document.execCommand("copy");
    // Remove the temporary input
    document.body.removeChild(tempInput);

    this._snakBar.open(
      'URL copied',
      'Ok',
      {
        duration:1500
      }
    )
  }
}
