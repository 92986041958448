import { Component, Input } from '@angular/core';
import { buttonShape, buttonType } from './global-button-styles.modal';
import { BUTTON_SHAPE, BUTTON_TYPE } from 'simpo-component-library';
import { WebsiteEditor } from 'src/app/website-generation/website-editor.service';

@Component({
  selector: 'app-global-button-styles',
  templateUrl: './global-button-styles.component.html',
  styleUrls: ['./global-button-styles.component.scss']
})
export class GlobalButtonStylesComponent {
  @Input() templatePage: any[] = [];

  constructor(private editorService: WebsiteEditor){}

  buttonType = buttonType
  buttonShape = buttonShape;

  style1Type: BUTTON_TYPE = 'Outline';
  style1Shape: BUTTON_SHAPE = 'Round'

  style2Type: BUTTON_TYPE = 'Solid';
  style2Shape: BUTTON_SHAPE = 'Round'


  changeStyle1Button(){
    this.editorService.changeGlobalButtonStyle(1, this.style1Type, this.style1Shape, this.templatePage);
  }

  changeStyle2Button(){
    this.editorService.changeGlobalButtonStyle(2, this.style2Type, this.style2Shape, this.templatePage);
  }
}
