import { ChangeDetectorRef, Component, Input, OnInit, Inject } from '@angular/core';
import { ElementHelper } from '../../../elements/elements_helper';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { fontSizes } from './../../../../../../../../constants/common-constant'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentImageUploadService } from 'src/app/services/component-image-upload.service';

@Component({
  selector: 'app-navbar-editor-card',
  templateUrl: './navbar-editor-card.component.html',
  styleUrls: ['./navbar-editor-card.component.scss']
})
export class NavbarEditorCardComponent implements OnInit{
  fontSizes : any = fontSizes;
  // @Input() data : any;
  singleValue : any;
  allPages : any;
  samePageComponents : any

  constructor(
    public dialogRef: MatDialogRef<NavbarEditorCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _eventHelper : EventEmmiterService,
  ){}

  ngOnInit(){
    console.log(this.data)
    if(this.data.edit){
      this.singleValue = this.data.data.item;
      this.allPages = this.data.allPages;
      this.samePageComponents = this.data.samePageComponents;
      this.gettingRedirectionUrls();
    }
    else{
      this.singleValue = this.data.data;
      this.singleValue.displayValue = '';
      this.buttonRedirectionValue = '';
      this.allPages = this.data.allPages;
      this.data.data.highlight = false;
      this.samePageComponents = this.data.samePageComponents
    }

    // this.singleValue.status = true;
  }


  gettingRedirectionUrls(){
    if(this.singleValue.redirectionType === 'INTERNAL'){
      this.buttonRedirectionValue = 'INTERNAL'
      this.internalPageRedirectionValue = this.singleValue.redirectTo;
    }
    else if (this.singleValue.redirectionType === 'EXTERNAL'){
      this.buttonRedirectionValue = 'EXTERNAL'
      this.externalPageRedirectionValue = this.singleValue.redirectTo;
    }
    else{
      this.buttonRedirectionValue = 'SAME_PAGE'
      // this.samePageRedirectionValue = this.singleValue.item.value;
      this.samePageComponents.components.forEach((spc,i) => {
        if(this.singleValue.redirectTo === i){
          this.samePageRedirectionValue = i
        }
      });
    }
  }
  fontSize: number | any ;

  buttonLinks : any = [
    {
      name:'external link',
      value:'EXTERNAL'
    },
    {
      name:'internal link',
      value:'INTERNAL'
    },
    {
      name:'same page',
      value:'SAME_PAGE'
    }
  ]

  onChange(ev){
    this.singleValue['font-size'] = ev.value+'px';
    this._eventHelper.showSaveOption.emit({data:true});
  }

  boldWeight : any = {
    name:'B',
    status : false
  }
  style : any = {
    name:'I',
    status : false
  }
  fontWeight(){
    this.boldWeight.status = !this.boldWeight.status;
    if(this.boldWeight.status){
      this.singleValue['font-weight'] = '900';
    }
    else{
      this.singleValue['font-weight'] = '400';
    }
    this._eventHelper.showSaveOption.emit({data:true});
  }
  fontStyle(){
    this.style.status = !this.style.status;
    if(this.style.status){
      this.singleValue['font-style'] = 'italic';
    }
    else{
      this.singleValue['font-style'] = 'normal'
    }
    this._eventHelper.showSaveOption.emit({data:true});
  }

  // colorChange(){
  //   let elementId = document.getElementById('browse_color');
  //   if(!elementId) return;
  //   elementId.click()
  // }

  increaseTextAreaHeight(ev){
    let code = ev.keyCode || ev.which;
    let text = ev.target.value
    if(code === 13){
      text = text.replace(/\n/g, "<br>");
      this.singleValue.value = text
    }
    this._eventHelper.showSaveOption.emit({data:true});
  }


  buttonRedirectionValue : any ='';
  internalPageRedirectionValue : any = '';
  samePageRedirectionValue : any = '';
  externalPageRedirectionValue : any = '';

  redirectionTypeChange(ev){
    this.singleValue.redirectionType = this.buttonRedirectionValue;
    this.singleValue.redirectTo = null;
    this._eventHelper.showSaveOption.emit({data:true});
  }

  buttonRedirections(ev){
    this.singleValue.redirectionType = ev;
    // this.singleValue.redirectTo = null
    if(ev === 'INTERNAL'){
      this.singleValue.redirectTo = this.internalPageRedirectionValue;
      // this._eventHelper.gettingButtonClickValues.emit({redirectionButtonClicked:this.buttonRedirectionValue,redirectionValue:this.internalPageRedirectionValue})
    }
    else if(ev === 'EXTERNAL'){
      this.singleValue.redirectTo = this.externalPageRedirectionValue;
      // this._eventHelper.gettingButtonClickValues.emit({redirectionButtonClicked:this.buttonRedirectionValue,redirectionValue:this.externalPageRedirectionValue})
    }
    else{
      this.singleValue.redirectTo = this.samePageRedirectionValue;
      // this._eventHelper.gettingButtonClickValues.emit({redirectionButtonClicked:this.buttonRedirectionValue,redirectionValue:this.samePageRedirectionValue})
    }
    // console.log(this.singleValue);
  }

  changeButtonStatus(ev){
    // console.log(ev)
    this.singleValue.status = ev;
    this._eventHelper.showSaveOption.emit({data:true});
  }



  close(){
    this.dialogRef.close({item:null});
  }

  updateList(){
    // console.log(this.singleValue)
    this.dialogRef.close({item:this.singleValue});
  }

}
