import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BusinessServiceService } from '../services/business-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DbService } from 'src/app/services/db-service/db.service';
import { CreateNewBusinessComponent } from '../create-new-business/create-new-business.component';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { TemplateService } from 'src/app/services/template.service';
import { ChangePasswordComponent } from 'src/app/our-components/signin-signup/change-password/change-password.component';
import { StorageKeys } from 'src/app/shared-module-files/simpo.constant';
import { CustomSnackbarComponent } from 'src/app/helper-components/custom-snackbar/custom-snackbar.component';
import { BlogServiceService } from '../../website/edit-website/website-generationcomponent/blogs/blog-service.service';
import { DeleteBusinessComponent } from '../delete-business/delete-business.component';
import { Dialog } from '@angular/cdk/dialog';
import { SocialLoginService } from '../../website-marketing/services/social-login-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-business',
  templateUrl: './list-business.component.html',
  styleUrls: ['./list-business.component.scss']
})
export class ListBusinessComponent implements OnInit {

  @ViewChild('targetElement') targetElement: ElementRef | undefined;
  @ViewChild('box') box: ElementRef | undefined;
  businessDetails: any;
  name: any;

  constructor(
    private router : Router,
    public ds : DomSanitizer,
    private _businessService : BusinessServiceService,
    private _snackBar : MatSnackBar,
    private _dbService : DbService,
    private _eventEmiterService : EventEmmiterService,
    private templateService : TemplateService,
    private renderer: Renderer2,
    public blogService: BlogServiceService,
    private authService:SocialAuthService,
    private socialLogin : SocialLoginService
  ){
    this.name = window.localStorage.getItem('fname');
    this.business = window.localStorage.getItem('business');
    this.business = JSON.parse(this.business);
    this.business.forEach(abi => {
      this.allIds.push(abi.id);
    });

    this.renderer.listen('window', 'click',(e:Event)=>{
      if(!this.targetElement) return;
      if(!this.box) return;
      if(e.target !== this.targetElement.nativeElement && e.target!==this.box.nativeElement){
        this.listBusiness.forEach(element => {
          element.openPopup = false;
        });
      }
    });
    this.getScreenSize();
  }

  business : any ;
  allIds : any = [];
  listBusiness : any = [];
  contactData: any
  listBusinessLoader : boolean = false;
  ngOnInit(): void {
    this.getAllBusinessDetails();

  }
  changePassword()
  {
    if(this.screenWidth > 475){
      this.templateService.openDialogWithData(
        ChangePasswordComponent,
        'auto',
        '420px',
        {
          'staffId':localStorage.getItem(StorageKeys.STAFF_ID)
        }
      )
    }
    if(this.screenWidth < 475){
      this.templateService.openDialogWithData(
        ChangePasswordComponent,
        '100vh',
        '100vw',
        {
          'staffId': localStorage.getItem(StorageKeys.STAFF_ID)
        }
      )
    }

  }
  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  logout() {
    window.localStorage.clear();
    this.authService.signOut().then(() => {
      this._snackBar.open(
        `logout sucessfully`,
        'Close',
        {
          duration: 1500
        }
      )
    }).catch(err => {
      this._snackBar.open(
        `${err.error.message}`,
        'Close',
        {
          duration: 1500
        }
      )
    })
    this.socialLogin.logout()
    // this.router.navigate([''])
    // window.open(`${environment.redirectingOtherSite}`,'_blank');
    if(window.location.href.includes("localhost")){
      window.location.href = 'http://localhost:4200'
    }
    else{
      window.location.href = environment.redirectingOtherSite
    }
  }
showDeleteIcon:boolean=false
  getAllBusinessDetails(){
    this.listBusinessLoader = true;
    this._businessService.getAllBusinessByStaffId(localStorage.getItem(StorageKeys.STAFF_ID)).subscribe(
      (res:any) => {
        let a : any = [];
        res.data.business.forEach((element,index) => {
          if(element.favourite){
            // element.favourite = true;
            const elem = res.data.business.splice(index,1)[0]
            res.data.business.splice(0,0,element)
          }
          // else{
          //   element.favourite = false
          // }
          a.push({favourite:element.favourite,id:element.id,name:element.name})
        });
        window.localStorage.setItem('business',JSON.stringify(a));
        if(res.data.business.length === 1){
          this.showDeleteIcon = true;
        }
        else{
          this.showDeleteIcon=false;
        }
        this.listBusiness = res.data.business;
        this.contactData = res.data
        console.log(this.contactData)
        this.listBusinessLoader = false;
      },
      (err) => {
        this.listBusinessLoader = false;
        this._snackBar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
  }

  getBusinessCategoryImages(id){
    this.templateService.getBusinessCategoryImages(id).subscribe(
      {
        next:(value:any)=>{
          // console.log(value)
          this.listBusiness.forEach((business) => {
            business.image = value.data.imageUrls[0];
          });
        },
        error:(err)=>{
         //console.log("template image url error",err);

        },
      }
    )
  }

  snackbar: any;
  markAsDefault(id:any){
    this.listBusinessLoader = true;
    this.listBusiness.forEach((lb,index) => {
      if(lb.id === id){
        lb.favourite = !lb.favourite;
        lb.openPopup = false;
        // Move the selected item to the beginning of the array
        if(index !== 0){
          this.listBusiness.unshift(this.listBusiness.splice(index, 1)[0]);
          localStorage.setItem('listBusinessOrder', JSON.stringify(this.listBusiness.map(item => item.id)));
        }
        let data ={
          staffId : localStorage.getItem(StorageKeys.STAFF_ID),
          businessId : lb.id,
          isFav : lb.favourite
        }
        this._businessService.markAsFav(data).subscribe(
          (res:any) => {
            window.localStorage.setItem('business',JSON.stringify(res.data.business));
            this._eventEmiterService.updateListingDefault.emit({updateDefault:true});
            // this.getAllBusinessDetails();
            this.snackbar = this._snackBar.openFromComponent(
              CustomSnackbarComponent,
              {
                data: {
                  type: lb.favourite ? 'SUCCESS' : 'ERROR',
                  message: `${lb.name} ${lb.favourite ? 'marked as': 'removed from'} default`,
                  buttonText: "Close",
                  snackbar: this.snackbar
                },
                duration: 2000,
                panelClass: lb.favourite ? 'success-snackbar' : 'error-snackbar'
              }
            );
            this.listBusinessLoader = false;
            // this.getAllBusinessDetails();
          },
          (err) => {
            this._snackBar.open(
              `${err.error.message}`,
              'Close',
              {
                duration:1500
              }
            )
          }
        )
      }
      else{
        lb.favourite = false;
      }
    });
  }

  viewWebsite(item:any){
    window.open(`${item}`,'_blank')
  }
  goToBusiness(item){
    this.listBusinessLoader = true;
    this._businessService.getDetailsByBusinessId(item.id, localStorage.getItem(StorageKeys.STAFF_ID)).subscribe(
      (res:any) => {
        window.localStorage.setItem('bDetails',JSON.stringify(res.data.business));
        localStorage.setItem(StorageKeys.BUSINESS_NAME, res.data.business.name);
        localStorage.setItem(StorageKeys.PERMISSIONS, JSON.stringify(res.data.staffPermissions))
        localStorage.setItem(StorageKeys.SUBINDUSTRYID, res.data?.business?.subIndustryId);
        this._dbService.setBusinessId(res.data.business.id);
        window.localStorage.setItem('type','admin');
        if(!res?.data?.business?.type || res?.data?.business?.type == 'static')
          this.router.navigate(['/admin/home']);
        else
          this.router.navigate(['/admin/products/list-products']);
        this.getCurrencySymbol(res.data.business.id)
           this.listBusinessLoader = false;
      },
      (err) => {
        //console.log(err.error.message);
        this.listBusinessLoader = false;
        this._snackBar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
  }

  getCurrencySymbol(bId:any){
    this._businessService.getCurrencySymbol(bId).subscribe(
      (res:any) => {
        console.log(res.data);
        this._dbService.setBusinessPrefernce(JSON.stringify(res.data));
      },
      (err) => {
        console.log(err)
      }
      // {
      //   next:(value:any)=>{
      //     console.log(value.data);

      //     this._dbService.setBusinessPrefernce(JSON.stringify(value.data));
      //   },
      //   error:(err:any)=>{
      //   },
      // }
    )
  }

  createNewBusiness(){
    if(this.screenWidth > 475)
    {
        this._businessService.openDialog(
        CreateNewBusinessComponent,
        'auto',
        '25%',
        {data:null}
      )
    }
    if(this.screenWidth < 475)
    {
      this._businessService.openDialog(
        CreateNewBusinessComponent,
        'auto',
        '100%',
        {data:null}
      )
    }
    // this.router.navigate(['/business-type'])
  }

  goToBusinessDetails(item){
    // this.router.navigate([`/admin/account/business-details`]);
    this.listBusinessLoader = true;
    this._businessService.getDetailsByBusinessId(item.id, localStorage.getItem(StorageKeys.STAFF_ID)).subscribe(
      (res:any) => {
        window.localStorage.setItem('bDetails',JSON.stringify(res.data.business));
        localStorage.setItem(StorageKeys.BUSINESS_NAME, res.data.business.name);
        localStorage.setItem(StorageKeys.PERMISSIONS, JSON.stringify(res.data.staffPermissions))
        this._dbService.setBusinessId(res.data.business.id);
        window.localStorage.setItem('type','admin');
        this.router.navigate([`/admin/account/business-details`]);
        this.getCurrencySymbol(res.data.business.id)
           this.listBusinessLoader = false;
      }
    )
  }

  popupPosition : any;
  openPopupItem(i,item){
    // console.log(i)
    this.listBusiness.forEach(element => {
      if(element.id === item.id){
        element.openPopup = !element.openPopup;
      }
      else{
        element.openPopup = false;
      }
    });
    let id = document.getElementById(i);
    if(!id) return;
    // console.log(id.getBoundingClientRect())
    this.popupPosition = id.getBoundingClientRect();
  }

  getPositionStyles(){
    // console.log(this.popupPosition);
    let top = (this.popupPosition.top + 15)+'px';
    let left = (this.popupPosition.left - 190)+'px';


    return {
      position:'absolute',
      left : left,
      top: top
    }
  }
  deleteBusiness(id:any){

   let dialog = this.blogService.openDeleteDialog(

    DeleteBusinessComponent,
    '250px',
    '350px',
    {data: id},
    '80vw'
   );
   dialog.afterClosed().subscribe((data:any)=>{
    this.getAllBusinessDetails();
   })


  }

  openSeparatePage(item){
    // this.listBusinessLoader = true;
    this._businessService.getDetailsByBusinessId(item.id, localStorage.getItem(StorageKeys.STAFF_ID)).subscribe(
      (res:any) => {
        window.localStorage.setItem('bDetails',JSON.stringify(res.data.business));
        localStorage.setItem(StorageKeys.BUSINESS_NAME, res.data.business.name);
        localStorage.setItem(StorageKeys.PERMISSIONS, JSON.stringify(res.data.staffPermissions))
        this._dbService.setBusinessId(res.data.business.id);
        window.localStorage.setItem('type','admin');
        // this.router.navigate(['/admin/home']);
        window.open(window.location.origin+'/admin/home','_blank')
        this.getCurrencySymbol(res.data.business.id)
           this.listBusinessLoader = false;
      },
      (err) => {
        //console.log(err.error.message);
        this.listBusinessLoader = false;
        this._snackBar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
  }
}


