<div class="total_container">
    <div class="main-container">
        <div class="headPart">
            <div class="title">{{data['lm_title']}}</div>
            <div class="regenerate-details" (click)="regenerateV2()">
            <div class="regenerate"  *ngIf="!submitLoader &&  selectedComponent?.category?.name !== 'Top Nav Bar' && selectedComponent?.category?.name !== 'Footer'">Regenerate</div>
            <div class="suggest-img"  *ngIf="!submitLoader &&  selectedComponent?.category?.name !== 'Top Nav Bar' && selectedComponent?.category?.name !== 'Footer'">
                <img src="../../../../../../../../../assets/images/marketing/suggest-post-img.svg">
            </div>
            <span  class="regenerate" *ngIf="submitLoader">&nbsp;Regenerating <i class="fas fa-spinner fa-spin"></i></span>
        </div>
        </div>
        <div class="descriptionPart">
            <textarea name="" id="multiliner" class="textarea"
            [(ngModel)]="value" (keyup)="increaseTextAreaHeight($event)"
            [cdkTextareaAutosize]="true"
            style="max-height: 100px;"></textarea>
        </div>
        <div class="color">
            <div class="color_related">
                <input type="color" name="bgColor"   placeholder="text" [(ngModel)]="data['color']" class="bgColor"
                (input)="changeColor($event)">
                <div class="color_title">Color</div>
            </div>
            <div class="fontsize_related">
                <div class="fontsize-dropdown whitebackgroundInput">
                    <!-- <mat-form-field appearance="outline" class="days_dropdown"> -->
                        <mat-select [(ngModel)]="fontSize" (selectionChange)="onChange($event)">
                            <mat-option [value]="item" *ngFor="let item of fontSizes"
                            class="fontsizes-dropdown">{{item}}</mat-option>
                        </mat-select>
                    <!-- </mat-form-field> -->
                </div>
                <div class="color_title_1">Size</div>
            </div>
            <div class="font-styles">
                <div [ngClass]="boldWeight.status ? 'font-color-change-active' : 'font-color-change'" (click)="fontWeight()">
                    <span class="color-label-bold">{{boldWeight.name}}</span>
                </div>
                <div class="vr-line"></div>
                <div [ngClass]="style.status ? 'font-color-change-active' : 'font-color-change'" (click)="fontStyle()">
                    <span class="color-label-italic">{{style.name}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="hr_line" *ngIf="dynamicItemHrbar === undefined"></div>

</div>
