<div class="signup_section" *ngIf="formType === 'SIGN_UP'">
  <div class="signup_left_section">
    <div class="header-website">
      <div class="close-image" (click)="goBackToPreviousPage()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        <img src="./../../../../assets/images/logo.svg" />
      </div>
    </div>
    <div class="signup_body">
      <div class="section_title">Welcome! Lets build your business</div>
      <form [formGroup]="signupForm">
        <div class="fields">
          <div class="field">
            <div class="label">Full Name</div>
            <mat-form-field appearance="outline" class="taking_input">
              <input placeholder="Enter Your Full Name" matInput formControlName="name" />
            </mat-form-field>
          </div>
          <div class="field">
            <div class="label">Mobile Number</div>
            <div class="code_number">
              <div class="country_code">
                <mat-form-field class="taking_input" appearance="outline">
                  <mat-select formControlName="dialCode" [disabled]="disableCountryCodeDropdown">
                    <mat-option [value]="item.shortCode" *ngFor="let item of countryCodes">
                      {{item.name}}({{item.dialingCode}})
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="mobile_number">
                <mat-form-field class="taking_input" appearance="outline">
                  <input placeholder="Enter Your Mobile Number" matInput formControlName="mobileNumber" type="number"
                    pattern="^[6-9]\d{9}$|^[1-9]\d{9}$" onKeyPress="if(this.value.length==15) return false;" />
                </mat-form-field>
              </div>
            </div>
          </div>
          <!-- <div class="field">
            <div class="label">Email Address</div>
            <mat-form-field appearance="outline" class="taking_input">
              <input matInput placeholder="Enter Email Address" (input)="onEmailChange($event)"
                formControlName="email" />
            </mat-form-field>
          </div> -->
          <div class="field">
            <div class="label">Email Address </div>
            <mat-form-field appearance="outline" class="taking_input">
              <input matInput placeholder="Enter Email Address" formControlName="email"
                (input)="onEmailChange($event)" />
            </mat-form-field>
          </div>
          <div class="field">
            <div class="label">Password</div>
            <mat-form-field class="taking_input" appearance="outline">
              <input matInput placeholder="Enter Password" [type]="signupHide ? 'password' : 'text'"
                formControlName="password" minlength="8" maxlength="16" id="focus" (blur)="hide()" />
              <mat-icon matSuffix (click)="signupHide = !signupHide" style="cursor:pointer"
                class="eye-icon">{{signupHide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
            <div class="password_length" style="font-size: 12px !important;">
              <div style="display:flex;justify-content: space-between; font-size: small;">
                <div style="font-size: small;"id="capital" class="validation"
                  [ngClass]="{'success_text': !signupForm.get('password')?.errors || signupForm.get('password')?.errors?.['oneLetterCapital']}">
                  <li style="font-size: small">At least One Letter Uppercase</li>
                </div>
                <div style="font-size: small;"id="special_character" class="validation"
                  [ngClass]="{'success_text': !signupForm.get('password')?.errors || signupForm.get('password')?.errors?.['oneSymbolPattern']}">
                  <li style="font-size: small">One Special Character</li>
                </div>
              </div>
              <div style="font-size: small;"style="display:flex;justify-content: space-between;">
                <div id="number" class="validation"
                  [ngClass]="{'success_text': !signupForm.get('password')?.errors || signupForm.get('password')?.errors?.['oneNumberPattern']}">
                  <li style="font-size: small">At least One Numeric</li>
                </div>
                <div style="font-size: small;"id="min_length" class="validation"
                  [ngClass]="{'success_text': !signupForm.get('password')?.errors || signupForm.get('password')?.errors?.['minimumLength']}">
                  <li style="font-size: small">8 Characters Minimum</li>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="term_conditions">
          By clicking "Create an account" you agree to simpo.ai <span style="color:#0267C1;cursor: pointer;"
            (click)="privacyPolicy()">Privacy Policy</span> and <span style="color: #0267C1;cursor: pointer;"
            (click)="termsConditions()">Terms and Conditions</span>.
        </div>
        <div class="continue_btn">
          <button (click)="createAnAccount()" [disabled]="!signupForm.valid" *ngIf="!loader">
            <span>Create an account</span>
          </button>
          <button *ngIf="loader">
            <span class="spinner">
              <i class="fas fa-spinner fa-spin"></i>&nbsp;Loading
            </span>
          </button>
        </div>
      </form>
      <div class="alrea_account">
        Already have an account? <span style="color:#0267C1;cursor: pointer;" (click)="redirectToSignIn()">Sign
          In</span>
      </div>
      <div class="continue-with">
        <hr>
        <div style="color: rgba(107,114,128,var(1));text-align: center;white-space: nowrap;">Or Continue with</div>
        <hr>
      </div>
      <div class="alrea_account" style="display: flex;justify-content: center;margin-top: 15px;">
        <asl-google-signin-button GoogleSigninButtonDirective type='standard' size='large' shape='rectangular'
          theme='filled_blue'></asl-google-signin-button>
      </div>
    </div>
  </div>
  <div class="signup_right_section">
    <img src="./../../../../assets/images/common/signup_right_full_image.svg" alt="signup_image">
  </div>
</div>
<div class="signup_section" *ngIf="formType === 'LOGIN'">
  <div class="signup-left-section">
    <div class="header-website">
      <div class="close-image" (click)="redirectFromSignin()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        <img (click)="redirectFromSignin()" src="./../../../../assets/images/logo.svg" />
      </div>
    </div>
    <div class="login_body">
      <div class="section_title">Welcome back!</div>
      <div class="section_subtitle">Login to simpo.ai account</div>
      <form [formGroup]="signInForm">
        <div class="fields">
          <div class="field">
            <div class="label">Email Address <span class="required">*</span> </div>
            <mat-form-field appearance="outline" class="taking_input">
              <input matInput placeholder="Enter Email Address" formControlName="email"
                (input)="onEmailChange($event)" />
            </mat-form-field>
          </div>
          <div class="field">
            <div class="label">Password <span class="required">*</span></div>
            <mat-form-field class="taking_input" appearance="outline">
              <input matInput placeholder="Enter Password" [type]="signinHide ? 'password' : 'text'"
                formControlName="password" (keyup.enter)="login()" />
              <mat-icon matSuffix (click)="signinHide = !signinHide" class="eye-icon">{{signinHide ? 'visibility_off' :
                'visibility'}}</mat-icon>
            </mat-form-field>
            <div class="remember_forget">
              <div class="remeber">
                <mat-checkbox color="primary" class="example-margin" formControlName="rememberMe">Remember
                  me</mat-checkbox>
              </div>
              <div class="forgot_password" (click)="forgotPassword()">Forgot password?</div>
            </div>
          </div>
        </div>
        <div class="continue_btn">
          <button (click)="login()" [disabled]="!signInForm.valid" *ngIf="!loader" [ngClass]="{'disable-btn': !signInForm.valid}">
            <span>Sign in</span>
          </button>
          <button *ngIf="loader">
            <span class="spinner">
              <i class="fas fa-spinner fa-spin"></i>&nbsp;Loading
            </span>
          </button>
        </div>
        <!-- <div class="continue">
            <img src="./../../../../assets/images/common/or_continue.svg" alt="continue">
          </div>
          <div class="google">
            <img src="./../../../../assets/images/common/continue_google.svg" alt="continue_google">
          </div> -->
        <div class="alrea_account">
          No account yet? <span style="color:#0267C1;cursor: pointer;" (click)="redirectToSignup()">Sign Up</span>
        </div>
        <div class="continue-with">
          <hr>
          <div style="color: rgba(107,114,128,var(1));text-align: center;white-space: nowrap;">Or Continue with</div>
          <hr>
        </div>
        <div class="alrea_account" style="display: flex;justify-content: center;margin-top: 15px;">
          <asl-google-signin-button GoogleSigninButtonDirective type='standard' size='large' shape='rectangular'
            theme='filled_blue'></asl-google-signin-button>
        </div>
      </form>
    </div>
  </div>
  <div class="signin_right_section">
    <img src="../../../../assets/images/sign_up_image.png" alt="signup_image">
  </div>
</div>
