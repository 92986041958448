import { Component, Input } from '@angular/core';
import { EventsService } from 'simpo-component-library';
import { TemplateService } from 'src/app/services/template.service';
import { WebsiteEditor } from 'src/app/website-generation/website-editor.service';

@Component({
  selector: 'app-bg-color-palette',
  templateUrl: './bg-color-palette.component.html',
  styleUrls: ['./bg-color-palette.component.scss']
})
export class BgColorPaletteComponent {

  @Input() templatePage: any[] = [];
  @Input() themes: any[] = [];

  constructor(private eventService: EventsService,
    private templateService: TemplateService,
    private websiteEditorService: WebsiteEditor){}

  ngOnInit(){
    this.checkCustomTheme()
  }

  colorPallet: boolean = true;

  selectedTab : 'Theme' | 'Custom' = "Theme";
  selectedTheme: any[] = [];

  changeTabs(selectedTab: 'Theme' | 'Custom'){
    this.selectedTab = selectedTab;
  }

  selectTheme(theme:any){
    this.selectedTheme = theme.componentStyles;
    this.websiteEditorService.applyGlobalThemeColor(theme, this.templatePage)
  }
  changeBackground(){
    this.websiteEditorService.changeCustomColor(this.lastColor, this.selectedBgColor ,this.selectedAccentColor, this.templatePage)
    this.lastColor = this.selectedBgColor;
    this.selectedTheme[this.editIndex].bgColor = this.selectedBgColor
  }

  changeAccentColor(){
    this.websiteEditorService.changeCustomColor(this.selectedBgColor, this.selectedBgColor ,this.selectedAccentColor, this.templatePage)
  }

  selectedBgColor: string = '';
  lastColor: string = '';
  selectedAccentColor: string = ''
  editIndex: number = 0;
  editColorPallet(style: any, index: number){
    this.editIndex = index
    this.colorPallet = false;
    this.selectedBgColor = style.bgColor;
    this.lastColor = style.bgColor;
    this.selectedAccentColor = style.accentColor;
  }

  colorSet = new Set();
  customSelectedColor = new Set();
  checkCustomTheme(){
    if(!this.templatePage[0].websiteThemeId){
      this.templatePage[0].websiteThemeId = this.themes[0].id
    }

    for(let theme of this.themes){
      if(theme.id === this.templatePage[0].websiteThemeId){
        for(let style of theme.componentStyles) {
          this.colorSet.add(style.bgColor);
        }
      }
    }

    for(let component of this.templatePage[0].components){
      if(this.customSelectedColor.size < 4){
        if(!this.customSelectedColor.has(component.styles.background.color)){
          this.selectedTheme.push({bgColor: component.styles.background.color, accentColor: component.styles.background.accentColor});
        }
        this.customSelectedColor.add(component.styles.background.color);
      }
      if(!this.colorSet.has(component.styles.background.color)) {
        this.selectedTab = 'Custom';
      }
    }

    // this.customSelectedColor.forEach((color: any) => {
    //   this.selectedTheme.push(color)
    // })
  }
}
