<div class="main_section">
    <div class="total">
        <div class="data_section">
            <div class="welcome_text">
                <app-text-element [data] = "data.welcometext"></app-text-element>
            </div>
            <div class="title">
                <app-text-element [data] = "data.title"></app-text-element>
            </div>
            <div class="description">
                <app-text-element [data] = "data.description"></app-text-element>
            </div>
        </div>
        <div class="itemdata_section">
            <ng-container *ngFor = "let item of itemData">
                <div class="card_container">
                    <div class="serial_num">
                        <app-text-element [data] = "item.number"></app-text-element>
                    </div>
                    <div class="item_title">
                        <app-text-element [data] = "item.title"></app-text-element>
                    </div>
                    <div class="item_description">
                        <app-text-element [data] = "item.description"></app-text-element>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    </div>