import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { customElements } from './../custom-elements';
import { WebisiteService } from '../../../webisite.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-list-custom-elements',
  templateUrl: './list-custom-elements.component.html',
  styleUrls: ['./list-custom-elements.component.scss' ,'./mq-list-custom-elements.component.scss']
})
export class ListCustomElementsComponent implements OnInit{

  customElements : any = customElements;
  localElements : any ;
  localArray : any = []

  constructor(
    public dialogRef: MatDialogRef<ListCustomElementsComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private websiteService : WebisiteService,
    private _snackBar : MatSnackBar
  ){
    this.businessId = window.localStorage.getItem('businessId');
    this.websiteService.getBusinessDetailsById(this.businessId).subscribe(
      (res:any) =>{
        
        this.businessAsPrivate = res.data.website.private;
        if(res.data.website.private){
          this.tabsSection.push(
            {
              name:'Private components',
              status:false,
              value:'PRIVATE_COMPONENTS'
            },
          )
        }
        console.log(res.data)
      }
      
    )

    this.getScreenSize();
  }

  selectedTab : any = ''

  tabsSection : any = [
    {
      name:'custom components',
      status:true,
      value:'CUSTOME_COMPONENTS'
    },
    {
      name:'web components',
      status:false,
      value:'WEB_COMPONENTS'
    },
    
  ];
  businessAsPrivate : any ;
  businessName : any;
  businessId : any;
  async ngOnInit() {

    // this.businessAsPrivate = window.localStorage.getItem('private') == 'true';
    // if(this.businessDetails){
    // }

    
    
    this.selectedTab = this.tabsSection[0].value;
    await this.websiteService.getCategoryList(1000 , 0).toPromise().then((response: any)=> {
      this.subCategories = response.data.map((data)=> {
        return {
          ...data,
          status: false
        }
      });
      this.subCategories[0].status = true;
      this.selectedCategory = this.subCategories[0].name;

    })

    this.getAllLocaElements();
  }

  pageLoader: boolean = false;

  close(){
    this.pageLoader = true;
    this.dialogRef.close({responseData:'',localElement:''})
  }


  changingTab(ev){
    this.tabsSection.forEach(tab => {
      if(tab.value === ev.value){
        this.selectedTab = tab.value;
        tab.status = true;
      }
      else{
        tab.status = false;
      }
    });
    this.changeSubCategory(this.subCategories[0] ,ev.value)
  }
  clickedOnCustomElement(ev){
    this.dialogRef.close({responseData:ev})
  }

  webComponentKeys : any = [];
  webComponentValues : any = [];
  subCategories: any[] = [];
  selectedCategory: string | null = null;
  selectedComponents: any = [];

  changeSubCategory(category: any , selectedTab:string) {
    this.subCategories.forEach((data) => data.status = false)
    category.status = true;
    this.selectedComponents = [];
    if(this.localElements[category.name] != undefined){
      if(selectedTab =='WEB_COMPONENTS'){
      // if(this.businessAsPrivate){
      //   this.selectedComponents = this.localElements[category.name];
      // }
      // else{
        this.localElements[category.name].forEach(element => {
          if(element.private === false){
            this.selectedComponents.push(element)
          }
        }); 
      // }
    }
    else{
      this.localElements[category.name].forEach(element => {
        if(element.private === true){
          this.selectedComponents.push(element)
        }
      });
    }
    }

  }

  getAllLocaElements(){
    this.websiteService.getAllLocalElements().subscribe(
      (res:any)=>{
        this.localElements = res.data;
        console.log(res.data)
        this.changeSubCategory(this.subCategories[0] ,'WEB_COMPONENTS' );


      },
      (err) => {
        this._snackBar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
  }
webComponentValue:any="";
  changingWebComponentValues(item){
    Object.keys(this.localElements).forEach(key => {
      if(key === item){
        this.webComponentValues = this.localElements[key];
        this.webComponentValue=item;
      }
    });
  }

  localElementsClicked(sub){
    this.dialogRef.close({responseData:'',localElement:sub})
  }
  srcHeight:any;
  srcWidth:any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth);
  }
}
