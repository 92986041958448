

<section class="main_section">
    <div class="image_section">
        <img src="./../../../../../../../../../assets/images/editor/featured_icon.svg" alt="warning_featured_image">
    </div>
    <div class="text_section">
        <div class="title_text">
           Delete 
        </div>
        <div class="description_text">
           Delete the page
        </div>
    </div>
    <div class="btn_section">
        <div class="discard_btn">
            <button (click)="discard()">Cancel</button>
        </div>
        <div class="save_changes_btn">
            <button (click)="apply()">
                <span *ngIf="!loader">Delete </span>
                <span class="spinner" *ngIf="loader">
                    <i class="fas fa-spinner fa-spin"></i>&nbsp;Loading
                </span>
            </button>
        </div>
    </div>
</section>
