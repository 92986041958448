import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { RedirectionLinkType } from 'simpo-component-library';
import { StaffServiceService } from 'src/app/master-config-components/micro-apps/staff/service/staff-service.service';
import { DynamicpickcomponentsService } from 'src/app/services/dynamicpickcomponents.service';
import { EcommerceService } from 'src/app/services/ecommerce.service';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { TemplateService } from 'src/app/services/template.service';
import { WebsiteGenerationService } from 'src/app/website-generation/website-generation.service';

@Component({
  selector: 'app-website-preview',
  templateUrl: './website-preview.component.html',
  styleUrls: ['./website-preview.component.scss']
})
export class WebsitePreviewComponent {

  @ViewChild('displayStructure', { read: ViewContainerRef })
  displayStructure: ViewContainerRef | any;

  constructor(
    private templateService: TemplateService,
    private pickupService: DynamicpickcomponentsService,
    private _eventHelper: EventEmmiterService,
    private websiteService: WebsiteGenerationService,
    private ecomService: EcommerceService,
    private staffService: StaffServiceService
  ) {

  }

  websiteData: any;
  selectedPageData: any;
  businessDetails: any;
  path: any;
  gettingWebsiteDataLoader: boolean = true;
  swithchBusiness: any;
  currentPageIndex:number = 0;

  viewAccess: boolean = true;
  ngOnInit(): void {
    this.viewAccess = this.staffService.getStaffPermission('WEBSITE', 'VIEW');
    let bDetails = window.localStorage.getItem('bDetails');
    if (bDetails) {
      this.businessDetails = JSON.parse(bDetails);
      this.templateId = this.businessDetails.templateId;
    }

    this.getTemplateDataById()
    this.checkingSwithBusiness();
  }

  ngOnDestroy(){
    if(this.subscriptionList.length > 0){
      for(let subscription of this.subscriptionList) {
        subscription.unsubscribe();
      }
    }
  }

  private templateId: string | null = null;

  getTemplateDataById() {
    if (!this.templateId)
      return;
    this.templateService.getWebsiteTemplate(this.templateId).subscribe((response: any)=> {
      let data = {}
      this.websiteData = response.data;
      this.unActivateLink(this.websiteData[0].id)
      this.getComponentData(response.data[0].components, 0, data);
      this.applyFontFamily()
    })
  }


  getComponentData(sections: any[], index: number,data: any){
    data['subIndustryId'] = this.businessDetails.subIndustryId;
    data['businessId'] = localStorage.getItem('businessId');
    const promises = sections.map(async (section, index) => {
        if(section.type === 'E_COMMERCE') {
          data['sectionType'] = section.sectionType;
          data['collectionId'] = section?.content?.collectionId;
          data['productId'] = data.productId ? data.productId : section?.content?.productId
          section['responseData'] = await this.ecomService.getData(data);
          console.log(data);
        }
    });

    Promise.all(promises).then(() => {
      this.gettingWebsiteDataLoader = false;
      this.currentPageIndex = index;

      setTimeout(()=> {
        const instance = this.websiteService.createDynamicComponents(this.displayStructure, sections, false);
        this.initiateSubscription(instance);
      }, 500);
    });
  }


  applyFontFamily(){
    if(this.websiteData[0]?.fonts && this.websiteData[0]?.fonts[0]){
      Object.keys(this.websiteData[0].fonts[0]).forEach((key, index) => {
        const value = this.websiteData[0].fonts[0][key];
        document.documentElement.style.setProperty('--bs-head-font-family', key);
        document.documentElement.style.setProperty('--bs-head-font-weight', value);
      });
    }
    if(this.websiteData[0]?.fonts && this.websiteData[0]?.fonts[1]){
      Object.keys(this.websiteData[0].fonts[1]).forEach((key, index) => {
        const value = this.websiteData[0].fonts[0][key];
        document.documentElement.style.setProperty('--bs-body-font-family', key);
        document.documentElement.style.setProperty('--bs-body-font-weight', value);
      })
    }
  }

  subscriptionList:Subscription[] = [];
  private initiateSubscription(instance: any) {
    this.subscriptionList.forEach(element => {
      element.unsubscribe();
    });
    this.subscriptionList.push(
      instance.pageRedirectionButton.subscribe((res:any) => {
        this.changePage(res.data.pageId)
      })
    )
    this.subscriptionList.push(
      instance.buttonRedirection.subscribe((res: any) => {
        this.buttonRedirection(res.data)
      })
    )
    this.subscriptionList.push(
      instance.blogByIdEvent.subscribe((res: any) => {
        this.redirectToViewBlog(res)
      })
    )
    this.subscriptionList.push(
      instance.openBlogListEvent.subscribe((res: any) => {
        this.redirectToBlogList();
      })
    )
    this.subscriptionList.push(
      instance.redirectToPage.subscribe((res: any) => {
        this.redirectToPage(res)
      })
    )
  }

  redirectToPage(res: any){
    if(res.redirectTo === '/list') {
      this.redirectToProductList(res?.data?.categoryId);
    }
    if(res.redirectTo === '/details') {
      this.redirectToDetailPage(res?.data?.productId)
    }
    if(res.redirectTo === '/cart') {
      // this.redirectToCartPage(res?.data?.userId)
    }
  }

  async redirectToProductList(categoryId: any){
    for(let i = 0;i<this.websiteData.length;i++) {
      let page = this.websiteData[i];
      if(page.path === '/list') {
        let data = {
          businessId: this.businessDetails.id,
          categoryId: categoryId
        }
        let section: any[] = [...page.components]
        this.addHeaderAndFooter(section);
        this.getComponentData(section, i, data);
        break;
      }
    }
  }



  redirectToDetailPage(productId: any){
    for(let i = 0;i<this.websiteData.length;i++) {
      let page = this.websiteData[i];
      if(page.path === '/details') {
        let data = {
          productId: productId
        }
        let section: any[] = [...page.components]
        this.addHeaderAndFooter(section);
        this.getComponentData(page.components, i, data);
        break;
      }
    }
  }



  redirectToBlogList(){
    for(let page of this.websiteData) {
      if(page.path === '/blog') {
        this.changePage(page.id);
        break;
      }
    }
  }

  buttonRedirection(response: any){
    if(response.linkType === RedirectionLinkType.External){
      window.open(response.redirectionUrl)
    }
    if(response.linkType === RedirectionLinkType.Page) {
      this.changePage(response.pageId)
    }
    if(response.linkType === RedirectionLinkType.section){
      this.websiteService.samePageRedirection(this.websiteData[this.currentPageIndex], response.pageId);
    }
  }

  redirectToViewBlog(blogId: any){
    let blogSection = {
      sectionType : 'viewBlog',
      blogId : blogId
    }
    let sectionList = [blogSection];
    this.addHeaderAndFooter(sectionList);

    const instance = this.websiteService.createDynamicComponents(this.displayStructure, sectionList, false);
    this.initiateSubscription(instance);
  }

  changePage(pageId: string){
    let index = 0;
    for(let page of this.websiteData){
      if(page.id === pageId){
        this.currentPageIndex = index;
        this.unActivateLink(pageId)

        let section: any[] = [...page.components];
        if(page.pageName !== 'Home'){
          this.addHeaderAndFooter(section);
        }

        const instance = this.websiteService.createDynamicComponents(this.displayStructure, section, false);
        this.initiateSubscription(instance);
        break
      }
      index++;
    }
  }

  addHeaderAndFooter(section: any[]) {
    section.splice(0,0,this.websiteData[0].components[0]);
    section.push(this.websiteData[0].components[this.websiteData[0].components.length - 1]);
  }

  unActivateLink(pageId: any){
    for(let link of this.websiteData[0].components[0].content.navbarButtons){
      if(!link.folder) {
        if(link.pageId === pageId){
          link.status = true;
        } else {
          link.status = false;
        }
      } else {
        let isFound = false;
        for(let page of link.folder.pages) {
          if(page.pageId === pageId) {
            isFound = true;
          }
        }
        if(isFound)
          link.status = true;
        else
          link.status = false
      }
    }
  }


  buttonCickedChecks(res: any) {
    if (res.buttonData.redirectionType === 'SAME_PAGE') {
      this.pickupService.samePageRedirection(this.selectedPageData, res.buttonData.redirectTo);
    }
    else if (res.buttonData.redirectionType === 'INTERNAL') {
      this.displayStructure.clear()

      this.websiteData.forEach(webData => {
        if (webData.path.split('/')[1] != undefined) {
          if ((res.buttonData.redirectTo).toLowerCase() === (webData.path).toLowerCase()) {
            if (webData.path != '' && webData.components[0].category.name != 'Top Nav Bar') {
              webData.components.splice(0, 0, this.websiteData[0].components[0])
              webData.components.splice(webData.components.length, 0, this.websiteData[0].components[this.websiteData[0].components.length - 1]);
            }
            else { }
            this.pickupService.dyanmicComponentPickup(this.displayStructure, webData.components, false);
            this.path = webData.path;
            this.selectedPageData = webData;
          }
        }
        else {
          this.path = webData.path;
          this.selectedPageData = webData;
          this.pickupService.dyanmicComponentPickup(this.displayStructure, webData.components, false);
        }
      });
      this.websiteData[0].components[0].itemStyles.forEach(checkHighlight => {
        if (checkHighlight.item.redirectTo === res.buttonData.redirectTo) {
          checkHighlight.item.highlight = true;
        }
        else {
          checkHighlight.item.highlight = false;
        }
      });
    }
    else {
      this.pickupService.externelUrls(res.buttonData.redirectTo)
    }
  }

  checkingSwithBusiness() {
    this.swithchBusiness = this._eventHelper.switchBusiness.subscribe(
      (res: any) => {
        if (res.switchBusiness) {
          this.viewAccess = this.staffService.getStaffPermission('WEBSITE', 'VIEW');
          this.gettingWebsiteDataLoader = true
          let bDetails = window.localStorage.getItem('bDetails');
          if (bDetails) {
            this.businessDetails = JSON.parse(bDetails);
          }
          this.getTemplateDataById();
        }
        setTimeout(() => {
          this.gettingWebsiteDataLoader = false
        }, 500);
      }
    )
  }
}
