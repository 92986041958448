<section class="main-section">
  <div>
    <!-- <p class="heading mb-0">Image</p> -->
    <p class="desc">Select the image you would like to display</p>
    <div class="upload-image" *ngIf="!imageData.url" (click)="updateImage($event)">
      <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/library-media/354514c1711643199684photo.png" alt=""
        style="height: 40px;">
      <p class="upload-file mb-0">Upload a file</p>
      <p class="mb-0">or select an existing one</p>
      <span style="color: #989DA7;">up to 32MB</span>
      <input type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" hidden
          (change)="updateImage($event)" class="browse-btn display_image" id="browse_bg_img" #uploadImage/>
    </div>
    <div class="sec position-relative" *ngIf="imageData.url">
      <img [src]="imageData.url" [alt]="imageData.altText" *ngIf="imageLoader === false">
      <div class="loader_section" *ngIf="imageLoader">
        <div class="api_loader">
            <app-anime-loader></app-anime-loader>
        </div>
      </div>
      <span class="regerateContent mt-1" (click)="getUnsplaceImage()">
        <i class="fa fa-bolt" *ngIf="!regenerateImageLoader"></i>
        <i class="fa fa-spinner fa-spin" *ngIf="regenerateImageLoader"></i>
        Regenerate
      </span>
      <div class="replace-delete">
        <div class="repalce-image cursor-pointer" (click)="updateImage($event)">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="24"
            height="24" class="icon w-4 h-4" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
              d="m4 16 4.58579-4.5858c.78104-.781 2.04741-.781 2.82841 0L16 16m-2-2 1.5858-1.5858c.781-.781 2.0474-.781 2.8284 0L20 14m-6-6h.01M6 20h12c1.1046 0 2-.8954 2-2V6c0-1.10457-.8954-2-2-2H6c-1.10457 0-2 .89543-2 2v12c0 1.1046.89543 2 2 2Z">
            </path>
          </svg>
          <span>Replace</span>
        </div>
        <input type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" hidden
          (change)="updateImage($event)" class="browse-btn display_image" id="browse_bg_img"/>
        <div class="delete-image cursor-pointer " >
          <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="red" viewBox="0 0 24 24" width="24"
            height="24" class="icon h-4 w-4" aria-hidden="true" >

            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
              d="m19 7-.8673 12.1425C18.0579 20.1891 17.187 21 16.1378 21H7.86224c-1.04928 0-1.92016-.8109-1.99492-1.8575L5 7m5 4v6m4-6v6m1-10V4c0-.55228-.4477-1-1-1h-4c-.55228 0-1 .44772-1 1v3M4 7h16">
            </path>
          </svg> -->
          <mat-icon style="color: red;cursor: pointer;" (click)="deleteImage()">delete</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100" *ngIf="imageData.hasOwnProperty('altText')">
    <p class="heading mb-0">Alt text
      <mat-icon mat-ra ised-button #tooltip="matTooltip" class="i-icon"
          matTooltip="{{'This text will be visible when the image fails'}}"
          matTooltipPosition="right" matTooltipClass="infotooltip" (click)="tooltip.toggle()"
          style="color: grey; font-size: 15px !important; height: 24px">info</mat-icon>
    </p>
    <input type="text" placeholder="Enter alt text" class="input-field w-100" [(ngModel)]="imageData.altText">
  </div>

  <div class="image" *ngIf="imageData.hasOwnProperty('position')">
    <p class="heading mb-0">Image Position</p>
    <div class="horizontal-action">
      <label>Horizontal</label>
      <div class="slider">
        <input [min]="min" [max]="max" step="5" type="range" style="width: 95%" [(ngModel)]="imageData.position.x"
        (input)="horizontalPosition()">
      </div>
    </div>
    <div class="horizontal-action" style="margin-top: 5px;">
      <label>Vertical</label>
      <div class="slider">
        <input [min]="min" [max]="max" step="5" type="range" style="width: 95%" [(ngModel)]="imageData.position.y"
        (input)="horizontalPosition()">
      </div>
    </div>
  </div>
</section>
