import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { baseApiUrl } from 'mapbox-gl';
import { env } from 'process';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CrmServiceService {

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private _bottomSheet: MatBottomSheet,
  ) { }

  openDialog(componentName:any, height:any, width:any, dataModel:any, maxWidth: string = '95vw', maxHeight: string = '80vh') {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth: maxWidth,
      maxHeight: maxHeight
    });
  }

  openSocialBrand(componentName: any, height: any, width: any,data:any) {
    return this.dialog.open(componentName, {
      height: height,
      width: width,
      data:data,

    });
}

  createLead(data:any){
    return this.http.post(
      environment.baseUrl+`crm/leads`,data
    )
  }

  openSnack(message: any, action: any) {
    this.snackbar.open(message, action, { duration: 5000 });
  }

  openServiceDialog(componentName:any, height:any, width:any, maxWidth:any, dataModel:any, position: any = {right:'0px',top:'0px'}) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      maxWidth:maxWidth,
      data: dataModel,
      disableClose: true,
      position: position,
    });
  }
  openServiceDialogMobile(componentName:any, height:any, width:any, maxWidth:any, dataModel:any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      maxWidth:maxWidth,
      data: dataModel,
      disableClose: true,
    });
  }

  getLeadApis(businessId:any,pageNo:any,size:any,leadType:any){
    return this.http.get(
      environment.baseUrl+`crm/lead/v2?businessId=${businessId}&size=${size}&pageNo=${pageNo}&leadType=${leadType}`
    )
  }

  searchLeadApis(businessId:any,text:any,pageNo:any,size:any){
    return this.http.get(
      environment.baseUrl+`crm/leads?businessId=${businessId}&size=${size}&pageNo=${pageNo}&text=${text}`
    )
  }
  searchLeadsCustomers(businessId:any,pageNo:any,size:any,leadType:any,searchText:any){
    return this.http.get(
      environment.baseUrl+`crm/lead/v2?businessId=${businessId}&pageNo=${pageNo}&size=${size}&leadType=${leadType}&text=${searchText}`
    )
  }
  deleteContact(id:any){
    return this.http.delete(
      environment.baseUrl+`crm/leads?leadId=${id}`
    )
  }

  getLeadTimeline(leadId:any , type:any){
    return this.http.get(
      environment.baseUrl+`crm/leads/timeline?leadId=${leadId}&type=${type}`
    )
  }
  getLeadById(leadId:any){
    return this.http.get(
      environment.baseUrl+`crm/leads/get-one?leadId=${leadId}`
    )
  }

  updateLead(data:any){
    return this.http.put(
      environment.baseUrl+`crm/leads`,data
    )
}

changeLeadStatus(leadStatus:any,leadId:any,isDeleted:any){
  return this.http.post(
    environment.baseUrl+`crm/leads/action?leadStatus=${leadStatus}&leadId=${leadId}&isDeleted=${isDeleted}`,{}
  )
}

openBottomSheet(componentName: any,data:any){
  return this. _bottomSheet.open(componentName, {
    data:data,

  });
}

// notes module
listNotes(leadId : any , recentFirst:any){
  return this.http.get(
    environment.baseUrl+`crm/notes?leadId=${leadId}&recentFirst=${recentFirst}`
  )
}
createNotes(data : any){
  return this.http.post(
    environment.baseUrl+`crm/notes/create`,data
  )
}
updateNotes(data : any){
  return this.http.put(
    environment.baseUrl+`crm/notes/update`,data
  )
}
deleteNotes(noteId:any){
  return this.http.delete(
    environment.baseUrl+`crm/notes/delete/${noteId}`
  )}

// attachments module
listAttachments(leadId :any){
  return this.http.get(
    environment.baseUrl+`crm/attachment?leadId=${leadId}`
  )
}
createAttachment(data :any){
  return this.http.post(
    environment.baseUrl+`crm/attachment/create`,data
  )
}
deleteAttachment(attachmentId:any){
  return this.http.delete(
    environment.baseUrl + `crm/attachment/delete/${attachmentId }`
  )
  }

  updateAttachment(data:any){
    return this.http.put(
      environment.baseUrl + `crm/attachment/update`,data
    )
  }

  viewAttachments(attachmentId:any){
    return this.http.get(
      environment.baseUrl + `crm/attachment/get/${attachmentId }`
    )
  }

  searchAttachments(leadId:any , text:any){
    return this.http.get(
      environment.baseUrl + `crm/attachment/search/${leadId}?text=${text}`
    )
  }

// calls module
listCalls(businessId:any,pageNo:any,size:any,callStatus:any){
  return this.http.get(
    environment.baseUrl+`crm/call?businessId=${businessId}&pageNo=${pageNo}&size=${size}&callStatus=${callStatus}`
  )
}

CallsListByLeadId(leadId:any,pageNo:any,size:any,callStatus:any){
  return this.http.get(
    environment.baseUrl + `crm/call/bylead?leadId=${leadId}&pageNo=${pageNo}&size=${size}&callStatus=${callStatus}`
  )
}
searchCalls(businessId:any,pageNo:any,size:any,callStatus:any,searchText:any){
  return this.http.get(
    environment.baseUrl+`crm/call?businessId=${businessId}&pageNo=${pageNo}&size=${size}&callStatus=${callStatus}&text=${searchText}`
  )
}
createCall(data:any){
  return this.http.post(
    environment.baseUrl+`crm/call/create`,data
  )
}
editCall(data:any){
  return this.http.put(
    environment.baseUrl+`crm/call/update`,data
  )
}
callFilter(businessId:any, pageNo:any,size:any , data:any){
  return this.http.put(
    environment.baseUrl+`crm/call/filter/${businessId}?pageNo=${pageNo}&size=${size}`,data
  )
}
changeCallStatus(callId:any, status:any){
  return this.http.put(
    environment.baseUrl+`crm/call/change/status?callId=${callId}&callStatus=${status}`,{}
  )
}
getCallDetails(callId:any){
  return this.http.get(
    environment.baseUrl+`crm/call/${callId}`
  )
}
getNotesByCallId(callId:any){
  return this.http.get(
    environment.baseUrl+`crm/call/taskDoc/${callId}`
  )
}
createNotesByCallId(callId:any, data:any){
  return this.http.post(
    environment.baseUrl+`crm/call/notes/create/${callId}`,data
  )
}
editNotesByCallId(callId:any, data:any){
  return this.http.put(
    environment.baseUrl+`crm/call/notes/update/${callId}`,data
  )
}
deleteNotesByCallId(callId:any,noteId:any){
  return this.http.delete(
    environment.baseUrl+`crm/call/notes/delete/${callId}?notesId=${noteId}`
  )
}

deleteCallActivityCallId(callId:any){
  return this.http.delete(
    environment.baseUrl + `crm/call/callActivity/delete/${callId }`
  )
}
// activity api

activityList(leadId:any){
  return this.http.get(
    environment.baseUrl+`crm/activity?leadId=${leadId}`
  )
}

activityStatus(leadId:any, pageNo:any , size:any , typeType:any){
  return this.http.put(
    environment.baseUrl + `crm/activity/status/${leadId}?pageNo=${pageNo}&size=${size}&typeType=${typeType}`,{}
  )
}

//meetings module
createMeeting(data:any){
  return this.http.post(
    environment.baseUrl+`crm/meeting/create`,data
  )
}

listMeeting(businessId:any ,pageNo:any , size:any, meetingStatus:any){
  return this.http.get(
    environment.baseUrl + `crm/meeting?businessId=${businessId}&pageNo=${pageNo}&size=${size}&meetingStatus=${meetingStatus}`
  )
}

listMeetingByLeadId(leadId:any, pageNo:any, size:any){
  return this.http.get(
    environment.baseUrl + `crm/meeting/withLead/${leadId}?page=${pageNo}&size=${size}`
  )
}

addParticipantsByMeetId(meetId:any,data:any){
  return this.http.put(
    environment.baseUrl+`crm/meeting/participants/${meetId}`,data
  )
}

searchMeeting(businessId:any ,pageNo:any , size:any, meetingStatus:any,text:any){
  return this.http.get(
    environment.baseUrl + `crm/meeting?businessId=${businessId}&pageNo=${pageNo}&size=${size}&meetingStatus=${meetingStatus}&text=${text}`
  )
}

getMeetingDetails(meetingId:any){
  return this.http.get(
    environment.baseUrl + `crm/meeting/${meetingId}`
  )
}
editMeeting(data:any){
  return this.http.put(
    environment.baseUrl+`crm/meeting/update`,data
  )
}
createNotesByMeetingId(meetId:any , data:any){
  return this.http.post(
    environment.baseUrl+`crm/meeting/notes/create/${meetId}`,data
  )
}
editNotesByMeetingId(meetId:any , data:any){
  return this.http.put(
    environment.baseUrl+`crm/meeting/notes/update/${meetId}`,data
  )
}

editAttachmentByMeetingId(meetId:any , data:any){
  return this.http.put(
    environment.baseUrl + `crm/meeting/attachments/update/${meetId}`, data
  )
}

getNotesByMeetingId(meetId:any){
  return this.http.get(
    environment.baseUrl + `crm/meeting/taskDoc/${meetId}`
  )
}


createAttachmentsByMeetingId(meetId:any , data:any){
  return this.http.post(
    environment.baseUrl + `crm/meeting/attachment/create/${meetId}`,data
  )}

  deleteAttachmentsByMeetingId(meetId:any , attachmentId:any){
    return this.http.delete(
      environment.baseUrl + `crm/meeting/attachment/delete/${meetId}?attachmentId=${attachmentId}`
    )
  }

  deleteMeeting(meetingId:any){
    return this.http.delete(
      environment.baseUrl + `crm/meeting/delete/${meetingId}`
    )
  }

  deleteMeetingByCallId(meetId:any,notesId:any){
    return this.http.delete(
      environment.baseUrl + `crm/meeting/notes/delete/${meetId}?notesId=${notesId}`
    )
  }

  meetingFilter(businessId  :any , meetingStatus:any , pageNo:any ,size:any ){
    return this.http.put(
      environment.baseUrl + `crm/meeting/filter/${businessId}?meetingStatus=${meetingStatus}&pageNo=${pageNo}&size=${size}`,{}
    )
  }

  meetingLeadFilter(leadId:any, meetingStatus:any,pageNo:any, size:any){
    return this.http.put(
      environment.baseUrl + `crm/meeting/filter/lead/${leadId}?meetingStatus=${meetingStatus}&pageNo=${pageNo}&size=${size}`,{}
    )

  }



// emails module
listEmails(leadId:any){
  return this.http.get(
    environment.baseUrl+`crm/mail?leadId=${leadId}`,
  )
}
createEmail(data : any){
  return this.http.post(
    environment.baseUrl+`crm/mail/create`,data
  )
}
editEmail(data:any){
  return this.http.put(
    environment.baseUrl+`crm/mail/update`,data
  )
}
viewEmailDetails(mailId:any){
  return this.http.get(
    environment.baseUrl+`crm/mail/get/${mailId}`
  )
}
deleteEmail(id:any){
  return this.http.delete(
    environment.baseUrl+`crm/mail/delete/${id}`
  )
}

// tasks module

listTasks(businessId:any, pageNo:any, size:any, taskStatus:any){
  return this.http.get(
    environment.baseUrl+`crm/task?businessId=${businessId}&pageNo=${pageNo}&size=${size}&taskStatus=${taskStatus}`
  )
}
createTask(data:any){
  return this.http.post(
    environment.baseUrl+`crm/task/create`,data
  )
}
editTask(data:any){
  return this.http.put(
    environment.baseUrl+`crm/task/update`,data
  )
}
getTaskDetails(taskId:any){
  return this.http.get(
    environment.baseUrl+`crm/task/getById/${taskId}`
  )
}

getTaskDocsByTaskId(taskId:any){
  return this.http.get(
    environment.baseUrl+`crm/task/taskDoc/${taskId}`
  )
}
createNotesByTaskId(taskId:any, data:any){
  return this.http.post(
    environment.baseUrl+`crm/task/notes/create/${taskId}`,data
  )
}
editNotesByTaskId(taskId:any, data:any){
  return this.http.put(
    environment.baseUrl+`crm/task/notes/update/${taskId}`,data
  )
}
createAttachmentsByTaskId(taskId:any,data:any){
  return this.http.post(
    environment.baseUrl+`crm/task/attachment/create/${taskId}`,data
  )
}
editAttachmentsByTaskId(taskId:any, data:any){
  return this.http.put(
    environment.baseUrl+`crm/task/attachments/update/${taskId}`,data
  )
}

searchTasks(businessId:any ,pageNo:any , size:any, taskStatus:any,text:any){
  return this.http.get(
    environment.baseUrl + `crm/task?businessId=${businessId}&pageNo=${pageNo}&size=${size}&taskStatus=${taskStatus}&text=${text}`
  )
}

deleteTask(taskId:any){
  return this.http.delete(
    environment.baseUrl+`crm/task/delete/${taskId}`
  )
}

changeTaskStatus(taskId:any,taskStatus:any ){
  return this.http.put(
    environment.baseUrl+`crm/task/change/status?taskId=${taskId}&taskStatus=${taskStatus}`,{}
  )
}
getTasksByLeadId(leadId:any){
  return this.http.get(
    environment.baseUrl+`crm/task/getByLeadId/${leadId}`
  )
}

deleteAttachmentByTastId(taskId:any ,attachmentId :any){
  return this.http.delete(
    environment.baseUrl + `crm/task/attachment/delete/${taskId}?attachmentId=${attachmentId}`
  )
}

taskLeadOpenClose(page:any ,size:any ,type:any , leadId:any, queryType:any){
  return this.http.get(
    environment.baseUrl + `crm/task/openclose?page=${page}&size=${size}&type=${type}&uuId=${leadId}&queryType=${queryType}`
  )
}

taskBusinessOpenClose(page:any,size:any,type:any){

}



taskFilter(page:any ,size:any , data:any ){
  return this.http.put(
    environment.baseUrl + `crm/task/filter?page=${page}&size=${size}`,data
  )
}

// update lead status
updateLeadStatus(leadId:any,status:any){
  return this.http.put(
    environment.baseUrl+`crm/lead/v2/convert?leadId=${leadId}&leadType=${status}`,{}
  )
}
// leads filter

openFilterLead(data:any){
  return this.http.put(
    environment.baseUrl +`crm/leads/filter`,data
  )
}
//activity filter
activityFilter(leadId:any,type:any){
  return this.http.get(
    environment.baseUrl + `crm/activity/list?leadId=${leadId}&type=${type}`
  )
}

// excel for lead listing
genrateLeadExcel(data:any,businessId:any){
  return this.http.post(
    environment.baseUrl + `crm/leads/uploadExcel?businessId=${businessId}`,data
  )
}

// sub industry
getSubIndustries(page:number,size:number){
  return this.http.get(
    environment.serverIp + `master/business/industry/sub?size=${size}&page=${page}`
  )
}

getDashboardData(id: string, startDate: string, endDate: string) {
  return this.http.get(
    environment.baseUrl + `crm/dashboard?businessId=${id}&startDate=${startDate}&endDate=${endDate}&leadXAxisType=WEEK`
  )
}

timelineEvent = new EventEmitter<any>();
}
