import { Component } from '@angular/core';

import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-lottie-poppper',
  templateUrl: './lottie-poppper.component.html',
  styleUrls: ['./lottie-poppper.component.scss']
})
export class LottiePoppperComponent {
  options: AnimationOptions = {
    path: './../../../../assets/animations/lottie-popper-animation.json',
  };

  animationCreated(animationItem: AnimationItem): void {
  }
}
