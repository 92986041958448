import { SelectionModel } from '@angular/cdk/collections';
import { Component, HostListener } from '@angular/core';
import { BlogServiceService } from '../../blog-service.service';
import { AddAuthorComponent } from '../add-author/add-author.component';
import { DeleteAuthorComponent } from '../delete-author/delete-author.component';
import { ViewAuthorComponent } from '../view-author/view-author.component';
import { PeriodicElement } from '../../tagsComponents/list-tags/list-tags.component';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { ActivatedRoute } from '@angular/router';
import { StaffServiceService } from 'src/app/master-config-components/micro-apps/staff/service/staff-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-list-author',
  templateUrl: './list-author.component.html',
  styleUrls: ['./list-author.component.scss', './mq-list-author.component.scss']
})
export class ListAuthorComponent {


  displayedColumns: string[] =
    [
      'position',
      'email',
      'count',
      'date',
      'Action'
    ];
  dataSource: any = [];
  ischecked: boolean = true;


  constructor(
    public blogService: BlogServiceService,
    private _eventHelper : EventEmmiterService,
    private activatedRoute: ActivatedRoute,
    private staffService: StaffServiceService,
    private snackBar: MatSnackBar
  ) {
    this.getScreenSize();
  }

  viewAccess: boolean = true;
  editAccess: boolean = true;
  createAccess: boolean = true;
  deleteAccess: boolean = true;
  ngOnInit() {
    this.getStaffAccess();
    this.activatedRoute.queryParams.subscribe((qParam)=> {
      if (qParam['action'] === "ADD") {
        this.addAuthor();
      }
    })

    this.getAllListing();
    this.checkingSwithBusiness();
  }

  getStaffAccess() {
    this.viewAccess = this.staffService.getStaffPermission('BLOGS', 'VIEW')
    this.editAccess = this.staffService.getStaffPermission('BLOGS', 'EDIT')
    this.createAccess = this.staffService.getStaffPermission('BLOGS', 'CREATE')
    this.deleteAccess = this.staffService.getStaffPermission('BLOGS', 'DELETE')
  }

  ngOnDestroy(){
    if(this.checkSiwtchBusiess){
      this.checkSiwtchBusiess.unsubscribe();
    }
  }

  checkSiwtchBusiess : any;
  checkingSwithBusiness(){
    this.checkSiwtchBusiess = this._eventHelper.switchBusiness.subscribe(
      (res:any) => {
        if(res.switchBusiness){
          this.getStaffAccess();
          // setTimeout(() => {
            this.getAllListing()
          // }, 500);
        }
      }
    )
  }


  searchbar: boolean = false;
  search() {
    this.searchbar = !this.searchbar;
  }
  closesearch() {
    this.searchbar = false;
    this.authorName = '';
    this.getAllListing();
  }

  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  addAuthor() {
    if(!this.createAccess) {
      this.snackBar.open('You dont have create access', 'close', {duration: 2000})
      return;
    }

    if (this.screenWidth > 475){
      let dialog = this.blogService.openDeleteDialog(
        AddAuthorComponent,
        'auto',
        '400px',
        { edit: false },
        '80vw'
      );
      dialog.afterClosed().subscribe((data: any) => {
        this.getAllListing();
      });
    }

    // if (this.screenWidth < 475){
    //   let dialog = this.blogService.openBottomSheet(
    //     AddAuthorComponent,
    //     'auto',
    //     { edit: false },
    //   );
    //   dialog.afterDismissed().subscribe((data: any) => {
    //     this.getAllListing();
    //   });
    // }
    if (this.screenWidth < 475){
      let dialog = this.blogService.openDeleteDialog(
        AddAuthorComponent,
        '100vh',
        '100vh',
        { edit: false },
        '100vh'
      );
      dialog.afterClosed().subscribe((data: any) => {
        this.getAllListing();
      });
    }
  }

  editAuthor(author: any) {
    if(!this.createAccess) {
      this.snackBar.open('You dont have edit access', 'close', {duration: 2000})
      return;
    }

    if (this.screenWidth > 475){
      let dialog = this.blogService.openDeleteDialog(
        AddAuthorComponent,
        'auto',
        '400px',
        { edit: true, data: author },
        '80vw'
      );
      dialog.afterClosed().subscribe((data: any) => {
        this.getAllListing();
      });
    }

    // if (this.screenWidth < 475){
    //   let dialog = this.blogService.openBottomSheet(
    //     AddAuthorComponent,
    //     'auto',
    //     { edit: true, data: author },
    //   );
    //   dialog.afterDismissed().subscribe((data: any) => {
    //     this.getAllListing();
    //   });
    // }

    if (this.screenWidth < 475){
      let dialog = this.blogService.openDeleteDialog(
        AddAuthorComponent,
        '100vh',
        '400px',
        { edit: true, data: author },
        '100vh'
      );
      dialog.afterClosed().subscribe((data: any) => {
        this.getAllListing();
      });
    }
  }


  deleteAuthor(authorId: any) {
    if(!this.createAccess) {
      this.snackBar.open('You dont have delete access', 'close', {duration: 2000})
      return;
    }
    if (this.screenWidth > 475){
      let dialog = this.blogService.openDeleteDialog(
        DeleteAuthorComponent,
        '250px',
        '350px',
        { data: authorId },
        '80vw'
      );
      dialog.afterClosed().subscribe((data: any) => {
        if (!data) return;
        this.getAllListing();
      });
    }
    if(this.screenWidth < 475){
      let dialogue = this.blogService.openBottomSheet(
        DeleteAuthorComponent,
        'auto',
        { data: authorId },
      );
      dialogue.afterDismissed().subscribe((data: any) => {
        this.getAllListing();
      });
    }
  }

  authorName: any;
  emptyloader: boolean = false;
  getAllListing() {
    this.emptyloader = true;
    this.blogService.getAllBlogAuthors(JSON.parse(window.localStorage.getItem('bDetails') || "").id).subscribe
      ({
        next: (res: any) => {
          this.emptyloader = false;
          this.dataSource = res.data;
        },
        error: (err: any) => {
          this.emptyloader = false;
          // this.blogService.openSnack(err.error.message, "close");
          this.dataSource = [];
        }
      })
  }


  _searchAuthor() {
    if (this.authorName.length > 2) this.searchAuthor();
    else if (this.authorName === '') this.getAllListing();
  }
  // search
  searchAuthor() {
    this.emptyloader = true;
    this.blogService.searchBlogsAuthorByBusinessId(this.authorName, JSON.parse(window.localStorage.getItem('bDetails') || "").id).subscribe
      ({
        next: (res: any) => {
          this.emptyloader = false;
          this.dataSource = res.data;
        },
        error: (err: any) => {
          this.emptyloader = false;
          this.blogService.openSnack(err.error.message, "close");
          this.dataSource = [];
        }
      })
  }

  // checkbox
  exportenquiry:any = [];
  enquirycheck(item: any, active: any, event: any) {
    event.stopPropagation();
    // if (!active) {
    //   this.exportenquiry.push(item);
    // } else {
    //   this.exportenquiry.forEach((element, i) => {
    //     if (item.leadId === element.leadId) {
    //       this.exportenquiry.splice(i, 1);
    //     }
    //   });
    // }
  }
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1
      }`;
  }
  selection = new SelectionModel<PeriodicElement>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.exportenquiry = [];
      return;
    }

    this.selection.select(...this.dataSource);
    this.exportenquiry = [];
    this.dataSource.forEach((element) => {
      this.exportenquiry.push(element);
    });
  }

  // toggle button
  isToggleChecked: boolean = false;
  activestatus(element: any, index: any) {
    this.isToggleChecked = true;
    // element.tt = true;
    element.active = !element.active;
  }


  viewTag(authorId: any) {
    if (this.screenWidth > 475){
      let dialog = this.blogService.openDeleteDialog(
        ViewAuthorComponent,
        '100vh',
        '50%',
        { data: authorId },
        '80vw'
      );
      dialog.afterClosed().subscribe((data: any) => {
        if (!data) return;
        this.getAllListing();
      });
    }
    if (this.screenWidth < 475){
      let dialog = this.blogService.openDeleteDialog(
        ViewAuthorComponent,
        '100vh',
        '100%',
        { data: authorId },
        '100vw'
      );
      dialog.afterClosed().subscribe((data: any) => {
        if (!data) return;
        this.getAllListing();
      });
    }
  }
}
