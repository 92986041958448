<div class="total_container">
    <div class="main-container">
        <div class="headPart">
            <!-- <div class="title">{{data['lm_title']}}</div> -->
            <div class="title" *ngIf="backgroundColor">Button</div>
            <div class="title" *ngIf="!backgroundColor">Other Button</div>
            <div class="display_section switch_toggle_btn" *ngIf="backgroundColor">
                <ng-switchery name="switchery-check" [(ngModel)]="data.status"
                (ngModelChange)="changeButtonStatus($event)"></ng-switchery>
            </div>
        </div>
        <div class="descriptionPart">
            <div *ngIf="backgroundColor">
                <div class="label">Label</div>
                <textarea name="" id="multiliner" class="textarea"
                [(ngModel)]="data.value" [cdkTextareaAutosize]="true"
                style="max-height: 100px;" maxlength="15" (keyup)="updatingLabel()"></textarea>
            </div>
            <div class="color">
                <div class="color_related">
                    <input type="color" name="bgColor"   placeholder="text" [(ngModel)]="data['color']" class="bgColor"
                    (input)="changeColor($event)">
                    <div class="color_title">Color</div>
                </div>
                <div class="fontsize_related">
                    <div class="fontsize-dropdown whitebackgroundInput">
                        <!-- <mat-form-field appearance="outline" class="days_dropdown"> -->
                            <mat-select [(ngModel)]="fontSize" (selectionChange)="onChange($event)">
                                <mat-option [value]="item" *ngFor="let item of fontSizes"
                                class="fontsizes-dropdown">{{item}}</mat-option>
                            </mat-select>
                        <!-- </mat-form-field> -->
                    </div>
                    <div class="color_title_1">Size</div>
                </div>
                <div class="font-styles">
                    <div [ngClass]="boldWeight.status ? 'font-color-change-active' : 'font-color-change'" (click)="fontWeight()">
                        <span class="color-label-bold">{{boldWeight.name}}</span>
                    </div>
                    <div class="vr-line"></div>
                    <div [ngClass]="style.status ? 'font-color-change-active' : 'font-color-change'" (click)="fontStyle()">
                        <span class="color-label-italic">{{style.name}}</span>
                    </div>
                </div>
            </div>
            <div class="link_related" *ngIf="backgroundColor">
                <div class="link_label">Link</div>
                <div class="link_and_value">
                    <div class="outer_box">
                        <mat-select [(ngModel)]="buttonRedirectionValue" class="button_dropdown">
                            <mat-option value="">--Select</mat-option>
                            <mat-option [value]="item.value" *ngFor="let item of buttonLinks" class="editor_button_dropdown">
                                {{item.name | titlecase}}
                            </mat-option>
                        </mat-select>
                        <div class="between-vr-line"></div>
                        <input matInput [disabled]="buttonRedirectionValue === ''" class="right_dropdown_input" *ngIf="buttonRedirectionValue === ''" />
                        <input matInput placeholder="External link" (keyup)="buttonRedirections('EXTERNAL')"
                         [(ngModel)]="externalPageRedirectionValue" class="right_dropdown_input" *ngIf="buttonRedirectionValue === 'EXTERNAL'">
                        <mat-select [(ngModel)]="internalPageRedirectionValue" (selectionChange)="buttonRedirections('INTERNAL')" class="right_dropdown" *ngIf="buttonRedirectionValue === 'INTERNAL'">
                            <mat-option value="">--Select--</mat-option>
                            <mat-option [value]="page.path" *ngFor="let page of allPages"
                            class="editor_button_dropdonw">{{page.pageName | titlecase}}</mat-option>
                        </mat-select>
                        <mat-select [(ngModel)]="samePageRedirectionValue" (selectionChange)="buttonRedirections('SAME_PAGE')" class="right_dropdown" *ngIf="buttonRedirectionValue === 'SAME_PAGE'">
                            <mat-option value="">--Select--</mat-option>
                            <mat-option [value]="i" *ngFor="let samePage of samePageComponents.components; let i = index"
                            class="editor_button_dropdonw">{{samePage?.category?.name | titlecase}}</mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
            <div class="page_level_style" *ngIf="backgroundColor">
                <div class="label_heading">Background Color</div>
                <div class="bgcolor_1 input-group">
                    <input type="color" name="bgColor" class="color_box"   placeholder="text" [(ngModel)]="data['background-color']"
                    (input)="changeBgColor()">
                    <input type="text" class="color_code" [(ngModel)]="data['background-color']">
                </div>
            </div>
        </div>
    </div>
    <div class="hr_line"></div>

</div>
