import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, startWith } from 'rxjs';
import { TemplateService } from './../../services/template.service';
import { MapsAPILoader } from '@agm/core';
import * as mapboxgl from 'mapbox-gl';
import { GeoJson } from 'src/app/constants/map';
import { environment } from 'src/environments/environment';
import { MapService } from 'src/app/services/map.service';
import { DbService } from 'src/app/services/db-service/db.service';
import { Meta, Title } from '@angular/platform-browser';
import { Industry, StorageKeys } from 'src/app/shared-module-files/simpo.constant';
import { BusinessServiceService } from 'src/app/master-config-components/micro-apps/business/services/business-service.service';
import { GenerateWebsitePopupComponent } from '../generate-website-popup/generate-website-popup.component';
import { EcommerceService } from 'src/app/services/ecommerce.service';

@Component({
  selector: 'app-business-type',
  templateUrl: './business-type.component.html',
  styleUrls: [
    './business-type.component.scss',
    './mq-business-type.component.scss',
  ],
})
export class BusinessTypeComponent implements OnInit {

  currentMarker: mapboxgl.Marker | null = null;

  constructor(
    private router: Router,
    private webService: TemplateService,
    private snackBar: MatSnackBar,
    private mapsAPILoader: MapsAPILoader,
    private _mapservice: MapService,
    private _dbService: DbService,
    private title: Title,
    private metaTagService: Meta,
    private _route : ActivatedRoute,
    private _businessService : BusinessServiceService,
    private ecommerceService: EcommerceService
  ) {
    window.localStorage.setItem(StorageKeys.PREVIOUS_URL, this.router.url);

  }
  websiteType: any;
  ngOnInit(): void {

    this.websiteType = localStorage.getItem(StorageKeys.WEBSITE_TYPE);

    if(window.location.href.includes("?")){
      this._route.queryParams.subscribe(
        (param:any)=>{
          this._dbService.setOrderId(`${param.id}`);
          this._dbService.setOrderPrice(`${param.p}`);
          this._dbService.setAnuallyOrNot(param.ia);
        }
      )
    }


    this.getScreenSize();

    setTimeout(() => {
      this.loaderSection = false;
    }, 3000);
    this.title.setTitle('Generate Website');
    this.metaTagService.updateTag({ name: 'description', content: 'You can generate website in Just 3 clicks under a minute' });

    this.gettingCurrentLocation();
    this.getAllIndustries();
    this.openPopUp();
  }


  latitude: any;
  longitude: any;
  zoom: any;

  //default settings
  map: mapboxgl.Map | any;
  style = 'mapbox://styles/mapbox/light-v11?optimize=true';
  lat: number = 0;
  lng: number = 0;

  //mapbox data
  source: any;
  markers: any;
  showAddress = '';

  industrySelectControl = new FormControl();
  ecommerceIndustrySelectControl = new FormControl();
  filteredIndustries: Observable<Industry[]> | any;
  filteredEcommerceIndustries: Observable<Industry[]> | any;
  industries: Industry[] = [];
  ecommerceIndustries: Industry[] = []

  selectedIndustry: Industry = {
    name: '',
    industryId: '',
    subIndustryId: '',
    industryName: '',
    subIndustryName: ''
  }

  searchBox = new FormControl();
  searchResults: Observable<string[]> | any;
  seeSuggestionsScreen: boolean = false;
  subCategory = '';
  allSubTypes: any = [];
  locations: any = [];
  indexValue: any = 0;
  // icon :any = {
  //   url: './../../../assets/images/map/map-pin.png',
  //   scaledSize: { height: 45, width: 30 },
  // };

  loaderSection: boolean = true;

  scrWidth: any;
  rejected: boolean = false;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.scrWidth = window.innerWidth;
  }

  _isGPSEnabled: boolean = false;
  async gettingCurrentLocation() {
    if (localStorage.getItem(StorageKeys.LATITUDE)) {

    }
    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition(
        (positions) => {
          this._isGPSEnabled = true;
          this.longitude = positions.coords.longitude;
          this.latitude = positions.coords.latitude;
          // window.localStorage.setItem('lat',this.latitude);
          localStorage.setItem(StorageKeys.LATITUDE, this.latitude);
          localStorage.setItem(StorageKeys.LONGITUDE, this.longitude);
          // window.localStorage.setItem('lng',this.longitude);
          setTimeout(() => {
            this.map.flyTo({
              center: [this.longitude, this.latitude],
            });
          }, 0);
          this.buildMap(this._isGPSEnabled);
        },
        (error: GeolocationPositionError) => {
          this._isGPSEnabled = false;
          if (!this._isGPSEnabled && localStorage.getItem(StorageKeys.LATITUDE)) {
            this._isGPSEnabled = true
            let longitude = localStorage.getItem(StorageKeys.LONGITUDE);
            let latitude = localStorage.getItem(StorageKeys.LATITUDE);
            this.ManualSelection(this._isGPSEnabled, latitude, longitude);
            localStorage.setItem(StorageKeys.dialCodeDropdown, 'true');
          }
          else {
            localStorage.setItem(StorageKeys.dialCodeDropdown, 'false');
            switch (error.code) {
              case error.PERMISSION_DENIED:
                // console.error('User denied the request for Geolocation.');
                this.rejectedScenario(this._isGPSEnabled);
                this.rejected = true;
                this.loaderSection = false;
                break;
              case error.POSITION_UNAVAILABLE:
                // console.error('Location information is unavailable.');
                this.rejectedScenario(this._isGPSEnabled);
                this.rejected = true;
                this.loaderSection = false;
                break;
              case error.TIMEOUT:
                // console.error('The request to get user location timed out.');
                this.rejectedScenario(this._isGPSEnabled);
                this.rejected = true;
                this.loaderSection = false;
                break;
              default:
                // console.error('An unknown error occurred.');
                this.rejectedScenario(this._isGPSEnabled);
                this.rejected = true;
                this.loaderSection = false;
                break;
            }
          }
        });
    }
    else {
      localStorage.setItem(StorageKeys.dialCodeDropdown, 'false');
      this.rejectedScenario(this._isGPSEnabled);
      this.loaderSection = false;
      this.showAddress = ''
      // console.log('Geolocation is not supported by this browser.');
    }
  }

  ManualSelection(geoLocation, lat, lng) {
    this.longitude = lng
    this.latitude = lat;
    // this._dbService.setLat(this.latitude);
    // this._dbService.setLng(this.longitude);
    setTimeout(() => {
      this.map.flyTo({
        center: [this.longitude, this.latitude],
      });
    }, 0);
    this.buildMap(geoLocation);
  }

  rejectedScenario(geoLocation) {
    this.longitude = 78.4772
    this.latitude = 17.4065;
    // this._dbService.setLat(this.latitude);
    // this._dbService.setLng(this.longitude);
    setTimeout(() => {
      this.map.flyTo({
        center: [this.longitude, this.latitude],
      });
    }, 0);
    this.buildMap(geoLocation);
  }
  isAtStart = true;
  start = {
    center: [80, 36],
    zoom: 1,
    pitch: 0,
    bearing: 0,
  };
  end = {
    center: [8.11862, 46.58842],
    zoom: 12.5,
    bearing: 130,
    pitch: 75,
  };

  locateMe() {
    navigator.permissions.query({ name: 'geolocation' }).then(function (results) {
      if (results.state === 'denied') {

      }
    })
  }

  buildMap(geoLocation) {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: 12,
      pitch: 0,
      bearing: 0,
      // center: [this.longitude, this.latitude],
      accessToken: environment.mapbox.accessToken,
    });
    const target = this._isGPSEnabled ? this.end : this.start;
    this._isGPSEnabled = !this._isGPSEnabled;
    this.map.flyTo({
      ...target, // Fly to the selected target
      duration: 1200, // Animate over 12 seconds
      essential: true,
    });
    this.addMarker([this.longitude, this.latitude]);
    // new mapboxgl.Marker({ color: '#0496ff' })
    //   .setLngLat([this.longitude, this.latitude])
    //   .addTo(this.map);
    // const geocoder = new MapboxGeocoder({
    //   accessToken: environment.mapbox.accessToken,
    //   mapboxgl: mapboxgl,
    //   zoom: 20, // Specify the zoom level for geocoding results
    // });
    // this.map.addControl(geocoder);

    //add marker on click
    this._mapservice.getAddress(this.latitude, this.longitude).subscribe(
      (res: any) => {
        ////console.log(res.features[0].text, res.features[1].text.split(' '));
        // console.log(res);
        if (geoLocation) {
          this.showAddress = `${res.features[0].text}, ${res.features[1].text}`;
        }
        else {
          this.showAddress = ''
        }
        // window.localStorage.setItem('locality',res.features[0].text);
        window.localStorage.setItem(StorageKeys.ADDRESS, res.features[0].place_name);
        localStorage.setItem(StorageKeys.LOCALITY, res.features[0].text);
        localStorage.setItem(StorageKeys.STATE, res.features[1].text);
        localStorage.setItem(StorageKeys.SHORT_CODE, res.features[2].properties.short_code);
        this.getCountryCode();
        res?.results?.forEach((location: any) => {
          if (location.types[0] === 'country') {
            this.selectedLocation = {
              short_code: location.address_components[0].short_name
            }
            window.localStorage.setItem('count',location.address_components[0].long_name);
          }
          if (location.types[0] === 'locality') {
            localStorage.setItem(StorageKeys.LOCALITY, location.address_components[0].long_name);
          }
          if (location.types[0] === 'administrative_area_level_1') {
            localStorage.setItem(StorageKeys.STATE, location.address_components[0].long_name);
          }
          if(location.types[0] === 'street_address'){
            window.localStorage.setItem('address',`${location.address_components[0].long_name},${location.address_components[1].long_name}`)
          }
          if(location.types[0]==='postal_code'){
            window.localStorage.setItem('postalCode',location.address_components[0].long_name)
          }
        });
        // window.localStorage.setItem('state',res.features[1].text);
      },
      (err) => {
        ////console.log(err);
      }
    );
    this.loaderSection = false
    // const geocoder = new MapboxGeocoder({
    //   accessToken:environment.mapbox.accessToken
    // });
  }
  addMarker(lngLat: [number, number]) {
    if (this.currentMarker) {
      this.currentMarker.remove();
    }
    this.currentMarker = new mapboxgl.Marker({ color: '#0496ff' })
      .setLngLat(lngLat)
      .addTo(this.map);
  }

  getCountryCode() {
    this._businessService.getAllCountries().subscribe(
      (res: any) => {

        let countryCodes = res.data?.data ?? [];
        const shortCode = localStorage.getItem(StorageKeys.SHORT_CODE);
        let shortCodeIndex = -1;

        if (shortCode) {
          shortCodeIndex = countryCodes.findIndex((c: any) => c.shortCode?.toLowerCase() === shortCode.toLowerCase())
        } else {
          shortCodeIndex = countryCodes.findIndex((c: any) => c.shortCode?.toLowerCase() === 'in');
        }

        if(shortCodeIndex > -1){
          localStorage.setItem("countryId",countryCodes[shortCodeIndex].id)
          localStorage.setItem("countryCode",countryCodes[shortCodeIndex].shortCode)
        }
      },
      (err) => {
        this.snackBar.open(
          `${err.error.message}`,
          'Close',
          {
            duration: 1500
          }
        )
      }
    )
  }
  flyTo(data: GeoJson) {
    this.map.flyTo({
      center: data.geometry.coordinates,
    });
  }

  getAllIndustries() {
    if(this.websiteType === 'STATIC'){
      this.getAllStaticIndustries();
    } else{
      this.getAllEcommerceIndustries();
    }
  }

  getAllStaticIndustries(){
    this.webService.getSubIndustry(0, 1000).subscribe(
      (res: any) => {
        this.industries = (res.data || []).map((data: any) => ({
          name: data.parent.name + ' - ' + data.name,
          industryId: data.parent.id,
          subIndustryId: data.id,
          industryName: data.parent.name,
          subIndustryName: data.name,
        }));
        this.filteredIndustries = this.industrySelectControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterIndustries(value))
        );
      },
      (err) => {
        this.snackBar.open(`${err.error.message}`, 'Close');
      }
    );
  }

  private _filterIndustries(value: any) {
    const filterValue = value.toLowerCase();

    return this.industries.filter((option: any) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  getAllEcommerceIndustries(){
    this.ecommerceService.getAllIndustries().subscribe((res:any) => {
        this.ecommerceIndustries = res.data;

        this.filteredEcommerceIndustries = this.ecommerceIndustrySelectControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterEcommerceIndustries(value))
        );
    })
  }

private _filterEcommerceIndustries(value: any) {
    const filterValue = value.toLowerCase();

    return this.ecommerceIndustries.filter((option: any) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }


  x: any = 3;
  suggestions: any = [];

  seeMore(ev: any) {
    this.suggestions = [];
    let n = this.industries.length;
    this.x = this.x % n;
    let first_x_elements = this.industries.slice(0, this.x);
    let remaining_elements = this.industries.slice(this.x, n);
    this.industries = [...remaining_elements, ...first_x_elements];
    this.industries.forEach((element: any, i: any) => {
      if (i < 3) {
        this.suggestions.push(element);
      }
    });
  }
  goBack() {
    this.seeSuggestionsScreen = false;
    this.suggestions = [];
  }
  goTobName() {
    this.generateLead()
    if (this.matOptionSelected) {
    }
    else {
      this.industries.forEach(element => {
        if (element.subIndustryName.toLowerCase() === 'others') {
          this.selectedIndustry.name = element.name;
          this.selectedIndustry.industryId = element.industryId;
          this.selectedIndustry.industryName = element.industryName;
          this.selectedIndustry.subIndustryId = element.subIndustryId;
          this.selectedIndustry.subIndustryName = element.subIndustryName;
        }
      });
    }
    localStorage.setItem(StorageKeys.SUBINDUSTRYID, this.selectedIndustry.subIndustryId)
    localStorage.setItem(StorageKeys.INDUSTRY_DETAILS, JSON.stringify(this.selectedIndustry));
    this.router.navigate(['/business-name']);
    this.matOptionSelected = false;
  }

  generateLead(){
    this.webService.updateLead(this.selectedIndustry.subIndustryId, this.selectedIndustry.subIndustryName, this.leadId).subscribe((res: any) => {})
  }

  matOptionSelected: boolean = false;
  selectBusinessType(ev: Industry) {
    // this.openPopUp();
    this.selectedIndustry.name = ev.name;
    this.selectedIndustry.industryId = ev.industryId;
    this.selectedIndustry.industryName = ev.industryName;
    this.selectedIndustry.subIndustryId = ev.subIndustryId;
    this.selectedIndustry.subIndustryName = ev.subIndustryName;
    this.matOptionSelected = true;
  }

  //mapbox locations

  changeLocationbox: boolean = false;
  changeLocation() {
    this.changeLocationbox = !this.changeLocationbox;
  }
  close() {
    this.changeLocationbox = false;
  }
  searchLocation = '';
  selectedLocation: any;
  search() {
    this._mapservice.searchAddress(this.searchLocation).subscribe(
      (res: any) => {
        let locations: any = [];
        if (res.features.length > 0) {
          // res.features.forEach((location: any) => {
          //   locations.push({
          //     displayName:
          //       location?.place_name.split(', ')[0] +
          //       ',' +
          //       location?.context[0]?.text +
          //       ',' +
          //       location?.context[2]?.text,
          //     latlng: location?.center,
          //     address:location?.place_name
          //   });
          // });
          res.features.forEach((feature) => {
            feature.context.forEach(context => {
              // let searchedLocality = feature.place_name;
              if (context.id.includes('country')) {
                locations.push({
                  displayName: feature.place_name + ', ' + context.text,
                  latlng: feature.center,
                  short_code: context.short_code
                })
              }
            });
          });
          this.locations = locations;
          this.searchResults = this.searchBox.valueChanges.pipe(
            startWith(''),
            map((value) => this._searchFilter(value))
          );
        }
        // ////console.log(this.locations);
      },
      (err) => {
        ////console.log(err);
      }
    );
  }
  private _searchFilter(value: any) {
    const filterValue = value.toLowerCase();

    return this.locations.filter((option: any) =>
      option.displayName.toLowerCase().includes(filterValue)
    );
  }

  selectedLocations(option: any) {
    this.selectedLocation = option;
    window.localStorage.setItem('address', option.address)
    this.addMarker(option.latlng);

   this.map.flyTo({
    center: option.latlng,
    zoom: 10
  });
  }

  saveLocation() {
    console.log(this.selectedLocation)
    this.changeLocationbox = false;
    let splitedLocation = this.selectedLocation.displayName.split(',');
    this.showAddress = splitedLocation[0] + ',' + splitedLocation[1];
    this.map.flyTo({
      center: [
        this.selectedLocation.latlng[0],
        this.selectedLocation.latlng[1],
      ],
    });
    // new mapboxgl.Marker({ color: '#0496ff' })
    //   .setLngLat([
    //     this.selectedLocation.latlng[0],
    //     this.selectedLocation.latlng[1],
    //   ])
    //   .addTo(this.map);

    localStorage.setItem(StorageKeys.LOCALITY, splitedLocation[0]);
    localStorage.setItem(StorageKeys.STATE, splitedLocation[1]);
    localStorage.setItem(StorageKeys.LATITUDE, this.selectedLocation.latlng[1]);
    localStorage.setItem(StorageKeys.LONGITUDE, this.selectedLocation.latlng[0]);
  }

  leadId: any;
  openPopUp(){
    if(window.localStorage.getItem('user')){

    }
    else{
        let dialog = this.webService.openDialog(
        GenerateWebsitePopupComponent,
        this.scrWidth > 475 ? '78vh' : '100%',
        this.scrWidth > 475 ? '52%' : '100%',
        {}
        );
        dialog.afterClosed().subscribe((data:any)=>{
          if(data?.leadId) {
            this.leadId = data.leadId;
          }
        })
    }
  }

  selectEcommerceBusiness(ev: any){
    this.selectedIndustry.name = ev.name;
    this.selectedIndustry.industryId = ev.id;
    this.selectedIndustry.industryName = ev.name;
    this.selectedIndustry.industryName = ev.name;
    this.selectedIndustry.subIndustryId = ev.id;
    this.matOptionSelected = true;
  }
}
