<section class="main_section">
    <div class="image_section">
        <img src="./../../../../assets/images/common/paymentfailed.gif" alt="loader">
    </div>
    <div class="success_heading">
        Payment Failed
    </div>
    <div class="payment_sub_text">
        Unfortunately Payment was rejected
    </div>
    <div class="payment_minimal_text">
        Page will be automatically redirect to homepage or click below button
    </div>
    <div class="plan_description">
        <button (click)="done()">Done</button>
    </div>
</section>