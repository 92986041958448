<ng-container *ngIf="screenWidth > 475">
    <section class="main-section">
        <div class="header">
            <div class="header-text">{{data.data.edit ? "Edit Meeting" : "Create Meeting"}}</div>
            <div class="close-image" (click)="cancel()">
                <img src="../../../../../../../../assets/images/close.svg" alt="">
            </div>
        </div>
        <hr class="section-divider">
        <div class="input-field-section">
            <div class="input-box-full">
                <div class="input-label">Title <span class="required">*</span></div>
                <mat-form-field class="full-width custom-mat-form-field" appearance="outline">
                    <input id="input" matInput placeholder="Enter subject" value="" [(ngModel)]="meetDetails.title">
                </mat-form-field>
            </div>

            <div class="input-box">
                <div class="input-label">Meeting Type</div>
                <mat-radio-group aria-label="" [(ngModel)]="meetDetails.meetingType"
                    (ngModelChange)="changeMeetingType()">
                    <div class="radio-btn-box">
                        <div class="content-box-small">
                            <mat-radio-button color="primary" value="PHYSICAL"></mat-radio-button>
                            <span>Physical Meeting</span>
                        </div>
                        <div class="content-box-small">
                            <mat-radio-button color="primary" value="VIRTUAL"></mat-radio-button>
                            <span>Virtual Meeting</span>
                        </div>
                    </div>
                </mat-radio-group>
            </div>
            <div class="input-box" *ngIf="meetDetails.meetingType === 'VIRTUAL'">
                <div class="input-label">Meeting Link <span class="required">*</span></div>
                <mat-form-field class="full-width custom-mat-form-field" appearance="outline">
                    <input matInput [(ngModel)]="meetDetails.meetingLink" placeholder="Enter link">

                    <div class="clipboard" (click)="clipboardCopy()" *ngIf="!clipboard">
                        <mat-icon>file_copy</mat-icon>
                    </div>
                    <div class="clipboard" *ngIf="clipboard">
                        <img src="./../../../assets/images/common/copied_link.svg" alt="" />
                    </div>
                </mat-form-field>
            </div>

            <div class="input-box input-box-meeting" *ngIf="meetDetails.meetingType === 'PHYSICAL'">
                <div class="input-label">Meeting Location</div>
                <mat-form-field class="sfull-width input_card" appearance="outline">
                    <input type="text" placeholder="Search for location" matInput [formControl]="searchBox"
                        [matAutocomplete]="auto2" [(ngModel)]="meetDetails.meetingLocated" (keyup)="search()" />
                    <mat-autocomplete #auto2="matAutocomplete">
                        <mat-option *ngFor="
                            let option of searchResults | async;
                            index as i
                        " [value]="option.displayName" (click)="selectedLocations(option)">
                            {{ option.displayName | titlecase }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>


            <div class="input-box">
                <div class="start-time">
                    <div class="input-label">Start Date & Time <span class="required">*</span></div>
                    <div class="checkbox">
                        <span class="input-label">All day</span>
                        <mat-checkbox [(ngModel)]="allDayCheckbox" (click)="setStartTime()"></mat-checkbox>
                    </div>
                </div>  
                <div class="email-remainder">
                    <mat-form-field class="email-date custom-mat-form-field custom-mat-form-field-size" appearance="outline">
                        <input matInput [matDatepicker]="startDatepicker" placeholder="DD/MM/YYYY"
                            [(ngModel)]="meetDetails.startDateTime" [min]="minDate" (dateChange)="onStartDateChange()">
                        <mat-datepicker-toggle class="custom-mat-datepicker-field" matIconSuffix
                            [for]="startDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker class="custom-mat-datepicker-field" #startDatepicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="email-time custom-mat-form-field custom-mat-form-field-size" appearance="outline">
                        <mat-select id="input" placeholder="HH : MM" [(ngModel)]="callStartTime"
                            [disabled]="allDayCheckbox" (selectionChange)="onStartTimeChange()">
                            <ng-container *ngFor="let item of filteredStartTimeArray">

                                <mat-option [value]="item.viewValue" [disabled]="item.isDisabled">{{item.viewValue}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="input-box">
                <div class="start-time">
                    <div class="input-label">End Date & Time</div>
                </div>
     
                <div class="email-remainder">
                    <mat-form-field class="email-date custom-mat-form-field custom-mat-form-field-size" appearance="outline">
                        <input matInput [matDatepicker]="endDatepicker" placeholder="DD/MM/YYYY"
                            [(ngModel)]="meetDetails.endDateTime" (dateChange)="onEndDateChange()"
                            [min]="meetDetails.startDateTime">
                        <mat-datepicker-toggle class="custom-mat-datepicker-field" matIconSuffix
                            [for]="endDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker class="custom-mat-datepicker-field" #endDatepicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="email-time custom-mat-form-field custom-mat-form-field-size" appearance="outline">
                        <mat-select id="input" placeholder="HH : MM" [(ngModel)]="callEndTime">
                            <ng-container *ngFor="let item of filteredEndTimeArray">
                                <mat-option [value]="item.viewValue" [disabled]="item.isDisabled">{{item.viewValue}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="input-box">
                <div class="input-label">Status</div>
                <mat-form-field class="full-width custom-mat-form-field" appearance="outline">
                    <mat-select id="input" placeholder="" [(ngModel)]="meetDetails.meetingStatus">
                        <ng-container *ngFor="let item of status">
                            <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="input-box">
                <div class="input-label">Reminder</div>
                <mat-form-field class="full-width custom-mat-form-field" appearance="outline">
                    <mat-select id="input" placeholder="" [(ngModel)]="meetDetails.remainder">
                        <ng-container *ngFor="let item of reminder">
                            <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="input-box">
                <div class="input-label">Participants <span class="required">*</span></div>

                <div class="add-participant">
                    <mat-form-field class="full-width custom-mat-form-field" appearance="outline"
                        (click)="addParticipants()">
                        <input matInput placeholder="Enter name" readonly>
                        <div class="add-participant-btn">
                            + Add
                        </div>
                    </mat-form-field>
                    <div class="total-leadname">
                        <ng-container *ngFor="let items of meetDetails.participantsList ; let i=index">
                            <div class="image_section">
                                <div class="_leadName">
                                    {{items.leadName}}
                                </div>
                                <div class="image_close" (click)="closeparticipant(i, true)">
                                    <img src="../../../../../../../../assets/images/close.svg" alt="">
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let email of meetDetails.emailData ; let i=index">
                            <div class="image_section">
                                <div class="_leadName">
                                    {{ email }}
                                </div>
                                <div class="image_close" (click)="closeparticipant(i, false)">
                                    <img src="../../../../../../../../assets/images/close.svg" alt="">
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

            </div>

            <div class="input-box">
                <div class="input-label">Participants Reminder</div>
                <mat-form-field class="full-width custom-mat-form-field" appearance="outline">
                    <mat-select id="input" placeholder="" [(ngModel)]="meetDetails.participantsRemainder">
                        <ng-container *ngFor="let item of reminder">
                            <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="input-box" *ngIf="data.data.type == 'ALL_MEETINGS'">
                <div class="input-label">Assign Staff <span class="required">*</span></div>
                <div class="lead-section">

                    <mat-form-field class="select custom-mat-form-field" appearance="outline">
                        <mat-select id="input" placeholder="" [(ngModel)]="participantType" (ngModelChange)="getdata()">
                            <ng-container *ngFor="let item of type">
                                <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="add-email custom-mat-form-field" appearance="outline">
                        <input type="text" placeholder="Search by name" matInput [(ngModel)]="selectedContact"
                            [formControl]="myControl" [matAutocomplete]="auto" (ngModelChange)="getdata()">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptions | async"
                                [value]="option.personalInfo.name" (click)="contactSelected(option)">
                                {{option.personalInfo.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>

            <div class="description">
                <div class="descriptionTitle">Description</div>
                <textarea name="" id="" cols="30" rows="5" [(ngModel)]="meetDetails.description"></textarea>
            </div>
        </div>
        <!-- <hr class="section-divider"> -->
        <div class="button-container">
            <div class="cancel-btn" (click)="cancel()">
                Cancel
            </div>
            <ng-container *ngIf="!addLoader; else addLoading">
                <button class="create-button"
                    [disabled]="!meetDetails.title || !meetDetails.startDateTime  || !(callStartTime || allDayCheckbox) || (!meetDetails.participantsList.length && !meetDetails.emailData.length)"
                    *ngIf="!data.data.edit">
                    <span *ngIf="createLoader"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
                    <span *ngIf="!createLoader" (click)="createMeet()">Create Meeting</span>
                </button>

                <button class="create-button" (click)="editMeet()"
                    [disabled]="!meetDetails.title || !meetDetails.startDateTime  || !(callStartTime || allDayCheckbox) || (!meetDetails.participantsList.length && !meetDetails.emailData.length)"
                    *ngIf="data.data.edit">
                    Update Meeting
                </button>
            </ng-container>
        </div>
    </section>
</ng-container>


<!-- For mobile screen  -->
<ng-container *ngIf="screenWidth < 475">
    <section class="main-section">
        <div class="header">
            <div class="header-text">{{data.data.edit ? "Edit Meeting" : "Create Meeting"}}</div>
            <div class="close-image" (click)="cancel()">
                <img src="../../../../../../../../assets/images/close.svg" alt="">
            </div>
        </div>
        <hr class="section-divider">
        <div class="input-field-section">
            <div class="input-box-full">
                <div class="input-label">Title <span class="required">*</span></div>
                <mat-form-field class="full-width" appearance="outline">
                    <input matInput placeholder="Enter subject" value="" [(ngModel)]="meetDetails.title">
                </mat-form-field>
            </div>
            <div class="input-box">
                <div class="input-label">Meeting Type</div>
                <mat-radio-group aria-label="" [(ngModel)]="meetDetails.meetingType">
                    <div class="radio-btn-box">
                        <div class="content-box-small">
                            <mat-radio-button color="primary" value="PHYSICAL"></mat-radio-button>
                            <span>Physical Meeting</span>
                        </div>
                        <div class="content-box-small">
                            <mat-radio-button color="primary" value="VIRTUAL"></mat-radio-button>
                            <span>Virtual Meeting</span>
                        </div>
                    </div>
                </mat-radio-group>
            </div>
            <div class="input-box input-box-meeting" *ngIf="meetDetails.meetingType === 'PHYSICAL'">
                <div class="input-label">Meeting Location <span class="required">*</span></div>
                <mat-form-field class="sfull-width input_card" appearance="outline">
                    <input type="text" placeholder="Search for location" matInput [formControl]="searchBox"
                        [matAutocomplete]="auto2" [(ngModel)]="meetDetails.meetingLocated" (keyup)="search()" />
                    <mat-autocomplete #auto2="matAutocomplete">
                        <mat-option *ngFor="
                            let option of searchResults | async;
                            index as i
                        " [value]="option.displayName" (click)="selectedLocations(option)">
                            {{ option.displayName | titlecase }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="input-box" *ngIf="meetDetails.meetingType === 'VIRTUAL'">
                <div class="input-label">Meeting Link <span class="required">*</span></div>
                <mat-form-field class="full-width" appearance="outline">
                    <input matInput [(ngModel)]="meetDetails.meetingLink" placeholder="Enter link">
                </mat-form-field>
            </div>

            <div class="input-box">
                <div class="start-time">
                    <div class="input-label">Start Date & Time <span class="required">*</span></div>
                    <div class="checkbox">
                        <span class="input-label">All day</span>
                        <mat-checkbox [(ngModel)]="allDayCheckbox" (click)="setStartTime()"></mat-checkbox>
                    </div>
                </div>

                <!-- <div class="email-remainder">
                    <mat-form-field class="email-date custom-mat-form-field-datepicker" appearance="outline">
                        <input matInput [matDatepicker]="enddatepicker" placeholder="DD/MM/YYYY"
                            [(ngModel)]="meetDetails.startDateTime" [min]="minDate" (dateChange)="onStartDateChange()">
                        <mat-datepicker-toggle matIconSuffix [for]="enddatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #enddatepicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="email-time custom-mat-form-field-datepicker" appearance="outline">
                        <mat-select placeholder="HH : MM" [(ngModel)]="callStartTime" [disabled]="allDayCheckbox">
                            <ng-container *ngFor="let item of filteredTimeArray">
                                <mat-option [value]="item.viewValue"
                                    (click)="setStartTime()">{{item.viewValue}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div> -->
            <div class="email-remainder">
                <mat-form-field class="email-date custom-mat-form-field custom-mat-form-field-size"
                    appearance="outline">
                    <input matInput [matDatepicker]="enddatepicker" placeholder="DD/MM/YYYY"
                        [(ngModel)]="meetDetails.startDateTime" [min]="minDate" (dateChange)="onStartDateChange()">
                    <mat-datepicker-toggle class="custom-mat-datepicker-field" matIconSuffix
                        [for]="enddatepicker"></mat-datepicker-toggle>
                    <mat-datepicker class="custom-mat-datepicker-field" #enddatepicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="email-time custom-mat-form-field custom-mat-form-field-size"
                    appearance="outline">
                    <mat-select id="input" placeholder="HH : MM" [(ngModel)]="callStartTime"
                        [disabled]="allDayCheckbox">
                        <ng-container *ngFor="let item of filteredTimeArray">
                            <mat-option [value]="item.viewValue" [disabled]="item.isDisabledForEndTime"
                                (click)="setStartTime()">{{item.viewValue}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

            <div class="input-box">
                <div class="input-label">End Date & Time </div>
                <div class="email-remainder">
                    <mat-form-field class="email-date custom-mat-form-field-datepicker" appearance="outline">
                        <input matInput [matDatepicker]="datepicker" placeholder="DD/MM/YYYY"
                            [(ngModel)]="meetDetails.endDateTime" (ngModelChange)="filterEndTime() "
                            [min]="meetDetails.startDateTime" (dateChange)="onStartDateChange()">
                        <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                        <mat-datepicker #datepicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="email-time custom-mat-form-field-datepicker"
                        appearance="outline">
                        <mat-select id="input" placeholder="HH : MM" [(ngModel)]="callEndTime">
                            <ng-container *ngFor="let item of filteredTimeArray">
                                <mat-option [value]="item.viewValue" (click)="setEndTime()"
                                    [disabled]="item.isDisabledForEndTime">{{item.viewValue}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="input-box">
                <div class="input-label">Status</div>
                <mat-form-field class="full-width" appearance="outline">
                    <mat-select placeholder="" [(ngModel)]="meetDetails.meetingStatus">
                        <ng-container *ngFor="let item of status">
                            <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="input-box">
                <div class="input-label">Reminder</div>
                <mat-form-field class="full-width" appearance="outline">
                    <mat-select placeholder="" [(ngModel)]="meetDetails.remainder">
                        <ng-container *ngFor="let item of reminder">
                            <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="input-box">
                <div class="input-label">Participants <span class="required">*</span> </div>

                <div class="add-participant">
                    <mat-form-field class="full-width" appearance="outline">
                        <input matInput placeholder="Enter name">
                        <div class="add-participant-btn" (click)="addParticipants()">
                            + Add
                        </div>
                    </mat-form-field>
                    <div class="total-leadname">
                        <ng-container *ngFor="let items of meetDetails.participantsList ; let i=index">
                            <div class="image_section">
                                <div class="_leadName">
                                    {{items.leadName}}
                                </div>
                                <div class="image_close" (click)="closeparticipant(i, true)">
                                    <img src="../../../../../../../../assets/images/close.svg" alt="">
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let email of meetDetails.emailData ; let i=index">
                            <div class="image_section">
                                <div class="_leadName">
                                    {{ email }}
                                </div>
                                <div class="image_close" (click)="closeparticipant(i, false)">
                                    <img src="../../../../../../../../assets/images/close.svg" alt="">
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

            </div>

            <div class="input-box">
                <div class="input-label">Participants Reminder</div>
                <mat-form-field class="full-width" appearance="outline">
                    <mat-select placeholder="" [(ngModel)]="meetDetails.participantsRemainder">
                        <ng-container *ngFor="let item of reminder">
                            <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="input-box" *ngIf="data.data.type == 'ALL_MEETINGS'">
                <div class="input-label">Select <span class="required">*</span></div>
                <div class="lead-section">
                    <mat-form-field class="select" appearance="outline">
                        <mat-select placeholder="" [(ngModel)]="participantType" (ngModelChange)="getdata()">
                            <ng-container *ngFor="let item of type">
                                <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="add-email" appearance="outline">
                        <input type="text" placeholder="Search by name" matInput [(ngModel)]="selectedContact"
                            [formControl]="myControl" [matAutocomplete]="auto" (keyup)="getdata()">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptions | async"
                                [value]="option.personalInfo.name" (click)="contactSelected(option)">
                                {{option.personalInfo.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div class="input-box-full">
                <div class="input-label">Description</div>
                <textarea class="text-box" placeholder="Add description" cols="100" rows="4"
                    [(ngModel)]="meetDetails. description"></textarea>
            </div>
        </div>
        <!-- <hr class="section-divider"> -->
        <div class="button-container">
            <div class="cancel-btn" (click)="cancel()">
                Cancel
            </div>
            <ng-container *ngIf="!addLoader; else addLoading">
                <button class="create-btn" (click)="createMeet()"
                    [disabled]="!meetDetails.title || !meetDetails.meetingLocated || !meetDetails.startDateTime  || !callStartTime || (data.data.type == 'ALL_MEETINGS'? !participantType : false ) || (!meetDetails.participantsList.length && !meetDetails.emailData.length)"
                    *ngIf="!data.data.edit">
                    <span *ngIf="createLoader"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
                    <span *ngIf="!createLoader">Create Meeting</span>
                </button>
                <button class="create-btn" (click)="editMeet()"
                    [disabled]="!meetDetails.title || !meetDetails.meetingLocated || !meetDetails.startDateTime  || !callStartTime || (!meetDetails.participantsList.length && !meetDetails.emailData.length)"
                    *ngIf="data.data.edit">
                    <span *ngIf="createLoader"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
                    <span *ngIf="!createLoader">Update Meeting</span>
                </button>
            </ng-container>
        </div>
    </section>
</ng-container>


<ng-template #addLoading>
    <button class="continue">
        <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
    </button>
</ng-template>
